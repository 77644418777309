/**
 * PS_AAI_5.0 import necessary statements
 */
 
import { useState, useEffect, useRef } from "react";
import { BlobServiceClient, ContentSettings, logger } from '@azure/storage-blob';
import { kbInsert } from "../AI Service/Api";
import Loader from "../Other-Components/loader";
import config from "../envVariable.config";
import { Urls } from "../api/Api-Constants";
export function AddKnowledgeBase({ setAskAsamiKbRetriever, editKburl, initialData, knowledgeData_func, promptName, index_func, collectionName, indexName, promptID,collection_func ,knowledgeUrl_func}) {
    
    /**
     * PS_AAI_5.1 Initialize the state variable
     */
 
    const [files, setFiles] = useState([]);
    const [urls, setUrls] = useState([]);
    const [url, setUrl] = useState('');
    const [error, setError] = useState("")
    const [kbFiles, setKbFiles] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('');
    const [editFiles,setEditFiles]=useState([])
    const[editUrls,setEditUrls]=useState([])
    const [errorVal, setErrorVal]=useState(false)
    const [cancel, setCancel] = useState(false)
    const [newFiles, setNewFiles] = useState([])
    const [newUrls, setNewUrls] = useState([])
    const [urlCancel, setUrlCancel] = useState(false)
    const fileInputRef = useRef(null)
 
 /**
     * PS_AAI_5.3 Initialize the useeffect
     */
 useEffect(() => {
    // console.log(initialData,"got the props");
    // console.log(editKburl, "hello")
    if (editKburl && initialData && Array.isArray(initialData)) {
        const editUrlArray = editKburl.split(',');
        const files = [];
        const urls = [];
 
        // Iterate over both arrays simultaneously
        for (let i = 0; i < initialData.length; i++) {
            const item = initialData[i];
            if (typeof item === 'object' && item.name) {
                // If the item in initialData is a file, treat the corresponding URL in editKburl as a blob URL
                files.push({ name: item.name });
                editFiles.push(editUrlArray[i]);
            } else if (typeof item === 'string' && item.startsWith('http')) {
                // If the item in initialData is a URL, treat the corresponding URL in editKburl as a regular URL
                urls.push(item);
                editUrls.push(editUrlArray[i])
            }
        }
 
        // Update the state with the separated URLs
        setFiles(files);
        setUrls(urls);
    }
}, [editKburl, initialData]);
 
 
   
 
 
    // console.log("hi).log(urls, files,editUrls,editFiles ,"HJKL");
    // console.log("hi).log(urls,"urls");
    // console.log("hi).log(files,"files");
    // console.log("hi).log(editUrls,"editUrls");
    // console.log("hi).log(editFiles,"editFiles");
    /**
     * PS_AAI_5.6 to PS_AAI_5.7 Set the target file
     * @param {e} event
     */
    const handleFileUpload = (event) => {
        // // 
        ;
        const acceptedExtensions = ['.txt', '.xls', '.xlsx', '.pdf', '.json','.csv'];
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const newFiles = [...event.target.files];
       
        for (const file of newFiles) {
            const extension = file.name.split('.').pop().toLowerCase();
            const fileSizeMB = file.size / (1024 * 1024);
   
            if (fileSizeMB > 10) {
                setErrorMessage('File size exceeds the maximum limit of 10 MB');
                setErrorVal(true)
                return;
            }
            else{
                if(!imageExtensions.includes(extension)){
                    setFiles([...files, file]);
                    setNewFiles([...newFiles, file])
                    setErrorMessage(''); // Clear any previous error message
                    setErrorVal(false)
                }
                else{
                    setErrorMessage('The file cannot not be an image');
                    setErrorVal(true)
                }
            }
        }
 
        // Check each file for its extension
        // for (const file of newFiles) {
        //     const extension = file.name.split('.').pop().toLowerCase();
        //     if (!acceptedExtensions.includes('.' + extension)) {
        //         setErrorMessage('File has an invalid extension');
        //         return; // Stop processing further files
        //     }
        // }
        // if(initialData){
        //     setEditFiles(editFiles.filter((_, i) => i !== index));
        // }
 
        // If all files have valid extensions, add them to the state
        
        
    };
 
    /**
     * PS_AAI_5.8 to PS_AAI_5.9 set the target urls
     */
    const handleUrlAdd = () => {
        ;
        // Regular expression to validate URL format
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
   
        // Validate URL format
        if (!urlRegex.test(url)) {
            setErrorMessage('Invalid URL format');
            setErrorVal(true)
            return;
        }
        
   
        // If the URL is valid, add it to the state
        setUrls([...urls, url]);
        setNewUrls([...newUrls, url]);
        setErrorMessage(''); // Clear any previous error message
        setErrorVal(false)
        setUrl(''); // Clear the URL input field
    };
 
    /***
     * PS_AAI_5.10 to PS_AAI_5.11 Delete the file
     */
    const handleFileDelete = (index) => {
        if(editKburl){
            setEditFiles(editFiles.filter((_, i) => i !== index));
        }
       
        setFiles(files.filter((_, i) => i !== index));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    /***
     * PS_AAI_5.12 to PS_AAI_5.13 Delete the url
     */
    const handleUrlDelete = (index) => {
        if(editKburl){
            setEditUrls(editUrls.filter((_, i) => i !== index));
        }
        setUrls(urls.filter((_, i) => i !== index));
    };
 
 
     /**
     * PS_AAI_5.17 to PS_AAI_5.19 ,Save function will combine both url and files and save to database
     */
 
    const handleSave = async () => {
        const formData = new FormData();
        setAskAsamiKbRetriever(true)
        setIsLoading(false);
        setErrorVal(false)
        setUrl('');
        setCancel(false)
        setUrlCancel(false)
        setNewFiles([])
        setNewUrls([])
        const combinedInputs = [];
        const inputs=[]
        // // ;
        try {
            if(initialData.length!=0){
               
                await Promise.all(files.map(async (file) => {
                    if (file instanceof File) {
                        const base64Data = await readFileAsBase64(file);
                        formData.append('filename', file.name);
                        formData.append('file', file);
                        
                       
                    }
                }));
                combinedInputs.push(...editFiles.map(url => typeof url === 'string' ? url : ''));
                combinedInputs.push(...urls.map(url => typeof url === 'string' ? url : ''));
               
                inputs.push(...files.map(file => ({ name: file.name })));
                inputs.push(...urls.map(url => typeof url === 'string' ? url : ''));
             
                // combinedInputs.push(...editFiles)
 
            }
            else{
           
   
            // Process files asynchronously
            await Promise.all(files.map(async (file) => {
                if (file instanceof File) {
                    const base64Data = await readFileAsBase64(file);
                    formData.append('filename', file.name);
                    formData.append('file', file);
                    inputs.push({
                        name: file.name,
                    });
                }
            }));
   
            // Add URLs directly
            combinedInputs.push(...urls.map(url => typeof url === 'string' ? url : ''));
           
            inputs.push(...urls.map(url => typeof url === 'string' ? url : ''));
        }
 
   
            // Construct the object to send to the backend
            const obj = {
                kbUrl: combinedInputs,
                collection_name: collectionName,
                index_name: indexName,
                promptName: promptName
            };
            formData.append("JsonObj",JSON.stringify(obj))
            // console.log(obj,"heeeeeeeeeeeeeeeeeeeeeeee")
   
            // Send data to the backend asynchronously
            const response = await kbInsert(formData);
            setIsLoading(true);
            // console.log(response,"krrrrrrrrrrr")
            // console.log("hi).log("Response from backend:", response.data);
   
            // Update state or perform any other actions
            collection_func(response.data.collection_name);
            index_func(response.data.index_name);
            knowledgeData_func(inputs);
            knowledgeUrl_func(response.data.blob_urls)
        //    console.log(files,"weeeeeeeeeeeeeee")
            // console.log("hi).log("After setting state:", collection_func, index_func, knowledgeData_func);
        } catch (error) {
            console.error("Error:", error);
            setError(error.message || "An error occurred");
        }
    };
   
   
    // Function to read file as base64
    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
   
            // Define the onload event handler
            reader.onload = () => {
                const base64Data = reader.result.split(",")[1];
                resolve(base64Data);
            };
   
            // Define the onerror event handler
            reader.onerror = (error) => {
                reject(error);
            };
   
            // Read the file as data URL
            reader.readAsDataURL(file);
        });
    };
 
    /**
     * PS_AAI_5.21 to PS_AAI_2.22 will empty the state variables
     */
 
    const handleCancel = () => {
        // setFiles([]);
        // setUrls([]);
        if(cancel){
            setFiles(prevFiles => prevFiles.filter(file => !newFiles.some(newFile => newFile.name === file.name)));
            setCancel(false)
        }
        else{
            setFiles(prevFiles => prevFiles.filter(file => !newFiles.some(newFile => newFile.name === file.name)));
        }
        // console.log(fileInputRef.current,"fileeeeeeeeeeeeeeeeeeeeeeeee")
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setErrorVal(false)
        initialData=[]
        editKburl=[]
    };

   
    const handleUrlCancel= () => {
        // setFiles([]);
        // setUrls([]);
        // console.log(urlCancel,"content..........")
        if(urlCancel){
            setUrls(prevUrls => prevUrls.filter(url => !newUrls.some(newUrl => newUrl === url)));
            setUrlCancel(false)
        }
        else{
            setUrls(prevUrls => prevUrls.filter(url => !newUrls.some(newUrl => newUrl === url)));
        }
        setErrorVal(false)
        initialData=[]
        editKburl=[]
        setUrl('')
    };
    return (
        <>
            {!isLoading ? <Loader isLoading={isLoading} /> :
                <div className="container-fluid temp-fix-kb">
                    {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Helpus-popup">
                    Help Us
                </button> */}
                    {/*Helpus modal starts here*/}
                    
                    <div className="modal fade " id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered custom-codesnippet-width cust-kb-popup border-0" style={{zIndex:'10'}}>
                            <div className="modal-content p-3 border-0 bg-color-4 pt-2">
                                <div className="modal-header border-0 pt-2 pb-2 ps-2">
                                    <h5 className="modal-title text-color-1 font-22 font-bold">Add Knowledge Base</h5>
                                    <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}/>
                                </div>
                                <div className="modal-body pt-2 px-0 temp-fix-kb">
                                    <div className="d-inline-flex">
                                        {/* tabs starts here */}
                                        <ul className="nav nav-pills font-14 font-semibold custom-pills color-white-bg rounded-2" id="newtab" role="tablist">
                                            <li className=" m-2" role="presentation">
                                                <button className="cust-btn-tab nav-link active d-flex px-3" id="file-tab" data-bs-toggle="pill" data-bs-target="#file" type="button" role="tab" aria-controls="file" aria-selected="true">Files</button>
                                            </li>
                                            <li className=" m-2" role="presentation">
                                                <button className="cust-btn-tab nav-link d-flex px-3" id="url-tab" data-bs-toggle="pill" data-bs-target="#url" type="button" role="tab" aria-controls="url" aria-selected="false">URL</button>
                                            </li>
                                        </ul>
                                        {/* tabs ends here */}
                                    </div>
                                    {/*tab inner content start here*/}
                                    <div className="tab-content mt-3" id="pills-newtab">
                                        {/*files tab starts here */}
                                        <div className="tab-pane fade show active px-3" id="file" role="tabpanel" aria-labelledby="file-tab">
                                            <div className="row">
                                                <div className="col-md-6 custom-border-right ps-0 cursor-pointer">
                                                    <p className="font-15 form-label-color font-semibold ">Upload Knowledge Base Files</p>
                                                    <div className="upload-btn-wrapper-popup">
                                                        <button className="upload-btn-popup font-12 font-medium color-grey-v2 cursor-pointer" onClick={()=>setCancel(true)}>
                                                            <img src="images/Default/popup-upload.svg" alt="upload" className="me-2 mb-3" />
                                                            Drag or Browse to choose file
                                                            <p>Maximum File Size : 10 MB</p>
                                                        </button>
                                                        <input type="file" name="myfile" onChange={handleFileUpload} ref={fileInputRef}/>
                                                     
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="table-responsive rounded-3 stack-table-scroll custom-border-4">
                                                        <table className="table theme-table font-regular font-14 mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="ps-4">Files</th>
                                                                    <th className="text-center">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {files.map((file, index) => (
                                                                    <tr key={index}>
                                                                        <td className="ps-4"><a className="text-decoration-none link-color">{file.name}</a></td>
                                                                        <td className="text-center"><span className="cursor-pointer" onClick={() => handleFileDelete(index)}><img src="images/Default/delete-icons.svg" alt="Delete" title="Delete" /></span></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*files tab ends here */}
                                        {/*URL tab starts here */}
                                        <div className="tab-pane fade " id="url" role="tabpanel" aria-labelledby="url-tab">
                                            <div className="row">
                                                <div className="col-md-6 custom-border-right ps-3">
                                                    <label htmlFor="Enter-Question-6" className=" font-bold text-color-1 mb-3">URL</label>
                                                    <input type="text" className="form-control theme-focus input-field mb-3 bg-transparent text-color-4 custom-border-3 cust-search-input rounded-3" id="Enter-Question-6" placeholder="Enter URL" value={url} onChange={e => setUrl(e.target.value)} />
                                                    <button type="button" className="btn primary-btn color-white font-14 font-semibold theme-focus me-3 px-4 w-100 mb-3" onClick={()=>{handleUrlAdd(); setUrlCancel(true)}}>Add</button>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="table-responsive rounded-3 stack-table-scroll custom-border-4">
                                                        <table className="table theme-table font-regular font-14 mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="ps-4">Files</th>
                                                                    <th className="text-center">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {urls.map((url, index) => (
                                                                    <tr key={index}>
                                                                        <td className="ps-4"><a href={url} className="text-decoration-none link-color">{url}</a></td>
                                                                        <td className="text-center"><span className="cursor-pointer" onClick={() => handleUrlDelete(index)}><img src="images/Default/delete-icons.svg" alt="Delete" title="Delete" /></span></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*URL tab starts here */}
                                    </div>
                                    {/*tab inner content ends here*/}
                                </div>
                                <h6>
                      {errorVal ? (
                        <div className="span-color">
                          {errorMessage}
                        </div>
                      ) : null}
                    </h6>
                                <div className="modal-footer border-0">
                                    <button type="button" className="btn btn-outline-secondary  font-14 px-4 py-2 me-3 font-medium" data-bs-dismiss="modal" onClick={()=>{handleUrlCancel();handleCancel()}}>Cancel</button>
                                    <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4 py-2" data-bs-dismiss="modal" aria-label="Close" onClick={handleSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Helpus modal ends here*/}
                </div>}
        </>
 
    )
}