// PS_MT_01: Import necessary modules
import React, { useState, useEffect } from 'react';
import { getTask , ExistData , similarityCheck ,getTaskConfig} from "../../AI Service/Api";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../Other-Components/loader';
import PopUpComponent from "../Popup";
import { ConfigPopup } from './ConfigPopUp';
import { ClipLoader } from 'react-spinners';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// PS_MT_02 to PS_MT_68 - fetch the Manage task grid and entire functionality associated on that page
export function ManageTask(navOpen) {
  const location = useLocation();
  const history = useHistory()
  const { projectIdFromMP, workItemFromMP , projectName } = location.state || {};

  let projectIdFromLocal = localStorage.getItem('projectIdFromMP');
  let projectNameFromLocal = localStorage.getItem('projectNameFromLocal');
  //(projectName,"asiofhoiag")
  
  //(projectIdFromMP, workItemFromMP, "durrraaaahhh")
  const [task, setTask] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [flag, setFlag] = useState("");
  const [filterAlert, setFilterAlert] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [projectID, setProjectID] = useState(projectIdFromLocal);
  const [searchTaskID, setSearchTaskID] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [endOffset, setEndOffset] = useState(10);
  const [workItemType, setWorkItemType] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscendingTaskID, setIsAscendingTaskID] = useState(true);
  const [isAscendingWorkItem, setIsAscendingWorkItem] = useState(true);
  const [isAscendingAssignedTo, setIsAscendingAssignedTo] = useState(true);
  const [isAscendingStatus, setIsAscendingStatus] = useState(true);
  const [lengthOfRecords, setLengthOfRecords] = useState("");
  const [cancel, setCancel] = useState(false);
  const [showConfigPop, setConfigPopUp] = useState(false)
  const [editScenario, setEditScenario] = useState("CONFIG")
  const [uniqueIdFromMP , setUniqueIdFromMp] = useState();
  const [searchDupTaskID, setSearchDupTaskID] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [taskID, setTaskID] = useState("");
  const [workItem, setWorkItem] = useState("")

  
  const [url,setUrl] = useState()
  const [PAT ,setPAT] = useState()


  // Sam-variables

  const [description, setDescription] = useState("");
  const [functions15, setFunctions15]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSimilarityCheckDone, setIsSimilarityCheckDone] = useState(false)
  const [TaskConfigValue, setTaskConfigValue] = useState([]);
  const [taskIDs, setTaskIDs] = useState([]);
  const [isProjectTimeline, setIsProjectTimeline] = useState(true);
  const [repoName, setRepoName] = useState()
  const [repoUrl , setRepoUrl] = useState()
  const [isLoad,setIsLoad] = useState(false)
  let project_image_from_loc = localStorage.getItem('project_image')
  //(editScenario, "balebaleblabu")
  // PS_MT_05 to PS_MT_22- useEffect will be rendered based on the dependency array
  useEffect(() => {
    fetchTask();
  }, [searchTaskID, workItemType, sortOrder, endOffset, showFilter]);

  useEffect(() => {
    if (taskIDs.length > 0) {
      fetchConfigTasks();
    }
  }, [taskIDs]);
  function convertDate(val, index){
    const date = new Date(val);
    // Get year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
 
    // Combine into desired format
    const formattedDate = `${year}-${month}-${day}`;
 
    console.log(formattedDate);
    if(index=='from'){
      setFromDate(formattedDate);
    }
    else if(index=='to'){
      setToDate(formattedDate)
    }
  }
  const fetchInitialData = async () => {
     
    try {
        const reqBody = {
            projectID: projectIdFromLocal
        }
        let response = await ExistData(reqBody)
        //(response.Responsedata[0],"125")
        setUrl(response.Responsedata[0].project_url)
        setPAT(response.Responsedata[0].PAT)
        setRepoName(response.Responsedata[0].Repo_data[0].Repo_name)
        setRepoUrl(response.Responsedata[0].Repo_data[0].Repo_Url)
        

    }
    catch (error) {
        //(error)
    }
}

//(url , PAT,"palfkn")

  useEffect(() => {
    fetchInitialData()
    setWorkItemType("task")
  },[])

  // PS_MT_06 - To fetch task details by passing the request payload to the getTask API
  const fetchTask = async () => {
    const data = {
      // projectID:projectIdFromMP,
      projectID: projectID,
      taskID: searchTaskID,
      filterStatus: filterStatus,
      fromDate: fromDate,
      toDate: toDate,
      workItemType: "task",
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      offsetValue: endOffset
    };
    console.log("showFilter",showFilter,"cancel",cancel)
    if(showFilter !=true && cancel!=true ){
      setIsLoad(true)
      let response = await getTask(data);
      setIsLoading(true)
      //(response.data, "prasanth123")
      setIsLoad(false)
      if (response.status == 200) {
        setTask(response.data[0].tasks);
        setLengthOfRecords(response.data[0].tasks.length);
        setTotalRecords(response.data[0].totalRecords);
        
        const uniqueProjectIDs = [...new Set(response.data[0].tasks.map(tasks => tasks.taskID))]
        .map(taskID => ({ taskID }));
        setTaskIDs(uniqueProjectIDs);
      }
    } else{
      setCancel(false)
    }
  };

  const fetchConfigTasks = async () => {
    try {
      let data = {
        "tasks": taskIDs
      }

      const response = await getTaskConfig(data);
      setIsLoading(true)
      if (response.status === 200) {
        setTaskConfigValue(response.data[0].tasks.tasks)
        setStatusDropdown(response.data[0].statusDropdownResult);
        
      }
    } catch (error) {
      console.error('Error in taskFetchIsconfig:', error);
    }
  };
  
  const getIsConfigured = (taskID) => {
    const config = TaskConfigValue.find(item => item.taskID === taskID);
    return config ? config.isConfigured : null;
  }
        // PS_MT_34 to PS_MT_36 - To check whether the user chooses atleast any one of the field
  function filterValidation() {
    let flag = 0;

    if (filterStatus != "") {
      flag++;
    }
    if (fromDate != "") {
      flag++;
    }
    if (toDate != "") {
      flag++;
    }

    if (flag != 0) {
      setShowFilter(false);
      setFilterAlert("");
      fetchTask();
    } else {
      setFilterAlert("alert");
    }
  }

  // PS_MT_42 to PS_MT_43 - To make the state variables null when the user clicks cancel in filter to fetch the initial grid
  const cancelFilter = () => {
    setShowFilter(false);
    setFilterStatus("");
    setFromDate("");
    setToDate("");
    setFilterAlert("");
  }

  const handleDescription=async()=>{
    setIsLoad(true)
    let obj={
      "question":description,
      "projectName" : projectNameFromLocal,
      "collectionName": repoName
    }
    setIsLoading(false)
    let response= await similarityCheck(obj);
    //(response)
    if(response.status==200){
      setIsLoading(true)
      setIsLoad(false)
      //(response, "SimilarityCheckResponse")
      let content= response.data.Response
      //(content, "SimilarityCheckContent")
      setFunctions15(content);
      setIsSimilarityCheckDone(true);
      history.push({
        pathname: '/chat',
        state: { functions15: content , isSimilarityCheckDone: true , projectID : projectID , Repo_url : repoUrl , PAT : PAT }
    });
    }
  }
  const currentRoute = location.pathname
  const newChatRoute = (taskID) => {
   setShowPopup(false)

    history.push({
      pathname: '/chat',
      state:{taskID: taskID }
    });
     
    window.location.reload();
  };

  const askAsamiRoute = (taskID, workitem) => {
   // debugger;
    localStorage.setItem('workItemFromMT', workitem)
    localStorage.setItem('collectionNameFromMT', repoName)
    localStorage.setItem('taskIDFromMT', taskID)
    setTaskID(taskID)


    console.log(projectNameFromLocal, projectName, repoName, workitem)

    history.push({
      pathname: '/chat',
      state: {
        startConversation: true,
        hidePrompts: true,
        sendMessage: false,
        fixButton: true,
        yesFixButton:true,
        projectName: projectNameFromLocal,
        tfsCollectionName: repoName, 
        workItem: workitem, 
        isSimilarityCheckDone: true, projectID: projectID, Repo_url: repoUrl, PAT: PAT,
        taskID :taskID
      }
    });
    window.location.reload();
  };

  return (
    <>
      {!isLoading ? (<div >
     
     <Loader isLoading={isLoading} />
   </div>): (
      <>
      
      <div className="container-fluid"> 
        <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
        <div className={navOpen.navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-3 me-lg-4 me-xl-5 col-11`}>
        <div className="row">
          <div className="col-md-12 px-4 pe-lg-0 pe-2 pt-3">
            <h1 className="color-black-v1 font-24 font-bold mt-5 mb-4 d-flex align-items-center">
              <a href="/ManageProject">
                <img
                  src="images-new/back-black-arrow.svg"
                  className="me-3 mb-1 icon-filter"
                  alt="back-arrow"
                  title="Back"
                />
              </a>
      
              <span className='text-color-1'>
              <img src={project_image_from_loc} alt="Profile"
                  className="me-2 mb-1"
                  style={{width:'50px'}}/>
                    {projectNameFromLocal}
              </span>
            </h1>
            <div className="my-4 d-grid d-md-flex justify-content-md-between align-items-center">
              <h2 className="font-bold font-20 mb-0 text-nowrap text-color-1">
                Manage Task ({task.length})
              </h2>
              <div className="table-header d-md-flex align-items-center">
                <div className="p-2 px-3 border border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0 search-bg border-color-10">
                  <span className="flex-nowrap text-nowrap text-color-9">Integrated with</span>
                  <img src="images-new/avalon-v2.svg" alt="icon" className="ms-2" />
                </div>
                {/*search*/}
                <div className="d-flex align-items-center mt-3 mt-md-0">
                  <div className="input-group transparent-grp w-225 me-3 m-0 search-bg">
                    {/* PS_MT_23 to PS_MT_28 - when the user searches the task ID */}
                    <input
                      type="text"
                      className="form-control custom-search-input border-0 bg-transparent focus-none font-14 font-medium pe-0 ps-3 cust-search-input text-color-4"
                      placeholder="Search" onChange={(e) => {
                        setSearchDupTaskID(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setSearchTaskID(e.target.value)
 
                        }
                      }}
                      value={searchDupTaskID}
                    />
                    <button className="btn focus-none bg-transparent border-0" type="button" onClick={(e) => {
                      setSearchTaskID(e.target.value)
                    }}>
                      <img className='filter-icons' src="images-new/input-search.svg" alt="input-search-icon" />
                    </button>
                  </div>
                  <div className="dropdown me-2">
                    <button
                      type="button"
                      className="btn font-16 font-semibold align-items-center d-flex transparent-grp py-2 theme-focus inter-btn search-bg"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setShowFilter(true);
                      }}
                    >
                      <img className='filter-icons' src="images-new/adv-filter.svg" alt="filter-icon" />
                    </button>
                    {/* advanced filter starts here */}
                    <span className=" dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown end-0 bg-color-4" style={{ display: showFilter ? "block" : "none" }}>
                      <span className="d-flex justify-content-between align-items-center mb-3">
                        <span className="font-18  font-semibold text-color-1">Advanced Filter</span>
                        <span className="" onClick={() => {
                          setCancel(true);
                          setShowFilter(false);
                          
                        }}>
                          <img
                            src="images-new/flter-close-icon.svg"
                            alt="flter-close-icon"
                            className="cursor-pointer close-icon-filter"
                          />
                        </span>
                      </span>
                      <span className="row ">
                        <span className="mb-3 d-block col-md-12">
                          <span className="mb-3">
                            <label
                              htmlFor="from"
                              className="form-label label-color font-15 font-semibold text-color-9"
                            >
                              Status
                            </label>
                            <select className="form-select bg-color-4 custom-border-3 rounded-3 text-color-2" id="model-type"
                              value={filterStatus}
                              onChange={(e) => {
                                setFilterStatus(e.target.value);
                              }}
                              onClick={(e)=>e.currentTarget.classList.toggle('open')}>
                              <option selected>Select</option>
                              {statusDropdown.map((value, index) => (

                                <option
                                  value={value.task_status}
                                  // id={value.approvalStatusID}
                                  key={index}
                                >
                                  {value.task_status}
                                </option>
                              ))};
                            </select>
                          </span>
                        </span>
                      </span>
                      <span className="mb-2 d-block">
                        <span className="d-block font-semibold font-16 text-color-9">
                          Date Range
                        </span>
                      </span>
                      <span className="row mb-2">
                        <span className="mb-3 d-block col-md-6">
                        <span className="mb-3">
                                <label htmlFor="from" className="form-label text-color-9 font-15 font-semibold">From</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="from"
                                    selected={fromDate}
                                    onChange={(date) => {
                                      convertDate(date,'from')
                                      setToDate(null);
                                    }}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                   placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('from').focus()}
                                  />
                                </div>
                              </span>
                        </span>
                        <span className="mb-3 d-block col-md-6">
                        <span className="mb-3">
                                <label htmlFor="to" className="form-label text-color-9 font-15 font-semibold">To</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="to"
                                    selected={toDate}
                                    onChange={(date) => convertDate(date,'to')}
                                    minDate={fromDate}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('to').focus()}
                                  />
                                </div>
                              </span>
                        </span>
                      </span>
                      <span className="w-100 d-flex justify-content-end gap-3 mt-3 mb-1">
                        <button className="btn custom-outline-btn font-14 font-semibold px-3 m-0"
                          onClick={() => {
                            cancelFilter();
                          }}>
                          Cancel
                        </button>
                        <button className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white m-0"
                          onClick={() => {
                            setFlag("filter")
                            {
                              filterValidation();
                            }
                          }}>
                          Apply
                        </button>
                      </span>
                      {filterAlert == "alert" ? (
                        <div className="span-color">
                          Select,atleast one filter!
                        </div>
                      ) : null}
                    </span>
                    {/* advanced filter ends here */}
                  </div>
                  <div className="dropdown" style={{position:'relative'}}>
                    <button
                      id="end-point-config"
                      className="btn input-field font-14 story-drpdwn-btn font-regular rounded-3 w-100 d-flex justify-content-between text-color-1 bg-color-12 border-0"
                      type="button"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <span className="issue-color font-medium font-14 d-flex">
                        <img
                          src="images-new/task-icon.svg"
                          alt="Issues"
                          className="me-2"
                        />
                        Task
                      </span>
                      <span className="ps-1">
                        <img
                          src="images-new/drpdwn-arrow.svg"
                          alt="dropdown-icon"
                          className="ms-1"
                        />
                      </span>
                    </button>
                    {isDropdownOpen && (
                      <ul className="dropdown-menu border-0 shadow-sm bg-color-4 py-2 w-100 show " style={{position:'absolute', top: '100%', right: '0%', zIndex: '1000', display: 'block'}}>
                        <li className="font-14 font-medium color-black px-2">
                          <a className="dropdown-item py-2 custom-dropdown-li text-color-1">
                            <img
                              src="images-new/task-icon.svg"
                              alt="Tasks"
                              className="me-2 icon-width"
                            />
                            View by Task
                          </a>
                        </li>
                        <li className="font-14 font-medium color-black px-2">
                          <a className="dropdown-item py-2 custom-dropdown-li text-color-1" onClick={() => {
                            history.push({
                              pathname: "/ManageIssue",
                              state: {
                                projectIdFromMP: projectIdFromLocal,
                              }
                            });
                            sessionStorage.setItem('isModule', 'issues');
                            console.log(sessionStorage.getItem('isModule', 'issues'), 'ooooooo')
                          }}>
                            <img
                              src="images-new/issues-icon.svg"
                              alt="Issues"
                              className="me-2"
                            />
                            View by Issue
                          </a>
                        </li>
                        {/* <li className="font-14 font-medium color-black">
                          <a className="dropdown-item py-2 custom-dropdown-li" onClick={() => {
                            history.push({
                              pathname: "/ManageStory",
                              state: {
                                projectIdFromMP: projectIdFromLocal,
                              }
                            })
                          }}>
                            <img
                              src="images-new/story-icon.svg"
                              alt="Story"
                              className="me-2 icon-width"
                            />
                            View by Story
                          </a>
                        </li> */}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              {/*search*/}
            </div>
            {/*grid starts here*/}
            {isLoad ? ( <div
                                        style={{
                                          textAlign: 'center',
                                          flex: 1,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          marginTop: '20%'
                                        }}
                                      >
                                        <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
                                      </div>):(
            <div className="col-md-12">
              <div className="table-responsive rounded-3 transparent-grp mb-4">
                <table className="table theme-table font-regular font-14 mb-0">
                  <thead>
                    {/* PS_MT_45 to PS_MT_52 - When the user clicks on particular column to sort */}
                    <tr className="grey-color-v5">
                      <th className="ps-5 py-4"
                        onClick={() => {
                          setSortColumn("project_task_key")
                          setIsAscendingTaskID(!isAscendingTaskID);
                          setSortOrder(
                            isAscendingTaskID
                              ? "Desc"
                              : "Asc"
                          );
                        }}>
                        Task ID
                        <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                            isAscendingTaskID
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingTaskID ? "sort-up" : "sort-down"} />
                        </span>
                      </th>

                      <th className="text-start"
                        onClick={() => {
                          setSortColumn("work_item")
                          setIsAscendingWorkItem(!isAscendingWorkItem);
                          setSortOrder(
                            isAscendingWorkItem
                              ? "Desc"
                              : "Asc"
                          );
                        }}>
                        Work Item
                        <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                            isAscendingWorkItem
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingWorkItem ? "sort-up" : "sort-down"} />
                        </span>
                      </th>

                      <th className="text-start"
                         onClick={() => {
                          setSortColumn("date_range")
                          setIsProjectTimeline(!isProjectTimeline);
                          setSortOrder(
                            isProjectTimeline
                              ? "Desc"
                              : "Asc"
                          );
                        }}
                        >
                          Project Timeline
                          <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                                  isProjectTimeline
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isProjectTimeline ? "sort-up" : "sort-down"} />
                          </span>
                        </th>

                      <th className="text-center"
                        onClick={() => {
                          setSortColumn("assigned_to")
                          setIsAscendingAssignedTo(!isAscendingAssignedTo);
                          setSortOrder(
                            isAscendingAssignedTo
                              ? "Desc"
                              : "Asc"
                          );
                        }}>
                        Assigned To
                        <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                            isAscendingAssignedTo
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingAssignedTo ? "sort-up" : "sort-down"} />
                        </span>
                      </th>

                      <th className="text-start"
                        onClick={() => {
                          setSortColumn("Status")
                          setIsAscendingStatus(!isAscendingStatus);
                          setSortOrder(
                            isAscendingStatus
                              ? "Desc"
                              : "Asc"
                          );
                        }}>
                        Status
                        <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                            isAscendingStatus
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingStatus ? "sort-up" : "sort-down"} />
                        </span>
                      </th>

                      <th className="text-center pe-5">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                  {task.length !== 0 ? (
                    (task.map((task, index) => (
                      <React.Fragment key={index}>
                        {index % 2 === 0 ?
                          <tr className="" key={index}>

                            <td className="ps-5 py-4">
                              {getIsConfigured(task.taskID) ? (
                                 <a  className="text-decoration-none link-color"
                                 style={{cursor:'pointer'}}
                                  onClick={() => {
                                  // if (currentRoute === '/ManageTasks') {
                                  //   askAsamiRotue(task.taskID);
                                  // }
                                  setTaskID(task.taskID)
                                  setWorkItem(task.workItem)
                                  setShowPopup(true)
                                }} >
                                  {task.taskID}
                                </a>
                              ) : (
                                <span>{task.taskID}</span>
                              )}
                            </td>

                            <td className="text-start">{task.workItem}</td>
                            <td className="text-start">{task.ProjectTimeline}</td>
                            <td className="text-center">
                            {task.imageUrl ? 
                              <img src={`data:image/jpeg;base64,${task.imageUrl}`} alt="Profile" className="profile-img-sm me-2 " title ={task.AssignedTo}/> :
                              <img src="images-new/default-profile-image.png" alt="Profile" className="profile-img-sm me-2 " title={task.AssignedTo} />
                            }
                            </td>
                            <td className="text-start">
                              {(() => {
                                const status = task.Status.toUpperCase().replace(/[-\s]/g, '');
                                switch (status) {
                                  case 'INPROGRESS'||'IN-PROGRESS'|| "IN PROGRESS":
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot in-progress-bg" />
                                        <span className="in-progress">In Progress</span>
                                      </div>
                                    );
                                  case 'NOTSTARTED'||'NOT STARTED'||'NOT-STARTED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot not-started-bg" />
                                        <span className="not-started">Not Started</span>
                                      </div>
                                    );
                                  case 'COMPLETED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot in-completed-bg" />
                                        <span className="completed">Completed</span>
                                      </div>
                                    );
                                  case 'INDELAYED'||'IN DELAYED' ||'IN-DELAYED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot danger-bg" />
                                        <span className="in-delay">In Delay</span>
                                      </div>
                                    );
                                  default:
                                    return <span>Unknown status: {task.Status}</span>;
                                }
                              })()}
                            </td>
                            <td className="text-center pe-5">
                              <button type="button" className="btn border-0">

                                {getIsConfigured(task.taskID) ? (
                                  <a onClick={() => {
                                    setEditScenario("update")
                                    setConfigPopUp(true)
                                    setUniqueIdFromMp(task.taskID)
                                    localStorage.setItem('uniqueIdFromLocal', task.taskID)
                                  }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/edit-icons.svg"
                                      alt="icon"
                                    />
                                  </a>
                                ) : (
                                  <a onClick={() => {
                                    setEditScenario("config")
                                    setConfigPopUp(true)
                                    setUniqueIdFromMp(task.taskID)
                                    localStorage.setItem('uniqueIdFromLocal',task.taskID)
                                  }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/right-icon.svg"
                                      alt="icon"
                                    />
                                  </a>
                                )}
                              </button>
                            </td>
                          </tr> :
                          <tr key={index}>

                            <td className="ps-5">
                            {getIsConfigured(task.taskID) ? (
                                 <a  className="text-decoration-none link-color"
                                 style={{cursor:'pointer'}}
                                  onClick={() => {
                                  // if (currentRoute === '/ManageTasks') {
                                  //   askAsamiRotue(task.taskID);
                                  // }
                                  setTaskID(task.taskID)
                                  setWorkItem(task.workItem)
                                  setShowPopup(true)
                                }} >
                                  {task.taskID}
                                </a>
                              ) : (
                                <span>{task.taskID}</span>
                              )}
                            </td>

                            <td className="text-start">{task.workItem}</td>
                            <td className="text-start">{task.ProjectTimeline}</td>
                            <td className="text-center">
                            {task.imageUrl ?<img src={`data:image/jpeg;base64,${task.imageUrl}`} alt="Profile" class="profile-img-sm me-2" title ={task.AssignedTo}/>
                        :
                        <img src="images-new/default-profile-image.png" alt="Profile" class="profile-img-sm me-2" title ={task.AssignedTo} />
                        }
                            </td>
                            <td className="text-start">
                              {(() => {
                                const status = task.Status.toUpperCase().replace(/[-\s]/g, '');
                                switch (status) {
                                  case 'INPROGRESS'||'IN-PROGRESS'|| "IN PROGRESS":
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot in-progress-bg" />
                                        <span className="in-progress">In Progress</span>
                                      </div>
                                    );
                                  case 'NOTSTARTED'||'NOT STARTED'||'NOT-STARTED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot not-started-bg" />
                                        <span className="not-started">Not Started</span>
                                      </div>
                                    );
                                  case 'COMPLETED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot in-completed-bg" />
                                        <span className="completed">Completed</span>
                                      </div>
                                    );
                                  case 'INDELAYED'||'IN DELAYED' ||'IN-DELAYED':
                                    return (
                                      <div className='d-flex text-start align-items-center gap-2'>
                                        <span className="status-dot danger-bg" />
                                        <span className="in-delay">In Delay</span>
                                      </div>
                                    );
                                  default:
                                    return <span>Unknown status: {task.Status}</span>;
                                }
                              })()}
                            </td>
                            <td className="text-center pe-5">
                              <button type="button" className="btn border-0">

                              {getIsConfigured(task.taskID) ? (
                                  <a onClick={() => {
                                    setEditScenario("update")
                                    setConfigPopUp(true)
                                    setUniqueIdFromMp(task.taskID)
                                    localStorage.setItem('uniqueIdFromLocal', task.taskID)
                                  }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/edit-icons.svg"
                                      alt="icon"
                                    />
                                  </a>
                                ) : (
                                  <a onClick={() => {
                                    setEditScenario("config")
                                    setConfigPopUp(true)
                                    setUniqueIdFromMp(task.taskID)
                                    localStorage.setItem('uniqueIdFromLocal',task.taskID)
                                  }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/right-icon.svg"
                                      alt="icon"
                                    />
                                  </a>
                                )}
                              </button>
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                   ))) ):(
                    <tr>
                      <td colSpan={6} className="text-center py-5 vh-50">
                        <img
                          src="images-new/no-record-img.svg"
                          alt="no-record-img"
                          className="no-record-img mb-5"
                        />
                        <span className="font-20 font-medium w-100 d-block">
                          Sorry! No task is assigned to you.
                        </span>
                      </td>
                    </tr>

                  )}

                  </tbody>
                </table>
              </div>
              <p className="font-regular font-14 text-color-9 my-3">
                      # of Records :{" "}
                      <span className="font-bold font-14 text-color-1">
                        {" "}
                        {task.length}
                      </span>{" "}
                      out of{" "}
                      <span className="font-bold font-14 text-color-1">
                        {totalRecords}
                      </span>
                    </p>
              <div className="text-center mb-5">
                {totalRecords > endOffset ?
                  <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                    onClick={() => {
                      setEndOffset(prevEndOffset => prevEndOffset + 10);
                    }}
                    hidden={totalRecords < 10 || lengthOfRecords < 10} >
                    <span className="color-gradient">Load More</span>
                  </button> :
                  <div></div>}

              </div>
            </div>)}
            {/*grid starts here*/}
          </div>
          {/*manage request tab*/}
        </div>
        {/*Tab inner content end here*/}
      </div>
      </div>
      </div>
    



      <div className="modal fade" id="issues" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered custom-popup-issues border-1">
          <div className="modal-content p-3 border-0 bg-color-4">
            <div className="modal-header border-0 p-0">
              <h5 className="modal-title text-dark font-22 font-bold text-color-1 ">Add Description</h5>
              <button type="button" className="btn-close close-icon-filter me-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body popup-scrl pt-2">
              <div className="row">
                {/* content */}
                <div className="col-md-12 col-sm-12 mt-4 p-0">
                  <label htmlFor="description" className="form-label label-color font-15 font-semibold text-color-2 mb-2">Description</label>
                  <textarea className="form-control font-14 font-regular border-radius-3 resize-none text-color-4 bg-transparent custom-border-3" value={description} id="description" rows={5} placeholder="Description" defaultValue={""} onChange={(e)=> setDescription(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 gap-3">
              <button type="button" className="btn custom-outline-btn font-14 px-4 pb-2 m-0 font-medium" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0" data-bs-dismiss="modal" onClick={handleDescription}>Save</button>
            </div>
          </div>
        </div>

      </div>
      {showPopup && (
        <PopUpComponent
          taskID={taskID}
          yesFunction={() => {
            debugger
            askAsamiRoute(taskID, workItem);
            sessionStorage.setItem('isModule', 'tasks');
          }}
          noFunction={() => newChatRoute(taskID)}
        />
        )}

      {showConfigPop ? <ConfigPopup projectIdFromMP={projectIdFromLocal} TypeOfData={editScenario} setShowPopUp={setConfigPopUp} typeOfGrid={"task"}  uniqueIdFromMP={localStorage.getItem('uniqueIdFromLocal')} url={url} PAT={PAT} /> : <h1></h1>}
    </>
  )}
</>
  )
}

export default ManageTask