import { useState, useContext, useEffect } from "react";
import MyContext from "./MyContext";
import TechStack from "./TechStack";
import Choices from "./Choices";
import { Text } from "./Text";
import { useHistory } from "react-router-dom";
import { CodeSnippet } from "./CodeSnippet";
import {  MediaUpload } from "./FileStructure";
import { AddFileOrFolder } from "./AddFileOrFolder";
// import Select from "react-select/dist/declarations/src/Select";
import UserContext from "../Auth/useContext";
import axios from "axios";
import { getSessionItem } from "../util-services/encrypt-storage";
import {  insertMessage ,getProjectForPrompt} from "../AI Service/Api";
import DropdownComponent from "./DropdownComponent";
import RadioComponent from "./RadioComponent";
import CheckboxComponent from "./CheckboxComponent";
import Loader from "../Other-Components/loader";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import { LoadBalancer } from "../AI Service/Api";
import Select from 'react-select'
import _ from 'lodash'
let loader = false;
/**
 * PS_CGAI_4.2 to PS_CGAI_4.15 here loop in to the propmtData.promptFields and hceck dor the refcode condition in switch case accoding
 * to the refcode render the components and bind all togthere and show to the user
 * and also pass the object that looped in that time to respective components as props and validationErrors object to show validation
 * @returns the form in popup with respective components binded accordingly
 */
export function PromptFormComponent() {
  const promptData = useContext(MyContext);
  // console.log(promptData, "promptDatapromptData")

  const userDetails = useContext(UserContext);
  const token = getSessionItem("access-token");
  const [userData, setuserData] = useState({});
  const [fieldValues, setFieldValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [fieldsMandatory, setfieldsMandatory] = useState({});
  const [promptFieldJson, setpromptFieldJson] = useState({});
  const [error, setError] = useState("");
  const [stackError, setStackError] = useState("");
  const [codeID, setcodeID] = useState();
  let [loader, setLoader] = useState(false);
  const [techStackID, settechstackID] = useState("");
  const [projects, setProjects] = useState([])
  const [projectError, setProjectError] = useState(false)
  // const [selectedProject, setSelectedProject] = useState(null);

  const [bindChoicesValues, setBindChoicesValues] = useState([]);

  const [selectedProject, setSelectedProject] = useState("")
  const [val, setVal] = useState("")
  const history = useHistory();
  useEffect(() => {
    setuserData(userDetails);
    fetchBindingValues();
  }, [userDetails]);

  // useEffect(() => {
  //   //("Loading Triggered", loader);
  // }, [loader]);
  useEffect(() => {
    getProject()
  }, [])

//   useEffect(()=>{
// console.log(bindChoicesValues, "bindChoicesValuesbindChoicesValues")
//   }, [bindChoicesValues])


  const mappedOptions = projects.map(item => ({
    value: item.Project_name,
    label: item.Project_name
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: 'inherit',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      flexWrap: 'nowrap',
      minWidth: '75%',
      maxWidth: '75%',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      flexShrink: 0,
      backgroundColor: 'var(--select-pill-bg, #e9ecef)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--select-pill-color, #495057)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--select-pill-remove-color, #495057)',
      ':hover': {
        backgroundColor: 'var(--select-pill-remove-hover-bg, #ffcccb)',
        color: 'var(--select-pill-remove-hover-color, #495057)',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    input: (provided) => ({
      ...provided,
      position: 'relative',
      margin: '0',
      padding: '0',
      maxWidth: '2px',
      color: 'var(--select-input-color, currentColor)',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'var(--select-placeholder-color, #6c757d)',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--select-option-focus-bg, #f0f0f0)'
        : 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      ':active': {
        backgroundColor: 'var(--select-option-active-bg, #e0e0e0)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--select-input-color, currentColor)',
    }),
  };

  const DarkcustomStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: '#333',  // Dark theme background color
      borderColor: '#555',
      color: '#fff',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      flexWrap: 'nowrap',
      minWidth: '75%',
      maxWidth: '75%',
      backgroundColor: '#333',  // Dark theme background color
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      color: '#fff',
    }),
    multiValue: (provided) => ({
      ...provided,
      flexShrink: 0,
      backgroundColor: '#444',  // Dark theme background for selected values
      color: '#fff',
    }),
    input: (provided) => ({
      ...provided,
      position: 'relative',
      margin: '0',
      padding: '0',
      maxWidth: '2px',
      color: '#fff',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#aaa',  // Placeholder color for dark theme
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',  // Dark theme background color for the menu
      color: '#fff',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? '#444' : isSelected ? '#555' : '#333',
      color: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
    }),
  };

  const getProject = async () => {
    try {
      const req={
        "search": "",
        "subSearch":"",
        "checkbox":[],
        "status":"",
        "fromDate": "",
        "toDate": "",
        "sortColumn": "",
        "sortOrder": "",
        "offsetValue":"10"
        }
      const response = await getProjectForPrompt(req); // Assuming this is the new API call
      // console.log(response);
   
      if (response && response[0] && response[0].projectDropDown) {
        const projectData = response[0].projectDropDown.map(project => ({
          Project_id: project.project_name, // Using project_name as ID since it's the only available field
          Project_name: project.project_name
        }));
   
        // Add the "Others" option
        projectData.push({
          Project_id: 'Others',
          Project_name: 'Others'
        });
   
        setProjects(projectData);
      } else {
        // console.log("Unexpected response format");
        setProjects([{
          Project_id: 'Others',
          Project_name: 'Others'
        }]);
      }
   
    } catch (error) {
      // console.log("Failed to fetch Project data in Prompt form.", error);
      setProjects([{
        Project_id: 'Others',
        Project_name: 'Others'
      }]);
    }
  };

  // const mappedOptions = projects.map(item => ({
  //   value : item.Project_name,
  //   label: item.Project_name
  // }))
  let flag = 0

  const fetchDataForInput = async (
    inputType,
    promptFieldID,
    inputValueOrder
  ) => {
    switch (inputType) {
      case "TS":
        const tsResult = await getInputTypeAndTechStack({ promptFieldID });
        // console.log(tsResult,"tsResult")
        return tsResult.status === 200
          ? { key: inputValueOrder, value: tsResult.data.techstack }
          : null;
      case "DD":
        const ddResult = await getInputTypeAndTechStack({ promptFieldID });
        // console.log(ddResult,"tsResult1")
        return ddResult.status === 200
          ? { key: inputValueOrder, value: ddResult.data.choices }
          : null;
      case "RB":
        const rbResult = await getInputTypeAndTechStack({ promptFieldID });
        // console.log(rbResult,"tsResult2")
        return rbResult.status === 200
          ? { key: inputValueOrder, value: rbResult.data.choices }
          : null;
      case "CB":
        const cbResult = await getInputTypeAndTechStack({ promptFieldID });
        // console.log(cbResult,"tsResult3")
        return cbResult.status === 200
          ? { key: inputValueOrder, value: cbResult.data.choices }
          : null;
      case "UF":
            const ufResult = await getInputTypeAndTechStack({ promptFieldID });
            // console.log(ufResult,"KKKK")
            return ufResult.status === 200
              ? { key: inputValueOrder, value: ufResult.data.choices }
              : null;
      // ... handle other cases like 'DD', 'RB', etc.
      default:
        return null;
    }
  };

  const fetchBindingValues = async () => {
    if (!promptData?.currentPrompt?.promptFields?.length) return;

    setLoader(true);

    const promises = promptData.currentPrompt.promptFields.map(
      ({ inputTypeRefCode, promptFieldID, inputValueOrder }) =>
        fetchDataForInput(inputTypeRefCode, promptFieldID, inputValueOrder)
    );

    const results = await Promise.all(promises);
    const validResults = results.filter(Boolean); // filter out any null results

    setBindChoicesValues(validResults);

    setLoader(false);
    // loader = false;
  };

  // console.log(bindChoicesValues,"bindchoicesvalues")
  /**
   * PS_CGAI_4.16 to PS_CGAI_4.24 set state the values to respected object fieldvalues,fieldsMandatory,promptFieldJson,codeID
   * @param {the key name for the value} id
   * @param {values from each component} value
   * @param {the order of the input values} inputValueOrder
   * @param {the input is mandatroy or not yest or true} mandatory
   * @param {choosen techstatck's typeofcode ID} typeOfCodeId
   */
  const handleFieldValues = (
    id,
    value,
    inputValueOrder,
    mandatory,
    typeOfCodeId
  ) => {
    // let val=record.labelName
    if (inputValueOrder == "input-2" || id.toLowerCase().includes('functionality')) {
      // console.log(value, "krrrrrrrrrrrrrrrrrrrr")
      setVal(value)

    }
    // if (id == "Chat Name") {
    //   console.log("hiiiiiiiii")
    // }
    // if(flag==1){
    //   console.log(id,"krrrrrrrrrrrrrrrrrrrr")
    // }

    if (
      promptData?.currentPrompt.promptName == "Code Conversion" &&
      (inputValueOrder == "input-4" || inputValueOrder == "input-5") &&
      (fieldValues.hasOwnProperty("input-4") ||
        fieldValues.hasOwnProperty("input-5"))
    ) {
      //("value there");
      if (fieldValues["input-4"] == value || fieldValues["input-5"] == value) {
        setStackError(
          "Please select different Tech-Stack for both Source and Target"
        );
      } else {
        setStackError("");
      }
    }
    setFieldValues((prevValues) => ({
      ...prevValues,
      [`${inputValueOrder}`]: value,
    }));
    setpromptFieldJson((prevValues) => ({
      ...prevValues,
      [`${id}`]: value,
    }));
    setfieldsMandatory((prevValues) => ({
      ...prevValues,
      [`${inputValueOrder}`]: mandatory,
    }));
    const updatedObject = { ...validationErrors };

    if (updatedObject.hasOwnProperty(`${inputValueOrder}`)) {
      delete updatedObject[`${inputValueOrder}`];

      // Update the state with the modified copy
      setValidationErrors(updatedObject);
    }
    if (promptData?.currentPrompt.promptName == "Generate code") {
      setcodeID(typeOfCodeId);
      settechstackID(value);
      if (typeOfCodeId) {
        const updatedObject = { ...fieldValues };
        const updatedFieldsMandatory = { ...fieldsMandatory };

        if (updatedObject.hasOwnProperty(`input-2`)) {
          delete updatedObject[`input-2`];
          delete updatedFieldsMandatory[`input-2`];

          // Update the state with the modified copy
          setFieldValues(updatedObject);

          setfieldsMandatory(updatedFieldsMandatory);
        }
      }

      if (inputValueOrder === "input-2") {
        const updatedObject = { ...validationErrors };

        if (updatedObject.hasOwnProperty(`input-2`)) {
          delete updatedObject[`input-2`];

          // Update the state with the modified copy
          setValidationErrors(updatedObject);
        }
      }
    }
  };


  //(stackError, "error");
  /**
   * PS_CGAI_4.25 to PS_CGAI_4.28 once the user clicks on submit after the values inputed check for the validation is error is
   * there means will show error or else calls the createChat()
   */
  
  // console.log(selectedProject,"selcted project")
  const handleFormSubmit = async () => {
    

    const errors = {};

    promptData?.currentPrompt.promptFields.length > 0 &&
      promptData.currentPrompt.promptFields.map((records, index) => {
        const {
          promptFieldOrder,
          inputValueOrder,
          inputTypeName,
          errorMessage,
          required,
        } = records;

        const fieldValue = [`${inputValueOrder}`];
        let value = fieldValue;

        const mandatory = [`${inputValueOrder}`];

        if (!fieldValues.hasOwnProperty(value) && records.required == "true") {
          errors[`${inputValueOrder}`] = records.errorMessage;
        }
        if (!fieldValues[value] && records.required == "true") {
          errors[`${inputValueOrder}`] = records.errorMessage;
        }

        if (!fieldValues[value]?.length && records.required == "true") {
          errors[`${inputValueOrder}`] = records.errorMessage;
        }

      });
    
    
      
      if (!selectedProject && !Object.keys(errors).length > 0) {
        setProjectError("Please select a project.");
        return;
      }else if(Object.keys(errors).length > 0 && selectedProject){
        setValidationErrors(errors);
        return;
      }else if(!selectedProject && Object.keys(errors).length > 0){
        setProjectError("Please select a project.");
        setValidationErrors(errors);
        return;

    } else {
      //(fieldValues, stackError);
      if (Object.keys(fieldValues).length === 0) {
        setError("Please provide Atleast one value");
      } else {
        
        if (
          promptData?.currentPrompt.promptName == "Code Conversion" &&
          (fieldValues.hasOwnProperty("input-4") ||
            fieldValues.hasOwnProperty("input-5"))
        ) {
          if (fieldValues["input-4"] == fieldValues["input-5"]) {
            setStackError(
              "Please select different Tech-Stack for both Source and Target"
            );
          } else {
            setStackError("");
            setValidationErrors({});
            promptData.setformHidden(false);
            if (!selectedProject) {
              setProjectError("Please select a project.");
              return;
            } else {
              setProjectError("");
            }
            
            const newPromptFieldJson = { ...promptFieldJson }
            // const newPromptFieldJson = { ...promptFieldJson, 'Chat Name': res };
            
            createChat(newPromptFieldJson);
          }
        } else {
          setValidationErrors({});
          promptData.setformHidden(false);
          if (!selectedProject) {
            setProjectError("Please select a project.");
            return;
          } else {
            setProjectError("");
          }
          
          const newPromptFieldJson = { ...promptFieldJson }
          // const newPromptFieldJson = { ...promptFieldJson, 'Chat Name': res };
          
          createChat(newPromptFieldJson);
        }
      }
      // promptData.appendjson(fieldValues,promptFieldJson,"kaviya.v.e@avasoft.com")
    }

  };

  const handleAPICall = async (firstContent) => {
    
    try {
      let newChat = "";
      //
      // console.log(firstContent, "meeeeeeeeeeeeeeee")
      // Make the API call
      const result = await LoadBalancer({
        modelConfigurationID: "",
        messages: [{
          "role": "user", "content": `Analyze this input: "${firstContent}".
    Task: Generate a single, descriptive word to serve as a chat name.
    This word should encapsulate the essence or main topic of the input.
    It must be ONE WORD ONLY, with no additional explanation.+
    If the input is code or technical, choose a relevant technical term.
    Respond with just this one word, nothing else.` }]
      });

      let newChatResponse = result?.data

      if (newChatResponse) {
        newChat = newChatResponse.ResponseData.response?.trim();

        // First, remove any truncated part (ending with ...)
        newChat = newChat.replace(/\s*\.{3,}$/, '').trim();

        // Then, remove any trailing dot
        newChat = newChat.replace(/\.$/, '');


      } else {
        newChat = "New Chat"
      }
      // console.log(newChat, "nameeeeeeeeeeeeeeeeeeeeee")
      setpromptFieldJson((prevValues) => ({
        ...prevValues,
        "Chat Name": newChat,
      }));
      return newChat
    } catch (error) {
      console.error('\n');
      throw error;
    }

  };

  /**
   * PS_CGAI_4.29 to PS_CGAI_4.35 here it will check whethere the user is user or not if user means get the manager id of the user
   * and call insertMessage() to create chat for the particular person ,else no need of any approver to create chat directly call
   * insertMessage to created chat in the backeend using postMessage API
   */
  async function createChat(chatName) {
    // console.log("hi).log(newChat,"insideeeeeeee")
    // console.log(chatName, "seeeeeeeeeeeeeeeeeeeeeeeee11111111111")
    if (userData?.role != "User" && userData?.role != "Lead" && userData?.role != "Leadership") {
      // console.log(chatName, "seeeeeeeeeeeeeeeeeeeeeeeee22222222222222")
      try {
        let data = `Bearer ${token}`;

        const approverEmailID = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${userData?.email}/manager`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        try {
          // console.log(promptFieldJson, "seeeeeeeeeeeeeeeeeeeeeeeee")
          let result = await insertMessage({
            approverEmailID: approverEmailID?.data.mail,
            inputJsonValues: fieldValues,
            userEmailID: userData?.email,
            userName: userData?.userName,
            promptFormJson: promptFieldJson,
            promptID: promptData.currentPrompt.promptID,
            role: "user",
            codegenStatus: "ip",
            projectName: selectedProject?.values,
            chatType: "Prompt",
          });

          //(result,"lkjhg")
          history.push(`/chat?chatID=${result.ResponseData}`);
          promptData.setJSON(promptFieldJson);
          // promptData.setreplaceObj(fieldValues);
          promptData.replaceObj = fieldValues;

          promptData.appendJson(
            approverEmailID?.data.userPrincipalName,
            result.ResponseData,
            approverEmailID?.data.givenName,
            fieldValues
          );
        } catch (error) {
          //(error.message);
        }
      } catch (error) {
      }
    } else {
      try {
        const approverEmailID = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${userData?.email}/manager`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let result = await insertMessage({
          approverEmailID: approverEmailID?.data.mail,
          userEmailID: userData.email,
          userName: userData.userName,
          promptFormJson: chatName,
          inputJsonValues: fieldValues,
          promptID: promptData.currentPrompt.promptID,
          role: "user",
          codegenStatus: "ip",
          projectName: selectedProject?.value,
          chatType: "Prompt",
        });

        history.push(`/chat?chatID=${result.ResponseData}`);

        promptData.setJSON(promptFieldJson);
        // promptData.setreplaceObj(fieldValues);
        promptData.replaceObj = fieldValues;
        promptData.setcurrentChatID(result.ResponseData);
        promptData.appendJson(
          null,
          result.ResponseData,
          userData.userName,
          fieldValues
        );
      } catch (error) {
        //(error.message);
      }
    }
  };
  // console.log(promptFieldJson, "lmaooooooooo")
  // console.log(fieldValues,"fieldvalues")
  return (
    <>
      {loader ? (
        <Loader isLoading={!loader} />
      ) : (
        <div class="container-fluid">
          <div
            className="modal fade show"
            id="Helpushtml-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered custom-codesnippet-width border-0">
              <div className="modal-content p-3 border-0 bg-color-2">
                <div className="modal-header border-0 pt-2 pb-0">
                  <h5 className="modal-title text-color-1 font-22 font-bold">
                    Help us with necessary inputs
                  </h5>
                  <button
                    type="button"
                    class="btn-close close-icon-filter"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      setFieldValues({});

                      setfieldsMandatory({});
                      setpromptFieldJson({});
                      promptData.setformHidden(false);
                      setValidationErrors({});
                    }}
                  ></button>
                </div>
                <div className="modal-body  pt-2">
                  <div className="row ">
                    <div className="common-scroll-popup">
                      <p className="font-15 font-medium text-color-9">
                        Your answer will help us to provide better solutions
                      </p>

                      <div className="col-md-6 mb-4 mt-3 form-label" style={{
                        width: "50%",

                      }}>
                        <h6 className="text-color-2"
                          form-label
                          style={{ display: 'inline-block' }}>Select Project </h6>
                        <span
                          class="color-red"
                          style={{ fontWeight: 'bold', paddingLeft: '1%' }}>*</span>
                        <Select options={mappedOptions}
                          defaultValue={selectedProject}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            setSelectedProject(selectedOption);
                            if (selectedOption) {
                              setProjectError("");
                            }
                          }}
                          required />
                        <span
                          className="mandatory-star font-14 font-medium mb-0"
                          style={{ color: "red" }}
                        >
                          {projectError}
                        </span>
                      </div>

                      <div className="col-md-6 mb-4 mt-3 form-label" style={{
                        width: "53%",
                        padding: "10px",
                        display: promptData?.selectedFunction?.length > 1 ? "block" : "none"
                      }}>
                        <h3 className=" text-color-2" form-label>Function/Method to work on </h3>
                        <textarea rows={7} cols={50}>{promptData.selectedFunction}</textarea>
                      </div>

                      <div className="row">
                        {promptData?.currentPrompt?.promptFields.length > 0 &&
                          promptData.currentPrompt.promptFields.map(
                            (record, index) => {
                              const { inputValueOrder } = record;
                              // console.log(record,"PPP");
                              

                              switch (record.inputTypeRefCode) {
                                case "TS":
                                  return (
                                    <>
                                      <div class="col-md-6 mb-4 mt-3">
                                        <DropdownComponent
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                            techStackID,

                                            bindChoicesValues:
                                              bindChoicesValues.find(
                                                (_value) =>
                                                  _value.key === inputValueOrder
                                              )?.value,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );

                                case "DD":
                                  return (
                                    <>
                                      <div class="col-md-6 mb-4 mt-3">
                                        <DropdownComponent
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                            techStackID,
                                            stackError,
                                            bindChoicesValues:
                                              bindChoicesValues.find(
                                                (_value) =>
                                                  _value.key === inputValueOrder
                                              )?.value,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                case "CB":
                                  return (
                                    <>
                                      <div class="col-md-6 mb-4 mt-3">
                                        <CheckboxComponent
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                            codeID,
                                            bindChoicesValues:
                                              bindChoicesValues.find(
                                                (_value) =>
                                                  _value.key === inputValueOrder
                                              )?.value,
                                            // setLoader,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                case "RB":
                                  return (
                                    <>
                                      <div class="col-md-6 mb-4 mt-3">
                                        <RadioComponent
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                            codeID,
                                            bindChoicesValues:{choiceName:record.choiceName, input:record.inputValueOrder}
                                              // bindChoicesValues.find(
                                              //   (_value) =>
                                              //     _value.key === inputValueOrder
                                              // )?.value,
                                            // setLoader,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                case "FS":
                                  return (
                                    <>
                                      <div class="col-md-12 mb-4">
                                        <AddFileOrFolder
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                case "TXT":
                                  return (
                                    <>
                                      <div class="col-md-12 mb-4 ">
                                        <Text
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                case "UF":
                                  return (
                                    <>
                                      <div class="col-md-12 mb-4 ">
                                      <MediaUpload
                                      record={{
                                        record,
                                        handleFieldValues,
                                        validationErrors,
                                        fieldValues,
                                      }}
                                    />
                                      </div>
                                    </>
                                  );

                                case "CS":
                                  let val = record.labelName
                                  if (record.inputValueOrder == "input-2" || val.toLowerCase().includes('functionality')) {
                                    // console.log(record, "heheeeeeeeeeeeeee")
                                    flag = 1

                                  }
                                  else {
                                    flag = 0
                                  }
                                  return (
                                    <>
                                      <div class="col-md-12 mb-4">
                                        <CodeSnippet
                                          record={{
                                            record,
                                            handleFieldValues,
                                            validationErrors,
                                            fieldValues,
                                            // setLoader,
                                          }}
                                        />
                                      </div>
                                    </>
                                  );

                                default:
                                  return <></>;
                              }
                              // setLoader(false);
                            }
                          )}
                        <div>
                          <span
                            className="mandatory-star font-14 font-medium mb-0"
                            style={{ color: "red" }}
                          >
                            {stackError}
                          </span>

                          <span
                            className="mandatory-star font-14 font-medium mb-0"
                            style={{ color: "red" }}
                          >
                            {error}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        type="button"
                        className="btn custom-outline-btn font-14 px-4 py-2 me-3 font-medium"
                        // data-bs-dismiss="modal"
                        onClick={() => {
                          setFieldValues({});

                          setfieldsMandatory({});
                          setpromptFieldJson({});
                          promptData.setformHidden(false);
                          setValidationErrors({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn primary-btn text-white font-14 px-4 py-2 font-medium theme-focus "
                        // aria-label="Close"
                        // data-bs-dismiss="modal"
                        onClick={(e) => {

                          handleFormSubmit();

                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      )}
    </>
  );
}