import { mdata } from "../config/prod.env";
export const msalConfig = {
  auth: {
    clientId:mdata.clientId /*To be changed */,
    authority:
      "https://login.microsoftonline.com/716f83c3-7abd-42a1-86d2-e0207f4aa981",
    redirectUri: window.location.origin + "/chat",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const TENANT_ID = mdata.tenant_id

export const isAuth = (accounts) => {
  //(accounts,"KKKK");
  return accounts.find((_account) => _account?.tenantId === TENANT_ID);
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};
