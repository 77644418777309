//PS_CGAI_15.1,PS_CGAI_15.2,PS_CGAI_15.3 Import the hooks and function from React and following file
 
// PS_AAI_1.0
// Necessary imports are made here
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { AddFileOrFolderSample } from "../AddFileStuctureSample";
import UserContext from "../../Auth/useContext";
import Loader from "../../Other-Components/loader";
import { isAuth } from "../../Auth/authConfig";
import { getBindParams, fetchCostAnalysisData, fetchProjectCost, fetchPracticeCost, fetchIndividualCost } from "../../AI Service/Api";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { ClipLoader } from 'react-spinners';
 
/**
 * PS_CGAI_15.4 to PS_CGAI_15.24 ,Declare All the state variables needed for the functions.
 
 * PS_CGAI_15.56 to PS_CGAI_15.60, Onclick of uparrow button desc of the data's will take place.
 * PS_CGAI_15.61 to PS_CGAI_15.65, onclick of downarrow button asc of the data will take place.
 * PS_CGAI_15.66 to PS_CGAI_15.69, Fetch the data according to the field searched
 * PS_CGAI_15.90 to PS_CGAI_15.92 ,Onclick of Ok button set the isPromptopenPopup to false
 * PS_CGAI_15.103 to PS_CGAI_15.110,onchange event for filter fields
 * PS_CGAI_15.126 to PS_CGAI_129 ,onclick of manage request should navigate to the manage request page
 */
 
function CostAnalysisTable({navOpen}) {
  const [search, setSearch] = useState("");
  const [IndividualsortOption, setIndividualSortOption] = useState("cost");
  const [IndividualsortValue, setIndividualSortValue] = useState("asc");
  const [PracticesortOption, setPracticeSortOption] = useState("cost");
  const [PracticesortValue, setPracticeSortValue] = useState("asc");
  const [ProjectsortOption, setProjectSortOption] = useState("cost");
  const [ProjectsortValue, setProjectSortValue] = useState("asc");
  const [individualcount, setindividualCount] = useState(1);
  const [practicecount, setpracticeCount] = useState(1);
  const [projectcount, setprojectCount] = useState(1);
  const [projectShownRecords, setprojectShownRecords] = useState(0);
  const [projectTotalRecords, setprojectTotalRecords] = useState(Number);
  const [practiceShownRecords, setpracticeShownRecords] = useState(0);
  const [practiceTotalRecords, setpracticeTotalRecords] = useState(Number);
  const [IndividualShownRecords, setIndividualShownRecords] = useState(0);
  const [IndividualTotalRecords, setIndividualTotalRecords] = useState(Number);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [isAscendingUserName, setIsAscendingUserName] = useState(true);
  const [isAscendingUsage, setIsAscendingUsage] = useState(true);
  const [isAscendingCost, setIsAscendingCost] = useState(true);
  const [isAscendingPracticeName, setIsAscendingPracticeName] = useState(true);
  const [isAscendingPracticeUsage, setIsAscendingPracticeUsage] = useState(true);
  const [isAscendingPracticeCost, setIsAscendingPracticeCost] = useState(true);
  const [isAscendingProjectName, setIsAscendingProjectName] = useState(true);
  const [isAscendingProjectUsage, setIsAscendingProjectUsage] = useState(true);
  const [isAscendingProjectCost, setIsAscendingProjectCost] = useState(true);
  const [bindProjectHistory, setbindProjectHistory] = useState();
  const [bindPracticeHistory, setbindPracticeHistory] = useState();
  const [bindIndividualHistory, setbindIndividualHistory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filterListPractice, setFilterListPractice] = useState("");
  const [filterListProject, setFilterListProject] = useState("")
  const [page, setPage] = useState("Project")
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [FilterBindPracticeHistory, setFilterBindPracticeHistory] = useState()
  const [options, setoptions] = useState()
  const [FilterBindProjectHistory, setFilterBindProjectHistory] = useState()
  const [buttonShow, setButtonShow] = useState("")
  const [showFilter, setShowFilter] = useState(false);
  const [dupSearch, setDupSearch] = useState("");
  const [isLoad,setIsLoad] = useState(false)
  const [practiceDupSearch, setPracticeDupSearch] = useState("")
  const [practiceSearch, setPracticeSearch] = useState("")
  const [projectDupSearch, setProjectDupSearch] = useState("")
  const [projectSearch, setProjectSearch] = useState("")
  const [isDark, setIsDark] = useState(false)
  const [projectSelectedOptions, setProjectSelectedOptions] = useState([]);
  const [practiceSelectedOptions, setPracticeSelectedOptions] = useState([]);
 
  // useEffect(() => {
  //   fetchHistory();
  // }, []);
 
 
 
  // useEffect(() => {
  //   // Load the saved theme from localStorage on component mount
  //   const savedTheme = localStorage.getItem("selectedTheme");
  //   //
  //   if (savedTheme) {
  //     setIsDark(savedTheme === "dark");
  //     console.log(isDark)
  //   }
  // }, []);
 
 
 
  // PS_AAI_1.11
  // This useEffect gets triggered when IndividualsortValue, individualcount,search state variables change
  useEffect(() => {
    fetchProjectHistory();
  }, [ProjectsortValue, projectcount, projectSearch, filterListProject, ProjectsortOption]);
 
  useEffect(() => {
 
    fetchIndividualHistory();
  }, [IndividualsortValue, individualcount, search, IndividualsortOption]);
 
  //  PS_PC_1.13
  // This useEffect gets triggered PracticesortValue, practicecount,search
  useEffect(() => {
    fetchPracticeHistory();
  }, [PracticesortValue, practicecount, practiceSearch, filterListPractice, PracticesortOption]);
 
  //  PS_PG_1.13
  // This useEffect gets triggered when ProjectsortValue, projectcount,search state variable gets change
 
 
  useEffect(() => {
    //
    optionSetter()
  }, [page])
 
  useEffect(()=>{
    if (page == "Practice")
      fetchPracticeHistory()
    else if (page == "Project")
      fetchProjectHistory()
    else
      fetchIndividualHistory()
  },[search])
  function optionSetter() {
    //
    if (page == "Practice") {
      setoptions(FilterBindPracticeHistory?.map(item => ({
        value: item.practiceName,
        label: item.practiceName,
      })));
    } else {
      setoptions(FilterBindProjectHistory?.map(item => ({
        value: item.projectName,
        label: item.projectName,
      })));
    }
  }
  // const fetchHistory = async (obj = false) => {
  //   await fetchProjectHistory();
  //   await fetchPracticeHistory();
  //   fetchIndividualHistory()
  // }
 
 
 
  const handleSelectChange = (selectedOptions) => {
    if (page === 'Project') {
      setProjectSelectedOptions(selectedOptions);
    } else if (page === 'Practice') {
      setPracticeSelectedOptions(selectedOptions);
    }
  };
 
  function assign_value(){
    if(page == "Project"){
      return projectDupSearch
    }
    else if(page == "Practice"){
      return practiceDupSearch
    }
    else{
      return dupSearch
    }
  }
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: 'inherit',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      flexWrap: 'nowrap',
      minWidth: '75%',
      maxWidth: '75%',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      flexShrink: 0,
      backgroundColor: 'var(--select-pill-bg, #e9ecef)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--select-pill-color, #495057)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--select-pill-remove-color, #495057)',
      ':hover': {
        backgroundColor: 'var(--select-pill-remove-hover-bg, #ffcccb)',
        color: 'var(--select-pill-remove-hover-color, #495057)',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    input: (provided) => ({
      ...provided,
      position: 'relative',
      margin: '0',
      padding: '0',
      maxWidth: '2px',
      color: 'var(--select-input-color, currentColor)',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'var(--select-placeholder-color, #6c757d)',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--select-option-focus-bg, #f0f0f0)'
        : 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      ':active': {
        backgroundColor: 'var(--select-option-active-bg, #e0e0e0)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--select-input-color, currentColor)',
    }),
  };
 
  const DarkcustomStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: '#333',  // Dark theme background color
      borderColor: '#555',
      color: '#fff',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      flexWrap: 'nowrap',
      minWidth: '75%',
      maxWidth: '75%',
      backgroundColor: '#333',  // Dark theme background color
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      color: '#fff',
    }),
    multiValue: (provided) => ({
      ...provided,
      flexShrink: 0,
      backgroundColor: '#444',  // Dark theme background for selected values
      color: '#fff',
    }),
    input: (provided) => ({
      ...provided,
      position: 'relative',
      margin: '0',
      padding: '0',
      maxWidth: '2px',
      color: '#fff',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#aaa',  // Placeholder color for dark theme
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',  // Dark theme background color for the menu
      color: '#fff',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? '#444' : isSelected ? '#555' : '#333',
      color: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
    }),
  };
 
  // const getStyles = () => {
  //   //
  //   console.log(isDark, "pppppp")
  //   let styles = isDark ? DarkcustomStyles : customStyles;
  //   console.log(styles , "lllllllll")
  //   return styles
 
  // };
 
  // PS_PG_1.16
  // This function fetches the cost details on project basis
  const fetchProjectHistory = async (obj = false) => {
    setIsLoad(true)
   
    try {
      console.log("starts here")
      console.log(ProjectsortOption)
      console.log(ProjectsortValue)
      console.log(projectcount)
      console.log(filterListProject)
      console.log("ends here")
      let ProjectHistoryObj = {
        pageValue: "Project",
        searchValue: projectSearch,
        sortOption: ProjectsortOption,
        sortValue: ProjectsortValue,
        offsetValue: projectcount,
        filterValue: filterListProject,
      };
      console.log(ProjectHistoryObj,"tadaaaaaaaaaaaaaaaaaaaaaaa")
 
      ProjectHistoryObj = obj ? obj : ProjectHistoryObj;
      // setIsLoading(false)
      // PS_PC_1.19
      // The function which makes the API call is called here
      let response = await fetchProjectCost(obj ? obj : ProjectHistoryObj);
      setIsLoad(false)
      // setIsLoading(true)
      console.log(response, "heheheeeeeeeeeee")
      // setIsLoading(true)
 
      let record = response.data1.length - projectShownRecords;
      setprojectShownRecords(record + projectShownRecords);
      console.log(response.data1,"dataaaaaaaaaaaaa")
      setbindProjectHistory(response.data1);
      setFilterBindProjectHistory(response.data2)
      setprojectTotalRecords(response.data2.length);
      console.log(response.data2.length,"checkinggggggggggggggg")
 
    }
    catch (error) {
      console.log(error);
    }
    optionSetter()
  };
 
  function handleApplyClick() {
    if (page == "Practice") {
      setFilterListPractice(practiceSelectedOptions.map(option => option.value))
    } else {
      setFilterListProject(projectSelectedOptions.map(option => option.value))
    }
 
  }
  // PS_PC_1.16
  // Function which fetches cost based on practice is defined here
  const fetchPracticeHistory = async (obj = false) => {
    setIsLoad(true)
   
    try {
      let PracticeHistoryObj = {//userData?.email,
        pageValue: "Practice",
        searchValue: practiceSearch,
        sortOption: PracticesortOption,
        sortValue: PracticesortValue,
        offsetValue: practicecount,
        filterValue: filterListPractice,
      };
 
      PracticeHistoryObj = obj ? obj : PracticeHistoryObj;
      // setIsLoading(false)
      console.log(PracticeHistoryObj, "laaaaaaaaaaaaaaaaaaaaaaa")
 
      // PS_PC_1.19
      // the function which makes the API call is called here
      let response = await fetchPracticeCost(obj ? obj : PracticeHistoryObj);
      setIsLoad(false)
      console.log(response, "Mervin is here")
      // setIsLoading(true)
 
      let record = response.data1.length - practiceShownRecords;
      setpracticeShownRecords(record + practiceShownRecords);
      setbindPracticeHistory(response.data1);
      setFilterBindPracticeHistory(response.data2)
      setpracticeTotalRecords(response.data2.length);
 
 
      // Update the state with the new list
    }
    catch (error) {
      console.log(error);
      setIsLoad(true)
    }
    optionSetter()
  };
 
  // PS_AAI_1.14
  // The function which fetches cost details on Individual basis is defined here
  const fetchIndividualHistory = async (obj = false) => {
    setIsLoad(true)
   
    try {
      let ProjectHistoryObj = {//userData?.email,
        pageValue: "Individual",
        searchValue: search,
        sortOption: IndividualsortOption,
        sortValue: IndividualsortValue,
        offsetValue: individualcount,
        filterValue: "",
      };
 
      ProjectHistoryObj = obj ? obj : ProjectHistoryObj;
      // setIsLoading(false)
      // PS_AAI_1.17
      // The function which does the API call is called here
      let response = await fetchIndividualCost(obj ? obj : ProjectHistoryObj);
      setIsLoad(false)
      // setIsLoading(true)
      console.log(response, "Mervin is here")
      // setIsLoading(true)
 
      let record = response.data1.length - IndividualShownRecords;
      setIndividualShownRecords(record + IndividualShownRecords);
      setbindIndividualHistory(response.data1);
      setIndividualTotalRecords(response.data2.length);
    }
    catch (error) {
      console.log(error);
    }
  };
 
  // PS_PG_1.36
  // The function which binds the Project based data to the table is defined here
  function bindProjectHistoryData() {
    console.log(bindProjectHistory, "hereeeeeeeeeeeeeeeee")
    if (bindProjectHistory != undefined) {
      console.log("newwwwwwwwwwww")
      return bindProjectHistory?.map((value) => {
        return (
          <tr>
            <td className="ps-5 py-3 text-start">{value.projectName==""?"Previous Project":value.projectName}</td>
            <td className="py-3 text-center" style={{paddingLeft:"75px"}} >{value.AsamiUsage}</td>
            {value.Cost==null?<td className="pe-5 py-3 text-end" >-</td>:<td className="pe-5 py-3 text-end" style={{textAlign:"left"}}>${Number(value.Cost).toFixed(2)}</td>}
          </tr>
        );
      });
    } else {
      return null;
    }
  }
  // PS_PC_1.36
  // The function which binds the Practice based data to the table is defined here
  function bindPracticeHistoryData(bindPracticeHistory) {
    if (bindPracticeHistory != undefined) {
      return bindPracticeHistory?.map((value) => {
        return (
          <tr>
            <td className="ps-5 py-3 text-start">{value.practiceName==""?"Previous Practice":value.practiceName}</td>
            <td className="py-3 text-center" style={{paddingLeft:"75px"}}>{value.AsamiUsage}</td>
            {value.Cost==null?<td className="pe-5 py-3 text-end">-</td>:<td className="pe-5 py-3 text-end">${Number(value.Cost).toFixed(2)}</td>}
          </tr>
        );
      });
    } else {
      return null;
    }
    // return (
    //   <>
    //     {isLoad ? (
    //       // <tr>
    //       //   <td colSpan="3">
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               flex: 1,
    //               display: 'flex',
    //               justifyContent: 'center',
    //               marginTop: '20%'
    //             }}
    //           >
    //             <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
    //           </div>
    //       //   </td>
    //       // </tr>
    //     ) : bindPracticeHistory ? (
    //       bindPracticeHistory.map((value, index) => (
    //         <tr key={index}>
    //           <td className="ps-5 py-4 text-start">{value.practiceName}</td>
    //           <td className="py-4 text-center">{value.AsamiUsage}</td>
    //           <td className="pe-5 py-4 text-end">{value.Cost}</td>
    //         </tr>
    //       ))
    //     ) : null}
    //   </>
    // );
  }
 
  // PS_AAI_1.31
  // The function which binds the individual based data to the table is defined here
  function bindIndividualHistoryData(bindIndividualHistory) {
    if (bindIndividualHistory != undefined) {
      return bindIndividualHistory?.map((value) => {
        return (
          <tr>
            <td className="ps-4 py-4 text-start">{value.userName}</td>
            <td className="py-4 text-start">{value.userEmailID}</td>
            <td className="py-4 text-center" >{value.AsamiUsage}</td>
            {value.Cost==null?<td className="pe-5 py-4 text-end">-</td>:<td className="pe-5 py-3 text-end">${Number(value.Cost).toFixed(2)}</td>}
          </tr>
        );
      });
    } else {
      return null;
    }
  }
 
  // PS_AAI_1.37 , PS_PG_1.41 , PS_PC_1.41
  // The function which fetches more data after the user clicks on load more button is defined here
  const loadmoreClick = () => {
 
 
    if (page == "Individual") {
      let countValue = individualcount + 1;
      setindividualCount(countValue);
    }
    else if (page == "Practice") {
      let countValue = practicecount + 1;
      setpracticeCount(countValue);
    }
    else if (page == "Project") {
      let countValue = projectcount + 1;
      setprojectCount(countValue);
    }
  };
  return (
    // <>{!isLoading ? (<div >
     
    //   <Loader isLoading={isLoading} />
    // </div>):
    (
      <div className="container-fluid">
      <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
      <div className={navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-4 mb-3 d-grid d-md-flex justify-content-md-between align-items-center">
              <h2 className="font-bold font-20 mb-0 text-color-1 "><a href="/dashboard"><img src="images/back-black-arrow.svg" alt="Back" className="me-2 icon-filter" /></a>Cost Analysis</h2>
              {/*search*/}
              <div className="table-header d-flex align-items-center">
                <div className="input-group transparent-grp w-225">
                  <input type="text" className="form-control custom-search-input cust-search-input search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3" placeholder="Search" onChange={(e) => {
                   
                    if(page =="Project"){
                      setProjectDupSearch(e.target.value)
                    }
                    else if(page =="Practice"){
                      setPracticeDupSearch(e.target.value)
                    }
                    else{
                      setDupSearch(e.target.value)
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key == "Enter" && page=="Practice") {
                   
                        setPracticeSearch(e.target.value);
                        // setPracticeDupSearch("")  
                       
                    }
                    else if(e.key == "Enter" && page=="Project"){
                   
                        setProjectSearch(e.target.value);
                        // setProjectDupSearch("")
                       
                    }
                    else if(e.key == "Enter" && page == "Individual" ){
                      setSearch(e.target.value);
                      // setDupSearch("")
                    }
                }
                     
              }
                    value={assign_value()} />
                  <button className="btn focus-none search-bg" type="button" onClick={(e) => {
                     if(page=="Practice"){
                      setPracticeSearch(practiceDupSearch);
                      }
                      else if(page=="Project"){
 
                          setProjectSearch(projectDupSearch);
                      }
                      else if(page =="Individual"){
                        setSearch(dupSearch);
                      }
                   
                   
                  }}><img className="filter-icons" src="images/input-search.svg" alt="input-search-icon" /></button>
                </div>
                {page !== "Individual" ? <div className="dropdown ms-3">
                  <button type="button"  className={`btn font-16 font-semibold align-items-center inter-btn d-flex transparent-grp py-2 search-bg theme-focus ${buttonShow ? "show" : ""}`} data-bs-toggle="dropdown" aria-expanded={buttonShow ? true : false} onClick={() =>{
                    setButtonShow((e) => !e);
                    setShowFilter(!showFilter);
                    optionSetter()}}>
                    <img className="filter-icons" src="images/adv-filter.svg" alt="filter-icon" />
                  </button>
                  {/* advanced filter starts here */}
 
                  {showFilter&&(<span className={`dropdown-menu dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown end-0 bg-color-4 mt-2 ${buttonShow ? "show" : ""}`} style={{ display: showFilter ? "block" : "none", overflowX: 'hidden' }}>
                    <span className="d-flex justify-content-between align-items-center mb-3">
                      <span className="font-18 font-semibold text-color-1">Advanced Filter</span>
                      <span onClick={()=>{setShowFilter(false);
                          setButtonShow(false);}} >
                        <img src="images/flter-close-icon.svg" alt="filter-close-icon" className="cursor-pointer close-icon-filter" />
                      </span>
                    </span>
                    <span className="row">
                      <span className="mb-4 d-block col-md-12">
                        <span className="mb-5">
                          <label htmlFor="from" className="form-label text-color-1 font-15 font-semibold">{page == "Practice" ? <>Practice</>:<>Project</>}</label>
                          {/* Multi-select dropdown */}
                         
                          <Select
                              isMulti
                              name="projects"
                              options={options}
                              // styles= "bg-color-4"
                              className= "bg-color-4"
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              value={
                                page === 'Project'
                                  ? (projectSelectedOptions.length > 0 ? projectSelectedOptions : null)
                                  : (practiceSelectedOptions.length > 0 ? practiceSelectedOptions : null)
                              }
                              styles= {customStyles}
                              title={selectedOptions.map(option => option.label).join(', ')}
                            />
                         
                        </span>
                      </span>
                    </span>
 
 
                    <span className="w-100 d-flex justify-content-end">
                      <button
                        className="btn custom-outline-btn font-14 font-semibold px-3 me-3"
                        onClick={() => { setButtonShow(false);page == "Practice" ? setPracticeSelectedOptions([]) : setProjectSelectedOptions([]); page == "Practice" ? setFilterListPractice("") : setFilterListProject("")  }} // Clear selection on cancel
                         
                      >
                        Cancel
                      </button>
                      <button
                        className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white"
                        onClick={() =>{setShowFilter(false);
                          setButtonShow(false);
                          handleApplyClick()}}
                      >
                        Apply
                      </button>
                    </span>
                  </span>)}
                  {/* advanced filter ends here */}
                </div> : null}
              </div>
              {/*search*/}
            </div>
            {/* tabs starts here */}
            <div className="custom-border-bottom mb-5">
              <ul className="nav nav-pills font-14 font-semibold custom-pills color-white-bg border-radius-5 cust-respt" id="newtab" role="tablist">
                <li className="nav-item mb-2" role="presentation">
                  <button className="custom-new-tab-btn nav-link  active d-flex px-3 font-16 font-semibold" id="project-tab" data-bs-toggle="pill" data-bs-target="#project" type="button" role="tab" aria-controls="project" aria-selected="true" onClick={() => { setPage("Project") }}>Project</button>
                </li>
                <li className="nav-item mb-2" role="presentation">
                  <button className="custom-new-tab-btn nav-link d-flex px-3 font-16 font-semibold" id="practice-tab" data-bs-toggle="pill" data-bs-target="#practice" type="button" role="tab" aria-controls="practice" aria-selected="false" onClick={() => { setPage("Practice") }}>Practice</button>
                </li>
                <li className="nav-item mb-2" role="presentation">
                  <button className="custom-new-tab-btn nav-link d-flex px-3 font-16 font-semibold" id="individual-tab" data-bs-toggle="pill" data-bs-target="#individual" type="button" role="tab" aria-controls="individual" aria-selected="false" onClick={() => { setPage("Individual") }}>Individual</button>
                </li>
              </ul>
            </div>
            {/* tabs ends here */}
            {/*Tab inner content starts here*/}
            <div className="tab-content mt-2">
              {/*manage request tab*/}
              <div className="tab-pane fade show active" id="project" role="tabpanel" aria-labelledby="project-tab">
                {/*grid starts here*/}
                {isLoad ? ( <div
                                        style={{
                                          textAlign: 'center',
                                          flex: 1,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          marginTop: '20%'
                                        }}
                                      >
                                        <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
                                      </div>
                        ):
                <div className="col-md-12">
                  <div className="table-responsive rounded-3 transparent-grp">
                    <table className="table theme-table font-regular font-14 mb-0">
                      <thead>
                      <tr>
                          <th className="ps-5 py-3 text-start" onClick={() => {
                            setIsAscendingProjectName(!isAscendingProjectName);
                            setProjectSortOption("Project");
                            setProjectSortValue(isAscendingProjectName ? "desc" : "asc");
                          }}>Project Name <span className="cursor-pointer">{!isAscendingProjectName?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                            <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                          <th className="py-3 text-center" onClick={() => {
                            setIsAscendingProjectUsage(!isAscendingProjectUsage);
                            setProjectSortOption("AsamiUsage");
                            setProjectSortValue(isAscendingProjectUsage ? "desc" : "asc");
                          }}>Asami Usage<span className="cursor-pointer ps-1">{!isAscendingProjectUsage?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                          <th className="text-end pe-5 py-3" onClick={() => {
                            setIsAscendingProjectCost(!isAscendingProjectCost);
                            setProjectSortOption("Cost");
                            setProjectSortValue(isAscendingProjectCost ? "desc" : "asc");
                          }}>Cost (in $)<span className="cursor-pointer ps-1">{!isAscendingProjectCost?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectTotalRecords !== 0 ? (
                          // console.log("hi).log("krrrrrrrrrrrrrr")
                          bindProjectHistoryData()
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center py-3 vh-50">
                              <img
                                src="images/Default/no-record-img.svg"
                                alt="no-record-img"
                                className="no-record-img mb-5"
                              />
                              <span className="font-20 font-medium w-100 d-block">
                                Sorry! No history of cost are recorded.
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <p className="font-regular font-14 text-color-9 mt-3 mb-4"># of Records : <span className="font-bold font-14 text-color-1">{projectShownRecords}</span> out of <span className="font-bold font-14 text-color-1">{projectTotalRecords}</span></p>
                  {(projectTotalRecords > projectShownRecords) && projectTotalRecords > 10 ? (<div className="text-center mb-5">
                    <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={() => { loadmoreClick() }}><span className="color-gradient">Load More</span></button>
                  </div>) :
                    (<div className="text-center mb-5">
                    </div>)}
                </div>}
                {/*grid starts here*/}
              </div>
              {/*manage request tab*/}
              {/*user activity logs tab*/}
              <div className="tab-pane fade" id="practice" role="tabpanel" aria-labelledby="practice-tab">
                {/*grid starts here*/}
                {isLoad ? ( <div
                                        style={{
                                          textAlign: 'center',
                                          flex: 1,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          marginTop: '20%'
                                        }}
                                      >
                                        <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
                                      </div>
                        ):
                <div className="col-md-12">
                  <div className="table-responsive rounded-3 transparent-grp">
                 
                    <table className="table theme-table font-regular font-14 mb-0">
                      <thead>
                      <tr>
                          <th className="ps-5 py-3 text-start" onClick={() => {
                            setIsAscendingPracticeName(!isAscendingPracticeName);
                            setPracticeSortOption("Practice");
                            setPracticeSortValue(isAscendingPracticeName ? "desc" : "asc");
                          }}>Practice Name <span className="cursor-pointer">{!isAscendingPracticeName?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                          <th className="py-3 text-center" onClick={() => {
                            setIsAscendingPracticeUsage(!isAscendingPracticeUsage);
                            setPracticeSortOption("AsamiUsage");
                            setPracticeSortValue(isAscendingPracticeUsage ? "desc" : "asc");
                          }}>Asami Usage<span className="cursor-pointer ps-1">{!isAscendingPracticeUsage?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                          <th className="text-end pe-5 py-3" onClick={() => {
                            setIsAscendingPracticeCost(!isAscendingPracticeCost);
                            setPracticeSortOption("Cost");
                            setPracticeSortValue(isAscendingPracticeCost ? "desc" : "asc");
                          }}>Cost (in $)<span className="cursor-pointer ps-1">{!isAscendingPracticeCost?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                        </tr>
                      </thead>
                     
                      <tbody>
                        {practiceTotalRecords !== 0 ? (
                           bindPracticeHistoryData(bindPracticeHistory)
 
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center py-3 vh-50">
                              <img
                                src="images/Default/no-record-img.svg"
                                alt="no-record-img"
                                className="no-record-img mb-5"
                              />
                              <span className="font-20 font-medium w-100 d-block">
                                Sorry! No history of cost are recorded.
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <p className="font-regular font-14 text-color-9 mt-3 mb-4"># of Records : <span className="font-bold font-14 text-color-1">{practiceShownRecords}</span> out of <span className="font-bold font-14 text-color-1">{practiceTotalRecords}</span></p>
                  {(practiceTotalRecords > practiceShownRecords) && practiceTotalRecords > 10 ? (<div className="text-center mb-5">
                    <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={() => { loadmoreClick() }}><span className="color-gradient">Load More</span></button>
                  </div>) :
                    (<div className="text-center mb-5">
                    </div>)}
                </div>}
                {/*grid ends here*/}
              </div>
              {/*user activity logs tab*/}
              {/*individual tab*/}
              <div className="tab-pane fade" id="individual" role="tabpanel" aria-labelledby="individual-tab">
                {/*grid starts here*/}
                {isLoad ? ( <div
                                        style={{
                                          textAlign: 'center',
                                          flex: 1,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          marginTop: '20%'
                                        }}
                                      >
                                        <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
                                      </div>
                        ):
                <div className="col-md-12">
                  <div className="table-responsive rounded-3 transparent-grp">
                    <table className="table theme-table font-regular font-14 mb-0">
                      <thead>
                      <tr>
                          <th className="ps-4 py-4 text-start" onClick={() => {
                            setIsAscendingUserName(!isAscendingUserName);
                            setIndividualSortOption("userName");
                            setIndividualSortValue(isAscendingUserName ? "desc" : "asc");
                          }}>User Name <span className="cursor-pointer">{!isAscendingUserName?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" />:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" />
                           
                          }</span></th>
                          <th className="py-4 text-start">Email Address</th>
                          <th className="text-center pe-5 py-4" onClick={() => {
                            setIsAscendingUsage(!isAscendingUsage);
                            setIndividualSortOption("AsamiUsage");
                            setIndividualSortValue(isAscendingUsage ? "desc" : "asc");
                          }}
                          >Asami Usage<span className="cursor-pointer">{!isAscendingUsage?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" style={{paddingLeft: '3px'}}/>:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up" style={{paddingLeft: '3px'}} />
                           
                          }</span></th>
                          <th className="text-end pe-5 py-4" onClick={() => {
                            setIsAscendingCost(!isAscendingCost);
                            setIndividualSortOption("Cost");
                            setIndividualSortValue(isAscendingCost ? "desc" : "asc");
                          }}
                          >Cost (in $)<span className="cursor-pointer">{!isAscendingCost?<img className="filter-icons" src="images/Sort-Down.svg" alt="Sort-Down" style={{paddingLeft: '3px'}}/>:
                          <img className="filter-icons" src="images/sort-up.svg" alt="sort-up"  style={{paddingLeft: '3px'}} />
                           
                          }</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {IndividualTotalRecords !== 0 ? (
                          bindIndividualHistoryData(bindIndividualHistory)
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center py-3 vh-50">
                              <img
                                src="images/Default/no-record-img.svg"
                                alt="no-record-img"
                                className="no-record-img mb-5"
                              />
                              <span className="font-20 font-medium w-100 d-block">
                                Sorry! No history of cost are recorded.
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <p className="font-regular font-14 text-color-9 mt-3 mb-4"># of Records : <span className="font-bold font-14 text-color-1">{IndividualShownRecords}</span> out of <span className="font-bold font-14 text-color-1">{IndividualTotalRecords}</span></p>
                  {(IndividualTotalRecords > IndividualShownRecords) && IndividualTotalRecords>10 ? (<div className="text-center mb-5">
                    <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3 border-0" type="button" onClick={() => { loadmoreClick() }}><span className="color-gradient">Load More</span></button>
                  </div>) :
                    (<div className="text-center mb-5">
                    </div>)}
                </div>}
                {/*grid ends here*/}
              </div>
              {/*individual tab*/}
            </div>
            {/*Tab inner content end here*/}
          </div>
        </div>
 
      </div>
      </div>
      </div>
      )
      // }</>
  );
}
 
export default CostAnalysisTable;