// PS_MP_01 - Import necessary modules
import React, { useState, useEffect } from 'react';
import { getProject,getProjectConfig, kgCheck } from '../../AI Service/Api';
import Loader from '../../Other-Components/loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ManageProjectForm from './TfsForm';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import devConfig from '../../config/env';

// PS_MP_06 to PS_MP_21 - fetch the Manage project grid and entire functionality associated on that page
export function ManageProject(navOpen) {

  // PS_MP_04 - Declare state variables
  const [project, setProject] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [flag, setFlag] = useState("");
  const [filterAlert, setFilterAlert] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [subSearchProject, setSubSearchProject] = useState("");
  const [projectCheckbox, setProjectCheckbox] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [endOffset, setEndOffset] = useState(10);
  const [projectDropdown, setProjectDropdown] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscendingProject, setIsAscendingProject] = useState(true);
  const [lengthOfRecords, setLengthOfRecords] = useState("");
  const [buttonShow, setButtonShow] = useState(false);
  const [loadThing, setLoadThing] = useState(false)
  const [searchDubProject, setSearchDubProject] = useState("");
  const [showTfsPopUp, setShowTfsPopUp] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [projectTFSId, setProjectTFSId] = useState("")
  const history = useHistory()
  const [advancefilterpopup, setadvancefilterpopup] = useState(false);
  const [showmenu, setShowMenu]=useState(false);
  const [tfsConfigOrUpdate, setTfsConfigOrUpdate] = useState("configure");
  const [isLoading, setIsLoading] = useState(false);
  const [projectIDs, setProjectIDs] = useState([]);
  const [projectConfigValue, setProjectConfigValue] = useState([]);
  const [btnIsconfig, setbtnIDconfig] = useState("");
  const [cancel, setCancel] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  // PS_MP_05 to PS_MP_21 - useEffect will be rendered based on the dependency array
  // useEffect(()=>{
  //   if(buttonShow==true){
  //     setShowFilter(true);
  //   }
  //   else{
  //     setShowFilter(false);
  //   }
  // },[buttonShow])
  
  useEffect(() => {
    if (projectIDs.length > 0) {
      fetchConfigProject();
    }
  }, [projectIDs]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchProject(source.token);

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [searchProject, subSearchProject, sortOrder, endOffset, showFilter]);

  useEffect(()=>{
    if(buttonShow==true){
      setShowFilter(true);
    }
    else{
      setShowFilter(false);
    }
  },[buttonShow])

  function convertDate(val, index){
    const date = new Date(val);
    // Get year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
 
    // Combine into desired format
    const formattedDate = `${year}-${month}-${day}`;
 
    console.log(formattedDate);
    if(index=='from'){
      setFromDate(formattedDate);
    }
    else if(index=='to'){
      setToDate(formattedDate)
    }
  }
  // useEffect(() => {
  //   // Call the API immediately on mount
  //   kgBuildCheck()
  //   // Set up the interval to call the API every 10 minutes

  //   const intervalId = setInterval(() => {
  //     //("inside interval")
  //     kgBuildCheck();
  //   }, 10 * 60 * 1000); // 10 minutes in milliseconds

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);


  // const kgBuildCheck = async () => {
  //   let response = await kgCheck({
  //     "id": 2
  //   })
  //   //()
  //   if (response.kgStat == 1) {
  //     setLoadThing(true)
  //   }
  // }

const [lengthIssue , setLengthIssue] = useState({
  "success":false
})

  // PS_MP_06 to PS_MP_21 - To fetch project details by passing the request payload to the getProject API
  const fetchProject = async (signal) => {
    
    let data = {
      search: searchProject,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      subSearch: subSearchProject,
      checkbox: projectCheckbox,
      status: filterStatus,
      fromDate: fromDate,
      toDate: toDate,
      offsetValue: endOffset
    };
  
    try {
      if(showFilter !=true && cancel!=true ){

        let response = await getProject(data,signal);
        // setIsLoading(true);
  
        if (response.status === 200) {
          setProject(response?.data[0]?.projects);
          setLengthOfRecords(response?.data[0]?.projects?.length);
          setTotalRecords(response?.data[0]?.totalRecords);
          setLengthIssue(response?.data[0])
          const uniqueProjectIDs = [...new Set(response?.data[0]?.projects?.map(project => project?.projectID))]
          .map(projectID => ({ projectID }));
          setProjectIDs(uniqueProjectIDs);
          setProjectDropdown(response.data[0]?.projectDropDown);
          setbtnIDconfig(response.data[0]?.group_name)
        }        
      }
      else{
        setCancel(false)
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Fetch error:', error);
      }
    }
  };

  console.log("setbtnIDconfig suba",btnIsconfig)
  const fetchConfigProject = async () => {
    try {
      // setIsLoad(true)
      let data = {
        "projects" :projectIDs
      }
      const response = await getProjectConfig(data);
      // setIsLoad(false)
      setIsLoading(true)
      if (response.status === 200) {
        setProjectConfigValue(response.data[0].projects.projects )
        setStatusDropdown(response.data[0].statusDropdownResult)
        // Handle the response from projectFetchIsconfig API
        
      }
    } catch (error) {
      console.error('Error in projectFetchIsconfig:', error);
    }
  };
  
    const getIsConfigured = (projectID) => {
      const config = projectConfigValue.find(item => item.projectID === projectID);
      return config ? config.isConfigured : null;
    }
  
  
    console.log("setProjectConfigValue",projectConfigValue)
  

  //(project, "cldsbacjdacj")



  // PS_MP_39 to PS_MP_41 - To check whether the user chooses atleast any one of the field
  function filterValidation() {
    let flag = 0;

    if (filterStatus != "") {
      flag++;
    }
    if (projectCheckbox != "") {
      flag++;
    }
    if (fromDate != "") {
      flag++;
    }
    if (toDate != "") {
      flag++;
    }

    if (flag != 0) {
      setShowFilter(false);
      setFilterAlert("");
      fetchProject();
      fetchConfigProject();

    } else {
      setFilterAlert("alert");
    }
  }

  //(fromDate, toDate);

  // PS_MP_46 to PS_MP_48 - To make the state variables null when the user clicks cancel in filter to fetch the initial grid
  const cancelFilter = () => {
    setShowFilter(false);
    setFilterStatus("");
    setFromDate("");
    setToDate("");
    setFilterAlert("");
    setProjectCheckbox([]);

  }

  const handleProjectChange = (e, projectList) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the practice to the selectedPractice state
      setProjectCheckbox(prevSelectedProject => [...prevSelectedProject, projectList]);
    } else {
      // Remove the practice from the selectedPractice state
      setProjectCheckbox(prevSelectedProject =>
        prevSelectedProject.filter(item => item !== projectList)
      );
    }
  }
  console.log("statusDropdown",statusDropdown)
  const accessCheck = async () => {
    const accountResponse = await instance.acquireTokenSilent({
      account,
      scopes: ["User.Read"],
    });

    try {
      // Check if user is in Leadership DL
      await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipDL}/transitiveMembers/${account.localAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );
      return true;
    } catch (err) {}

    try {
      // Check if user is in Leadership Group
      await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipGroup}/members/${account.localAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );  
      return true;
    } catch (err) {}

    try {
      // Check if user is in Lead Group
      await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${account.localAccountId}`,
        { headers: { Authorization: `Bearer ${accountResponse.accessToken}` } }
      );
      
       return true;
     } catch (error) {}
   
     // If none of the conditions are met, return false by default.
     return false;
   };

  useEffect(() => {    
    async function checkAccess() {      
      const result = await accessCheck();      
      setHasAccess(result);
    }
    checkAccess();
  }, []); // Add any dependencies here



  return (
    <>
     {!isLoading ? (<div >
     
     <Loader isLoading={isLoading} />
   </div>): (
    <>
    <div className="container-fluid"> 
    <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
    <div className={navOpen.navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-3 me-lg-4 me-xl-5 col-11`}>
    {/* <div className={navOpen.navOpen === "Close" ? "container-fluid second-component-manageProject-nav-close" : "container-fluid second-component-manageProject"}> */}
      {/* <div className="container-fluid"> */}
        <div className="row">
          <div className="col-md-12 px-4 pt-3 pe-2">
            <div className="mt-3 mb-4 d-grid d-md-flex justify-content-md-between align-items-center">
              <h2 className="font-bold font-20 mb-0 text-nowrap text-color-1">Manage Projects ({project?.length})</h2>
              <div className="table-header d-md-flex align-items-center">
                <div className="p-2 px-3 border border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0 search-bg border-color-10">
                  <span className="flex-nowrap text-nowrap text-color-9">Integrated with</span>
                  <img src="images-new/avalon-v2.svg" alt="iocn" className="ms-2" />
                </div>
                {/*search*/}
                <div className="d-flex align-items-center mt-3 mt-md-0">
                  <div className="input-group transparent-grp w-225 me-3 m-0 search-bg">
                  <input type="text" className="form-control custom-search-input text-color-4 bg-transparent border-0 focus-none font-14 font-medium pe-0 ps-3 text-nowrap" placeholder="Search"
                      onChange={(e) => {
                        setSearchDubProject(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setSearchProject(e.target.value)
                          // setSearchDubProject("")
                        }
                      }}
                      value={searchDubProject} />
                    <button className="btn focus-none border-0" type="button" onClick={(e) => {
                      setSearchProject(searchDubProject)
                      // setSearchDubProject("")
                    }}>
               <img className='filter-icons' src="images-new/input-search.svg" alt="input-search-icon" /></button>
                  </div>
                  <div className="dropdown" >
                    <button type="button" className={`btn font-16 font-semibold align-items-center inter-btn d-flex transparent-grp py-2 theme-focus search-bg ${buttonShow ? "show" : ""}`}  onClick={() => {
                      setButtonShow((e) => !e);
                      // setShowFilter(true);

                    }}>
                      <img className='filter-icons' src="images-new/adv-filter.svg" alt="filter-icon" />
                    </button>
                    {/* advanced filter starts here */}
                   {buttonShow?( <span className={` dropdown-menu-end p-3 filter-dropdown-bg bg-color-4 w-filter-dropdown end-0 ${buttonShow ? "show" : ""}`} >
                      <span className="d-flex justify-content-between align-items-center mb-3">
                        <span className="font-18  font-semibold text-color-1">Advanced Filter</span>
                        <span className onClick={() => {
                          setShowFilter(false);
                          setButtonShow(false);
                          setCancel(true);
                        }}><img src="images-new/flter-close-icon.svg" alt="filter-close-icon" className="cursor-pointer close-icon-filter " /></span>
                      </span>
                      <span className="row ">

                        <div className="mb-3 d-block col-md-12">
                          <div className="dropdown" style={{width:'100%'}}>
                            <label htmlFor="model-type" className="form-label  font-15 font-semibold text-color-9">Project</label>
                            <button id="model-type" className="btn input-field font-14 font-regular border-radius-3  w-100 d-flex justify-content-between text-color-4 bg-color-4 custom-border-3 cust-search-input" type="button"
                            style={{width:'100%'}}
                            onClick={()=>{setShowMenu(!showmenu)}} >
                              <span className="color-grey-v3">Select</span>
                              <span className="ps-1  filter-icons"><img src="images-new/dropdown-grey-icon.svg" 
                              alt="dropdown-icon"
                              style={{ transform: !showmenu ? "" :  "rotate(180deg)"}}
                              /></span>
                            </button>

                            {showmenu && (<ul className="dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 common-dropdown-scroll-popup position-relative"  style={{display : showmenu ? "block" :"none"}}>
                              <li className="font-14 font-medium color-black">
                                <div className="input-group transparent-grp w-100 me-3 custom-border-3 bg-transparent">
                                  <input
                                    type="text"
                                    className="form-control custom-search-input border-0 cust-search-input bg-transprent focus-none font-14 font-medium pe-0 ps-3 "
                                    placeholder="Search"
                                    value={subSearchProject}
                                    onChange={(e) =>
                                      setSubSearchProject(e.target.value)}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        fetchProject()
                                        fetchConfigProject()
                                        setSubSearchProject("")

                                      }
                                    }}
                                  />
                                  <button
                                    className="btn focus-none"
                                    type="button"
                                  >
                                    <img
                                      src="images-new/input-search.svg"
                                      alt="input-search-icon"
                                    />
                                  </button>
                                </div>
                              </li>
                              {projectDropdown && projectDropdown.length !== 0 ? (
                                projectDropdown.map((projectList, index) => (
                                  <li className="font-14 font-medium text-color-1" key={index}>
                                    <div className="me-4">
                                      <input
                                        className="form-check-input align-middle m-0 ms-2 mb-1 user-checkbox"
                                        type="checkbox"
                                        id={`flexCheckDefault${index}`}
                                        value={projectList.project_name}
                                        checked={projectCheckbox.includes(projectList.project_name)}
                                        onChange={(e) => handleProjectChange(e, projectList.project_name)}
                                      />
                                      <label
                                        className="form-check-label text-color-9 p-2"
                                        htmlFor={`flexCheckDefault${index}`}>
                                        {projectList.project_name}
                                      </label>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <p>No option</p>
                              )}
                            </ul>)}
                          </div>
                        </div>
                        <span className="mb-3 d-block col-md-12">
                          <span className="mb-3">
                            <label htmlFor="from" className="form-label label-color font-15 font-semibold text-color-9">Status</label>
                            <select className="form-select text-color-4 bg-color-4 custom-border-3 cust-search-input" id="model-type"
                              value={filterStatus}
                              onChange={(e) => {
                                setFilterStatus(e.target.value);
                              }}
                              onClick={(e)=>e.currentTarget.classList.toggle('open')}>
                              <option selected>Select</option>
                              {statusDropdown.map((value, index) => (

                              <option
                              value={value.project_status}
                              // id={value.approvalStatusID}
                              key={index}
                              >
                              {value.project_status.charAt(0).toUpperCase() + value.project_status.slice(1)}
                              </option>

                              ))};
                            </select>
                          </span>
                        </span>
                      </span>


                      
                      <span className="mb-2 d-block">
                          <span className="d-block font-semibold font-16 text-color-9">Date Range</span>
                          
                          <span className="row mb-2">
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label htmlFor="from" className="form-label text-color-9 font-15 font-semibold">From</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="from"
                                    selected={fromDate}
                                    onChange={(date) => {
                                      convertDate(date,'from')
                                      setToDate(null);
                                    }}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                   placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('from').focus()}
                                  />
                                </div>
                              </span>
                            </span>
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label htmlFor="to" className="form-label text-color-9 font-15 font-semibold">To</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="to"
                                    selected={toDate}
                                    onChange={(date) => convertDate(date,'to')}
                                    minDate={fromDate}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('to').focus()}
                                  />
                                </div>
                              </span>
                          </span>
                        </span>
                      </span>

                      <span className="w-100 d-flex justify-content-end gap-3 mt-2">
                        <button className="btn custom-outline-btn font-14 font-semibold px-3 m-0"
                          onClick={() => {
                            setButtonShow(false);
                            setSubSearchProject("");
                            cancelFilter();
                          }}>Cancel
                        </button>

                        <button className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white m-0"
                          onClick={() => {
                            setFlag("filter")
                            {
                              setSubSearchProject("");
                              setButtonShow(false);
                              filterValidation();

                            }
                          }}>Apply</button>
                      </span>
                      {filterAlert == "alert" ? (
                        <div className="span-color">
                          Select,atleast one filter!
                        </div>
                      ) : null}
                    </span>

                   ):(<></>)}
                  </div>
                  {/* advanced filter ends here */}
                </div>
              </div>
              {/*search*/}
            </div>
            {/*grid starts here*/}
            
            <div className="col-md-12">
              <div className="table-responsive rounded-3 transparent-grp mb-4">
                <table className="table theme-table font-regular font-14 mb-0">
                  <thead>
                    <tr className="grey-color-v5">
                      <th className="ps-5 py-4"
                        onClick={() => {
                          setSortColumn("project_name")
                          setIsAscendingProject(!isAscendingProject);
                          setSortOrder(
                            isAscendingProject
                              ? "Desc"
                              : "Asc"
                          );
                        }}>Project Name
                        <span className="cursor-pointer ms-1">
                          <img className='filter-icons' src={
                            isAscendingProject
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingProject ? "sort-up" : "sort-down"} />
                        </span>
                      </th>
                      <th className="text-start"
                      onClick={() => {
                        setSortColumn("date_range")
                        setIsAscendingProject(!isAscendingProject);
                        setSortOrder(
                          isAscendingProject
                            ? "Desc"
                            : "Asc"
                        );
                      }}
                      >Planned Timeline
                      <span className="cursor-pointer ms-1">
                          <img className='filter-icons' src={
                            isAscendingProject
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingProject ? "sort-up" : "sort-down"} />
                        </span></th>
                      <th className="text-start"
                      onClick={() => {
                        setSortColumn("project_status")
                        setIsAscendingProject(!isAscendingProject);
                        setSortOrder(
                          isAscendingProject
                            ? "Desc"
                            : "Asc"
                        );
                      }}>Status
                      <span className="cursor-pointer ms-1">
                          <img className='filter-icons' src={
                            isAscendingProject
                              ? "images-new/sort-up.svg"
                              : "images-new/sort-down.svg"
                          } alt={isAscendingProject ? "sort-up" : "sort-down"} />
                        </span>
                      </th>
                      <th className="text-center pe-5">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {project?.length == 0|| null|| undefined || lengthIssue?.success == false ? (
                    <tr>
                    <td colSpan={6} className="text-center py-5 vh-50">
                      <img
                        src="images-new/no-record-img.svg"
                        alt="no-record-img"
                        className="no-record-img mb-5"
                      />
                      <span className="font-20 font-medium w-100 d-block">
                        Sorry! No project is assigned to you.
                      </span>
                    </td>
                  </tr>
                ): 
                    (project.map((project, index) => (
                      <React.Fragment key={index}>
                        {index % 2 === 0 ?
                          <tr className="">
                            <td className="ps-5 py-4 d-flex align-items-center">
                              <span className="project_logo_bg">{project.image_url?<img src={project.image_url} alt="Profile" class=" me-2" />
                                :
                                <img src="images-new/default-project-icon.svg" alt="Profile" class=" me-2" />
                                }  </span>
                              <div className="d-block ">
                                {getIsConfigured(project.projectID) == 2 ?
                                  (<a href className="d-block blue-link text-decoration-none font-14 font-semibold cursor-pointer" onClick={() => {
                                    localStorage.removeItem('projectIdFromMP')
                                    localStorage.removeItem('projectName')
                                    localStorage.setItem('projectIdFromMP',project.projectID)
                                    localStorage.setItem('projectNameFromLocal',project.projectName)
                                    localStorage.setItem('project_image', project.image_url)
                                    history.push({
                                      pathname: "/ManageTasks",
                                      state: {  
                                        projectIdFromMP : project.projectID,
                                        workItemFromMP: "task",
                                        projectName : project.projectName
                                      }
                                    })
                                  }}>
                                    {project.projectName}</a>) : getIsConfigured(project.projectID) == 1 ? (
                                      <span className="d-block font-14 font-semibold text-color-9" disabled></span>
                                    ) :
                                    (<span className="d-block font-14 font-semibold text-color-9">
                                      {project.projectName}</span>)}

                                <p className="font-12 mb-0 secondary-color">{project.projectName.length > 10 ? `${project.projectName.substring(0, 10)}...` : project.projectName}</p>
                              </div>
                            </td>
                            <td className="text-start">{project.projectTimeline}</td>
                            <td className="text-start d-flex align-items-center gap-2">
                              <div>
                                {project.Status === "inProgress" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">In Progress</span></div>
                                ) : project.Status === "notStarted" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot not-started-bg "></span>
                                    <span class="not-started text-color">Not Started</span></div>
                                ) : project.Status === "completed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-completed-bg" />
                                    <span className="completed">Completed</span></div>
                                ) : (project.Status === "inDelay" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot danger-bg"></span>
                                    <span className="in-delay">In Delay</span></div>
                                ) :
                                  <span>Unknown status</span>
                                )}
                              </div>
                             
                            </td>
                            <td className="text-center pe-5">

                              {getIsConfigured(project.projectID) === 2 ? (
                                <div>

                                  <button type="button " className="btn border-0 p-0 me-2" onClick={() => {
                                    setShowTfsPopUp(true);
                                    setProjectName(project.projectName);
                                    setTfsConfigOrUpdate("EDIT");
                                    setProjectTFSId(project.projectID);
                                  }}>
                                    <img className="actions-edit" src="images-new/edit-img.svg" alt="icon" />
                                  </button>
                                  <button type="button " className="btn border-0 p-0" disabled>
                                    <img className="action-reload" src="images-new/re-load.svg" alt="reload" />
                                  </button>

                                </div>
                              ) : getIsConfigured(project.projectID) === 1 ? (
                                // telpathri
                                <button type="button" className="btn border-0 text-color-1" onClick={() => {}} disabled>
                                LOADING
                              </button>

                              ) : (
                                <button type="button" className="btn border-0" 
                                disabled={!hasAccess} 
                                onClick={() => {
                                  setShowTfsPopUp(true);
                                  setProjectName(project.projectName);
                                  setProjectTFSId(project.projectID);
                                  setTfsConfigOrUpdate("configure")
                                  
                                  //(project.projectID, "asifjasoifjasiofjsaijg")
                                }}>
                                  <img className="actions-right" src="images-new/right-icon.svg" alt="icon" />
                                </button>
                              )}
                            </td>
                          </tr>
                          :
                          <tr>
                            <td className="ps-5  d-flex align-items-center">
                              <span className="project_logo_bg">{project.image_url?<img src={project.image_url} alt="Profile" class=" me-2" />
                                :
                                <img src="images-new/default-project-icon.svg" alt="Profile" class=" me-2" />
                                }</span>
                              <div className="d-block">
                                {getIsConfigured(project.projectID) == 2 ?
                                  (<a href className="d-block blue-link text-decoration-none font-14 font-semibold cursor-pointer" onClick={() => {
                                    localStorage.removeItem('projectIdFromMP')
                                    localStorage.removeItem('projectName')
                                    localStorage.setItem('projectIdFromMP',project.projectID)
                                    localStorage.setItem('projectNameFromLocal',project.projectName)
                                    localStorage.setItem('project_image', project.image_url)
                                    history.push({
                                      pathname: "/ManageTasks",
                                      state: {
                                        projectIdFromMP: project.projectID,
                                        workItemFromMP: "task",
                                        projectName : project.projectName
                                      }
                                    })
                                  }}>
                                    {project.projectName}</a>) : getIsConfigured(project.projectID) == 1 ? (
                                      <div>
                                        <span className="d-block font-14 font-semibold text-color-9"disabled>
                                          {project.projectName}</span>
                                      </div>
                                    ) :
                                    (<span className="d-block font-14 font-semibold text-color-9">
                                      {project.projectName}</span>)}
                                <p className="font-12 mb-0 secondary-color">{project.projectName}</p>
                              </div>
                            </td>
                            <td className="text-start text-color-9">{project.projectTimeline}</td>
                            <td className="text-start d-flex align-items-center gap-2">
                              <div>
                                {project.Status === "inProgress" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">In Progress</span></div>
                                ) : project.Status === "notStarted" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot not-started-bg "></span>
                                    <span class="not-started">Not Started</span></div>
                                ) : project.Status === "completed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">Completed</span></div>
                                ) : (project.Status === "inDelay" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot danger-bg"></span>
                                    <span class="in-delay">In Delay</span></div>
                                ) :
                                  <span>Unknown status</span>
                                )}
                              </div>
                            </td>
                            <td className="text-center pe-5">

                              {getIsConfigured(project.projectID) == 2 ? (
                                <div>

                                  <button type="button " className="btn border-0 p-0 me-2" onClick={() => {
                                    setShowTfsPopUp(true);
                                    setProjectName(project.projectName);
                                    setProjectTFSId(project.projectID);
                                    setTfsConfigOrUpdate("EDIT")
                                  }}>
                                    <img className="actions-edit" src="images-new/edit-img.svg" alt="icon" onClick={() => {
                                      setShowTfsPopUp(true);
                                      setProjectName(project.projectName);
                                    }} />
                                  </button>
                                  <button type="button " className="btn border-0 p-0" disabled>
                                    <img className="action-reload" src="images-new/re-load.svg" alt="reload" />
                                  </button>

                                </div>
                              ) : getIsConfigured(project.projectID) == 1 ? (
                                <button type="button" className="btn border-0 text-color-1" onClick={() => {
                                  setShowTfsPopUp(true);
                                  setProjectName(project.projectName);
                                }} disabled>
                                  LOADING
                                </button>

                              ) :
                                (
                                  <button type="button" className="btn border-0" 
                                  disabled={!hasAccess} 
                                  onClick={() => {
                                    setShowTfsPopUp(true);
                                    setProjectName(project.projectName);
                                    setProjectTFSId(project.projectID);
                                    setTfsConfigOrUpdate("configure")
                                  }} >
                                    <img className="actions-right" src="images-new/right-icon.svg" alt="icon" />
                                  </button>
                                )
                              }
                            </td>
                          </tr>}
                      </React.Fragment>

                    )))}

                  </tbody>
                </table>
              </div>
              <p className="font-regular font-14 text-color-9 my-3">
                      # of Records :{" "}
                      <span className="font-bold font-14 text-color-1">
                        {" "}
                        {project?.length}
                      </span>{" "}
                      out of{" "}
                      <span className="font-bold font-14 text-color-1">
                        {totalRecords}
                      </span>
                    </p>

              <div className="text-center mb-5">
                <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                  onClick={() => {
                    setEndOffset(prevEndOffset => prevEndOffset + 10);
                  }}
                  hidden={totalRecords < 10 || lengthOfRecords < 10}>
                  <span className="color-gradient">Load More</span></button>
              </div>
            </div>
            {/*grid starts here*/}
          </div>
          {/*manage request tab*/}
        </div>
        </div>
        </div>
        <div>
          {showTfsPopUp ? (
            <div>
              <ManageProjectForm projectName={projectName} setShowTfsPopUp={setShowTfsPopUp} projectID={projectTFSId} configureOrEdit={tfsConfigOrUpdate}></ManageProjectForm>
            </div>
          ) : (
            <div>
            </div>
          )}
        </div>
      </div>
      </>
      )}
    </>
  )
}

export default ManageProject