//PS_CGAI_15.1,PS_CGAI_15.2,PS_CGAI_15.3 Import the hooks and function from React and following file

import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getApprovalRequestData, getPromptFieldJson, fetchChat } from "../AI Service/Api";
import { AddFileOrFolderSample } from "./AddFileStuctureSample";
import UserContext from "../Auth/useContext";
import Loader from "../Other-Components/loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAuth } from "../Auth/authConfig";
import { getBindParams, insertModelConfig } from "../AI Service/Api";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";
import { getSessionItem } from "../util-services/encrypt-storage";


/** 
 * PS_CGAI_15.4 to PS_CGAI_15.24 ,Declare All the state variables needed for the functions.

 * PS_CGAI_15.56 to PS_CGAI_15.60, Onclick of uparrow button desc of the data's will take place.
 * PS_CGAI_15.61 to PS_CGAI_15.65, onclick of downarrow button asc of the data will take place.
 * PS_CGAI_15.66 to PS_CGAI_15.69, Fetch the data according to the field searched
 * PS_CGAI_15.90 to PS_CGAI_15.92 ,Onclick of Ok button set the isPromptopenPopup to false
 * PS_CGAI_15.103 to PS_CGAI_15.110,onchange event for filter fields
 * PS_CGAI_15.126 to PS_CGAI_129 ,onclick of manage request should navigate to the manage request page
 */

function UserActivityLogs(navOpen) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("createdDateTime Desc");
  const [count, setCount] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [codegenStatus, setCodegenStatus] = useState("");
  const [show, setShow] = useState(false);
  const [filterAlert, setFilterAlert] = useState("");
  const [shownRecords, setShownRecords] = useState(count);
  const [totalRecords, setTotalRecords] = useState(0);
  const [approvalStatusData, setApprovalStatusData] = useState("");
  const [codegenStatusData, setCodegenStatusData] = useState("");
  const [promptMessageJson, setPromptMessageJson] = useState("");
  const [approvalRequestData, setApprovalRequestData] = useState();
  
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [isAscendingPrompt, setIsAscendingPrompt] = useState(true);
  const [isAscendingDateTime, setIsAscendingDateTime] = useState(true);
  const [isAscendingUserName, setIsAscendingUserName] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [isAscendingApprovalStatus, setIsAscendingApprovalStatus] =
    useState(true);
  const [isAscendingCodegenStatus, setIsAscendingCodegenStatus] =
    useState(true);
  const [isPromptFieldPopupOpen, setIsPromptFieldPopupOpen] = useState(true);
  const [filterFlag, setFilterFlag] = useState(false);
  const [toDateAlert, setToDateAlert] = useState("");
  const userDetails = useContext(UserContext);

  const [userData, setuserData] = useState({});
  const { instance, accounts } = useMsal();
  const [flag, setFlag] = useState("")
  const [manageRequest, setManageRequest] = useState("");


  const [profileEmail, setProfileEmail]= useState("");

  const [sharePromptName , setSharePromptName] = useState("")
  const [shareChatID, setShareChatID] = useState("")  
  const [isSharePopupOpen , setIsSharePopupOpen] = useState("")
  const [chatContent, setChatContent] = useState("")
  const [shareDate, setShareDate] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const token = getSessionItem("access-token");

  const share = window.location.href;
  const url = new URL(share);
  const baseUrl = url.origin;
  
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  function setDate(utcDateTime) {
    // Parse the input date string
    const date = new Date(utcDateTime);

    // Define options for formatting the date
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };


    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Extract parts and assemble the custom format
    const parts = formatter.formatToParts(date);
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;
    const year = parts.find(part => part.type === 'year').value;
    const hour = parts.find(part => part.type === 'hour').value;
    const minute = parts.find(part => part.type === 'minute').value;
    const meridian = parts.find(part => part.type === 'dayPeriod').value;

    // Construct the custom formatted string
    const formattedDate = `${month}/${day}/${year} ${hour}:${minute} ${meridian}`;
    //(formattedDate, "OOOO");
    setShareDate(formattedDate)

    // return formattedDate;
  }

  function convertDate(val, index){
    const date = new Date(val);
    // Get year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
 
    // Combine into desired format
    const formattedDate = `${year}-${month}-${day}`;
 
    // console.log(formattedDate);
    if(index=='from'){
      setFromDate(formattedDate);
    }
    else if(index=='to'){
      setToDate(formattedDate)
    }
  }



 async function profileImg(profileEmail) {
  // console.log(profileEmail,"profileEmailprofileEmail" )
  try {
    let profileImage = await axios.get(
      `https://graph.microsoft.com/v1.0/users/${profileEmail}/photo/$value`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    )
    const reader = new FileReader();
    reader.readAsDataURL(profileImage.data);
    await timeout(500);
    profileImage = reader.result;
    //(profileImage,"jjjj");
    setProfileImage(profileImage)
    //(profileImage,profileImage.config.url,"ooooo")
    // setProfileImage(profileImage)

  }
  catch (error) {
    let profileImage = "images/Default/default-profile-image.png";
    //(profileImage, "ooooo")
    setProfileImage(profileImage)
  }
}

useEffect(()=>{
  profileImg()
},[profileEmail])


  const getChatMessages = async (chatID) => {
    // 
    //(chatID,"OOOOOOOOO");
    // sethiddenObjValues({
    //   ...hiddenObjValues,
    //   binding: false,
    // });

    try {
      let result = await fetchChat({ chatID: chatID });
      //(result.ResponseData.currentChat,"LLLLLLLLLLLLLL");
      setChatContent(result.ResponseData.currentChat)
      // console.log(result.ResponseData, "poiuytrewqwertyuio")
    }
    catch (error) {
      //(error)

    }
  }

  function bindChatContent(chatContent) {
    // 
    // console.log(profileImage)
    if (chatContent && Array.isArray(chatContent)) {
      return chatContent.map((value, index) => (

        <div className="col-md-12 py-3 px-3">
          <span className="d-flex">
            <span className="d-block">
              <img className style={{ height: "44px", width: "44px", borderRadius: "50%" }} src={value.role === "user" ? profileImage : "images/Default/asami-bot.svg"} alt="profile-pic" />
            </span>
            <span className="d-block ms-3 mt-2">
              <h5 className="font-18 font-bold text-color-1">{value.role === "user" ? "You" : "Asami"}</h5>
              <p className="font-medium font-16 text-color-10">{value.content}</p>
            </span>
          </span>
        </div>


      ));
    }
  }


  useEffect(() => {
    //("hello !!")
    fetchBindParams();
  }, []);
  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      setManageRequest(response.data.manageRequest[0]["isActive"])

    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_15.25 Declare an variable history and assign useHistory Hook to it.
   */
  const history = useHistory();

  /**
   *PS_CGAI_15.26 Give the useEffect and call the function fetchChatHistory  
 with dependencies search,sort and count.
   */
  useEffect(() => {

    if (userDetails?.email) {
      setuserData(userDetails);
      fetchApprovalRequestData()
    }

  }, [userDetails]);
  useEffect(() => {

    if (userDetails?.email) {
      fetchApprovalRequestData();
    }

  }, [sort, count]);


  useEffect(() => {
    //(accounts,"account");
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);



  let currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  /**
   * PS_CGAI_15.27 to PS_CGAI_15.41 Call the fetchApprovalrequestData Function where getChatHistory 
   function will be triggerd and data will get from the backend
   */
  const fetchApprovalRequestData = async (obj = false) => {

    try {
      let approvalRequestDataObject = {
        search: search,
        sort: sort,
        count: count,
        fromDate: fromDate,
        toDate: toDate,
        approvalStatusName: approvalStatus,
        codegenStatusName: codegenStatus,
        approverEmailID: userDetails?.email,
      };
      approvalRequestDataObject = obj ? obj : approvalRequestDataObject;

      if (
        approvalRequestDataObject.fromDate != "" ||
        approvalRequestDataObject.toDate != "" ||
        approvalRequestDataObject.approvalStatusName != ""
      ) {
        setFilterFlag(true);
      } else {
        setFilterFlag(false);
      }
      setIsLoading(false)

      let response = await getApprovalRequestData(
        obj ? obj : approvalRequestDataObject
      );

      if (response.status === 200) {

        setIsLoading(true)
        let record = response.data.ResponseData[0].length - shownRecords;
        setShownRecords(record + shownRecords);
        setApprovalRequestData(response.data.ResponseData[0]);
        setApprovalStatusData(response.data.ResponseData[2]);
        setCodegenStatusData(response.data.ResponseData[3]);
        //(response.data.ResponseData,"PROMPTCOUNT")
        setTotalRecords(response.data.ResponseData[1][0].PromptCount);
      }
    } catch (error) {
      //(error);
    }
  };



  /**
   * PS_CGAI_15.50 to PS_CGAI_15.55, Trigger the loadmore functionality and add the 10 more content to the grid.
   */

  const loadmoreClick = () => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };

  /**
   * PS_CGAI_15.111 to PS_CGAI_15.120, on submit do the validation and filter the contents
   */

  function filterValidation() {
    let flag = 0;

    if (approvalStatus !== "") {
      flag++;
    }
    if (codegenStatus !== "") {
      flag++;
    }
    if (fromDate !== "") {
      flag++;
    }
    if (toDate !== "") {
      flag++;
    }

    if (flag !== 0) {
      setShow(false);
      setFilterAlert("");
      fetchApprovalRequestData();
    } else {
      if (
        approvalStatus === "" &&
        codegenStatus === "" &&
        fromDate === "" &&
        toDate === ""
      ) {
        setFilterAlert("alert");
      } else {
        setFilterAlert("");
      }
    }
  }

  /**
   *  PS_CGAI_15.121 to PS_CGAI_15.125,on cancel empty the state variable and close the filter.
   */
  let cancelFilter = () => {
    setFilterAlert("");
    setFromDate("");
    setToDate("");
    setApprovalStatus("");
    setCodegenStatus("");
    setShow(false);

    let approvalRequestDataObject = {
      search: search,
      sort: sort,
      count: count,
      fromDate: "",
      toDate: "",
      approvalStatusName: "",
      codegenStatusName: "",
      approverEmailID: userDetails?.email,
    };
    if (filterFlag) {
      fetchApprovalRequestData(approvalRequestDataObject);
    }
  };

  /**
   * This function will format the date which has been given in IST with time
   * @param {pass the current date} date
   * @returns the formatted date
   */

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (hours < 10) {
      hours = "0" + hours; // Add leading zero for single-digit hours
    }

    if (minutes < 10) {
      minutes = "0" + minutes; // Add leading zero for single-digit minutes
    }

    return (
      [month, day, year].join("/") +
      " " +
      [hours, minutes].join(":") +
      " " +
      ampm
    );
  };
  /**
   * PS_CGAI_15.70 to PS_CGAI_15.89,onclick of the promptName bind the json information of the particular prompt in the popup
   * @param {Id of the particular prompt} id
   */
  const fetchPromptFieldJson = async (id) => {
    try {
      const response = await getPromptFieldJson({ chatID: id });
      const parsedJson = JSON.parse(response.data.ResponseData.content);
      setPromptMessageJson(parsedJson);
      setIsPromptFieldPopupOpen(false);
    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_15.93 to PS_CGAI_15.98, bind the approval status choices dynamically
   * @param {state variable which has approvalStatus Data from the Database} approvalStatusData
   * @returns the filter with binded data's
   */
  function bindApprovalStatus(approvalStatusData) {
    if (approvalStatusData && Array.isArray(approvalStatusData)) {
      return approvalStatusData.map((value, index) => (
        <option
          value={value.approvalStatusName}
          id={value.approvalStatusID}
          key={index}
        >
          {value.approvalStatusName}
        </option>
      ));
    }
  }

  /**
   * PS_CGAI_15.99 to PS_CGAI_15.102, bind the approval status choices dynamically
   * @param {state variable which has codegenStatus Data from the Database} codegenStatusData
   * @returns the filter with binded data's
   */

  function bindCodegenStatus(codegenStatusData) {
    if (codegenStatusData && Array.isArray(codegenStatusData)) {
      return codegenStatusData.map((value, index) => (
        <option
          value={value.codegenStatusName}
          id={value.codegenStatusID}
          key={index}
        >
          {value.codegenStatusName}
        </option>
      ));
    }
  }

  /**
   *
   * @param {approvalRequestData state variable which has the grid data's} approvalRequestData
   * PS_CGAI_15.42 to PS_CGAI_15.49,set the state variables with the data's and bind it to the grid
   * @returns  the binded data in the grid to the user
   */
  function bindApprovalRequestData(approvalRequestData) {

    if (approvalRequestData != undefined) {


      if (approvalRequestData && Array.isArray(approvalRequestData)) {
        return approvalRequestData?.map((value) => {

          return (
            <tr>
              <td className="ps-4">{value.userName}</td>
              <td>{value.userEmailID}</td>
              <td>{formatDate(value.createdDateTime)}</td>
              <td style={{cursor:"pointer"}}>
                <a
                  className="text-decoration-none link-color"
                  data-bs-toggle="modal"
                  data-bs-target="#share-convo-popup-chatHistory"
                  onClick={(e) => {
                    let chatID = `${value.chatID}`;
                    fetchPromptFieldJson(chatID);
                    setIsPromptFieldPopupOpen(false);
                    setSharePromptName(value.chatType === 'General' ? 'Ask Asami' : value.promptName);
                    setShareChatID(value.chatID)
                    profileImg(value.userEmailID)
                    setProfileEmail(value.userEmailID)
                    // history.push(`/chat?chatID=${value.chatID}&share=true`);
                    getChatMessages(value.chatID)
                    setIsSharePopupOpen(true)
                  }}
                >
                  {value.promptName}
                </a>
              </td>
              {manageRequest ? (<td className="text-center">
                {" "}
                <span
                  className={`table-status ${value.approvalStatusName === "Rejected"
                    ? "danger-bg"
                    : value.approvalStatusName === "Approved"
                      ? "success-bg"
                      : "pending-bg"
                    } my-2`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title={`${value.approvalStatusName === "Approved"
                    ? "Approved"
                    : value.approvalStatusName === "Rejected"
                      ? "Rejected"
                      : "pending"
                    }`}
                />{" "}
              </td>) : null}
              <td className="text-center">
                {" "}
                <span
                  className={`table-status ${value.codegenStatusName === "Abort"
                    ? "danger-bg"
                    : value.codegenStatusName === "Success"
                      ? "success-bg"
                      : "pending-bg"
                    } my-2`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title={`${value.codegenStatusName === "Success"
                    ? "Success"
                    : value.codegenStatusName === "Abort"
                      ? "Abort"
                      : "In Progress"
                    }`}
                />{" "}
              </td>
            </tr>
          );
        });
      }
    } else {
      return null;
    }
  }

  return (
    <>
      {!isLoading ? <Loader isLoading={isLoading} /> :

       <>
          {/* request log grid  starts  */}
         
          <div className="row w-100 justify-content-lg-end justify-content-center">
          <div className={navOpen.navOpen == "Close" ? "col-11 ps-lg-5 me-xl-4 me-lg-2" : "col-lg-9 col-11 me-xl-4 me-lg-2 me-0 ms-3 ms-md-0"} id="chat-history-center" >
            
            <div className="row" >
              {/* <div className="row"> */}
              <div className="col-md-12" >
                <div className="mt-5 mb-3 d-flex justify-content-between align-items-center">
                  {/* tab structure starts here */}
                  {manageRequest ? (
                    <ul
                      className="nav nav-pills mb-3 "
                      id="pills-tab"
                      role="tablist"
                    >

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link custom-tabs font-16 font-medium"
                          id="pills-managerequest-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-managerequest"
                          type="button"
                          role="tab"
                          aria-controls="pills-managerequest"
                          aria-selected="false"
                          onClick={() => {
                            history.push("/asami-manage-request");
                          }}
                        >
                          Manage Request
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link  custom-tabs active font-16 font-medium"
                          id="pills-useractivity-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-useractivity"
                          type="button"
                          role="tab"
                          aria-controls="pills-useractivity"
                          aria-selected="true"
                        >
                          User Activity Logs
                        </button>
                      </li>
                    </ul>) :
                    <h3 className="font-20 font-semibold text-color-1">User Activity Logs</h3>}
                  <div className="table-header d-flex align-items-center filter-fix">
                    <div className="input-group transparent-grp">
                      <input
                        type="text"
                        className="form-control border-0 focus-none font-14 font-medium search-bg text-color-2"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            fetchApprovalRequestData()
                          }
                        }}
                        value={search}

                      />
                      <button className="btn focus-none search-bg" type="button" onClick={(e) => {
                        fetchApprovalRequestData()
                      }}>
                        <img
                          className="filter-icons"
                          src="images/Default/input-search.svg"
                          alt="input-search-icon"
                        />
                      </button>
                    </div>
                    {/* <button className="btn text-nowrap ms-3 btn-outline-danger custom-danger-btn font-semibold font-14 py-2">
                  Reject Request
                </button>
                <button className="btn text-nowrap ms-3 theme-color-bg text-white theme-focus font-semibold font-14  py-2">
                  Approve Request
                </button> */}
                    <div className="dropdown ms-3 mr-4x">
                      <button
                        type="button"
                        className="btn font-16 font-semibold search-bg align-items-center d-flex transparent-grp py-2 theme-focus"
                        onClick={() => {
                          setShow(true);
                        }}
                        // data-bs-toggle="dropdown"
                      >
                        <img
                          className="filter-icons"
                          src="images/Default/adv-filter.svg"
                          alt="filter-icon"
                        />
                      </button>
                      {/* advanced filter starts here */}

                      {show ? (
                        <span
                          className="dropdown-menu dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown end-0 bg-color-4"
                          style={{ display: show ? "block" : "none" }}
                          // data-bs-popper="none"
                          // data-popper-placement="bottom-end"
                        >
                          <span className="d-flex justify-content-between align-items-center mb-3">
                            <span className="font-18  font-semibold text-color-1">
                              Advanced Filter
                            </span>
                            <span
                              onClick={() => {
                                setShow(false);
                              }}
                            >
                              <img
                                src="images/Default/flter-close-icon.svg"
                                alt="flter-close-icon"
                                className="cursor-pointer close-icon-filter"
                                onClick={() => {
                                  setShow(false);
                                }}
                              />
                            </span>
                          </span>
                          <span className="mb-2 d-block">
                            <span className="d-block font-semibold font-16 text-color-2">
                              Date Range
                            </span>
                          </span>
                          <span className="row mb-2">
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label
                                  htmlFor="from"
                                  className="form-label font-14 font-medium text-color-9"
                                >
                                  From
                                </label>
                                <div className="position-relative">
                                <DatePicker
                                    id="from"
                                    selected={fromDate}
                                    onChange={(date) => {
                                      convertDate(date,'from')
                                      setToDate(null);
                                    }}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                   placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('from').focus()}
                                  />
                                  </div>
                              </span>
                            </span>
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label
                                  htmlFor="to"
                                  className="form-label font-14 font-medium text-color-9"
                                >
                                  To
                                </label>
                                <div className="position-relative">
                                <DatePicker
                                    id="to"
                                    selected={toDate}
                                    onChange={(date) => convertDate(date,'to')}
                                    minDate={fromDate}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('to').focus()}
                                  />
                                  </div>
                              </span>
                            </span>
                            {toDateAlert === "toDataAlert" ? (
                              <div className="span-color ">
                                Enter the toDate greater than fromDate
                              </div>
                            ) : null}
                          </span>
                          <span className="mb-2 d-block">
                            <span className="d-block font-semibold font-16 text-color-2">
                              Status
                            </span>
                          </span>
                          <span className="row mb-4">
                            {manageRequest ? <>
                              <span className="mb-3 d-block col-md-12">
                                <span className="mb-3">
                                  <label
                                    htmlFor="from"
                                    className="form-label font-14 font-medium text-color-9"
                                  >
                                    Approval Status
                                  </label>
                                  <select
                                    className="form-select text-color-1"
                                    value={approvalStatus}
                                    onChange={(e) => {
                                      setApprovalStatus(e.target.value);
                                    }}
                                    onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                  >
                                    <option selected>Select</option>
                                    {bindApprovalStatus(approvalStatusData)}
                                  </select>
                                </span>
                              </span>
                            </> : <></>}

                            <span className="mb-3 d-block col-md-12">
                              <span className="mb-3">
                                <label
                                  htmlFor="to"
                                  className="form-label font-14 font-medium text-color-9"
                                >
                                  Asami Status
                                </label>
                                <select
                                  className="form-select text-color-2 custom-border-3 text-color-4 bg-color-4"
                                  value={codegenStatus}
                                  onChange={(e) => {
                                    setCodegenStatus(e.target.value);
                                  }}
                                  onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                >
                                  <option selected>Select</option>
                                  {bindCodegenStatus(codegenStatusData)}
                                </select>
                              </span>
                            </span>
                          </span>
                          <span className="w-100 d-flex justify-content-end">
                            <button
                              className="btn custom-outline-btn  font-14 px-3 py-2 me-3 font-medium font-14 "
                              onClick={() => {
                                cancelFilter();
                              }}
                            >
                              Clear
                            </button>
                            <button
                              className="btn primary-btn text-white font-14 px-3 py-2 font-medium font-14 theme-focus"
                              onClick={() => {
                                filterValidation();
                              }}
                            >
                              Apply
                            </button>
                          </span>
                          {filterAlert == "alert" ? (
                            <div className="span-color">
                              Select,atleast one filter!
                            </div>
                          ) : null}
                        </span>
                      ) : (
                        <></>
                      )}
                      {/* advanced filter ends here */}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade"
                    id="pills-managerequest"
                    role="tabpanel"
                    aria-labelledby="pills-managerequest-tab"
                  >
                    <div className="table-responsive rounded-3 transparent-grp"></div>
                    <p className="font-regular font-14 text-color-9 mt-3 mb-4"> 
                  # of Records :{" "}
                  <span className="font-bold font-14 text-color-1">
                    {totalRecords > shownRecords ? shownRecords : totalRecords}
                  </span>{" "}
                  out of{" "}
                  <span className="font-bold font-14 text-color-1">
                    {totalRecords}
                  </span>
                </p>
                <div className="text-center mb-5">
                  <button
                    className="btn gradient-btn  border-radius-4 font-14 font-semibold py-2 px-3"
                    style={{ color: "#865BE7" }}
                    onClick={() => {
                      loadmoreClick();
                    }}
                    // disabled={isLoadMoreDisabled}
                    hidden={totalRecords <= 10 || isLoadMoreDisabled}
                  >
                    {" "}
                    <span className="color-gradient">Load More</span>
                  </button>
                </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="pills-useractivity"
                    role="tabpanel"
                    aria-labelledby="pills-useractivity-tab"
                  >
                    <div className="table-responsive rounded-3 transparent-grp">
                      <table className="table theme-table font-regular font-14 mb-0">
                        <thead>
                          <tr>
                            <th
                              className="ps-4"
                              onClick={() => {
                                setIsAscendingUserName(!isAscendingUserName);
                                setSort(
                                  isAscendingUserName
                                    ? "userName Desc"
                                    : "userName Asc"
                                );
                              }}
                            >
                              User Name
                              <span className="cursor-pointer">
                                {" "}
                                <img
                                  className="filter-icons"
                                  src={
                                    isAscendingUserName
                                      ? "images/Default/sort-up.svg"
                                      : "images/Default/sort-down.svg"
                                  }
                                  alt={
                                    isAscendingUserName ? "sort-up" : "sort-down"
                                  }
                                />
                              </span>
                            </th>
                            <th>
                              Email Address
                              <span className="cursor-pointer"></span>
                            </th>
                            <th
                              onClick={() => {
                                setIsAscendingDateTime(!isAscendingDateTime);
                                setSort(
                                  isAscendingDateTime
                                    ? "createdDateTime Desc"
                                    : "createdDateTime Asc"
                                );
                              }}
                            >
                              Date &amp; Time
                              <span className="cursor-pointer">
                                {" "}
                                <img
                                  className="filter-icons"
                                  src={
                                    isAscendingDateTime
                                      ? "images/Default/sort-up.svg"
                                      : "images/Default/sort-down.svg"
                                  }
                                  alt={
                                    isAscendingDateTime ? "sort-up" : "sort-down"
                                  }
                                />
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                setIsAscendingPrompt(!isAscendingPrompt);
                                setSort(
                                  isAscendingPrompt
                                    ? "promptName Desc"
                                    : "promptName Asc"
                                );
                              }}
                            >
                              Prompt
                              <span className="cursor-pointer">
                                {" "}
                                <img
                                  className="filter-icons"
                                  src={
                                    isAscendingPrompt
                                      ? "images/Default/sort-up.svg"
                                      : "images/Default/sort-down.svg"
                                  }
                                  alt={
                                    isAscendingPrompt ? "sort-up" : "sort-down"
                                  }
                                />
                              </span>
                            </th>
                            {manageRequest ? <>
                              <th
                                className="text-center"
                                onClick={() => {
                                  setIsAscendingApprovalStatus(
                                    !isAscendingApprovalStatus
                                  );
                                  setSort(
                                    isAscendingApprovalStatus
                                      ? "approvalStatusName Desc"
                                      : "approvalStatusName Asc"
                                  );
                                }}
                              >
                                Approval Status
                                <span className="cursor-pointer">
                                  {" "}
                                  <img
                                    className="filter-icons"
                                    src={
                                      isAscendingApprovalStatus
                                        ? "images/Default/sort-up.svg"
                                        : "images/Default/sort-down.svg"
                                    }
                                    alt={
                                      isAscendingApprovalStatus
                                        ? "sort-up"
                                        : "sort-down"
                                    }
                                  />
                                </span>
                              </th>
                            </> : <></>}

                            <th
                              className="text-center"
                              onClick={() => {
                                setIsAscendingCodegenStatus(
                                  !isAscendingCodegenStatus
                                );
                                setSort(
                                  isAscendingCodegenStatus
                                    ? "codegenStatusName Desc"
                                    : "codegenStatusName Asc"
                                );
                              }}
                            >
                              Asami Status
                              <span className="cursor-pointer">
                                {" "}
                                <img
                                  className="filter-icons"
                                  src={
                                    isAscendingCodegenStatus
                                      ? "images/Default/sort-up.svg"
                                      : "images/Default/sort-down.svg"
                                  }
                                  alt={
                                    isAscendingCodegenStatus
                                      ? "sort-up"
                                      : "sort-down"
                                  }
                                />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalRecords != 0 ? (
                            bindApprovalRequestData(approvalRequestData)
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center py-5 vh-50">
                                <img
                                  src="images/Default/no-record-img.svg"
                                  alt="no-record-img"
                                  className="no-record-img mb-5"
                                />
                                <span className="font-20 font-medium w-100 d-block text-color-1">
                                  Sorry! You don’t have any recent User Activity
                                  Logs
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <p className="font-regular font-14 text-color-9 my-3">
                      # of Records :{" "}
                      <span className="font-bold font-14 text-color-1">
                        {" "}
                        {totalRecords > shownRecords
                          ? shownRecords
                          : totalRecords}
                      </span>{" "}
                      out of{" "}
                      <span className="font-bold font-14 text-color-1">
                        {totalRecords}
                      </span>
                    </p>
                <div className="text-center mb-5">
                  <button
                    className="btn gradient-btn  border-radius-4 font-14 font-semibold py-2 px-3"
                    style={{ color: "#865BE7" }}
                    onClick={() => {
                      loadmoreClick();
                    }}
                    // disabled={isLoadMoreDisabled}
                    hidden={totalRecords <= 10 || isLoadMoreDisabled}
                  >
                    {" "}
                    <span className="color-gradient">Load More</span>
                  </button>
                </div>
                  </div>
                </div>
                {/* tab structure ends here */}
              </div>
              {/* </div> */}
            </div>
          </div>
          </div>
          
          {/* request log grid  ends  */}

        </>
      }



      
      <div hidden={isPromptFieldPopupOpen}>
        <div
          className="modal fade"
          id="specifiedprompts-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/* Modal content */}
          <div className="modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0">
            <div className="modal-content p-3 border-0 bg-color-4">
              <div className="modal-header border-0 pt-2 pb-0">
                <h5 className="modal-title font-22 font-bold text-color-1">
                  Specified Prompts
                </h5>
                <button
                  type="button"
                  className="btn-close close-icon-filter"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setIsPromptFieldPopupOpen(true);
                  }}
                />
              </div>
              <div className="modal-body popup-scrl">
                <div className="row">
                  {Object.entries(promptMessageJson).map(([key, value]) => (
                    <>
                      {key.trim() === "Any existing file structure" ? (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            <AddFileOrFolderSample props={value} />
                          </span>
                        </div>
                      ) : key.trim() === "Tech Stack" ||
                        key.trim() === "Type Of Code" ? (
                        <div className="col-md-6 mb-3" key={key}>
                          <label className="font-14 font-semibold text-color-9">
                            {key}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      ) : (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="modal-footer border-0 d-flex justify-content-center pt-0">
                <button
                  type="button"
                  className="btn primary-btn text-white font-14 px-4 py-2 font-medium theme-focus"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>

          

        </div>
      </div>

      <div class="row justify-content-center">
        <div className="modal fade" id="share-convo-popup-chatHistory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered custom-popup-width border-0">
            <div className="modal-content p-3 border-0 bg-color">
              <div className="modal-header border-0 pt-2 px-1 pb-0">
                <h5 className="modal-title text-color-1 font-20 font-bold">Share Conversation Link</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body pt-2">
                <div className="row border border-radius-2 custom-border-5">
                  <div className="custom-convo-scroll">
                    {/*conversation*/}
                    {bindChatContent(chatContent)}
                    {/*conversation*/}
                    {/*buttons*/}
                  </div>
                  <div className="d-flex justify-content-between align-items-center border-top py-3">
                    <div>
                      <h5 className="font-16 font-medium text-color-1 mb-0" id="promptNameDisplay">{sharePromptName}</h5>
                      <span className="font-14 font-medium text-color-10">{shareDate}</span>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => navigator.clipboard.writeText(baseUrl + `/chat?chatID=${shareChatID}&share=true`).then(() => {
                          // Optional: Display a message that the URL has been copied.
                          //('URL copied to clipboard!');
                          // history.push("/Asami-user-activity")
                          setIsSharePopupOpen(false)

                        }).catch(err => {
                          // Error handling
                          console.error('Failed to copy: ', err);
                        })}
                      >
                        <img className="me-2" src="images/Default/link-icon.svg" alt="link-icon" />
                        Copy Link
                      </button>
                    </div>
                  </div>
                  {/*buttons*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default UserActivityLogs;
