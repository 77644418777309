//PS_CGAI_3.1 to PS_CGAI_3.2 necessary imports
import React from "react";
import * as XLSX from 'xlsx';
import { useEffect, useRef } from "react";
import { fetchPrompt, fetchChat, serpAPI, generateNewPrompt, getBindParams, pushToTFSFunc, similarityCheck, insertChatID, CostCalculationNav } from "../AI Service/Api";
import { useState } from "react";
import { ClipLoader } from 'react-spinners';
import MyContext from "./MyContext";
import UserContext from "../Auth/useContext";
import { PromptFormComponent } from "./PromptFormComponent";
import { useContext } from "react";
import { modelId } from "../config/prod.env";
import ReactDiffViewer from 'react-diff-viewer';
import {
  insertMessage,
  talkToAI,
  upsertNotification,
  errorLogs,
  updateApproval,
} from "../AI Service/Api";
import { CodeSnippetComponent } from "./CodeSnippetComponent";
import { ChatHistory } from "./ChatHistory";
import Loader from "../Other-Components/loader";
import LoadingIndicator from "./LoadingIndicator";
import { EditorComponent } from "./Editor";
import ManageRequest from "./ManageRequest";
import { AddFileOrFolderSample } from "./AddFileStuctureSample";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getSessionItem } from "../util-services/encrypt-storage";
import { useHistory, useLocation } from "react-router-dom";
import PopUpComponent from "./Popup";
import axios from "axios";
import TextStream, { index, set_Index_zero } from "./TextStream";
import { isAuth } from "../Auth/authConfig";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import devConfig from "../config/env";
import { logger } from "@azure/storage-blob";
import { pseudocodeCSV } from "../AI Service/Api";
import { LoadBalancer } from "../AI Service/Api";
import { KBRetriever, getImageDocResponse, getBlobURL } from "../AI Service/Api";
import { AddKnowledgeBase } from "./AddKnowledgeBase";
import { ConfigContext } from "./ConfigContext";
import ConfigurationButton from "./ChatConfigure";
import { DownloadAsExcel } from "../AI Service/Api";
import { UrlsAi } from "../api/Api-Constants";
import FeedBackForm from "./FeedBackForm";
import { left } from "@popperjs/core";
const plantUmlRegex = /^@startuml|(?:^|\s)\bplantuml\b/i
const d2Regex = /^--d2\s*\n/m;
const mermaidRegex = /^--mermaid\s*\n/m;
let globalJSON;
let AI_token;
let AI_Url;
let message_Order = 1;
let promptIDMessagesArray = [];



let block_trigger_once = true;
let block_Get_once = true;


/**
 * PS_CGAI_3.3 to decalre the necessary state variable for the funtion
 * PS_CGAI_3.7 to PS_CGAI_3.11 check for the chat id in useEffect load based on that call funtion show parompt and getachat ans set the date
 * PS_CGAI_3.41 to PS_CGAI_3.45 required useEffect to set date and getPromptMessage() and hiddenObjValues
 * PS_CGAI_3.52 to PS_CGAI_3.61 user clicks on the prompts disble the prompt and show the link to fill the form and show the form to the user if they clicks on the link
 * PS_CGAI_3.183 to PS_CGAI_3.193 based on th efix response show the input fields
 * @returns return the chat for the user
 */
export default function ChatComponent({ navOpen, setRun, ChatHistory, settrue001, dropdown, movechat }) {

  const [defaultModelFromEnv, setDefaultModelFromEnv] = useState("")
  const [profile, setProfile] = useState("")
  const { config } = useContext(ConfigContext);
  const myElement = useRef(null);

  // Use the configuration values as needed
  //(config.maxResponse, config.temperature, "poiuytrewq");
  //(navOpen);

  
  const location = useLocation();

  //(navOpen);

  const search = window.location.search;
  const newChat = new URLSearchParams(search).get("chatID");

  const isAuthenticated = useIsAuthenticated();

  const [selectedFunction, setSelectedFunctionCode] = useState("");
  const [selectedParentName, setSelectedParentName] = useState()
  const [selectedStartLine, setSelectedStartLine] = useState()
  const [selectedEndLine, setSelectedEndLine] = useState()
  const [leftCode, setLeftCode] = useState("");
  const [rightCode, setRightCode] = useState("")
  
  const [leftCodeArray, setLeftCodeArray] = useState({});
  const [rightCodeArray, setRightCodeArray] = useState({});
  const [showCodeCompare, setShowCodeCompare] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const hasUpdatedRef = useRef(false);
  const { functions15, isSimilarityCheckDone, projectID, Repo_url, PAT, taskID, tfsCollectionName, projectName,   workItem } = location.state || { functions15: [], isSimilarityCheckDone: false };
  //console.log(taskID, functions15, isSimilarityCheckDone, projectID, Repo_url, PAT,tfsCollectionName, projectName, workItem,   "Printing props from manage task dyurrrrrrrrrr")

//console.log(localStorage.getItem('projectNameFromLocal'), "projectNameFromLocal")
//console.log(localStorage.getItem('workItemFromMI'), "workItemFromMI")
//console.log(localStorage.getItem('collectionNameFromMI'), "collectionNameFromMI")
//console.log(localStorage.getItem('issueIDFromMI'), "issueIDFromMI")


let projectNameFromLocal=localStorage.getItem('projectNameFromLocal')
let workItemFromMI=localStorage.getItem('workItemFromMI')
let collectionNameFromMI=localStorage.getItem('collectionNameFromMT')
let issueIDFromMI=localStorage.getItem('issueIDFromMI')
//console.log(collectionNameFromMI,workItemFromMI,  projectNameFromLocal, "oiuytrewerty7u8iuytrdesadrtyui9")

// //console.log(localStorage.getItem('projectNameFromLocal'), "projectNameFromLocal")

const [popMsg, setPopMsg]= useState(false)
  const similarFunction = functions15?.slice(0, 5);
  const additionalSimilarFunction = functions15?.slice(5, 15);
  const [similarFunctions, setSimilarFunctions] = useState(similarFunction);
  const [PATState, setPATState] = useState()
  const [repoURLState, setRepoUrlState] = useState()
  const [additionalSimilarFunctions, setAdditionalSimilarFunction] = useState(additionalSimilarFunction)
  //console.log(similarFunctions, additionalSimilarFunctions, "similarFunctions")
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(0);
  //console.log(currentFunctionIndex, "currentFunctionIndex")

  const [codeSnippetValue, setCodeSnippetValue] = useState("");
  
  const [incrementval, setincrementval] = useState({})
  const [regen, setRegen] =  useState(false)
  const [regenTextarea, setRegenTextarea]= useState(false)
  const [userChatId, setUserChatId]= useState('')
  let [isModule, setIsModule] = useState('');
 
 
  // const [sendButton, setSendButton] = useState(false)
  function incrementfunc(Chat_index) {
    setincrementval((previncrementval) => ({
      ...previncrementval,
      [Chat_index]: previncrementval.hasOwnProperty(Chat_index) ? previncrementval[Chat_index] + 1 : 1
    }))
    //console.log("mervin here for incremental val", incrementval, currentChat)
  }
  function decreamentfunc(Chat_index) {
    setincrementval((previncrementval) => ({
      ...previncrementval,
      [Chat_index]: previncrementval.hasOwnProperty(Chat_index) ? previncrementval[Chat_index] - 1 : 1
    }))
    //console.log("mervin here for decremental val", incrementval, currentChat)
  }
  useEffect(() => {
    setPATState(PAT)
    setRepoUrlState(Repo_url)
  }, [])

  let keysToHide = {
    promptHide: true,
    helpMe: true,
    requestSendMsg: true,
    approvedMsg: true,
    rejectedMsg: true,
    inputFix: "Send a message...",
    promptDisabled: false,
    sendMessage: true,
    regenerateBtn: false,
    success: true,
    status: "",
    awaiting: true,
    manageRequest: true,
    popUp: false,
    popUpErrorMsg: "",
    popUpId: "",
    loader: false,
    binding: true,
    showMore: false,
    responseBtn: false,
    index: 0,
    tokenError: "",
    responseLoading: true,
    invalidChat: false,
    APIError: "",
    hidechathistorycomponent: false,
  };
  const [excelURLs, setExcelURLs] = useState({});
  const [modelID, setModelID] = useState("")
  const [IsKbRetrieverActive, setIsKbRetrieverActive] = useState(false);
  const [isLoad,setIsLoad] = useState(false)
  const [hiddenObjValues, sethiddenObjValues] = useState(keysToHide);
  const [loading, setLoading] = useState(false);
  const [jsonObj, setJSON] = useState();
  const [promptData, setpromptData] = useState([]);
  let [currentChat, setcurrentChat] = useState([]);
  const [currentPrompt, setcurrentPrompt] = useState();
  const [promptIDMessages, setpromptIDMessages] = useState();
  const [currentPromptID, setcurrentPromptID] = useState();
  const userDetails = useContext(UserContext);
  const [formHidden, setformHidden] = useState(false);
  const [promptName, setpromptName] = useState();
  const [chatName, setChatName] = useState();
  const [approverName, setapproverName] = useState();
  let [messageOrder, setmessageOrder] = useState(1);
  let [replaceObj, setreplaceObj] = useState();
  const [flag, setfalg] = useState(0);
  const [lastArrayObj, setlastArrayObj] = useState({});
  const [systemMessage, setSystemMessage] = useState();
  let [sendMessageValue, setsendMessageValue] = useState("");
  const [currentChatID, setcurrentChatID] = useState("");
  const [fixValue, setfixValue] = useState("");
  const [CodeSnippet, setCodeSnippet] = useState(false);
  const [promptSearch, setPromptSearch] = useState("");
  const [hide, setHide] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hidePrompts, setHidePrompts] = useState(false)
  let [AIToken, setAIToken] = useState();
  let [AIUrl, setAIUrl] = useState();
  const [finishReason, setFinishReason] = useState("");
  let [tokenLimit, setTokenLimit] = useState(0);
  const [data, setData] = useState("");
  const [userData, setUserData] = useState({});
  const [utcDateTime, setUtcDateTime] = useState(new Date());
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelEndpoint, setModelEndpoint] = useState("");
  const [modelType, setModelType] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [downloadPseudo, setDownloadPseudo] = useState("");
  const [kbFiles, setKbFiles] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const token = getSessionItem("access-token");
  const [kbPromptName, setKbPromptName] = useState("")
  const [collectionName, setCollectionName] = useState("")
  const [indexName, setIndexName] = useState("")
  const [kbQuery, setKbQuery] = useState("")
  const [isKbRetriever, setIsKbRetriever] = useState()
  const [searchPromptData, setSearchPromptData] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [collapse, setCollapse] = useState(false)
  const [excelFileUrl, setExcelFileUrl] = useState(null);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const fillOut = useRef();
  const [filename, setfilename] = useState("");
  const [startConversation, setStartConversation] = useState(false);
  const [fixButtonHide, setFixButtonHide] = useState(false);
  const [askAsamiChatName, setAskAsamiChatName] = useState("");
  const [apiCallMade, setApiCallMade] = useState(false);
  const recognitionRef = useRef(null);
  const [voiceToText, setVoiceToText] = useState("");
  // const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const transcriptRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [askAsamiCollectionName, setAskAsamiCollectionName] = useState("");
  const [askAsamiIndexName, setAskAsamiIndexName] = useState("");
  const [askAsamiKbUrl, setAskAsamiKbUrl] = useState("");
  const [askAsamiKnowledge, setAskAsamiKnowledge] = useState("");
  const [kbAskAsamiRetrieverUrl, setKbAskAsamiRetrieverUrl] = useState("");
  const [knowledgeBaseButton, setKnowledgeBaseButton] = useState("");
  const [similarityResult, setSimilarityResult] = useState("");
  const [fileDropDown, setFileDropDown] = useState(false);
  const [askAsamiChatCreation, setAskAsamiChatCreation] = useState(false);
  const [imgDocFile, setImgDocFile] = useState("")
  const [imageDocResponse, setImageDocResponse] = useState("");
  const [bURL, setBUrl] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const controllerRef = useRef(new AbortController);
  const [populateMsg, setPopulateMsg] = useState("")
  let [isEditMessage, setIsEditMessage] = useState(false);
  const [abort, setAbort] = useState(false)
  const textareaRef = useRef(null);
  // const [isEditing, setIsEditing] = useState(false);
  // Kathir states
  let askAsamiKbObj = {
    chatID: "",
    kbFiles: [],
    kbUrl: "",
    collectionName: "",
    indexName: ""
  };
  const [askAsamiKbData, setAskAsamiKbData] = useState(askAsamiKbObj);
  const [askAsamiKbRetriever, setAskAsamiKbRetriever] = useState(false);
  const [askAsamiKbReturn, setAskAsamiKbReturn] = useState("");
  const [askAsamiChatID, setAskAsamiChatID] = useState("");
  const [pauseButtonEnable, setPauseButtonEnable] = useState(false);
  const [showAddKnowledgeBase, setShowAddKnowledgeBase] = useState(false);
  const [KbChatName, setKbChatName] = useState(false);
  const [yesFixButton, setYesFixButton] = useState(false);
  const [showPopUpKb, setShowPopUpKb] = useState(false)
  const [showPopUpWeb, setShowPopUpWeb] = useState(false)
  const [webResult, setWebResult] = useState("")
  const [chatState, setChatState] = useState('');
  const [issueState, setIssueState] = useState('');
  const [kbContents, setKbContents] = useState({});
  const [openKbIndex, setOpenKbIndex] = useState(null);
  const [newMessageID, setNewMessageID] = useState("");
  const [isWebSearch, setIsWebSearch] = useState(false);
  const [configdata, setConfigSet] = useState()
  const [webContents, setWebContents] = useState({});
  const [openWebIndex, setOpenWebIndex] = useState(null);
  const [isKGBuild,setIsKGBuild]=useState(false)
  const [isImage, setIsImage] = useState(false)
  const [haveLastAssistantMessageCome, setHaveLastAssistantMessageCome ] = useState(true)
  // const [isDragging, setIsDragging] = useState(false);
  // const [textareaHeight, setTextareaHeight] = useState('auto');

  // File Upload states
  const [fileUploaded, setFileUploaded] = useState(false)


  const [wordCount,  setWordCount] = useState(0)
  const [minWidth , setMinWidth] = useState(0)
  const [minHeight , setMinHeight] = useState(0)
  const [isUploading, setIsUploading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showInitialPreview, setShowInitialPreview] = useState(false);
  const [showPreviewOnHover, setShowPreviewOnHover] = useState(false);



  // Edit scenario Pradeep states




  // Kathir states end
  //

  const [promptGenData, setPromptGenData] = useState([])
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage,setEditedMessage] = useState("")
  // const [imgDocFileAppended, setImgDocFileAppended] = useState(false);

  // useEffect(() => {
  //   if (imgDocFile && !imgDocFileAppended) {
  //     setsendMessageValue(prevValue => prevValue + imgDocFile + " ");
  //     setImgDocFileAppended(true);
  //   }
  // }, [imgDocFile]);


  useEffect(() => {
    hasUpdatedRef.current = false;
  }, [currentChat]);
  useEffect(() => {
    if (jsonObj?.["Chat Name"])
      setfilename(jsonObj["Chat Name"])
  }, [jsonObj])

  useEffect(() => {
    if (!newChat) {
      showPrompts();
      setDate();
    } else {
      setRegen(true)
      setPopMsg(true)
      // if(messageOrder)
      const access_token = getSessionItem("access-token");
      //console.log("acess",access_token)
      // userProfile=requests.get(f'https://graph.microsoft.com/v1.0/users/${userDetails.get("id")}/photo/$value')
      const getUserDetails = async () => {
        try {
          const result = await getPromptMsgOrder(newChat)
          let val=result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder >= result.ResponseData.promptIDMessages.length
          if(val){
            sethiddenObjValues({
              ...hiddenObjValues,
              binding: false,
              sendMessage: false,
            });
          }
          // console.log(val,"laaaaaaaaaaaaaaaaaa")
          // console.log(result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder,"heyyyyyyyyyyyy")

          const requests=await getUsername(newChat)
          //console.log("hellllooooo", access_token)
         const url =`https://graph.microsoft.com/v1.0/users/${requests}/photo/$value`
          let response = await axios.get(url, {
            headers: {
              "access-token": access_token, 
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            responseType: "blob",
          });
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          await timeout(500);
          response = reader.result;
          // setPopMsg(true)
          // //console.log(response)
          // //console.log("yyyyyyyyyyyyyyy",response.data)
          // const image=response.data
          // const base64String = toBase64(image);
          // const textDecoder = new TextDecoder();
          // const binaryString = textDecoder.decode(image);
          // const base64String = window.btoa(binaryString);
          // const base64_image_string =`data:image/jpeg;base64/${base64String}`
          const base64_image_string = response
          //console.log(base64_image_string,"iiiiiiiiiiiiiiiii")
  
          // if (response.status === 200) {
          //   setUserDetails(response.data);
          // } else {
          //   setError('Error fetching user details');
          // }
          // setUserData({
          //   ...userData,
          //   profileImage: base64_image_string
          // });
          setProfile(base64_image_string)
          // showPrompts();
          
        } catch (error) {
          //console.log('Error fetching user details',error);
        }
      };
      getUserDetails()
      
      setcurrentChatID(newChat);
      setChatName(newChat);
      if (block_Get_once) getChatMessages();
      else block_Get_once = false;
      setDate();
    }
    return () => console.log("Done");
  }, []);

  useEffect(() => {
    message_Order = 1;
  }, []);
  async function getUsername(newChat){
    try {
      //console.log('Entered')
      let result = await fetchChat({ ExistchatID: newChat });
      let data=result[0][0]
      return data
    }
    catch(e){
      //console.log(e)
    }
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleD2LinkClick = (e, _res) => {
    sessionStorage.setItem('d2Code',_res.content)
    const path = `/d2`;
    sessionStorage.setItem('d2Data', JSON.stringify({
      chatId: currentChatID
    }));
    e.preventDefault();
    window.open(path, '_blank');
  };

  const handleMermaidLinkClick = (e, _res) => {
    sessionStorage.setItem('mermaidCode',_res.content)
    const path = `/mermaid`;
    sessionStorage.setItem('mermaidData', JSON.stringify({
      chatId: currentChatID
    }));
    e.preventDefault();
    window.open(path, '_blank');
  };

  const handleLinkClick = (e, _res) => {
    sessionStorage.setItem('plantUMLCode', _res.content);
    const path = `/plantUml`;
    sessionStorage.setItem('plantUMLData', JSON.stringify({
      chatId: currentChatID
    }));
    e.preventDefault();
    window.open(path , '_blank');
  };



  const fetchDefaultModel = async () => {

    try {
      let response = await getBindParams()
      setDefaultModelFromEnv(response.data.defaultModel[0]["default_model_id"])

    }
    catch (error) {
      //("logging error")
    }
  }


  useEffect(() => {
    fetchDefaultModel()
  }, [])

  //(promptData, "fghjk");

  useEffect(() => {
    //(accounts, "account");
    if (!isAuth(accounts) && localStorage.getItem("login") != "true") {
      history.push("/");
    }
  }, []);

  // useEffect(() => {

  //   setKbPromptName(promptData?.promptName)
  //   setIndexName(promptData?.indexName)
  //   setCollectionName(promptData.collectionName)

  // }, [promptData]);

  //(kbPromptName,indexName,collectionName,"PPPPP");

  useEffect(() => {
    // if (!isAuthenticated) {
    //   if (newChat) {
    //     history?.push(`/chat?chatID=${newChat}`);
    //   } else {
    //     history?.push("/");
    //   }
    // }
    setUserData(userDetails);
  }, [userDetails]);
  useEffect(() => {
    setDate();
  }, [utcDateTime]);
  useEffect(() => { }, [flag, hiddenObjValues]);



  //(currentPrompt, "++++");
  const setDate = () => {
    const currentUtcDate = new Date(); // Get the current UTC date
    const utcDate = new Date(utcDateTime);
    const istDate = new Date(utcDate.getTime()); // Adding 5.5 hours for the IST offset
    const formattedDate = istDate.toDateString();

    let displayDate;
    if (
      currentUtcDate.getUTCFullYear() === utcDate.getUTCFullYear() &&
      currentUtcDate.getUTCMonth() === utcDate.getUTCMonth() &&
      currentUtcDate.getUTCDate() === utcDate.getUTCDate()
    ) {
      displayDate = "Today";
    } else if (
      currentUtcDate.getUTCFullYear() === utcDate.getUTCFullYear() &&
      currentUtcDate.getUTCMonth() === utcDate.getUTCMonth() &&
      currentUtcDate.getUTCDate() === utcDate.getUTCDate() + 1
    ) {
      displayDate = "Yesterday";
    } else {
      displayDate = formattedDate;
    }

    setFormattedDateTime(displayDate);
  };

  function decodeUrlIfNeeded(value) {
    if (typeof value === 'string' && value.includes('avaeusasamistgbsdev.blob.core.windows.net')) {
      try {
        const url = new URL(value);
        return decodeURIComponent(url.pathname.split('/').pop());
      } catch (error) {
        console.error("Error decoding URL:", error);
        return value;
      }
    }
    return value;
  }

  // const handleCancel = () => {

  //   setIsEditing(false);
  // };

  // useEffect(() => {
  //   if (isEditing && textareaRef.current) {
  //     adjustTextareaHeight();
  //     adjustTextareaWidth();
  //   }
  // }, [isEditing]);

  

  // const adjustTextareaHeight = () => {
  //   const textarea = textareaRef.current;
  //   if (textarea) {
  //     textarea.style.height = 'auto';
  //     textarea.style.height = `${textarea.scrollHeight}px`;
  //   }

  // };
  const adjustTextareaWidth = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.width = 'auto';
      // textarea.offsetWidth;
      textarea.style.width = `${Math.min(textarea.scrollWidth, 700)}px`;
    }
    let wordLen = sendMessageValue?.trim()?.split(" ")?.length
    //console.log(wordLen , "printing word length")
    if(wordLen > 7){
      setMinWidth(500)
    }
    else{
      setMinWidth(wordLen*20)
    }
    
    
  };
  //(promptData, "?????");

  // const KBRetriever = async () => {
  //   let obj={

  //       "query":kbQuery,
  //       "collection_name":promptName+"_collection",
  //       "index_name":indexName

  //   }
  //   let response = await KBRetriever({ obj });

  //   let content=response.data.message
  //   hitGPT(currentChat, content);
  //   //(response, "III");
  // };

  /**
   * PS_CGAI_3.12 to PS_CGAI_3.21 call getPrompt API to bind the prompts using state variable
   */
  const showPrompts = async () => {
    //("Prompt search value:", promptSearch);
    try {
      sethiddenObjValues({
        ...hiddenObjValues,
        loader: true
      });
      let result = await fetchPrompt({ search: promptSearch });

      if (result.data.ResponseData) {
        const filteredPrompts = result.data.ResponseData.prompts.filter(_prompt => _prompt.draft);
        setpromptData(filteredPrompts);

        const promptNames = filteredPrompts.map(_prompt => _prompt.promptName);
        setSearchPromptData(promptNames);



        sethiddenObjValues({ ...hiddenObjValues, loader: false });
      }
    } catch (error) {
      //("Logging error");
    }
  };

  //(searchPromptData,"uuuu");


  /**
   * PS_CGAI_3.22 to PS_CGAI_3.32 is chat id is there in url call getChat API to get the chat for the particular chat
   *PS_CGAI_3.33 to PS_CGAI_3.34 check for the lead/leadership chat message progress and set the required values if their chat didn't completed  means proceed to complete
   * PS_CGAI_3.35 set the variabe if their chat is completed mans show the fields to proceed the normla chat
   * PS_CGAI_3.36 condition for user set the value once the request is send and waiting for confirmation
   * PS_CGAI_3.37 set the value if their chat is approved by the approverand proceed to call AI
   * PS_CGAI_3.38 set the value if the chat is rejected and stop the process
   * PS_CGAI_3.39 set the values to variable if the user chat is on progress and proceed with the AI chat
   * PS_CGAI_3.40 set the values to the variable and bind the chat and procedd with the normal chat
   *
   */
  const getPromptMsgOrder = async (newChat) => {
      let result = await fetchChat({ chatID: newChat });
      //
      // console.log(result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder>=result.ResponseData.promptIDMessages.length,"weeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      return result
  }
  const getChatMessages = async () => {
    //
    sethiddenObjValues({
      ...hiddenObjValues,
      binding: false,
    });

    try {
      setIsLoad(true)
      let result = await fetchChat({ chatID: newChat });
      //
      // console.log(result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder==result.ResponseData.promptIDMessages.length,"weeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      
      //(result,"result");

      if (!Object.keys(result.ResponseData).length == 0) {
        setUtcDateTime(result.ResponseData.currentTxt[0].createdDateTime);
        if (result.ResponseData.kbRetriever[0].chatType == 'General') {
          setHidePrompts(true);
          setYesFixButton(true);
          setConfigSet(true)
          sethiddenObjValues((pre) => ({
            ...pre,

            success: true,
            sendMessage: false,
            binding:false
          }));
        }
        var approverName = "";
        if (result.ResponseData.promptIDMessages[0]?.approverEmailID) {
          let value =
            result.ResponseData.promptIDMessages[0].approverEmailID.split("@");
          approverName = value[0];
        }
        setJSON(result.ResponseData.JSON);

        let kbFilesArray = [];
        let kbPairs = [];

        let kbCollName = "";
        let KbIndName = "";

        if (result.ResponseData.kbRetriever && result.ResponseData.kbRetriever[0]) {
          debugger;
          // console.log(result,"yyyyyyyyyyyyyy")

          let kbRetrieverData = result.ResponseData.kbRetriever[0];
          let isAskAsamiKbRetriever = kbRetrieverData.isKbRetriever;

          setAskAsamiKbReturn(isAskAsamiKbRetriever)
          //(isAskAsamiKbRetriever, askAsamiKbReturn, "kb retriever true or not")

          let kbFiles = kbRetrieverData.kbFiles;
          let kbUrl = kbRetrieverData.kbUrl;
          //console.log(kbRetrieverData.kbUrl,"trrrrrrrrrrrrrrrrrrrrrr")
          if (kbRetrieverData.collectionName) {
            kbCollName = kbRetrieverData.collectionName;
          }
          setAskAsamiCollectionName(kbCollName);

          if (kbRetrieverData.indexName) {
            KbIndName = kbRetrieverData.indexName;
          }
          setAskAsamiIndexName(KbIndName);

          // Parse kbFiles if it exists
          if (kbFiles) {
            try {
              kbFilesArray = JSON.parse(kbFiles);
            } catch (error) {
              console.error("\n");
            }
          }

          // Map kbFiles to include kbUrl
          // kbPairs = kbFilesArray.map(file => ({ file, kbUrl: kbUrl }));

          // Set the knowledge data and URL using your existing methods
          setAskAsamiKnowledge(kbFilesArray);
          setKbAskAsamiRetrieverUrl(kbRetrieverData.kbUrl);
        }
        //(askAsamiKnowledge, kbAskAsamiRetrieverUrl, "result");

        // if (result.ResponseData.kbRetriever && result.ResponseData.kbRetriever[0]) {
        //   let kbRetrieverData = result.ResponseData.kbRetriever[0];
        //   let isKbRetriever = kbRetrieverData.isKbRetriever;
        //   let kbFiles = kbRetrieverData.kbFiles;
        //   let kbUrl = kbRetrieverData.kbUrl;

        //   // Parse kbFiles if it exists
        //   if (kbFiles) {
        //     try {
        //       kbFilesArray = JSON.parse(kbFiles);
        //     } catch (error) {
        //       console.error("Error parsing kbFiles:", error);
        //     }
        //   }

        //   // Map kbFiles to include kbUrl
        //   kbPairs = kbFilesArray.map(file => ({ file, kbUrl: kbUrl }));

        //   // Set the knowledge data and URL using your existing methods
        //   setAskAsamiKnowledge(kbFilesArray);
        //   setKbAskAsamiRetrieverUrl(kbUrl);
        // }
        //(askAsamiKnowledge, kbAskAsamiRetrieverUrl, "result")

        if (
          result.ResponseData.currentTxt.length == 1 &&
          result.ResponseData.currentChat.length != 0 &&
          result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder !=
          0
        ) {
          if (
            result.ResponseData.currentChat[
              result.ResponseData.currentChat.length - 1
            ].role == "user"
          ) {
            setcurrentChat(result.ResponseData.currentChat);
            currentChat = result.ResponseData.currentChat;
            setKbContents(result.ResponseData.kbContents);
            setWebContents(result.ResponseData.webContents);
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setreplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;
            setmessageOrder(
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder;
            messageOrder = message_Order;
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setpromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              )
            );

            if (result.ResponseData.kbRetriever[0].chatType == 'General') {
              setHidePrompts(true);
              setYesFixButton(true);
              setConfigSet(true)
              //(configdata, "NOFEMMDVMDFOVMOFDMVODFMVODFOVMFDOVMFOV")
              sethiddenObjValues((pre) => ({
                ...pre,

                success: true,
                sendMessage: false,
                binding:false
              }));
            }
            else {
              setConfigSet(false)
              sethiddenObjValues((pre) => ({
                ...pre,

                success: true,
                sendMessage: true,
                binding:false
              }));
            }

            requestGptToken(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              ),
              globalJSON
            );
          } else {
            setcurrentChat(result.ResponseData.currentChat);
            setKbContents(result.ResponseData.kbContents);
            setWebContents(result.ResponseData.webContents);
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setreplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;
            setmessageOrder(
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder;
            messageOrder = message_Order;
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setpromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              )
            );
            if (result.ResponseData.kbRetriever[0].chatType == 'General') {
              setHidePrompts(true);
              setYesFixButton(true);
              setConfigSet(true)
              //(configdata, "NOFEMMDVMDFOVMOFDMVODFMVODFOVMFDOVMFOV")
              sethiddenObjValues((pre) => ({
                ...pre,

                success: true,
                sendMessage: false,
                binding:false
              }));
            }
            else {
              sethiddenObjValues((pre) => ({
                ...pre,

                success: true,
                sendMessage: true,
                binding:false
              }));
            }
            sethiddenObjValues({
              ...hiddenObjValues,
              binding: false,
            });
          }
        } else if (
          result.ResponseData.currentTxt.length == 1 &&
          result.ResponseData.currentChat.length != 0 &&
          result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder ==
          0
        ) {
          setcurrentChat(result.ResponseData.currentChat);
          currentChat = result.ResponseData.currentChat;
          setKbContents(result.ResponseData.kbContents);
          setExcelURLs(result.ResponseData?.excelURLs);
          setLeftCodeArray(result.ResponseData.existingParentFunction)
          setRightCodeArray(result.ResponseData.solvedParentFunction)
          setWebContents(result.ResponseData.webContents);

          setmessageOrder(
            result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder
          );
          message_Order =
            result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder;
          sethiddenObjValues((pre) => ({
            ...pre,

            success: false,
            sendMessage: false,
          }));
          if (
            result.ResponseData.currentChat[
              result.ResponseData.currentChat.length - 1
            ].role == "user"
          ) {
            requestGptToken(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              ),
              globalJSON
            );
            sethiddenObjValues({
              ...hiddenObjValues,
              binding: false,
            });
          }
          sethiddenObjValues({
            ...hiddenObjValues,
            binding: false,
          });
        } else if (result.ResponseData.currentTxt.length == 2) {
          promptIDMessagesArray = result.ResponseData.promptIDMessages;
          setapproverName(result.ResponseData.approverName);
          setpromptName(result.ResponseData.promptName);
          setpromptIDMessages(
            result.ResponseData.promptIDMessages.sort(
              (a, b) => a.promptMessageOrder - b.promptMessageOrder
            )
          );
          setreplaceObj(result.ResponseData.promptFormIDJson);
          globalJSON = result.ResponseData.promptFormIDJson;

          sethiddenObjValues((pre) => ({
            ...pre,

            success: false,
            sendMessage: true,
            awaiting: false,
            requestSendMsg: false,
            binding:false
          }));
          setapproverName(approverName);
        } else if (
          result.ResponseData.currentTxt.length == 3 &&
          result.ResponseData.currentChat.length == 0
        ) {
          //(
          //   !block_trigger_once,
          //   result.ResponseData.approvalStatus == "Approved"
          // );
          if (result.ResponseData.approvalStatus == "Approved") {
            if (!block_trigger_once) {
              setpromptName(result.ResponseData.promptName);
              setpromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a, b) => a.promptMessageOrder - b.promptMessageOrder
                )
              );
              setreplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              sethiddenObjValues((pre) => ({
                ...pre,

                requestSendMsg: false,
                approvedMsg: false,
                awaiting: true,
                status: result.ResponseData.approvalStatus,
                sendMessage: true,
                binding: false,
              }));
              setapproverName(approverName);
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              requestGptToken(
                result.ResponseData.promptIDMessages,
                result.ResponseData.promptFormIDJson
              );
            } else {
              setpromptName(result.ResponseData.promptName);
              setpromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a, b) => a.promptMessageOrder - b.promptMessageOrder
                )
              );
              setreplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              sethiddenObjValues((pre) => ({
                ...pre,

                requestSendMsg: false,
                approvedMsg: false,
                awaiting: true,
                status: result.ResponseData.approvalStatus,
                sendMessage: true,
                binding: false,
              }));
              setapproverName(approverName);
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              requestGptToken(
                result.ResponseData.promptIDMessages,
                result.ResponseData.promptFormIDJson
              );
              block_trigger_once = false;
            }
          } else {
            const hiddenObjectValues = {
              ...hiddenObjValues,
              requestSendMsg: false,
              rejectedMsg: false,
              status: result.ResponseData.approvalStatus,
            };

            sethiddenObjValues(hiddenObjectValues);
            sethiddenObjValues({
              ...hiddenObjValues,

              requestSendMsg: false,
              rejectedMsg: false,
              status: result.ResponseData.approvalStatus,
              awaiting: true,
              sendMessage: true,
              binding: false,
            });

            setapproverName(approverName);
          }
        } 
        else if (
          result.ResponseData.currentTxt.length == 3 &&
          result.ResponseData.currentChat.length != 0 &&
          result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder !=
          0
        ) {
          if (
            result.ResponseData.currentChat[
              result.ResponseData.currentChat.length - 1
            ].role == "user"
          ) {
            sethiddenObjValues((pre) => ({
              ...pre,
              requestSendMsg: false,
              approvedMsg: false,
              status: result.ResponseData.approvalStatus,
              binding:false
            }));
            setpromptName(result.ResponseData.promptName);
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setapproverName(approverName);
            setcurrentChat(result.ResponseData.currentChat);
            currentChat = result.ResponseData.currentChat;
            setKbContents(result.ResponseData.kbContents);
            setWebContents(result.ResponseData.webContents);
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setpromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              )
            );
            setreplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;

            setmessageOrder(
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder;
            messageOrder = message_Order;
            requestGptToken(result.ResponseData.promptIDMessages, globalJSON);
          } else {
            sethiddenObjValues((pre) => ({
              ...pre,
              requestSendMsg: false,
              approvedMsg: false,
              status: result.ResponseData.approvalStatus,
              binding:false
            }));
            setpromptName(result.ResponseData.promptName);
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setapproverName(approverName);
            setcurrentChat(result.ResponseData.currentChat);
            setKbContents(result.ResponseData.kbContents);
            setWebContents(result.ResponseData.webContents);
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setpromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              )
            );
            setreplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;

            setmessageOrder(
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder[0]
                .promptMessageOrder;
            messageOrder = message_Order;
          }
        } else if (
          result.ResponseData.currentTxt.length == 3 &&
          result.ResponseData.currentChat.length != 0 &&
          result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder ==
          0
        ) {
          sethiddenObjValues((pre) => ({
            ...pre,

            requestSendMsg: false,
            approvedMsg: false,
            status: result.ResponseData.approvalStatus,
            binding:false
          }));
          setmessageOrder(
            result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder
          );
          message_Order =
            result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder;
          setapproverName(approverName);
          setpromptName(result.ResponseData.promptName);
          promptIDMessagesArray = result.ResponseData.promptIDMessages;
          setcurrentChat(result.ResponseData.currentChat);
          currentChat = result.ResponseData.currentChat;
          setKbContents(result.ResponseData.kbContents);
          setWebContents(result.ResponseData.webContents);
          setExcelURLs(result.ResponseData?.excelURLs);
          setLeftCodeArray(result.ResponseData.existingParentFunction)
          setRightCodeArray(result.ResponseData.solvedParentFunction)
          setpromptIDMessages(
            result.ResponseData.promptIDMessages.sort(
              (a, b) => a.promptMessageOrder - b.promptMessageOrder
            )
          );
          setreplaceObj(result.ResponseData.promptFormIDJson);
          globalJSON = result.ResponseData.promptFormIDJson;

          if (
            result.ResponseData.currentChat[
              result.ResponseData.currentChat.length - 1
            ].role == "user"
          ) {
            requestGptToken(
              result.ResponseData.promptIDMessages.sort(
                (a, b) => a.promptMessageOrder - b.promptMessageOrder
              ),
              globalJSON
            );
            sethiddenObjValues({
              ...hiddenObjValues,
              binding: false,
            });
          }

          sethiddenObjValues((pre) => ({
            ...pre,
            success: false,
            sendMessage: false,
            binding:false
          }));
        }
      } else {
        sethiddenObjValues({
          ...hiddenObjValues,
          invalidChat: true,
          APIError: "Invalid chat",
          binding:false
        });
      }
      setIsLoad(false)
      let val=result.ResponseData.currentPromptMessageOrder[0].promptMessageOrder >= result.ResponseData.promptIDMessages.length
          if(val){
            sethiddenObjValues({
              ...hiddenObjValues,
              binding: false,
              sendMessage: false,
            });
          }
    
    
    } catch (error) {
      //("Logging error");
      sethiddenObjValues({
        ...hiddenObjValues,
        binding: false,
      });

    }
    sethiddenObjValues({
      ...hiddenObjValues,
      binding: false,
    });
  };
  // console.log(hiddenObjValues,"objjjjjj")

  function bindSearch(searchResult) {
    if (searchResult && Array.isArray(searchResult)) {
      // Slice the array to get the top 4 prompts
      const topPrompts = searchResult;
      //console.log(searchResult.length,"krr")
      if(searchResult.length==0){
        return(
          <span className="text-center py-5 vh-50">
                                      <img
                                        src="images/Default/no-record-img.svg"
                                        alt="no-record-img"
                                        className="no-record-img"
                                        style={{width:'100%', height:'50%', marginBottom: '2rem !important'}}
                                      />
                                      <span className="font-20 font-medium w-100 d-block text-color-1">
                                        Sorry! No Matching prompt found.
                                      </span>
          </span>
        )
      }
      else{
        return topPrompts.map((value, index) => (
          <>
            <span
              className="searchSuggestionList font-semibold font-14 denter-flex gap-2 align-items-center text-color-1"
              onClick={(e) => {
                //console.log("Hii there",value)
                //console.log(searchResult.length,"krr")
                handlePromptClick(e, value);
                setPromptSearch("");
              }}
            >
              <img src={value.promptLogoPath} className="prompt-card-img" />{" "}
              {value.promptName}
            </span>
          </>
        ));
      }
    }
  }

  // Click handler for the right arrow button
  function handleNextClick() {
    const nextIndex = currentIndex + 6;
    if (nextIndex < promptData.length) {
      setCurrentIndex(nextIndex);
    }
  }

  // Click handler for the left arrow button
  function handlePrevClick() {
    const prevIndex = currentIndex - 6;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
    }
  }



  const handleFuncPrevClick = () => {
    setCurrentFunctionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleFuncNextClick = () => {
    setCurrentFunctionIndex((prevIndex) => (prevIndex < similarFunctions.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handleFetchMoreClick = () => {
    setSimilarFunctions((prevData) => [...prevData, ...additionalSimilarFunctions]);
  };

  const handleYesContinue = () => {
    
    setShowPrompt(true);

    setSelectedFunctionCode(similarFunctions[currentFunctionIndex].functionCode);
    setSelectedParentName(similarFunctions[currentFunctionIndex].parentName);
    setSelectedStartLine(similarFunctions[currentFunctionIndex].functionStart)
    setSelectedEndLine(similarFunctions[currentFunctionIndex].functionEnd)
  };


  const codeCompare = (Chat_index) => (
    // <div className="incoming-content w-100 mt-5">
    //   <div className="position-relative ms-4 mb-3">
    //     <img src="images/asami-bot.svg" alt="bot-happy" className="chat-img-left position-absolute" />
    //     <div className="chat-box compare-code-chatbox">
    //       <div className="row">
    //         <div className="col-md-12">
    //           <div className="mb-3">
    //             <span className="font-16 font-bold">Code Comparison</span>
    //           </div>

              <ReactDiffViewer
                oldValue={leftCodeArray[Chat_index]}
                newValue={rightCodeArray[Chat_index]}
                splitView={true}
                hideLineNumbers={false}
                showDiffOnly={false}
                disableWordDiff={true}
                styles={{
                  variables: {
                    light: {
                      diffViewerBackground: '#fff',
                      diffViewerColor: '#212529',
                      addedBackground: '#e6ffed',
                      addedColor: '#24292e',
                      removedBackground: '#ffeef0',
                      removedColor: '#24292e',
                      wordAddedBackground: '#acf2bd',
                      wordRemovedBackground: '#fdb8c0',
                      addedGutterBackground: '#cdffd8',
                      removedGutterBackground: '#ffdce0',
                      gutterBackground: '#f7f7f7',
                      gutterBackgroundDark: '#f3f1f1',
                      highlightBackground: '#fffbdd',
                      highlightGutterBackground: '#fff5b1',
                      codeFoldGutterBackground: '#dbedff',
                      codeFoldBackground: '#f1f8ff',
                      emptyLineBackground: '#fafbfc',
                      gutterColor: '#212529',
                      addedGutterColor: '#212529',
                      removedGutterColor: '#212529',
                      codeFoldContentColor: '#212529',
                      diffViewerTitleBackground: '#fafbfc',
                      diffViewerTitleColor: '#212529',
                      diffViewerTitleBorderColor: '#eee',
                    },
                  },
                  line: {
                    padding: '10px 2px',
                  },
                  gutter: {
                    minWidth: '60px',
                  },
                }}
              />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );


  const bindPrompts = () => {
    if (promptData) {
      let flag = 0
      if (promptData.length >= 1) {
        const visiblePrompts = promptData.filter((val) => val.draft === true);
        //(promptData.length - currentIndex)
        if ((promptData.length - currentIndex) >= 3) {
          flag = 0
        }
        else if ((promptData.length - currentIndex) % 2 == 0) {
          flag = 2
        }
        else {
          flag = 1
        }
        return visiblePrompts.slice(currentIndex, currentIndex + 6)
          .map((val, i) => (
            <div
              class={`${flag == 0 ? `col-lg-4 col-md-6 mb-3 custom-padding` : flag == 2 ? `col-lg-6.5 col-md-6 mb-3 custom-padding` : `col-lg-12 col-md-6 mb-3 custom-padding`}`}
              key={val.promptID}
            >
              <div
                className={`prompt-card h-100 ${currentPromptID === val.promptID ? "active" : ""
                  }`}
                onClick={(e) => handlePromptClick(e, val)}
              >
                <div className="text-start">
                  <span>
                    <img
                      src={val.promptLogoPath}
                      alt={val.promptName}
                      className="prompt-card-img new-chat-icons"
                    />
                  </span>
                  <span className="d-block font-12 font-semibold">
                    {val.promptName}
                  </span>
                </div>
              </div>
            </div>
          ));

      }
    }
  };

  // Click handler for individual prompt click
  const handlePromptClick = (e, val) => {

    if (!hiddenObjValues.promptDisabled) {
      sethiddenObjValues({
        ...hiddenObjValues,
        promptDisabled: true,
        helpMe: false,
      });
      setpromptName(val.promptName);
      setIndexName(val.indexName)
      setCollectionName(val.collectionName)
      setcurrentPromptID(val.promptID);
      setHidePrompts(true)
      setPromptGenData(val.promptFields)
    }
  };

  //(hidePrompts,"GGGG");

  /**
   * PS_CGAI_3.62 to PS_CGAI_3.67 return the values after the chat created and send request based on the role
   * PS_CGAI_3.68 to PS_CGAI_3.74 is the role is user send the notification request to their lead
   * @param {approverEmailID} id
   * @param {chatID} chatID
   * @param {approverName} approverName
   * @param {input valu objects} replaceObjJson
   */
  const appendJson = async (id, chatID, approverName, replaceObjJson) => {
    replaceObj = replaceObjJson;

    globalJSON = replaceObjJson;

    if (id) {
      let result = await upsertNotification({
        approverEmailID: id,
        userEmailID: userData?.email,
        chatID: chatID,
      });

      if ((result.Success = true)) {
        // setcurrentChatID(chatID);
        setapproverName(approverName);
        sethiddenObjValues({
          ...hiddenObjValues,
          requestSendMsg: false,
          awaiting: false,
        });
      }
    } else {
      let chatid = chatID;
      let replacejson = replaceObjJson;

      // setcurrentChatID(chatid);

      // setreplaceObj(replaceObjJson);
      // getPromptMessage(replaceObjJson, promptIDMessages);
      requestGptToken(promptIDMessages, replaceObj);
      // getPromptMessage(promptIDMessages, replacejson);
    }
  };

  /**
   * PS_CGAI_3.75  to PS_CGAI_3.87 call talkToAI API to get the access token for openAI based on the response call the funtion getPromptMessage using status and messageOrder
   * @param {input values object} replaceObj
   */

  const requestGptToken = async (promptIDMessages, replaceObj) => {
    // debugger;

    try {

      const desiredOrder = message_Order; // Replace with the order you want
      const desiredMessage = promptIDMessages?.find(
        (message) => message.promptMessageOrder === desiredOrder
      );

      if (
        message_Order &&
        currentChat[currentChat.length - 1]?.role == "user"
      ) {
        if(localStorage.getItem("ViewChat")=="True"){
          localStorage.setItem("VIeChat","False")
          }
          else{
                  getGPTResponse(desiredMessage);
          }

      

      }

      if (
        message_Order &&
        hiddenObjValues.inputFix != "Fix your response here" &&
        (currentChat[currentChat?.length - 1]?.role != "user" ||
          currentChat.length == 0)
      ) {
        getPromptMessage(promptIDMessages, replaceObj);
      }
      if (!message_Order) {

        getGPTResponse(desiredMessage);
      }

    } catch (error) {
      //("Logging error");
    }
  };

  /**
   * PS_CGAI_3.88 to PS_CGAI_3.93 check for the condition the lenfth of promptMessageOrder and messageOrder is equal ,and if inputKey and inputValue is there call checkCondition() to check the condition
   * PS_CGAI_3.94 to PS_CGAI_3.102 and update the chat statue as success based on the checkCondition() execute or skip the message after replace
   * PS_CGAI_3.103 to PS_CGAI_3.108 promptIDMessage.length is > meassageOrder check for the key and based on that call checkCondition and skip or replace the message based on the checkCondition return value
   * if true means replace that prompt if not skip the prompt
   * @param {array of promptMessage for the particular prompt} promptIDMessages
   * @param {user input values with the input key} replaceObj
   */
  const getPromptMessage = async (promptIDMessages, replaceObj) => {
    //  debugger;
      let hello = replaceObj
      // setLoading(true)
      //(promptIDMessages, "UUUU");
      // setPopMsg(false)
      if (promptIDMessages.length == messageOrder) {
        for (const val of promptIDMessages) {
  
  
          if (val.promptMessageOrder == messageOrder) {
            if (val.inputValue && val.inputKey && val.conditionRefCode) {
              // console.log(val, replaceObj, "val, replaceObj")
              if (checkCondition(val, replaceObj)) {
                //(val, "****");
                const desiredMessage = promptIDMessages.find(
                  (message) => message.promptMessageOrder === messageOrder
                );
                let message = val.promptMessage;
  
                //
                let a = val
                // promptGen implementation
                let pMessageId = val.promptMessageID
                let pFieldJs = replaceObj
                let req_body = {
                  "messageID": val.promptMessageID,
                  "userInput": replaceObj
                }
                let proceedWithAI = 0
                promptGenData.forEach((ele) => {
                  if (ele.hitAI == 1) {
                    proceedWithAI = 1
                  }
                })
                if (proceedWithAI == 1) {
                  let response = await generateNewPrompt(req_body)
                  message = response.finalPrompt
                }
                setRegen(false)
  
                let formattedResponse = ""
                let webresultfromprompt = ""
  
                if (val.webContent !== "" && val.webContent !== null) {
                  //(val.webContent, "UUUU");
                  //
                  let response = await serpAPI({ query: val.webContent });
                  //(response, "UUUUU");
                  formattedResponse = `
                  $$$
                  ${val.webContent}
                  
                  As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                  
                  ${response.data.output.google_result[0]}
                  
                  Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`
                  
                  webresultfromprompt = response.data.output.google_result[0]
                  // setWebResult(response.data.output.google_result[0],response.data.output.google_result[1]); //Kathir Changesw
                }
                const replacedString = message.replaceAll(
                  /\((input-\d+)\)/g,
                  (match, key) => {
                    return replaceObj[key] || match;
                  }
                );
  
                const finalString = replacedString + formattedResponse;
  
                // console.log(finalString,webresultfromprompt,"check")
  
                setIsKbRetriever(val?.isKbRetriever)
                setKbQuery(replacedString)
                  ./////
  
                  setlastArrayObj({
                    role: "user",
                    content: replacedString,
                  });
  
                pushChat(currentChat, "user", finalString);
  
                if (webresultfromprompt != "") {
                    pushChat(currentChat, "user", "", "", webresultfromprompt);
                } 
  
                postMessage(
                  {
                    role: "user",
                    content: replacedString,
                  },
                  null,
                  desiredMessage,webresultfromprompt
                );
              }
              else{
                sethiddenObjValues({
                  ...hiddenObjValues,
                  sendMessage: false,
                  responseBtn: true,
                });
              }
            } else {
              let message = val.promptMessage;
              let proceedWithAI = 0
              promptGenData.forEach((ele) => {
                if (ele.hitAI == 1) {
                  proceedWithAI = 1
                }
              })
              if (proceedWithAI == 1) {
                let response = await generateNewPrompt({
                  'messageID': val.promptMessageID,
                  'userInput': replaceObj
                })
                message = response.finalPrompt
                setRegen(false)
              }
  
  
              let formattedResponse = ""
              let webresultfromprompt = ""
  
              if (val.webContent !== "" && val.webContent !== null) {
                //(val.webContent, "UUUU");
                //
                let response = await serpAPI({ query: val.webContent });
                //(response, "UUUUU");
                formattedResponse = `
                $$$
                ${val.webContent}
                
                As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                
                ${response.data.output.google_result[0]}
                
                Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`
                
                webresultfromprompt = response.data.output.google_result[0]
                // setWebResult(response.data.output.google_result[0],response.data.output.google_result[1]); //Kathir Changesw
              }
  
              const desiredMessage = promptIDMessages.find(
                (message) => message.promptMessageOrder === messageOrder
              );
              const replacedString = message.replaceAll(
                /\((input-\d+)\)/g,
                (match, key) => {
                  return replaceObj[key] || match;
                }
              );
              const finalString = replacedString + formattedResponse;
              setKbQuery(replacedString)
              setIsKbRetriever(val?.isKbRetriever)
              setlastArrayObj({
                role: "user",
                content: replacedString,
              });
  
              // console.log(finalString,webresultfromprompt,"check")
  
              pushChat(currentChat, "user", finalString);
  
              if (webresultfromprompt != "") {
                  pushChat(currentChat, "user", "", "", webresultfromprompt);
              } 
  
              postMessage(
                {
                  role: "user",
                  content: replacedString,
                },
                null,
                desiredMessage,webresultfromprompt
              );
            }
          }
        }
        setmessageOrder(0);
      } else if (promptIDMessages.length > messageOrder) {
  
        for (const val of promptIDMessages) {
  
          // setIsKbRetriever(val?.isKbRetriever)
          if (val.promptMessageOrder == messageOrder) {
            if (val.inputValue && val.inputKey && val.conditionRefCode) {
              if (checkCondition(val, replaceObj)) {
                //(val, "FF");
                const desiredMessage = promptIDMessages.find(
                  (message) => message.promptMessageOrder === messageOrder
                );
                let message = val.promptMessage;
                let proceedWithAI = 0
                promptGenData.forEach((ele) => {
                  if (ele.hitAI == 1) {
                    proceedWithAI = 1
                  }
                })
                if (proceedWithAI == 1) {
                  let response = await generateNewPrompt({
                    'messageID': val.promptMessageID,
                    'userInput': replaceObj
                  })
                  message = response.finalPrompt
                  setRegen(false)
                }
  
                let formattedResponse = ""
                let webresultfromprompt = ""
  
                if (val.webContent !== "" && val.webContent !== null) {
                  //(val.webContent, "UUUU");
                  //
                  let response = await serpAPI({ query: val.webContent });
                  //(response, "UUUUU");
                  formattedResponse = `
                  $$$
                  ${val.webContent}
                  
                  As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                  
                  ${response.data.output.google_result[0]}
                  
                  Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`
                  
                  webresultfromprompt = response.data.output.google_result[0]
                  // setWebResult(response.data.output.google_result[0],response.data.output.google_result[1]); //Kathir Changesw
                }
                const replacedString = message.replaceAll(
                  /\((input-\d+)\)/g,
                  (match, key) => {
                    return replaceObj[key] || match;
                  }
                );
                const finalString = replacedString + formattedResponse;
                setKbQuery(replacedString)
                setIsKbRetriever(val?.isKbRetriever)
                setlastArrayObj({
                  role: "user",
                  content: replacedString,
                });
                // console.log(finalString,webresultfromprompt,"check")
                pushChat(currentChat, "user", finalString);
  
                if (webresultfromprompt != "") {
                    pushChat(currentChat, "user", "", "", webresultfromprompt);
                } 
              
  
                postMessage(
                  {
                    role: "user",
                    content: replacedString,
                  },
                  null,
                  desiredMessage,webresultfromprompt
                );
              } else {
                messageOrder += 1;
                message_Order = messageOrder;
              }
            } else {
              let message = val.promptMessage;
              let proceedWithAI = 0
              promptGenData.forEach((ele) => {
                if (ele.hitAI == 1) {
                  proceedWithAI = 1
                }
              })
              if (proceedWithAI == 1) {
                let response = await generateNewPrompt({
                  'messageID': val.promptMessageID,
                  'userInput': replaceObj
                })
                message = response.finalPrompt
                setRegen(false)
              }
              const desiredMessage = promptIDMessages.find(
                (message) => message.promptMessageOrder === messageOrder
              );
  
              let formattedResponse = ""
              let webresultfromprompt = ""
  
              if (val.webContent !== "" && val.webContent !== null) {
                //(val.webContent, "UUUU");
                //
                let response = await serpAPI({ query: val.webContent });
                //(response, "UUUUU");
                formattedResponse = `
                $$$
                ${val.webContent}
                
                As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                
                ${response.data.output.google_result[0]}
                
                Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`
                
                webresultfromprompt = response.data.output.google_result[0]
                // setWebResult(response.data.output.google_result[0],response.data.output.google_result[1]); //Kathir Changesw
              }
  
              const replacedString = message.replaceAll(
                /\((input-\d+)\)/g,
                (match, key) => {
                  return replaceObj[key] || match;
                }
              );
              const finalString = replacedString + formattedResponse;
              setKbQuery(replacedString)
              setIsKbRetriever(val?.isKbRetriever)
              setlastArrayObj({
                role: "user",
                content: replacedString,
              });
              // console.log(finalString,webresultfromprompt,"check")
              pushChat(currentChat, "user", finalString);
  
              if (webresultfromprompt != "") {
                  pushChat(currentChat, "user", "", "", webresultfromprompt);
              } 
  
              postMessage(
                {
                  role: "user",
                  content: replacedString,
                },
                null,
                desiredMessage,webresultfromprompt
              );
            }
          }
        }
      } else {
        updateStatus("sucs");
        sethiddenObjValues((pre) => ({
          ...pre,
          success: false,
          sendMessage: false,
        }));
      }
      // setLoading(false)
    };

  //(isKbRetriever,"KBRetriever");

  /**
   * PS_CGAI_3.93 to PS_CGAI_3.102 update the chat status as success using updateApproval API
   * @param {status of the chat in short form as refcode} id
   */
  const updateStatus = async (id) => {

    try {
      const currentChat_ID = new URLSearchParams(window.location.search).get(
        "chatID"
      );

      let result = await updateApproval({
        id: id,

        chatID: currentChat_ID,
        messageOrder: 0,
        promptMessageOrder: 0,
      });

      if (result.data.Success == true) {
        setmessageOrder(0);
        // sethiddenObjValues({
        //   ...hiddenObjValues,
        //   sendMessage: false,
        //   success: false,
        //   inputFix: "Send a message...",
        // });
      }
    } catch (error) {
      //("Logging error");
    }
  };
  /**
   * PS_CGAI_3.109 to PS_CGAI_3.111 check for the includes condition and return true or false based on the conditon
   * PS_CGAI_3.112 to PS_CGAI_3.113 check for not includes condition and return true or false based on the condition
   * PS_CGAI_3.114 to PS_CGAI_3.115 check for the condition equal to and return true or false based on the condition
   * PS_CGAI_3.116 to PS_CGAI_3.118 check for the condition not equal and return true or false based on the condition and return the value true or false
   * @param {promptMessage} promptIDMessage
   * @param {input values with input keys} replaceObj
   * @returns either true or false
   */
  const checkCondition = (promptIDMessage, replaceObj) => {
    ;
    let key = promptIDMessage.inputValue;
    // //console.log(replaceObj[promptIDMessage.inputKey])
    let keysArray = Object.values(replaceObj);
    // //console.log(keysArray, "keysArray")
    // //console.log(key.includes(replaceObj[promptIDMessage.inputKey]),  promptIDMessage.inputValue == replaceObj[promptIDMessage.inputKey], "condition" )
    let result = true;

    if (promptIDMessage.conditionRefCode == "INC") {
      if (
        key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue == replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if ((promptIDMessage.conditionRefCode == "NINC")) {
      if (!key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue != replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if (promptIDMessage.conditionRefCode == "EQ") {
      if (
        // key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue == replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if (promptIDMessage.conditionRefCode) {
      if (
        // key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue !== replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  };
  /**
   * PS_CGAI_3.119 to PS_CGAI_3.120 push the user and assistant respons and request to the currentChat
   * @param {current chat array} currentChat
   * @param {user or assistant} roleProvided
   * @param {chat content/response} contentProvided
   */
  const pushChat = async (currentChat, roleProvided, contentProvided, kbContentGiven, webContentGiven) => {
    // debugger
    //
    //(isKbRetriever,"push");
    //(isKbRetriever,"push");
    //("Logging error")
    if (imgDocFile && roleProvided === "user") {
      contentProvided = contentProvided + " " + imgDocFile
    }
    if (roleProvided === "user" && kbContentGiven) {
      const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
      if (lastUserChatIndex !== -1) {
        currentChat[lastUserChatIndex].content += `\n\nKnowledge Base:\n${kbContentGiven}`;
      }
    }
    else if (roleProvided === "user" && webContentGiven) {
      const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
      if (lastUserChatIndex !== -1) {
        // console.log(currentChat[lastUserChatIndex].content,"ppppppppppppppppppppppppppp")
        currentChat[lastUserChatIndex].content += `\n\nWeb Content:\n${webContentGiven}`;
      }
    }
    else {
      
      currentChat.push({
        role: roleProvided,
        content: contentProvided
      });
      // console.log(currentChat,"currentchat......")
      // console.log(currentChat[currentChat.length - 1],"roleeeeeesssssssssssss")
      
      if(currentChat[currentChat.length - 1]['role']==="assistant"){
        setHaveLastAssistantMessageCome(true)
      }else{
        setHaveLastAssistantMessageCome(false)
      }
      
    }
    // currentChat.push({
    //   role: roleProvided,
    //   content: contentProvided,
    // });
    // setLoading(true)
    // setLoading(loading === true ? false : true);
    const assistantContent = currentChat
      .filter((item) => item.role === "assistant") // Filter out objects where role is 'assistant'
      .map((item) => item.content); // Extract 'content' from filtered objects

    // const pseudocodePattern =
    //   /^\|\s*Sequence Number\s*\|\s*Pseudocode Number\s*\|\s*File Name\s*\|\s*Function Name\s*\|\s*Steps\/Algorithm\s*\|\s*$/m;

    // // Check if any line in the assistantContent matches the pattern
    // // const lines = assistantContent.split('\n');
    // // const containsPseudocode = lines.some(line => pseudocodePattern.test(line));

    // if (pseudocodePattern.test(assistantContent)) {
    //   // The assistantContent contains pseudocode data
    //   let response = await pseudocodeCSV({ query: assistantContent });
    //   //(response, "UUUUUUUUUUUUUUUUUUUUU");
    //   setDownloadPseudo(response.data.Generated_url);
    // }

    setcurrentChat(currentChat);
    setlastArrayObj({
      role: roleProvided,
      content: contentProvided,
    });
    // console.log(currentChat,"currentchat......")
    // console.log(currentChat[-1]['role'],"roleeeeeesssssssssssss")
    
    if(currentChat[-1]['role']==="assistant"){
      setHaveLastAssistantMessageCome(true)
    }else{
      setHaveLastAssistantMessageCome(false)
    }
  };

  //(loading,"kjhg");
  /**
   * PS_CGAI_3.123 to PS_CGAI_3.133 insert the messages to the MessageTbl using postMessage API based on the response call getGPTResponse or requestGptToken
   * and also update the chat as success if the chat is completed
   * @param {object with role and content} AIResponse
   * @param {AI response status} finish_reason
   */
  // const postMessage = async (AIResponse, finish_reason, desiredMessage) => {

  //   //(isKbRetriever,"post");

  //   //(desiredMessage, "++++++++++++++++++++++++++++++++++++++");

  //   const kbCheck = desiredMessage?.isKbRetriever||IsKbRetrieverActive;
  //   if(desiredMessage?.isKbRetriever){
  //     setIsKbRetrieverActive(true)
  // }
  // if(promptData?.indexName){
  //   setIndexName(promptData?.indexName)
  //   setCollectionName(promptData?.collectionName)
  // }


  //   try {
  //     const currentChat_ID = new URLSearchParams(window.location.search).get(
  //       "chatID"
  //     );

  //     let promptMessageOrder = message_Order;
  //     if (!messageOrder) {
  //       promptMessageOrder = 0;
  //     }

  //     setlastArrayObj(AIResponse);
  //     setcurrentChatID(currentChat_ID);

  //     let result = await insertMessage({
  //       AIResponse: AIResponse,
  //       chatID: currentChat_ID,
  //       messageType: "chat",
  //       promptMessageOrder: promptMessageOrder,
  //     });
  //     if (result.Success == true) {
  //       if (AIResponse.role == "user") {
  //         if (!AI_token) {
  //           requestGptToken(promptIDMessages, replaceObj);
  //         } else {
  //           // kbRetriever==false 
  //           // ? KBRetriever():
  //           getGPTResponse(desiredMessage,AIResponse,promptMessageOrder);
  //         }
  //       } else {
  //         if (promptIDMessages?.length == messageOrder) {
  //           updateStatus("sucs");
  //         }
  //       }

  //       // requestGPT(currentChat_ID, currentChat);
  //     }
  //     //  let result = await insertMessage({
  //     //    AIResponse: AIResponse,
  //     //    chatID: currentChat_ID,

  //     //    messageType: "chat",
  //     //    promptMessageOrder: messageOrder,
  //     //  });
  //   } catch (error) {
  //     //(error.message);
  //   }
  // };

  const postMessage = async (AIResponse, finish_reason, desiredMessage, webSearchResult, excelURL, parentFunction, solvedParentFunction) => {
    // debugger;
    //;
  
    const kbCheck = desiredMessage?.isKbRetriever || IsKbRetrieverActive;
    if (desiredMessage?.isKbRetriever) {
      setIsKbRetrieverActive(true)
    }
    if (promptData?.indexName) {
      setIndexName(promptData?.indexName)
      setCollectionName(promptData?.collectionName)
    }
    try {
      const currentChat_ID = new URLSearchParams(window.location.search).get(
        "chatID"
      );

      let promptMessageOrder = message_Order;
      if (!messageOrder) {
        promptMessageOrder = 0;
      }
      //(promptMessageOrder,"promptMessageOrder")

      setlastArrayObj(AIResponse);
      setcurrentChatID(currentChat_ID);

      let result = await insertMessage({
        isEditMessage: isEditMessage,
        AIResponse: AIResponse,
        chatID: currentChat_ID,
        messageType: "chat",
        promptMessageOrder: promptMessageOrder,
        url: excelURL ? excelURL : null,
        webContent: webSearchResult ? webSearchResult : "",  
        kbContent: "" ,
        existingParentFunction: parentFunction ? parentFunction : null,
        solvedParentFunction: solvedParentFunction ? solvedParentFunction : null,
      });

      if(AIResponse?.cost && !isEditMessage && currentChat_ID && result['ResponseData']!=null){
        // console.log("AIResponseCost",AIResponse.cost)
        // console.log("EditMessage",isEditMessage)
        // console.log("currentChatID",currentChat_ID)
        // console.log('result.ResponseData',result['ResponseData'])
        const costReq={
          AIResponse:{ "cost":AIResponse.cost},
          isEditMessage: isEditMessage,
          chatID: currentChat_ID,
          MessageID:result['ResponseData']
        }
        await CostCalculationNav(costReq)
        // console.log("CostRequestBody",costReq)
      }

      // if (result.Success == true && AIResponse.role == "assistant") {
      //   //console.log(isSimilarityCheckDone, "isSimilarityCheckDone")
      //   // if (AIResponse.role == "assistant" && isSimilarityCheckDone) {
      //   //   setShowCodeCompare(true)
      //   // }
      //   sethiddenObjValues({
      //     ...hiddenObjValues,
  
      //     sendMessage: false,
      //   });
      //   // setSendButton(false)
      // }
      if (result.Success == true) {
        
        if (AIResponse.role == "user") {
          //(AI_token, "AITOKEN")
          //(desiredMessage, "desiredmessagebro")
          getGPTResponse(desiredMessage, AIResponse, promptMessageOrder, result.ResponseData);
          setNewMessageID(result.ResponseData)
        } else {
          if (promptIDMessages?.length == messageOrder) {
            updateStatus("sucs");
            // setSendButton(false)
          }
        }
      }
    } catch (error) {
      //("Logging error");
    }
  };

  const convertToExcel = (markdownContent) => {
    const workbook = XLSX.utils.book_new();
    const wsName = "Pseudocode"; // Sheet name
    const rows = markdownContent.trim().split('\n');
    //(rows,"rows")
    const wsData = [];

    // Process each row
    rows.forEach((row) => {
      // Remove leading and trailing '|' characters and split the row by '|'
      const cells = row.replace(/^\s*\|+|\|+\s*$/g, '').split('|');

      // Trim whitespace from each cell and push it to the worksheet data array
      wsData.push(cells.map(cell => cell.trim()));
    });

    //(wsData,"wsData")
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, ws, wsName);

    // Convert the workbook to an Excel file in binary format
    const excelFileBinary = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });

    // Convert the binary data to a Base64 URL
    const excelFileBase64 = btoa(excelFileBinary);
    const excelFileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFileBase64}`;
    //(excelFileUrl,"url")
    // Set the Excel file URL to the state variable
    // setExcelFileUrl(excelFileUrl);
    return excelFileUrl
    //(excelFileUrl,"seturl")
  }
  const handleDownloadExcel = () => {
    if (excelFileUrl) {
      // window.open(excelFileUrl);

      const a = document.createElement('a');
      a.href = excelFileUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } else {
      console.error('Excel file URL is empty.');
    }
  };



  /**
   * PS_CGAI_3.134 to PS_CGAI_3.140 based on the response show the error to the user
   * PS_CGAI_3.141 to PS_CGAI_3.146 if error means insert the erro to th errorTbl using errorLogs API
   * PS_CGAI_3.147 to PS_CGAI_3.158 read the gpt response to the user using stream
   */

  // const getGPTResponse=async(desiredMessage)=>{
  //   try{
  //      //(desiredMessage,currentChat,
  //       "PPPPPPPPPPP")
  //   }
  //   catch (error) {
  //     //(error,"XDCFV")
  //   }
  // }
  // const getGPTResponse = async (desiredMessage) => {

  //   setLoading(true)

  //   //(isKbRetriever,"YYYY");
  //   const kbCheck = desiredMessage?.isKbRetriever



  //   if (kbCheck == true) {
  //     try {
  //       let message = desiredMessage?.promptMessage
  //       const replacedString = message.replaceAll(
  //         /\((input-\d+)\)/g,
  //         (match, key) => {
  //           return replaceObj[key] || match;
  //         }
  //       );
  //       let obj = {
  //         "query": replacedString,
  //         "collection_name": collectionName,
  //         "index_name": indexName
  //       };
  //       let response = await KBRetriever(obj);


  //       //(response)
  //       let content = response?.data?.message;
  //       let finish_reason = "stop"
  //       setLoading(false)
  //       hitGPT(currentChat, content, finish_reason);
  //       //(response, "III");
  //     } catch (error) {
  //       console.error('Error occurred while retrieving knowledge base:', error);
  //     }
  //   }
  //   else {

  //     try {
  //       let modelConfigurationID = desiredMessage?.modelConfigurationID || "";
  //       let result = await LoadBalancer({
  //         modelConfigurationID: modelConfigurationID,
  //         // createdBy: desiredMessage.createdBy,
  //         messages: currentChat,
  //       });
  //       //(result,result.data, "TTTTT");
  //       //(typeof(result.data), "TTTTT");

  //       // Parse the AIResponse string as JSON
  //       let responseData = result?.data

  //       //(responseData, "TTTTT");

  //       // Check if responseData is defined and contains choices
  //       if (responseData) {
  //         let content = responseData.choices[0].message.content;
  //         //(content,"contenttt")
  //         const regex = /\|---/;
  //         const codeArray = content.split("```").map((code, index) => {
  //           return index % 2 !== 0 ? { type: "code", content: code } : null;
  //         }).filter(Boolean);
  //         //(codeArray,"codearray")

  //         if (codeArray) {
  //           const pseudocode = codeArray[0]?.content
  //           //(pseudocode,"pseudocode")
  //           const hasPseudocode = regex.test(codeArray[0]?.content);
  //           //(hasPseudocode,"haspseudocode")
  //           setShowDownloadButton(hasPseudocode);
  //           if (hasPseudocode) {
  //             convertToExcel(pseudocode);
  //           }
  //         }
  //         let finish_reason = responseData.choices[0].finish_reason;
  //         //
  //         //(content, finish_reason);
  //         setLoading(false)
  //         hitGPT(currentChat, content, finish_reason);
  //       } else {
  //         console.error('No choices found in the response data.');
  //       }
  //     } catch (error) {
  //       //(error);
  //       setLoading(false);
  //       setData("");
  //       set_Index_zero();
  //       sethiddenObjValues({
  //         ...hiddenObjValues,
  //         sendMessage:
  //           hiddenObjValues.inputFix == "Fix your response here"
  //             ? true
  //             : hiddenObjValues.sendMessage,
  //         tokenError: "Your token got expired try after few minutes",
  //       });

  //       let errorResult = await errorLogs({
  //         errorMessage: error.message,
  //         userEmailID: userData?.email,
  //         errorOccured: "frontEnd",
  //         functionName: "getGPTResponse",
  //       });

  //       if (errorResult.Success == true) {
  //         setLoading(false);
  //         sethiddenObjValues({
  //           ...hiddenObjValues,
  //           sendMessage:
  //             hiddenObjValues.inputFix == "Fix your response here"
  //               ? true
  //               : hiddenObjValues.sendMessage,
  //           tokenError: "Your token got expired try after few minutes",
  //         });
  //       } else {
  //         sethiddenObjValues({
  //           ...hiddenObjValues,
  //           sendMessage:
  //             hiddenObjValues.inputFix == "Fix your response here"
  //               ? true
  //               : hiddenObjValues.sendMessage,
  //           tokenError: "Your token got expired try after few minutes",
  //         });
  //       }

  //       // Handle other errors
  //     }

  //   }
  // }

  useEffect(() => {
    const storedIsModule = sessionStorage.getItem('isModule');
    // console.log('Stored isModule:', storedIsModule);
    if (storedIsModule) {
      setIsModule(prevState => {
        // console.log('Updating isModule from', prevState, 'to', storedIsModule);
        return storedIsModule;
      });
      // console.log(isModule, 'isModule logged')
      // sessionStorage.removeItem('isModule');
    }
  }, []);

  const getGPTResponse = async (desiredMessage, AIResponse, promptMessageOrder, MessageID) => {
    // debugger
    setLoading(true)
    const kbCheck = desiredMessage ? desiredMessage.isKbRetriever : isKbRetriever
    
    if (bURL && bURL.endsWith(".mp4")) {
      let obj = {
        "query": sendMessageValue,
        "url": bURL
      };
      let response = await getImageDocResponse(obj);
      let content = response?.ResponseData
      let finish_reason = "stop"
      setBUrl("")
      setImgDocFile("")
      setLoading(false)
      setData(content)
      hitGPT(currentChat, content, finish_reason);
    }


    if (isKGBuild) {
      
      let obj={
        "question":sendMessageValue,
        "projectName" : projectNameFromLocal,
        "collectionName": collectionNameFromMI,
        "workItem" : workItemFromMI,
        "module" : isModule
         }
      const response = await similarityCheck(obj);
      //console.log(response, "response")
      const content = response?.data?.Response
      //console.log(response?.data?.parentFunction, "response?.data?.parentFunction")
      //console.log(response?.data?.solvedParentFunction, "response?.data?.solvedParentFunction")
      
      if (isModule !== 'tasks') {
      // console.log('issues module is active');
      setLeftCodeArray(prevState => ({
        ...prevState,
        [currentChat.length]:response?.data?.parentFunction
      }));
      setRightCodeArray(prevState => ({
        ...prevState,
        [currentChat.length]:response?.data?.solvedParentFunction
      }));
 
      hitGPT(currentChat, content, "stop", null, null, null,response?.data?.parentFunction, response?.data?.solvedParentFunction );
      }

      else {
        // console.log('Tasks module is activee');
        hitGPT(currentChat, content, "stop")
      }
      
      // setIsKGBuild(false)
      setLoading(false)
      //console.log(response)
  }

    if (!bURL.endsWith(".mp4") && !isKGBuild) {
      try {
        // let modelConfigurationID = desiredMessage?.modelConfigurationID || "";

        setModelID(desiredMessage?.modelConfigurationID)
        let modelConfigurationID = desiredMessage?.modelConfigurationID || modelID || "";

        if (config.selectedModel) {
          modelConfigurationID = config.selectedModel;
        }
        let access_token = "";
        access_token = getSessionItem("access-token");
        let req;
        req = { "modelConfigurationID": modelConfigurationID, "messages": currentChat, "HyperConfig": {} }
        //
        //(modelId.modelConfigurationID,"printing modelID from env", yesFixButton)
        if (yesFixButton) {
          //
          if (modelConfigurationID == "") {
            modelConfigurationID = defaultModelFromEnv
          }
          req = { "modelConfigurationID": modelConfigurationID, "messages": currentChat, "HyperConfig": { "maxResponse": config.maxResponse, "temperature": config.temperature, "frequencyPenalty": config.frequencyPenalty, "presencePenalty": config.presencePenalty, "topP": config.topP } }
        }
        if (bURL) {
          req = { "modelConfigurationID": modelConfigurationID, "messages": currentChat, "HyperConfig": {}, "url": bURL, "query": sendMessageValue }
        }
        // console.log(desiredMessage, AIResponse, promptMessageOrder, MessageID, askAsamiKbReturn,IsKbRetrieverActive, kbCheck, "========================================")
        if (((kbCheck == true || (promptMessageOrder == 0 && IsKbRetrieverActive)) || askAsamiKbReturn == true) && AIResponse) {
          setKnowledgeBaseButton(true)
          let message = AIResponse.content;
          // console.log(AIResponse.content,"777777777777777777777777777")
          let obj = {
            "query": message,
            "collection_name": askAsamiKbReturn ? askAsamiCollectionName : collectionName,
            "index_name": askAsamiKbReturn ? askAsamiIndexName : indexName,
            "messages": currentChat
          };
          //console.log(obj, "obj")

          let response = await KBRetriever(obj);

          pushChat(currentChat, "user", "", response?.data?.similaritySearchResult);
          let result = await insertMessage({
            chatID: userChatId,
            isEditMessage: isEditMessage,
            messageID: MessageID,
            kbContent: response?.data?.similaritySearchResult
          });
          req = { "modelConfigurationID": modelConfigurationID, "messages": currentChat, "HyperConfig": {}, "query": AIResponse.content, "collection_name": askAsamiKbReturn ? askAsamiCollectionName : collectionName, "index_name": askAsamiKbReturn ? askAsamiIndexName : indexName }
        }
        // console.log(req,"plssssssssssssssssssssssssssssssss")
        const controller = controllerRef.current
        const signal = controller.signal;
        
        //console.log(req.messages, "mervin is here with the req body")
        const updatedList = req.messages.map((item) => {
          if (item.role === "assistant" && Array.isArray(item.content)) {
            return {
              ...item,
              content: item.content[item.content.length - 1]
            };
          }
          return item;
        });
        req.messages = updatedList
        // console.log(req.messages,"bbbbbbbbbbbbbbbbbbbbbbbbbbbb")
        const options = {
          method: 'POST',
          headers: {
            access_token: access_token,
            authorization: `Bearer ${token}`,
            // "Access-Control-Allow-Origin":"*" ,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(req),
          signal
        };
        const response = await fetch(UrlsAi.stream_loadBalancer, options);
        //(response.status)
        if (response.status !== 200) {

          setLoading(false)
          // setLoading(false);
          setData("");
          set_Index_zero();
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix == "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            tokenError: "Something went wrong, please do try again later",
            binding:false
          });

        }
        else{
          sethiddenObjValues({
            ...hiddenObjValues,
            popUpErrorMsg:"",
            tokenError: "",
            binding:false
          });
        }
        setLoading(false)

        setData("");
        // Check if the response is ok

        const reader = response.body.getReader();
        let result = '';
        let llmbedrock = "";

        // Read data as it streams
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }
          let ai_data = new TextDecoder().decode(value)

          const searchString = "ASA$MIcODE";
          const searchString2 = "modelVal~~BedRock"
          const searchString3 = "~~~~"
          const searchString4 = "~~~~~"
          if (ai_data.includes(searchString4)) {
            llmbedrock = ai_data.split(searchString4)[1]
            ai_data = ai_data.split(searchString4)[0]
          }
          //(ai_data, "STREMING DATA");

          if (ai_data === "ErrorLo@db@l@ncer") {
            hitGPT(currentChat, result, "error")
            break;
          }
          if (ai_data === "context_length_exceeded") {
            hitGPT(currentChat, result, "content_filter")
            break;
          }
          if (ai_data !== searchString && !ai_data.endsWith(searchString) && !ai_data.endsWith(searchString2)) {
            result += ai_data;
          } else if (ai_data.startsWith(searchString)) {
            result += ai_data.slice(searchString.length);
          }
          else if (ai_data.endsWith(searchString2)) {
            //console.log("Hey mervin here" + ai_data.slice(0, -searchString2.length));
            if (ai_data.includes(searchString3)) {
              result += ai_data.split('~~~~')[0];
            }
            llmbedrock = ai_data.split('~~~~')[1].slice(0, -searchString2.length);
            //console.log("Hey mervin here", llmbedrock)
          }
          else if (ai_data.endsWith(searchString)) {
            result += ai_data.slice(0, -searchString.length);
          }
          setData(() => result)


          if (ai_data === "ASA$MIcODE" || ai_data.includes("ASA$MIcODE") || ai_data.endsWith(searchString2)) {
            const regex = /\|---/;
            setBUrl("")
            setImgDocFile("")
            const hasPseudocode = regex.test(result);
            console.log(hasPseudocode, "excelexcelexcel")
            if (hasPseudocode) {
              let obj = {
                "modelConfigurationID": "",
                "messages":[ {
                  "role": "user",
                  "content": result
                }]
              }
              console.log(obj, "excelexcelexcel")
              let excelURL = await DownloadAsExcel(obj);

              console.log(excelURL, "excelexcelexcel")
              //([currentChat.length], excelURL.data.BlobURL, "blob_url");
              // let exceldownloadurl = convertToExcel(result)
              //console.log(excelURL.data.BlobURL, "exceldownloadurl")
              // setExcelFileUrl(excelURL.data.BlobURL)
              // setExcelFileUrl(exceldownloadurl)

              setExcelURLs(prevState => ({
                ...prevState,
                [currentChat.length]: excelURL?.data?.BlobURL
              }));

              if (sampleArray.length != 0) {
                sampleArray.push(result)
                hitGPT(currentChat, sampleArray, "stop", excelURL?.data?.BlobURL, llmbedrock = llmbedrock)
              }
              else {
                hitGPT(currentChat, result, "stop", excelURL?.data?.BlobURL, llmbedrock = llmbedrock)
              }
              break;
            } else {
              let codeArray = result.split("```").map((code, index) => {
                return index % 2 !== 0 ? { type: "code", content: code } : null;
              }).filter(Boolean);
              //console.log(codeArray, isSimilarityCheckDone, "codeArraycode")
              // // 
              if (codeArray && isSimilarityCheckDone) {
                setLeftCode(similarFunctions[currentFunctionIndex].functionCode)
                setRightCode(codeArray[0].content)
              }
              if (sampleArray.length != 0) {
                sampleArray.push(result)
                hitGPT(currentChat, sampleArray, "stop","",  llmbedrock = llmbedrock)
            }
            else {
                hitGPT(currentChat, result, "stop","",  llmbedrock = llmbedrock)
            }
              break;
            }
          }
          if (ai_data === "L3n&th-" || ai_data.includes("L3n&th-")) {
            hitGPT(currentChat, result, "length", "", llmbedrock = llmbedrock)
            break;
          }


        }

      } catch (error) {
        //(error);
        setLoading(false);
        setData("");
        set_Index_zero();
        sethiddenObjValues({
          ...hiddenObjValues,
          sendMessage:
            hiddenObjValues.inputFix == "Fix your response here"
              ? true
              : hiddenObjValues.sendMessage,
          tokenError: "Your token got expired try after few minutes",
          binding:false
        });

        let errorResult = await errorLogs({
          errorMessage: error.message,
          userEmailID: userData?.email,
          errorOccured: "frontEnd",
          functionName: "getGPTResponse",
        });

        if (errorResult.Success == true) {
          setLoading(false);
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix == "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            tokenError: null,
            binding:false
          });
        } else {
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix == "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            tokenError: "Your token got expired try after few minutes",
            binding:false
          });
        }
      }
    }



    // }
    // }
  }






  /**
   * PS_CGAI_3.159 to PS_CGAI_3.164 based on the finish_reason insert the content to the table
   * @param {currentChat for the chat} currentChat
   * @param {object that to be inserted in chat} AIResponse
   * @param {sttaus of the AI } finish_reason
   * @returns the current chat for the particular chat
   */
  const hitGPT = (currentChat, AIResponse, finish_reason, excelURL, llmbedrock, abort, parentFunction, solvedParentFunction) => {
    // // 
    //(AIResponse,"kjhgf");
    // // 
    sethiddenObjValues({
      ...hiddenObjValues,

      sendMessage: true,
    });
    if (hiddenObjValues.inputFix == "Fix your response here") {
      sethiddenObjValues({
        ...hiddenObjValues,

        sendMessage: true,
        inputFix: "Send a message...",

        responseBtn: false,
      });
    } else {
      sethiddenObjValues({
        ...hiddenObjValues,
        inputFix: "Send a message...",

        responseBtn: false,
      });
    }

    // setLoading(false);
    let msg = "Your Max response limit is reached";
    if (yesFixButton) {
      msg = "Your Max response limit is reached, Kindly increase the Max Response in the configurations to proceed"
    }
    if (abort) {
      //console.log("trieee")
      // setFinishResult('stopStream')
      // //console.log('iii')
      sethiddenObjValues({
        ...hiddenObjValues,
        regenerateBtn: false,
        popUpErrorMsg: "",
        tokenError: "",
        binding:false,
        inputFix: "Send a message...",
        responseBtn: false,
        // sendMessage: false
      });
      
      currentChat = pushChat(currentChat, "assistant", AIResponse);
      postMessage(
        {
          isEditMessage: isEditMessage,
          role: "assistant",
          content: AIResponse,
        },
        finish_reason,
        null,
        null,
        excelURL, 
        parentFunction, 
        solvedParentFunction
      );
      setData("");
      setIsEditMessage(false);
      return
      //need to insert json resp into db along with user email
    }
    else {
      if (currentChat.length > 0) {
        switch (finish_reason) {
          case "stop":
            sethiddenObjValues({
              ...hiddenObjValues,
              regenerateBtn: false,
              popUpErrorMsg: "",
              tokenError: "",
              sendMessage:
                hiddenObjValues.inputFix == "Fix your response here"
                  ? true
                  : hiddenObjValues.sendMessage,
              inputFix: "Send a message...",
              responseBtn: false,
              binding:false
            });
            currentChat = pushChat(currentChat, "assistant", AIResponse);
            // setSendButton(true)
            postMessage(
              {
                isEditMessage: isEditMessage,
                role: "assistant",
                content: AIResponse,
                cost: llmbedrock
              },
              finish_reason,
              null,
              null,
              excelURL, 
              parentFunction, 
              solvedParentFunction
            );
            setData("");
            setIsEditMessage(false);
            setPopMsg(true)
            setRegen(true)
            if(yesFixButton || message_Order==0){
              // console.log(messageOrder,"ttttttttttttttttttttttttttttttttttttttttt")
              sethiddenObjValues({
                ...hiddenObjValues,
                sendMessage: false,
              });
            }
            //need to insert json resp into db along with user email
            break;

          case "length":
            sethiddenObjValues({
              ...hiddenObjValues,
              sendMessage:
                hiddenObjValues.inputFix == "Fix your response here"
                  ? true
                  : hiddenObjValues.sendMessage,
              popUpErrorMsg: msg

            });

            setData("");

            break;

          case "error":

            sethiddenObjValues({
              ...hiddenObjValues,
              popUpErrorMsg:
                "Something went wrong, please do try again later",
                binding:false
            });
            break
          case "content_filter":
            sethiddenObjValues({
              ...hiddenObjValues,
              sendMessage:
                hiddenObjValues.inputFix == "Fix your response here"
                  ? true
                  : hiddenObjValues.sendMessage,
              popUpErrorMsg: "Your Input Token Context Limit is has reached try with New Chat.",
            });

            setData("");
            break;
          case null:
            sethiddenObjValues({
              ...hiddenObjValues,
              sendMessage:
                hiddenObjValues.inputFix == "Fix your response here"
                  ? true
                  : hiddenObjValues.sendMessage,
              popUpErrorMsg: "Something went wrong. Try New Chat",
              binding:false,
              tokenError:""
            });

            setData("");

            //couldnt generate try again
            break;
        }
      } else {
        sethiddenObjValues({
          ...hiddenObjValues,

          popUpErrorMsg: "ran out of tokens try again after sometimes",
        });
        setData("");
      }

    }

    return currentChat;
  };
  /**
   * PS_CGAI_3.165to PS_CGAI_3.168 bind the user chat to the right side
   * PS_CGAI_3.169 to PS_CGAI_3.178 bind the assistant chat on left side and buttons to proceed the resposne
   * @returns the chat to the user
   */


  const handleAPICall = async (firstContent) => {
    try {
      let askAsami = "";
      //


      // Make the API call
      const result = await LoadBalancer({
        modelConfigurationID: "",
        messages: [{
          "role": "user", "content": `Analyze this input: "${firstContent}".
    Task: Generate a single, descriptive word to serve as a chat name.
    This word should encapsulate the essence or main topic of the input.
    It must be ONE WORD ONLY, with no additional explanation.
    If the input is code or technical, choose a relevant technical term.
    Respond with just this one word, nothing else.` }]
      });
      let askAsamiResponse = result?.data
      if (askAsamiResponse) {
        askAsami = askAsamiResponse.ResponseData.response?.trim();

        // First, remove any truncated part (ending with ...)
        askAsami = askAsami.replace(/\s*\.{3,}$/, '').trim();

        // Then, remove any trailing dot
        askAsami = askAsami.replace(/\.$/, '');
      } else {
        askAsami = "Ask Asami"
      }

      setApiCallMade(true);
      //(askAsami, "AskAsami")

      if (KbChatName == true) {
        chatNameInsertion(askAsami)
      }
      else {
        await createChat(askAsami);
      }
      setAskAsamiChatCreation(false)
      //(askAsamiChatCreation, "here")
      return askAsami
    } catch (error) {
      console.error('\n');
      throw error;
    }

  };


  const chatNameInsertion = async (askAsami) => {
    //
    try {
      const currentChat_ID = new URLSearchParams(window.location.search).get(
        "chatID"
      );

      let result = await insertMessage({
        chatID: currentChat_ID,
        asamiChatName: askAsami, // Use template literals here
      });
      // Handle the result or any additional logic here
      //(result, "Insert Message Result");
    }
    catch (error) {
      console.error('\n');
    }
  };

  const togglePopUpKb = (index) => {
    setOpenKbIndex(openKbIndex === index ? null : index);
  }

  const togglePopUpWeb = (index) => {
    setOpenWebIndex(openWebIndex === index ? null : index);
  }

  useEffect(() => {
    scrollInto()
  }, [currentChat])

  const pushToTFS = async () => {
    // // 
    try {
      let req_body = {
        "repo_url": repoURLState,
        "pat": PATState,
        "branch_name": "dev",
        "commit_message": "asami commit",
        "code": rightCode,
        "parent_name": selectedParentName,
        "start_line": selectedStartLine,
        "end_line": selectedEndLine
      }
      let response = await pushToTFSFunc(req_body)
      if (response.status_code == 200) {
        window.alert("pushed to tfs")
      }
      else {
        window.alert("please retry")
      }

    }
    catch (error) {
      //console.log("logging error")
    }
    finally {
      localStorage.setItem("PastConversation","False" )
      history.push("/chat")
    }
  }




  useEffect(() => {
    // Function to check if the file is an image
    const checkIfImageFile = (file) => {
      const fileExtension = file.split('.').pop().toLowerCase();
      return fileExtension === 'png' || fileExtension === 'jpg';
    };

    if (bURL) {
      setIsImage(checkIfImageFile(bURL));
    }
  }, [bURL]);

 



  const scrollInto = () => {
    myElement.current?.scrollIntoView({
      behavior: "instant",
      block: "end"
    });
  }


  const handleEditIconClick = (content) => {
    //debugger
    //setPopMsg(false)
    setsendMessageValue(content);
    setIsEditMessage(true);
    setIsEditing(true)
    sethiddenObjValues({
      ...hiddenObjValues,
      sendMessage: true,
    });
   
      // const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
      // console.log(message_Order,"krrrrrrrrrrrrrrrrrrrrrrrr")
      if(messageOrder!=0){
        setRegenTextarea(false)
      }
      else{
        setRegenTextarea(true)
      }
      // const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
      // if (lastUserChatIndex !== -1) {
      //   //currentChat[lastUserChatIndex].content = '';
      //   // if (currentChat[lastUserChatIndex].content === '') {
      //     currentChat.splice(lastUserChatIndex, 1);
      //   // }
      // }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };
  
  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaHeight();
      // adjustTextareaWidth();
    }
  }, [isEditing]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
    
  };
  // const adjustTextareaWidth = () => {
  //   const textarea = textareaRef.current;
  //   if (textarea) {
  //     textarea.style.width = 'auto';
  //     // textarea.offsetWidth;
  //     textarea.style.width = `${Math.min(textarea.scrollWidth, 100)}vw`;
  //   }
  // };

  const generateMessageHistory = () => {
    // //

    let assistantIndex = 0;
    let last_index = currentChat.length;
    currentChat.find((value, index) => {
      if (value?.role == "assistant") {
        assistantIndex = index;
      }
    });

    const lastUserIndex = currentChat.reduce((lastIndex, item, index) => (
      item.role === 'user' ? index : lastIndex
    ), -1);

    const lastAssistantIndex = currentChat.reduce((lastIndex, item, index) => (
      item.role === 'assistant' ? index : lastIndex
    ), -1);

    return (
    <>
    {isLoad ?   (<div style={{textAlign:'center', flex: 1,
                          display: 'flex',
                          justifyContent: 'center', marginTop:'15%', marginLeft:'-5%'}}>
                            <ClipLoader
                                      color={'#865BE7'}
                                      loading={isLoad}
                                      size={50}
                                    /></div>) :
      <>
        {currentChat.map((value, Chat_index) => {
          let profilePic = "";

          let role = value.role;
          let mainContent = value.content;
          let kbContent = kbContents[Chat_index];
          // let webContent = webContents[Chat_index];
          let webContent = webContents[Chat_index] !== null && webContents[Chat_index] !== undefined ? webContents[Chat_index] : webResult;

          let isLastUserMessage = Chat_index === lastUserIndex;

          let isLastAssistantMessage = Chat_index === lastAssistantIndex;
          //console.log(isLastAssistantMessage, "islastassistantmessage")
          if (isLastAssistantMessage && !hasUpdatedRef.current) {
            if (!yesFixButton) {
              hasUpdatedRef.current = true;
              setTimeout(() => {
                // sethiddenObjValues(prev => ({
                //   ...prev,
                //   sendMessage: true
                // }));
              }, 0);

            }
            else {
              hasUpdatedRef.current = true;
              setTimeout(() => {
                sethiddenObjValues(prev => ({
                  ...prev,
                  sendMessage: false
                }));
              }, 0);
            }
          }
          // Check if kbContent is present in the content and split if necessary
          // console.log(value,"valueeee")
          if (typeof value.content === 'object' && value.content !== null && !Array.isArray(value.content)) {
            // Convert the object to a JSON string
            value.content = JSON.stringify(value.content);
          } else if (typeof value.content === 'string') {
            // If it's already a string, use it as is
            value.content = value.content;
          }
          if (value?.content?.includes('\n\nKnowledge Base:\n')) {
            [mainContent, kbContent] = value.content.split('\n\nKnowledge Base:\n');
          }
          if (value?.content?.includes('\n\nWeb Content:\n')) {
            [mainContent, webContent] = value.content.split('\n\nWeb Content:\n');
          }
          if (value?.content?.includes('$$$')) {
            // Split the content and keep only the part before the delimiter
            [mainContent] = value.content.split('$$$');
          }
          switch (role) {
            case "user":
              // profilePic = "";
              //  var i=Chat_index

              return (
                <div
                  onLoad={(e) => {
                    e.target.focus();
                  }}
                >
                  <div className="outgoing-content  d-flex w-100 mt-5">
                    <div className="chat-content position-relative">
                      <img
                        src={userData?.profileImage}
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 end-0 header-profile"
                      />
                      <div className="chat-box right position-relative">
                        {isEditing && isLastUserMessage ? (<div style={{ position: 'relative' }}>
                          <textarea
                            ref={textareaRef}
                            value={sendMessageValue}
                            onChange={(e) => { setsendMessageValue(e.target.value); }}
                            // rows="1"
                            style={{'minWidth': minWidth+'px' , 'minHeight': minHeight+"px"}}
                            className="edit-textarea text-color-1"
                          />

                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button
                            className="btn focus-none border-0"
                            type="button"
                            id="button-addon2"
                            onClick={async (e) => {
                              const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
                              if (lastUserChatIndex !== -1 && popMsg) {
                                currentChat.splice(lastUserChatIndex, 1)
                              }
                              setPopMsg(false)
 
                              handleCancel()

                              if (sendMessageValue.trim() != "") {
                                if (
                                  hiddenObjValues.inputFix == "Fix your response here"
                                ) {
                                  // console.log("888888888888888888888888888")
                                  sethiddenObjValues({
                                    ...hiddenObjValues,
                                    inputFix: "Send a message...",
                                    sendMessage: false,
                                  });
                                  setsendMessageValue("");
                                  pushChat(currentChat, "user", sendMessageValue);
                                  postMessage({
                                    role: "user",
                                    content: sendMessageValue,
                                  });
                                } else {
                                  setmessageOrder(0);
                                  sethiddenObjValues({
                                    ...hiddenObjValues,
                                    inputFix: "Send a message...",
                                    sendMessage: true,
                                  });
                                  setsendMessageValue("");
                                  if (codeSnippetValue && CodeSnippet)
                                    sendMessageValue +=
                                      " Code:" + codeSnippetValue.toString() + " ";
                                      if (isEditMessage == true) {
                                        const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
                                        if (lastUserChatIndex !== -1) {
                                          if (isWebSearch) {
                                            currentChat.pop()

                                          }
                                          else{
                                            currentChat[lastUserChatIndex].content = sendMessageValue;
                                          }
                                          
                                        }
                                      }
                                  

                                  if (isWebSearch) {
                                    //
                                    const { formattedResponse, rawOutput } = await handleSearch(sendMessageValue);
                                    // debugger
                                    sendMessageValue = formattedResponse;
                                    webSearchResult = rawOutput;
                                    // console.log(currentChat.slice(0,currentChat.length),"ssssssssssssssssssssss")
                                    pushChat(currentChat, "user", formattedResponse);
                                  }
                                  

                                  if (askAsamiChatCreation === false) {
                                    //
                                    // pushChat(currentChat, "user", sendMessageValue);
                                    // if (isEditMessage) {
                                    //   // debugger
                                    //   pushChat(currentChat, "user", sendMessageValue);
                                    // }
                                    if (imgDocFile) {
                                      await postMessage({
                                        role: "user",
                                        content: imgDocFile + " " + sendMessageValue,
                                      }, null, null, webSearchResult);
                                    }

                                    else {
                                      await postMessage({
                                        role: "user",
                                        content: sendMessageValue,
                                      }, null, null, webSearchResult);
                                    }
                                    if (webSearchResult != "") {
                                      pushChat(currentChat, "user", "", "", webSearchResult);
                                    }
                                  }

                                }
                              } else {
                                //("give you content");
                              }
                              // setImgDocFile("");
                              setFileUploaded(false)


                            }}><img src="images/Default/send-img.svg" alt="send-img" style={{ width: '20px', height: '20px' }} /></button>
                            <button
                              className="btn focus-none border-0"
                              type="button"
                              id="button-addon2"
                              onClick={() => { 
                                if(yesFixButton  || message_Order==0){
                                  sethiddenObjValues({
                                    ...hiddenObjValues,
                                    sendMessage: false,
                                  });
                                }
   
                                handleCancel(); setsendMessageValue("") }}>
                              <img src="images/Default/cancel-icon.png" alt="send-img"
                                style={{ width: '15px', height: '15px' }} /></button></div>
                        </div>) : (<p
                          className="font-14 font-semibold m-0 text-color-15"
                          style={{ whiteSpace: "pre-wrap" , wordBreak : "break-word" }}
                          onLoad={(e) => {
                            e.target.focus();
                          }}
                        >
                          {}
                          
                          {mainContent.trim().split(/(\bhttps?:\/\/\S+)/).map((part, index) => {
                            if (part.startsWith('https://avaeusasamistgbsdev.blob.core.windows.net/')) {
                              const fullFilename = decodeURIComponent(part.split('/').pop());
                              const baseFilename = fullFilename.split('_')[0]; // Extract the part before the first underscore
                              return (
                                <span key={index} style={{color: 'blue', textDecoration: 'underline'}}>
                                  {baseFilename}
                                </span>
                              );
                            }
                            return <React.Fragment key={index}>{part}</React.Fragment>;
                          })}



                        </p>)}
                        <div class="d-flex justify-content-end align-items-center gap-2 my-2">
                          <div class="btn-group">
                            <button type="button" class="btn font-10 px-2 py-1 font-10 color-black rounded-1 px-2 py-1 font-semibold custom-outline-btn-2 "
                              style={{ "display": webContent ? "block" : "none"}}
                              onClick={() => togglePopUpWeb(Chat_index)}>Web</button>

                            <div class="dropdown-menu src-dpd rounded-3 p-4 m-1 src-pop-up bg-color-4 border-color-10 web-sorce-dropdown"
                              style={{ display: openWebIndex === Chat_index ? "block" : "none", width: "500px" }}>
                              <div class="d-flex justify-content-between mb-3">
                                <h4 class="font-20 text-color-1 font-medium m-0">Source</h4>
                                <img src="images\Close-icon.svg" alt="close" class="cursor-pointer close-icon-filter" width="16"
                                  onClick={() => togglePopUpWeb(null)} />
                              </div>
                              <p class="font-semibold font-10 text-color-1 rounded-1 px-2 py-1 bg-transparent violet-border d-inline">Source : <span>Web</span></p>
                              <div class="font-medium font-14 color-grey-v2 mt-3 src-dpd-content">
                                <p class="mb-0 text-color-15">{webContent}</p>
                              </div>
                            </div>
                          </div>
                          <div class="btn-group">
                            <button type="button" class="btn font-10 px-2 py-1 font-10 color-black rounded-1 px-2 py-1 font-semibold custom-outline-btn-2"
                              style={{ "display": kbContent ? "block" : "none"}}
                              onClick={() => togglePopUpKb(Chat_index)} >Knowledge Base
                            </button>

                            <div class="dropdown-menu src-dpd rounded-3 p-4 m-1 src-pop-up web-sorce-dropdown bg-color-4"
                              style={{ display: openKbIndex === Chat_index ? "block" : "none", width: "500px" }}>
                              <div class="d-flex justify-content-between mb-3">
                                <h4 class="font-20 text-color-1 font-medium m-0">Source</h4>
                                <img src="images\Close-icon.svg" alt="close" class="cursor-pointer close-icon-filter" width="16"
                                  onClick={() => togglePopUpKb(null)} />
                              </div>
                              <p class="font-semibold font-10 text-color-1 rounded-1 px-2 py-1 bg-transparent violet-border d-inline">Source : <span>Knowledge Base</span></p>
                              <div class="font-medium font-14 color-grey-v2 mt-3 src-dpd-content">
                                <p class="mb-0 text-color-15">{kbContent}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundImage: `url(${!newChat ? userData.profileImage: profile})`,
                            objectFit: 'cover',
                            backgroundSize: '100%',
                            borderRadius: '50%', // Make it round
                            position: 'absolute',
                            width: '50px',
                            height: '50px',
                            right: '-25px',
                            bottom: '-20px',
                          }}
                        ></div>
                        {!data ?
                     <div className="chat-option-edit bg-transparent">
                     {
                     isLastUserMessage && popMsg && (
                       <button type="button" className ="btn border-0 filter-icons" onClick={(e) => {e.stopPropagation(); handleEditIconClick(mainContent)}}>
                             <img
                             
                               src="/images-new/edit-icon.svg"
                               alt="edit-icon"
                               onClick={(e) => {e.stopPropagation(); handleEditIconClick(mainContent)}}
                             />
                       </button>
                           )
                         }
                    </div>
                    :null}
                      </div>
                    </div>
                  </div>
                </div>
              );

            case "assistant":
              // 
              let tempvaluecontent = ""
              var totallength = 0
              var currentlength = 0
              if (!Array.isArray(value?.content)) {
                  tempvaluecontent = value?.content
              }
              else {
                  // 
                  // let chatvalue="mervin is here"
                  let temp = { [Chat_index]: 0 }
                  // //console.log(chatvalue,"mervin here")

                  //console.log(temp, "chat index is set to 0")
                  if (!incrementval.hasOwnProperty(Chat_index)) {
                      
                      incrementval[Chat_index] = 0
                      tempvaluecontent = value?.content[incrementval[Chat_index]]
                      totallength = value.content.length - 1
                      currentlength = incrementval[Chat_index]

                  }
                  else {
                      tempvaluecontent = value?.content[incrementval[Chat_index]]
                      totallength = value.content.length - 1
                      currentlength = incrementval[Chat_index]
                      //console.log(tempvaluecontent, totallength, currentlength, "Printing this one")
                  }
              }
              profilePic = "";
              if (tempvaluecontent) {
                const code_array = tempvaluecontent?.split("```")
                    ? tempvaluecontent?.split("```")
                    : tempvaluecontent?.split("@")
                        ? tempvaluecontent?.split("@")
                        : "";

              // if (value.content) {
              //   const code_array = value?.content?.split("```")
              //     ? value?.content?.split("```")
              //     : value?.content?.split("@")
              //       ? value?.content?.split("@")
              //       : "";

                let results = [];

                // code_array.forEach((_code, index) => {
                //   if (index % 2 === 0) {
                //     results.push({
                //       type: "text",
                //       content: _code,
                //     });
                //   } else {
                //     results.push({
                //       type: "code",
                //       content: _code,
                //     });
                //   }
                // });

                code_array.forEach((_code, index) => {
                  // if (index % 2 === 0) {
                  //   results.push({
                  //     type: "text",
                  //     content: _code,
                  //   });
                  // } else {
                  //   results.push({
                  //     type: "code",
                  //     content: _code,
                  //   });
                  // }
                  const d2Match = _code.match(d2Regex);
                  if (d2Match) {
                    //console.log("Original D2 code:", _code);
                
                    // Remove everything up to and including the --d2 line
                    const trimmedD2Code = _code.slice(_code.indexOf(d2Match[0]) + d2Match[0].length).trim();
                
                    //console.log("Trimmed D2 code:", trimmedD2Code);
                
                    results.push({
                      type: 'd2',
                      content: trimmedD2Code,
                    });
                
                    //console.log("D2 result added:", results[results.length - 1]);
                  } else if (plantUmlRegex.test(_code.trim()) && index === 1) {
                    results.push({
                      type: 'plantuml',
                      content: _code,
                    });
                  } 
                  else if (mermaidRegex.test(_code.trim())) {
                    const mermaidMatch = _code.match(mermaidRegex);
                    if (mermaidMatch) {
                        // Remove everything up to and including the --mermaid line
                        const trimmedMermaidCode = _code.slice(_code.indexOf(mermaidMatch[0]) + mermaidMatch[0].length).trim();
            
                        results.push({
                            type: 'mermaid',
                            content: trimmedMermaidCode,
                        });
                    }
                  }
                  else if (index % 2 === 0) {
                    results.push({
                      type: "text",
                      content: _code,
                    });
                  } else {
                    results.push({
                      type: 'code',
                      content: _code,
                    });
                  }
                });

                return (
                  <div className={`d-flex w-100 mt-5 flex-col ${ Chat_index == assistantIndex && last_index - 1 == assistantIndex ? "last-spacing" : null} `} ref={myElement}>
                    <div className={`chat-content position-relative ${hiddenObjValues.sendMessage == false ? "incoming-margin" : null} `} >
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />

                      <div className="chat-area position-relative">
                        <div className="chat-box position-relative font-14 font-semibold m-0 text-color-15">
                          {results?.map((_res, idex) => {
                            switch (_res.type) {
                              case "code":
                                return (
                                  <>
                                    <>
                                    
                                    {!projectNameFromLocal && (
                                      <EditorComponent props={_res.content} />
                                    )
                                    }

                                    {projectNameFromLocal && isModule === 'tasks' && (
                                      <EditorComponent props={_res.content} />
                                    )
                                    }

                                      {projectNameFromLocal && isModule === 'issues' && (
                                        <div
                                          style={{ whiteSpace: "pre-wrap" , wordBreak : "break-word"}}
                                          onLoad={(e) => {
                                            e.target.focus();
                                          }}
                                        >
                                          {leftCodeArray && codeCompare(Chat_index)}{" "}
                                        </div>
                                      )
                                      }
                                    </>
                                  </>
                                );

                          case "plantuml":
                            return (
                              <>
                                {/* <PlantUMLComponent props={_res.content} /> */}
                                {/* {_res.content} */}
                                <h6 class ={"plantuml-heading"}>You can find your UML diagram.</h6>
                                <nav>
                                {/* <NavLink to={{ pathname: '/plantUml', search: `?plantUMLCode=${encodeURIComponent(_res.content)}` }}>UML Diagram</NavLink>  */}
                                <Link to={{ pathname: '/plantUml' }} state={
                                    {chatId: currentChatID}
                                    // Add any other props you want to pass
                                  } onClick={(e)=>{  
                                    //console.log(currentChatID,"Hehheeee");
                                    //console.log(messageOrder,"promptmessageorder");
                                   handleLinkClick(e,_res)}}>UML Diagram</Link>
                                {/* <a href={<PlantUMLPreview props={_res.content} />}>UML Diagram</a> */}
                                
                                </nav>
                              </>
                            );
                            case "d2":
                              return (
                                <>
                                  <h6 className={"d2-heading"}>You can find your D2 diagram here.</h6>
                                  <nav>
                                    <Link 
                                      to={{ 
                                        pathname: '/d2', 
                                        search: `?d2Code=${encodeURIComponent(_res.content)}`,
                                      }} 
                                      state={{ chatId: currentChatID }}
                                      onClick={(e) => handleD2LinkClick(e, _res)}
                                    >
                                      D2 Diagram
                                    </Link>
                                  </nav>
                                </>
                              );
                              case "mermaid":
                              return (
                                <>
                                  <h6 className={"d2-heading"}>You can find your mermaid diagram here.</h6>
                                  <nav>
                                    <Link 
                                      to={{ 
                                        pathname: '/mermaid', 
                                        search: ``,
                                      }} 
                                      state={{ chatId: currentChatID }}
                                      onClick={(e) => handleMermaidLinkClick(e, _res)}
                                    >
                                      Mermaid Diagram
                                    </Link>
                                  </nav>
                                </>
                              );
                            case "text":
                              return (
                                <>
                                  <div
                                    style={{ whiteSpace: "pre-wrap" }}
                                    onLoad={(e) => {
                                      e.target.focus();
                                    }}
                                  >
                                    {_res.content}{" "}
                                  </div>
                                </>
                              );
                          }
                        })}
                            </div>
                        {/* <p class="font-14 font-semibold m-0 grey-blue"></p> */}
                        <div className="position-absolute d-flex gap-2 bot-prev-next-decor" style={{marginLeft:"608px"}}>
                          {incrementval[Chat_index] > 0 ? (<img  style={{cursor:"pointer"}} src="\images\prev-arrow-icon.svg" alt="prev-arrow"  className="arrow-icon" onClick={() => { ; decreamentfunc(Chat_index) }}/>) : (<></>)}
                            {totallength > incrementval[Chat_index] || incrementval[Chat_index] > 0 ? (<span className="text-color-7 font-12">{incrementval[Chat_index]}/{totallength}</span>) : (<></>)}
                            {totallength > incrementval[Chat_index] ? (<img src="\images\next-arrow-icon.svg" style={{cursor:"pointer"}} className="arrow-icon" alt="next-arrow"onClick={() => { ; incrementfunc(Chat_index) }}/>) : (<></>)}
     
                      
                      </div>

                        <div className="chat-box-btns d-flex position-absolute ">

                          {hiddenObjValues.success && 
                            messageOrder != null &&
                            yesFixButton == false &&
                            Chat_index == assistantIndex &&
                            last_index - 1 == assistantIndex ? (
                            <>
                              <button
                                className="btn primary-gradiant-btn border-0 text-white font-12 font-medium me-3"
                                id="yes"
                                // disabled={
                                //   !hiddenObjValues.inputFix ? true : false
                                // }

                                disabled={hiddenObjValues.responseBtn}
                                onClick={(e) => {
                                  sethiddenObjValues({
                                    ...hiddenObjValues,
                                    responseBtn: true,
                                  });
                                  handleBtns(e);
                                  setPopMsg(false)
                                }}
                              >
                                Yes, looks good
                              </button>

                              <button
                                className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium  me-3"
                                id="fix"
                                // disabled={
                                //   !hiddenObjValues.inputFix ? true : false
                                // }
                                disabled={hiddenObjValues.responseBtn}
                                onClick={(e) => {
                                  sethiddenObjValues({
                                    ...hiddenObjValues,
                                    responseBtn: true,
                                    sendMessage: false,
                                    inputFix: "Fix your response here",
                                  });
                                }}
                              >
                                Fix this response
                              </button>
                            </>
                          ) : null}

                          
      {excelURLs[Chat_index] && excelURLs[Chat_index] !== 'None'  && excelURLs[Chat_index].includes('blob.core.windows.net/asa') && <button className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium" onClick={() => {
                                const url = excelURLs[Chat_index];
                                window.open(url, '_blank', 'noopener,noreferrer');
                                                    }}>
                            Download as Excel
                          </button>}



                        </div>
                        <div className="chat-option-regenrate bg-transparent">
                          {
                            isLastAssistantMessage && regen && (
                              <button onClick={handleRegen} type="button" class="btn bg-transparent lh-0 p-2">
                                <img className="filter-icons" src="/images-new/regenerate-icon.svg" alt="regenerate-icon" />
                              </button>)
                          }
                        </div>
                      </div>


                    </div>
                  </div>
                );
              }
          }
        })}
      </>
  }
</>

    );
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPromptSearch(value);
    if (value.trim() !== '') {
      // Filter prompt data based on the search input
      const filteredPrompts = promptData.filter(prompt =>
        prompt.promptName.toLowerCase().includes(value.toLowerCase())
      );
      // Set the filtered prompt data into a new state variable
      setSearchResult(filteredPrompts);
    }
  }



  //(hiddenObjValues.sendMessage,"TTT");
  /**
   * PS_CGAI_3.178 to PS_CGAI_3.182 based on the button button's onClick
   * @param {event for buttons} e
   * @returns the response based on the event
   */
  const handleBtns = (e) => {

    // debugger; 
    if (e.target.id == "yes") {
      message_Order += 1;
      messageOrder = message_Order;

      if (AI_token && globalJSON && promptIDMessages && messageOrder) {
        getPromptMessage(promptIDMessages, globalJSON);
      } else if ( promptIDMessages && messageOrder) {
        requestGptToken(promptIDMessages, globalJSON);
      }
    } else if (e.target.id == "fix") {
      sethiddenObjValues({
        ...hiddenObjValues,
        sendMessage: false,
        responseBtn: true,
      });
    } else if (e.target.id == "ok") {
      sethiddenObjValues({
        ...hiddenObjValues,
        inputFix: true,
      });
      pushChat(currentChat, "user", fixValue);
      postMessage({
        role: "user",
        content: fixValue,
        messageType: "text",
      });
    } else if (e.target.id === "Regen") {


      const desiredMessage = promptIDMessages?.find(
        (message) => message.promptMessageOrder === messageOrder
      );

      sethiddenObjValues({
        ...hiddenObjValues,
        regenerateBtn: true,
        popUpErrorMsg: "",
        tokenError: "",
        binding:false
      });
      // kbRetriever==false 
      //         ? KBRetriever():
      getGPTResponse(desiredMessage);
    } else {
      return null;
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setPromptSearch(value);

    // Clear previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout
    const timeout = setTimeout(() => {
      showPrompts();
    }, 200); // Adjust the delay as needed

    setTypingTimeout(timeout);
  };
  //(promptData,"fghjkl");

  /**
   * PS_CGAI_3.194 to PS_CGAI_3.196 get the value from the codeSnippetComponent proceed to give the response
   * @param {value for the codeSnippet component} value
   */
  const appendCodeSnippet = (value) => {
    // setmessageOrder(null);
    pushChat(currentChat, "user", value.Code);
    setCodeSnippetValue(value.Code);

    // postMessage(
    //   {
    //     role: "user",
    //     content: value.Code,
    //   },
    //   "",
    //   0
    // );
  };



  const createChat = async (askAsami) => {
    //
    if (userData?.role != "User" && userData?.role != "Lead" && userData?.role != "Leadership") {
      setStartConversation(false)
      sessionStorage.removeItem('issueState');
      try {
        let data = `Bearer ${token}`;

        const approverEmailID = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${userData?.email}/manager`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        try {
          let result = await insertMessage({
            projectName: projectNameFromLocal?projectNameFromLocal:"",
            approverEmailID: approverEmailID?.data.mail,
            chatType: "General",
            userEmailID: userData?.email,
            userName: userData?.userName,
            promptFormJson: { "Chat Name": askAsami },
            role: "user",
            codegenStatus: "ip"
          });

          //(result, "result")
          setAskAsamiChatID(result.ResponseData)
          history.push(`/chat?chatID=${result.ResponseData}`);
          //(askAsamiChatCreation, "askasami");
          return 200
        } catch (error) {
          //("Logging error");
        }
      } catch (error) {
        //(",logging error>");
      }
    } else {
      setStartConversation(false)
      sessionStorage.removeItem('issueState');
      let data = `Bearer ${token}`;

      const approverEmailID = await axios.get(
        `https://graph.microsoft.com/v1.0/users/${userData?.email}/manager`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      try {
        let result = await insertMessage({
          projectName: projectNameFromLocal?projectNameFromLocal:"",
          approverEmailID: approverEmailID?.data.mail,
          chatType: "General",
          userEmailID: userData?.email,
          userName: userData?.userName,
          promptFormJson: { "Chat Name": askAsami },
          role: "user",
          codegenStatus: "ip"
        });

        setAskAsamiChatID(result.ResponseData)
        history.push(`/chat?chatID=${result.ResponseData}`);

        //(askAsamiChatCreation, "askasami")
        //("Result........",result)
        return 200
      } catch (error) {
        //("Logging error");
      }
    }
  };

  useEffect(() => {
    if (location.state?.startConversation == true) {
      
      setHidePrompts(location.state.hidePrompts);
      setStartConversation(location.state.startConversation);
      hiddenObjValues.sendMessage = location.state.sendMessage;
      setFixButtonHide(location.state.fixButton);
      setYesFixButton(location.state.yesFixButton) // Kathir Changes
      setAskAsamiChatCreation(true)
    }
  }, [location.state]);



  // Kathir components

  useEffect(() => {
    // 
    // Then check sessionStorage
    //
    const storedState = sessionStorage.getItem('chatState');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      if (parsedState.getAskAsamiChat) {
        setYesFixButton(parsedState.yesFixButton);
        setHidePrompts(parsedState.hidePrompts);
        hiddenObjValues.sendMessage = parsedState.sendMessage;
      }
      //(yesFixButton, parsedState.yesFixButton, "fix button")

      // Set other state values as needed
      setChatState(parsedState);
      // Clear the stored state
      sessionStorage.removeItem('chatState');
    }

    //(yesFixButton, "fixed the button hide");
  }, [location]);

  useEffect(() => {
    // Then check sessionStorage
    //
    const storedState = sessionStorage.getItem('issueState');
    if (storedState) {
      // debugger
      const parsedState = JSON.parse(storedState);
      if (parsedState.startConversation) {
        // debugger;
        setHidePrompts(parsedState.hidePrompts);
        setStartConversation(parsedState.startConversation);
        hiddenObjValues.sendMessage = parsedState.sendMessage;
        setFixButtonHide(parsedState.fixButton);
        setYesFixButton(parsedState.yesFixButton) // Kathir Changes
        setAskAsamiChatCreation(true)
      }

     setIssueState(parsedState)
   
    }
    
    //(yesFixButton, "fixed the button hide");
  }, [location]);

  // if (startConversation == true) {
  //   createChat();
  // }

  // useEffect(() => {
  //   if (location.state?.getAskAsamiChat) {
  //     //
  //     setFixButtonHide(location.state.fixButton);
  //     //(fixButtonHide, "fixed the button hide")
  //   }
  // }, [location.state]);



  const uploadMedia = async (e) => {
    //;
    setIsUploading(true);
    setShowInitialPreview(true);
    // setAlertType("uploading...")
    let file = e.target.files[0];
    let fileName = file.name
    // setFileName(file.name);
    // setsendMessageValue(fileName + " ");
    let newFileName = fileName
    newFileName = newFileName
    setImgDocFile(newFileName)
    // setImgDocFileAppended(false);
    //(file.type, "typeeee")
    const supportedFormats = ['image/jpeg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
      'application/pdf', // pdf
      'text/csv', // csv
      'text/plain', // txt
      'image/png', 'image/jpg', 'image/gif', 'image/webp', 'video/mp4'];

    if (file && file.size > 30 * 1024 * 1024) {
      setAlertMessage('File size exceeds 30MB');
      setAlertType('error');
    }

    else if (file && !supportedFormats.includes(file.type)) {
      // setImgDocFile(()=>file.name);
      let base = await handleImageDocChange(file);
      // setImgDocFile();
      const data = {
        file: base,
        fileName: file.name + ".txt",
        fileExtension: 'text/plain',
      };
      let uploadToBackend = await uploadToBlob(data);
    }

    else if (file.type === "video/mp4") {
      // await handleVideoUpload(file);
      //(file, "fileeeeeee")
      const formData = new FormData();
      formData.append('fileName', fileName);
      formData.append('video', file);
      //(formData.get('video'), "formmmmmmmmmmmm")
      let uploadToBackend = await uploadToBlob(formData);
      //(uploadToBackend, "urllll")
    }

    else {
      setAlertMessage('');
      try {
        // handle the image upload logic
        // setImgDocFile(()=>file.name);
        let base = await handleImageDocChange(file);
        // setImgDocFile("")
        // setImgDocFile();
        const data = {
          file: base,
          fileName: file.name,
          fileExtension: file.type,
        };
        let uploadToBackend = await uploadToBlob(data);
        // setAlertType("uploaded")
        // setAlertMessage('Uploaded')
      } catch (error) {
        setAlertType("unable to upload")
        console.error('\n');
      }
      finally{
        setIsUploading(false)
      }
    }

    setTimeout(() => {
      setAlertMessage('');
      setShowInitialPreview(false);
      setShowPreviewOnHover(true);
    }, 3000);
    setIsUploading(false)
  };

  
  //ChangesMade
  //PC_IMGDOC_15 to PC_IMGDOC_22
  //This function is where the imageBlobURL of the content uploaded is generated
  const uploadToBlob = async (body) => {
    //;
    try {
      const results = await getBlobURL(body);
      //(results, "VID_IMG")
      const bloburl = results?.BlobURL;
      if (results.status_code === 200) {
        setFileUploaded(true)
        // setAlertType("uploaded")s
        // setAlertMessage('Uploaded')
      } else {
        setAlertType("unable to upload")
      }


      //(bloburl, "blobURL")
      setBUrl(results.BlobURL)
      // setAlertType('success');
    }
    catch (error) {
      console.error('\n');
    }
  }


  //ChangesMade
  //PC_IMGDOC_23 to PC_IMGDOC_28
  //This function is where the image is converted into base64 using FileReader
  const handleImageDocChange = (file) => {
    //;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAbort = () => {

    if (controllerRef.current) {
      
      //console.log("hello there abort")
      controllerRef.current.abort()
    }
    controllerRef.current = new AbortController

    let abort = setAbort(true)
    //console.log(abort, 'ooooo')
    // setStreamstop(true)

    hitGPT(currentChat, null, undefined, undefined, undefined, abort)
    setIsEditMessage(true);
    setData("")
  }
  let sampleArray = [];
  const handleRegen = () => {
    
    const lastAssistantChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
    if (lastAssistantChatIndex !== -1) {
      for (let i = 0; i < currentChat[lastAssistantChatIndex].content.length && Array.isArray(currentChat[lastAssistantChatIndex].content); i++) {
        sampleArray.push(currentChat[lastAssistantChatIndex].content[i])
      }
      if (!Array.isArray(currentChat[lastAssistantChatIndex].content)) {
        sampleArray.push(currentChat[lastAssistantChatIndex].content)
      }
      // sampleArray.concat(currentChat[lastAssistantChatIndex].content);
      currentChat.splice(lastAssistantChatIndex, 1);
    }
    

    const desiredMessage = promptIDMessages?.find(
      (message) => message.promptMessageOrder === messageOrder
    );
    sethiddenObjValues({
      ...hiddenObjValues,
      sendMessage:true, 
      regenerateBtn: true,
      popUpErrorMsg: "",
      tokenError: "",
      binding:false
    });
    // kbRetriever==false
    //         ? KBRetriever():
    isEditMessage = true
    getGPTResponse(desiredMessage);
    //console.log(lastAssistantChatIndex, "lastassistantChatIndex")
  }

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        const file = new File([blob], "pasted_image.png", { type: blob.type });
        uploadMedia({ target: { files: [file] } });
        e.preventDefault();
        break;
      }
    }
  }
  // For voice to text convert
  const startRecording = async () => {
    try {
      //("_--____----__-____-___-____-__-_____", reviewMessage);
      //(questionType, "start recording function triggered", result);
      setVoiceToText("");
      // setStartVisual(true);
      audioChunksRef.current = [];
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      //console.log(recognitionRef.current, 'I am here for you')
      // if (questionType) {
      recognitionRef.current.start();


      recognitionRef.current.onresult = (event) => {
        const fullTranscript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        transcriptRef.current = fullTranscript;
        //(fullTranscript, "voice");
        //console.log(fullTranscript, "voice");
        setVoiceToText(fullTranscript);
        setsendMessageValue(fullTranscript);
        if (fullTranscript == "") {
          setsendMessageValue("Listening...")
        }
        else {
          setsendMessageValue(sendMessageValue + fullTranscript);
        }
      };

      setIsRecording(true);
    } catch (error) {
      console.error("\n");
      // Provide feedback to the user if necessary
    }
  };

  const stopRecording = () => {
    try {
      //("stop recording function triggered.");
      // setStartVisual(false);
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsRecording(false);
    } catch (e) {
      const data = {
        error_type: "review component",
        error_source: "stop recording",
        error_description: e.toString()
      };
      //(data);
    }
  };


  const voiceButtonClick = (e) => {
    if (isRecording) {
      stopRecording(e);
    } else {
      startRecording(e);
    }
  };


  // For ask asami Kb Upload scenerio

  const handleAddKB = async () => {
    //
    sethiddenObjValues({
      ...hiddenObjValues,
      loader: true
    });
    const currentChat_ID = new URLSearchParams(window.location.search).get("chatID");
    if (currentChat_ID === null) {
      setKbChatName(true)
      await createChat("knowledgeBase");
    }
    sethiddenObjValues({
      ...hiddenObjValues,
      loader: false
    });
  };


  const handleAddKnowledgeBaseClick = () => {
    setShowAddKnowledgeBase(true);
  };

  useEffect(() => {
    //
    const currentChat_ID = new URLSearchParams(window.location.search).get(
      "chatID"
    );
    setUserChatId(currentChat_ID)
    const newAskAsamiKbData = {
      chatID: currentChat_ID,
      collectionName: askAsamiCollectionName,
      indexName: " ",
      kbFiles: askAsamiKnowledge,
      kbUrl: askAsamiKbUrl,
      isKbRetriever: askAsamiKbRetriever
    };

    setAskAsamiKbData(newAskAsamiKbData);
    //(newAskAsamiKbData, "newAskAsamiKbData");
    // insertData(newAskAsamiKbData);

  }, [askAsamiCollectionName, askAsamiIndexName, askAsamiKnowledge, askAsamiKbUrl, askAsamiKbRetriever]);


  useEffect(() => {
    //
    if (askAsamiKbData['isKbRetriever'] &&
      askAsamiKbData['collectionName'] !== "" &&
      askAsamiKbData['indexName'] !== "" &&
      (askAsamiKbData['kbFiles'] !== "" || askAsamiKbData['kbUrl'] !== "")) {
      insertData(askAsamiKbData);
      //(askAsamiKbData, "result-final")
    }
  }, [askAsamiKbData]);
  const hasInsertedChat = useRef(false);

  useEffect(() => {
    // debugger;
    if (!hasInsertedChat.current) {
      insertChatinProject();
      hasInsertedChat.current = true;
    }
  }, [setIsKGBuild]);

  
  const insertChatinProject = async () =>{
    const currentChat_ID = new URLSearchParams(window.location.search).get("chatID");
    let obj ={}
    if (issueIDFromMI){
    obj={
      issueID: issueIDFromMI, 
      chatID: currentChat_ID 
    }
  }
  // if (taskID){
  //  obj={
  //     taskID: taskIDFromMT, 
  //     chatID: currentChat_ID 
  //   }   
  // }
    let response= await insertChatID(obj)
    //console.log(response)
  }

  const insertData = async (data) => {
    //(askAsamiKbData['isKbRetriever'], data, "in insert")
    //
    try {
      let result = await insertMessage(data);
      //('Data successfully inserted:', result.data);
      setAskAsamiKbReturn(true)
    } catch (error) {
      console.error('\n');
    }
  };

  let webSearchResult = "";

  const handleSearch = async (query) => {
    debugger
    
    //
    try {
      const response = await serpAPI({ query });
      // const formattedResponse = `Task: Answer user query using provided search results. Query - ${query} | Results - ${response.data.output}. Respond concisely, using only provided data. Address query directly, summarize key points, state any limitations or assumptions`;
      // const formattedResponse = `${query}\n---CONTEXT_TEXT---\nTask: There will be a context text in the end of this query, refer that and answer above question. Don't answer on your own. Just interprate what's on the context. Context: - <context> ${response.data.output} </context>. Respond concisely, using only provided data. Address query directly, summarize key points`;
      const formattedResponse = `${query}
      $$$
      
      As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
      
      ${response.data.output.google_result[0]}
      
      ${response.data.output.google_result[1]}
      
      Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`
      //console.log(response.data.output.google_result[0],"google")
      return {
        formattedResponse,
        rawOutput: response.data.output.google_result
      };
      //(response.data.Google_Search_Result,"gooooooo")
      // return `${query} Answer using the Google search result: ${response.data.Google_Search_Result}`;
      // return `${query} Answer using the Google search result: ${response.data.output}`;
    } catch (error) {
      console.error("\n");
      return query;
    }
  };

  const askAsami = () => {
    // 
    
    history.push({
      pathname: '/chat',
      state: {
        startConversation: true,
        hidePrompts: true,
        sendMessage: false,
        fixButton: true
      }
    });
    window.location.reload();
  };
  useEffect(() => {
    if (ChatHistory == true) {

      askAsami()
    }
  }, [ChatHistory])

  useEffect(() => {
    if (dropdown == true) {
      setFileDropDown(true)
    }
  }, [dropdown])
  useEffect(() => {
    if (movechat == true) {
      // // 

      // Navigate to "/chat" if not already there
      localStorage.setItem("PastConversation","False" )
      history.push("/chat");
      window.location.reload();
    }
  }, [movechat])



  return (

    <div className="row w-100 justify-content-lg-end justify-content-center">
    <div className={navOpen === "Close" ? "col-md-11 my-2 py-2" : "col-md-9 my-2 py-2"} id="chat-center">

      {!hiddenObjValues?.loader ? (
        <div className="justify-content-center d-flex" >
          <div className=" mb-auto w-100 position-relative" id="chatContainer" >

            {
            // isSimilarityCheckDone ? (
            //   <>
            //     {/* <div className="container-fluid"> */}
            //     <div className="row justify-content-center">
            //       <div className="incoming-content w-100 mt-5">
            //         <div className="position-relative ms-4 mb-3">
            //           <img src="images-new/asami-bot.svg" alt="bot-happy" className="chat-img-left position-absolute" />
            //           <div className="chat-box selected-prompt-chat">
            //             <div className="row ms-2">
            //               <div className="mb-3 ps-0">
            //                 <span className="font-16 font-bold">Pick a code to proceed with the select prompt</span>
            //               </div>
            //               <textarea
            //                 rows={15}
            //                 className="px-2 py-2 er-snippet selected-prompt"
            //                 value={similarFunctions[currentFunctionIndex].functionCode}
            //                 readOnly
            //               />
            //             </div>
            //           </div>
            //         </div>

            //         <div className="ms-5 d-flex align-items-center">
            //           <div className="d-flex align-items-center">
            //             <button className="btn py-0" onClick={handleFuncPrevClick}><img src="images-new/left-arrow.svg" alt="Forward" /></button>
            //             <span className="dont-regular font-12 mt-1">{`${currentFunctionIndex + 1} / ${similarFunctions.length}`}</span>
            //             <button className="btn py-0" onClick={handleFuncNextClick}><img src="images-new/right-arrows.svg" alt="Backward" /></button>
            //           </div>
            //           <button className="btn theme-outline-btn font-12 font-semibold px-3" onClick={handleFetchMoreClick}>Fetch More</button>
            //           <button className="btn primary-gradiant-btn text-white font-12 font-medium me-3 border-0 ms-2" onClick={handleYesContinue}>Yes, Continue</button>
            //         </div>
            //       </div>
            //     </div>
            //     {/* </div>          */}
            //   </>
            // ) : null
            }
            {hiddenObjValues.invalidChat ? (
              <div
                className="chat-box  position-relative"
                style={{ border: "1px solid #F7BF55", alignItems: "center" }}
              >
                <p className="font-14 font-semibold m-0 grey-blue">
                  {hiddenObjValues.APIError}
                </p>
              </div>
            ) : null}

            <div className="mt-auto custom_chat-padding chat-page-height-fix ms-md-0 ms-5 pb-5" >
              <div className="w-50 d-flex align-items-center justify-content-between">
              {yesFixButton ? (<h2 class=" font-bold font-24 m-0 ask-asami-h2 text-color-1" style={{ left: '25px' }} >Ask Asami</h2>) : null}
              {!hiddenObjValues.invalidChat ? (
                <p className="text-end font-12 font-medium my-3 text-color-9"  style={yesFixButton?{}:{marginLeft:'90%',marginRight:'-8%'}}>
                  {formattedDateTime}
                </p>
              ) : null}
              </div>
              {hiddenObjValues.binding  ? ( 
                <div className="incoming-content row justify-content-center  d-flex w-100 mt-5">
                  <div class="col-10 col-md-11 col-lg-9 my-5 bg-color-2 px-0 border-radius-15">
                    <div class={`search-prompt-bg border-radius-15 py-4 px-4 ${hidePrompts ? "searchPrompt" : null}`} hidden={hidePrompts} >
                      <div class="row">
                        <div class="col-md-12 mb-4">
                          <label class="font-24 text-color-1 font-medium mb-3 ma">
                            Where Solutions Unfold
                          </label>
                          <div className="w-100">
                            <div
                              className="chat-content position-relative">

                              <p className="font-14 font-semibold m-0 text-color-15 mb-1">
                                {" "}
                                Hi <span className="text-color-4">
                                  {userData?.userName}
                                </span>{" "}
                                How can I help you with?
                              </p>
                            </div>
                          </div>
                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control w-100 font-16 font-medium border-radius-10 border-0 cust-input-sty search-pad-35 py-3 text-color-4"
                              id="search"
                              placeholder="Ask Away..."
                              onChange={(e) => {
                                handleInputChange(e)

                              }}
                              // onKeyUp={(e) => {
                              //   if (e.key === "Enter") {
                              //     showPrompts();
                              //   }
                              // }}
                              value={promptSearch}
                            />
                            <span class="inputfield-icon-sty">
                              <a>
                                <img
                                  src="images/Default/input-search.svg"
                                  alt="Search icon"
                                  class="search-icon-sty filter-icons"
                                  onClick={(e) => {
                                    showPrompts();
                                  }}
                                />
                              </a>
                            </span>
                            {promptSearch != "" ? (
                              <div className="searchSuggestions text-color-4 cust-input-sty dashboard-table-scroll pe-2">
                                {bindSearch(searchResult)}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12 text-center">
                          <label class="font-16 font-medium color-gradient" style={{ cursor: "pointer" }} onClick={() => { setCollapse(collapse == false ? true : false) }}>
                            <img
                              class="me-2"
                              src="images/Default/explore-prompts-icon.svg"
                              alt="propmpt-icon"
                            />
                            Explore Prompts
                          </label>

                          <p class="mb-2">
                            <a
                              class={`custom-prompt-toggle ${collapse == true ? "" : "collapsed"}`}
                              data-bs-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <img
                                class="collapse-prompt-arrow"
                                src="images/Default/accordion-gradient-arrow.svg"
                                alt="dropdown-icon"
                              />
                            </a>
                          </p>

                          <div class={`${collapse == true ? "collapse show" : "collapse"}`} id="collapseExample">
                            <div class="d-flex align-items-center justify-content-center">
                              <div class="d-block pe-3">
                                <a 
                                  className={currentIndex <= 0 ? 'disabled' : ''}
                                  onClick={currentIndex > 0 ? handlePrevClick : undefined}
                                >
                                  <img
                                    class="cursor-pointer-fix"
                                    src="images/Default/carosel-arrow-left.svg"
                                    alt="arrow-left"
                                    style={{ opacity: currentIndex <= 0 ? 0.5 : 1, cursor: currentIndex <= 0 ? 'default' : 'pointer' }}
                                  />  
                                </a>
                              </div>

                              <div class="d-block">
                                <div class="row">
                                  {/* <!--prompt cards--> */}
                                  {bindPrompts()}
                                  {/* <!--prompt cards--> */}
                                </div>
                              </div>

                              {/* <!--right arrow--> */}
                              <div class="d-block ps-3">
                              <a 
                                  className={currentIndex + 6 >= promptData.length ? 'disabled' : ''}
                                  onClick={currentIndex + 6 < promptData.length ? handleNextClick : undefined}
                                >
                                  <img
                                    class="cursor-pointer-fix"
                                    src="images/Default/carosel-arrow-right.svg"
                                    alt="arrow-right"
                                    style={{ 
                                      opacity: currentIndex + 6 >= promptData.length ? 0.5 : 1, 
                                      cursor: currentIndex + 6 >= promptData.length ? 'default' : 'pointer' 
                                    }}
                                  />
                                </a>
                              </div>
                              {/* <!--right arrow--> */}
                            </div>
                          </div>
                          {/* <!--accordion body ends here--> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              ) : null}
              <div className="outgoing-content  d-flex w-100 mt-5">
                <div
                  className="chat-content position-relative"
                  hidden={hiddenObjValues.helpMe}
                >
                  {/* <img
                    src={userData?.profileImage}
                    alt="bot-happy"
                    className="chat-img position-absolute bottom-0 end-0 header-profile"
                  /> */}
                  <div className="chat-box right position-relative">
                    <p className="font-14 font-semibold m-0 text-color-5">
                      Help me with,
                      <span className="text-color-4"> {promptName}</span>{" "}
                    </p>
                    <div
                      style={{
                        backgroundImage: `url(${userData.profileImage})`,
                        objectFit: 'cover',
                        backgroundSize: '100%',
                        borderRadius: '50%', // Make it round
                        position: 'absolute',
                        width: '50px',
                        height: '50px',
                        right: '-25px',
                        bottom: '-20px',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex w-100 mt-5`} 
                onLoad={(e) => {
                  e.target.focus();
                  if (settrue001 == true) {
                    setRun(true)
                    setYesFixButton(true)
                  }
                  else {
                    setRun(false)
                  }
                }}
              >
                <div
                  className="chat-content position-relative"
                  hidden={hiddenObjValues.helpMe}
                >
                  <img
                    src="images\ai-images\Bot_profile.svg"
                    alt="bot-happy"
                    className="chat-img position-absolute bottom-0 chat-bot"
                  />
                  <div className="chat-box  position-relative">
                    <p
                      className="font-14 font-semibold m-0 text-color-5"
                      // data-bs-toggle={currentChatID ? "" : "modal"}
                      // data-bs-target={currentChatID ? "" : "#Helpus-popup"}
                      disabled={currentChatID}
                    >
                      {" "}
                      Sure I can help you with. Kindly{" "}
                      <span
                        id="fill-out-this"
                        className=" cursor-pointer"
                        ref={fillOut}
                        disabled={currentChatID}
                        onClick={(e) => {
                          setformHidden(true);
                          {
                            promptData?.map((val, i) => {
                              if (promptData[i].promptID == currentPromptID) {
                                setcurrentPrompt(promptData[i]);
                                setpromptIDMessages(
                                  promptData[i].promptMessages.sort(
                                    (a, b) =>
                                      a.promptMessageOrder -
                                      b.promptMessageOrder
                                  )
                                );
                              } else {
                                return null;
                              }
                            });
                          }
                        }}
                      >
                        <a
                          className={`text-decoration-none link-color ${newChat ? "" : "cursor-pointer"
                            }`}
                        >
                          {" "}
                          fill out this form{" "}
                        </a>
                      </span>{" "}
                      to <span className="text-color-4"> {promptName}</span>
                    </p>
                  </div>
                </div>
              </div>
              {jsonObj ? (
                <div
                  className="outgoing-content d-flex w-100 mt-5"
                  onLoad={(e) => {
                    e.target.focus();
                  }}
                >
                  <div className="chat-content position-relative">
                    {/* <img
                      src={userData?.profileImage}
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 end-0 header-profile"
                    /> */}
                    <div
                      className="chat-box right position-relative"
                      style={{ padding: "20px" }}
                    >
                      <div className="row">
                        {Object.entries(jsonObj).map(([key, value]) => (
                          
                          <>
                       
                            {key.trim() === "Any existing file structure" ? (
                              <div className="col-md-12 mb-2" key={key}>
                                <label className="font-14 font-semibold text-color-1">
                                  {key !== "" ? key : "code"}:
                                </label>
                                <span className="font-14 font-semibold grey-blue">
                                  <AddFileOrFolderSample props={value} />
                                </span>
                              </div>
                            ) : key.trim() === "Tech Stack" ||
                              key.trim() === "Type Of Code" ? (
                              <div className="col-md-6 mb-3" key={key}>
                                <label className="font-14 font-semibold grey-blue">
                                  {key}:
                                </label>

                                {Array.isArray(value) ? (
                                  <span className="font-14 font-semibold text-color-1">
                                    {value.join(",")}
                                  </span>
                                ) : (
                                  <span className="font-14 font-semibold text-color-1">
                                    {(() => {
                                      if (typeof value === 'string' && value.includes('avaeusasamistgbsdev.blob.core.windows.net')) {
                                        try {
                                          const url = new URL(value);
                                          const fullFilename = decodeURIComponent(url.pathname.split('/').pop());
                                          const baseFilename = fullFilename.split('_')[0]; // Extract the part before the first underscore
                                          return baseFilename;
                                        } catch (error) {
                                          console.error("Error processing URL:", error);
                                          return value;
                                        }
                                      }
                                      return value;
                                    })()}
                                  </span>
                                )}
                              </div>
                            ) : key.trim() === "Uploaded media/Doc" ? (
                              <div className="col-md-12 mb-2" key={key}>
                                <label className="font-14 font-semibold text-color-1">
                                  {key !== "" ? key : "User Request"}:
                                </label>
                                {Array.isArray(value) ? (
                                  <span className="font-14 font-semibold text-color-1">
                                    {value.join(",")}
                                  </span>
                                ) : (
                                  <span className="font-14 font-semibold text-color-1">
                                    {(() => {
                                      if (typeof value === 'string' && value.includes('avaeusasamistgbsdev.blob.core.windows.net')) {
                                        try {
                                          const url = new URL(value);
                                          const fullFilename = decodeURIComponent(url.pathname.split('/').pop());
                                          const baseFilename = fullFilename.split('_')[0]; // Extract the part before the first underscore
                                          return baseFilename;
                                        } catch (error) {
                                          console.error("Error processing URL:", error);
                                          return value;
                                        }
                                      }
                                      return value;
                                    })()}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div className="col-md-12 mb-2" key={key}>
                                <label className="font-14 font-semibold grey-blue">
                                  {key !== "" ? key : "User Request"}:
                                </label>
                                {Array.isArray(value) ? (
                                  <span className="font-14 font-semibold text-color-1">
                                    {value.join(",")}
                                  </span>
                                ) : (
                                  <span className="font-14 font-semibold text-color-1">
                                    {(() => {
                                    if (typeof value === 'string' && value.includes('avaeusasamistgbsdev.blob.core.windows.net')) {
                                      try {
                                        const url = new URL(value);
                                        const fullFilename = decodeURIComponent(url.pathname.split('/').pop());
                                        const baseFilename = fullFilename.split('_')[0]; // Extract the part before the first underscore
                                        return baseFilename;
                                      } catch (error) {
                                        console.error("Error processing URL:", error);
                                        return value;
                                      }
                                    }
                                    return value;
                                  })()}
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        ))}
                        <div
                          style={{
                            backgroundImage: `url(${userData.profileImage})`,
                            objectFit: 'cover',
                            backgroundSize: '100%',
                            borderRadius: '50%', // Make it round
                            position: 'absolute',
                            width: '50px',
                            height: '50px',
                            right: '-25px',
                            bottom: '-20px',
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* {JSON ? <>{JSON}</> : null} */}
              {!hiddenObjValues.awaiting ? (
                <p className="text-center font-12 font-medium my-3 grey-v2">
                  Awaiting Confirmation from{" "}
                  <span className="font-semibold">{approverName}</span>
                </p>
              ) : null}
              {!hiddenObjValues.requestSendMsg ? (
                <>
                  <div>
                    <div className="incoming-content  d-flex w-100 mt-5">
                      <div className="chat-content position-relative">
                        <img
                          src="images\ai-images\Bot_profile.svg"
                          alt="bot-happy"
                          className="chat-img position-absolute bottom-0 chat-bot"
                        />
                        <div className="chat-box  position-relative">
                          <p className="font-14 font-semibold m-0 grey-blue">
                            You need access to use this prompt. So, I have sent
                            the{" "}
                            <span className="grey-v1">approval request</span> to
                            your{" "}
                            <span className="grey-v1">reporting person</span>{" "}
                            {""}
                            and will notify you on the same once it’s done.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </>
              ) : null}
              <div hidden={hiddenObjValues.approvedMsg}>
                <div className="incoming-content   d-flex w-100 mt-5">
                  <div className="chat-content position-relative">
                    <img
                      src="images\ai-images\Bot_profile.svg"
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 chat-bot"
                    />
                    <div className="chat-box  position-relative">
                      <p className="font-14 font-semibold m-0 grey-blue">
                        Your request has been{" "}
                        <span className="grey-v1">
                          {hiddenObjValues.status}
                        </span>{" "}
                        Now you can proceed with{" "}
                        <span className="grey-v1">{promptName}</span>
                      </p>
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div hidden={hiddenObjValues.rejectedMsg}>
                <div className="incoming-content  d-flex w-100 mt-5">
                  <div className="chat-content position-relative">
                    <img
                      src="images\ai-images\Bot_profile.svg"
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 chat-bot"
                    />
                    <div className="chat-box position-relative">
                      <p className="font-14 font-semibold m-0 grey-blue">
                        Your request has been{" "}
                        <span className="grey-v1">
                          {hiddenObjValues.status}
                        </span>{" "}
                        .<span className="grey-v1">{promptName}</span>
                      </p>
                    </div>
                  </div>
                </div>{" "}
              </div>
              {generateMessageHistory()}

              {yesFixButton ? (<div className="chat-configure-edit p-4">
                <ConfigurationButton isImage={isImage}></ConfigurationButton>
              </div>) : null}
              {loading ? (
                <LoadingIndicator />
              ) : null}
              {data ? (
                <div  >
                  <div className="incoming-content  d-flex w-100 mt-5" >
                    <div className="chat-content position-relative">
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                      <div className="chat-box  position-relative" >
                        <p
                          className="font-14 font-semibold m-0 grey-blue "
                        // datatype={data}
                        >
                          <TextStream text={data} />
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : null}
              {hiddenObjValues.popUpErrorMsg && !hiddenObjValues.tokenError && !haveLastAssistantMessageCome ? (
                <div className="incoming-content d-flex w-100 mt-5"  >
                  <div className={`chat-content position-relative ${hiddenObjValues.sendMessage == false ? "incoming-margin" : null} `} >
                    <img
                      src="images\ai-images\Bot_profile.svg"
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 chat-bot"
                    />
                    <div
                      className="chat-box  position-relative  font-14 font-semibold m-0 grey-blue"
                      style={{ border: "1px solid #F7BF55" }}
                    >
                      <p className="font-14 font-semibold m-0 grey-blue " >
                        {hiddenObjValues.popUpErrorMsg}
                      </p>
                    </div>

                    <div className="chat-box-btns d-flex position-absolute ">
                      <button
                        className="btn primary-gradiant-btn text-white font-12 font-medium me-3 border-0"
                        id="Regen"
                        disabled={hiddenObjValues.regenerateBtn}
                        onClick={(e) => {
                          handleBtns(e);
                        }}
                      >
                        Regenerate
                      </button>
                      <button
                        className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium"
                        id="try"
                        onClick={(e) => {
                          if (yesFixButton) {
                            history.push({
                              pathname: '/chat',
                              state: {
                                startConversation: true,
                                hidePrompts: true,
                                sendMessage: false,
                                fixButton: true,
                                yesFixButton: true
                              }
                            });
                            window.location.reload();
                          } else {
                            window.location.href = "/chat";
                          }
                        }}
                      >
                        Try New Chat
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {!hiddenObjValues.popUpErrorMsg && hiddenObjValues.tokenError && !abort && !haveLastAssistantMessageCome ? (
                <div className="incoming-content d-flex w-100 mt-5"  >
                  <div className={`chat-content position-relative ${hiddenObjValues.sendMessage == false ? "incoming-margin" : null} `} >
                    <img
                      src="images\ai-images\Bot_profile.svg"
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 chat-bot"
                    />
                    <div
                      className="chat-box  position-relative  font-14 font-semibold m-0 grey-blue"
                      style={{ border: "1px solid #F7BF55" }}
                    >
                      <p className="font-14 font-semibold m-0 grey-blue " >
                        {hiddenObjValues.tokenError}
                      </p>
                    </div>
                    <div className="chat-box-btns d-flex position-absolute ">
                      <button
                        className="btn primary-gradiant-btn text-white font-12 font-medium me-3 border-0"
                        id="Regen"
                        disabled={hiddenObjValues.regenerateBtn}
                        onClick={(e) => {
                          handleBtns(e);
                        }}
                      >
                        Regenerate
                      </button>
                      <button
                        className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium"
                        id="try"
                        onClick={(e) => {
                          if (yesFixButton) {
                            history.push({
                              pathname: '/chat',
                              state: {
                                startConversation: true,
                                hidePrompts: true,
                                sendMessage: false,
                                fixButton: true,
                                yesFixButton: true
                              }
                            });
                            window.location.reload();
                          } else {
                            window.location.href = "/chat";
                          }
                        }}
                      >
                        Try New Chat
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {hiddenObjValues.tokenError && hiddenObjValues.popUpErrorMsg && !haveLastAssistantMessageCome ? (
                <div className="incoming-content  d-flex w-100 mt-5">
                  <div className={`chat-content position-relative ${hiddenObjValues.sendMessage == false ? "incoming-margin" : null}  `} >
                    <img
                      src="images\ai-images\Bot_profile.svg"
                      alt="bot-happy"
                      className="chat-img position-absolute bottom-0 chat-bot"
                    />
                    <div
                      className="chat-box position-relative font-14 font-semibold m-0 grey-blue"
                      style={{ border: "1px solid #F7BF55" }}
                    >
                      <p className="font-14 font-semibold m-0 grey-blue" style={{ zIndex: "-2" }}>
                        {"An error occurred. Please try again."}
                      </p>
                    </div>
                    <div className="chat-box-btns d-flex position-absolute ">
                      {/* <button
                        className="btn primary-gradiant-btn text-white font-12 font-medium me-3 border-0"
                        id="Regen"
                        disabled={hiddenObjValues.regenerateBtn}
                        onClick={(e) => {
                          handleBtns(e);
                        }}
                      >
                        Regenerate
                      </button> */}
                      <button
                        className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium"
                        id="try"
                        onClick={(e) => {
                          window.location.href = "/chat";
                        }}
                      >
                        Try New Chat
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {data && yesFixButton? (
              <div>
                <div
                  className={navOpen === "Close" ? "stop-generating-nav-close" : "stop-generating" }
                >
                  <button
                    className="btn primary-gradiant-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2"
                    onClick={(e) => {
                      handleAbort();
                      setsendMessageValue(populateMsg);
                    }}
                    type="button"
                    // style={{animation:"pulse 1s infinite"}}
                  >
                    <img
                      className="me-1 filter-icons"
                      src="/images/stop-icon.svg"
                      alt="stop-icon"
                    />
                    <span className="text-white custom-none NewChat" style={{fontSize:'0.9rem'}}>
                      Stop Generating
                    </span>
                  </button>
                </div>

                <style>
                  {`
                      @keyframes pulse {
                        0% {
                          transform: scale(1);
                        }
                        50% {
                          transform: scale(1.05);
                        }
                        100% {
                          transform: scale(1);
                        }
                      }
                    `}
                </style>
              </div>) : null}
            {!hiddenObjValues.sendMessage ? (
               <div className={`row ${navOpen === "Close" ? "leftnav" : "fixed-bottom"} chat-form-content pe-0`}>
                <div className="custom-bg fixed-bottom" style={{ "left": navOpen == "Close" ? "7.5%" : null, "width": navOpen == "Close" ? "92%" : "78%" }}>
                  <div className="col-md-12 ">
                  <div className="input-group bg-color-4 mb-2 chat-form-field mt-3">

                      <div class="btn-group dropup file-upload-additional-css">
                        <button type="button" class="file-input-wrapper cursor-pointer btn focus-none border-0 me-3" style={{ "display": pauseButtonEnable ? "none" : "block" }} onClick={((e) => fileDropDown ? setFileDropDown(false) : setFileDropDown(true))}>
                          <img className="filter-icons" src="\images\attach-file-icon.svg" alt="attach-file" />
                        </button>

                        {/* <div className={`alertt ${alertMessage ? 'show' : ''} ${alertType}`} role="alert">
                          {alertMessage}
                        </div> */}
                        <div className={`alertt ${alertMessage ? 'show' : ''} ${alertType}`} role="alert">
                          {alertMessage}
                        </div>
                        <ul class="upload-dpd m-1 font-14 font-medium py-3 px-2 file-upload-list-ele" style={{ "display": fileDropDown ? "block" : "none" }}>


                          {/* <li class="dropdown-item border-bottom py-2 cursor-pointer" onClick={((e) => setFileDropDown(false))}>
                            Upload Media
                            <button type="button" className="file-input-wrapper cursor-pointer btn focus-none border-0 me-3">
                              <input type="file" name="fileToUpload" id="fileToUpload" onChange={(e)=> {uploadMedia(e)}} />
                              <label htmlFor="fileToUpload">
                                <img src="images-new/attach-img.svg" alt="send-img" className="btn-upload link-text font-12 font-regular cursor-pointer text-underline image" />
                              </label>
                            </button>
                          </li> */}
                          <label htmlFor="fileToUpload" className="dropdown-item custom-dropdown-li custom-border-bottom text-color-9 py-2 cursor-pointer knowledgedropdown" onClick={(e) => setFileDropDown(false)}>
                            Upload Media
                            <button type="button" className="file-input-wrapper cursor-pointer btn focus-none border-0 me-3">
                              <input type="file" name="fileToUpload" id="fileToUpload" onChange={(e) => { uploadMedia(e) }} />
                              {/* <img src="images-new/attach-img.svg" alt="send-img" className="btn-upload link-text font-12 font-regular cursor-pointer text-underline image" /> */}
                            </button>
                          </label>


                          {/* <button type="button" className="file-input-wrapper cursor-pointer btn focus-none border-0 me-3">
                          <input type="file" name="fileToUpload" id="fileToUpload" onChange={uploadImg} />
                          <label htmlFor="fileToUpload">
                            <img src="images-new/attach-img.svg" alt="send-img" className="btn-upload link-text font-12 font-regular cursor-pointer text-underline image" />
                          </label>
                        </button> */}


                          <li class="dropdown-item py-2 cursor-pointer custom-dropdown-li CustomKnowledegeBase text-color-9" data-bs-toggle="modal" data-bs-target="#Helpus-popup" onClick={((e) => { handleAddKB(); setFileDropDown(false); })}>
                            Custom Knowledge Base
                          </li>

                        </ul>
                        {/* {fileUploaded && (
                          <div className="upload-container file-name-background d-flex align-items-start justify-content-between p-3 bg-color-4 rounded-3" style={{ bottom: pauseButtonEnable ? '310%' : '150%' }}>
                            
                                <div className="text-center">
                                <button type="button" className="btn p-0 lh-0 chat-area-btn-fix">
                                  <img width={imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'webp' || imgDocFile.split(".").at(-1) == 'gif' || imgDocFile.split(".").at(-1) == 'jpg' || imgDocFile.split(".").at(-1) == 'png' || imgDocFile.split(".").at(-1) == 'jpeg' ? "20px" : "30px"} src={`${imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'webp' || imgDocFile.split(".").at(-1) == 'gif' || imgDocFile.split(".").at(-1) == 'jpg' || imgDocFile.split(".").at(-1) == 'png' || imgDocFile.split(".").at(-1) == 'jpeg' ? `images-new/attach-img.svg` : `images-new/file-icon.svg`} `} alt="close-icon" onClick={() => { setFileUploaded(false) }} />
                                </button>
                                <p className="mb-0">{imgDocFile}</p>
                                </div>
                                <button type="button position-absolute right-0" className="btn p-0 lh-0 chat-area-btn-fix">
                                  <img src="images/Close-icon.svg" alt="close-icon" onClick={() => { setImgDocFile(""); setFileUploaded(false); setBUrl("") }} />
                                </button>
                              
                          </div>
                        )} */}
                        {fileUploaded || isUploading ? (
                          <div className="text-area-bind" style={{ position: 'relative' }}>
                            <div
                              onMouseEnter={() => {
                                if (showPreviewOnHover) {
                                  setIsHovering(true);
                                }
                              }}
                              onMouseLeave={() => {
                                if (showPreviewOnHover) {
                                  setIsHovering(false);
                                }
                              }}
                            >
                              <img
                                width={imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'webp' || imgDocFile.split(".").at(-1) == 'gif' || imgDocFile.split(".").at(-1) == 'jpg' || imgDocFile.split(".").at(-1) == 'png' || imgDocFile.split(".").at(-1) == 'jpeg' ? "20px" : "30px"}
                                src={`${imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'mp4' || imgDocFile.split(".").at(-1) == 'webp' || imgDocFile.split(".").at(-1) == 'gif' || imgDocFile.split(".").at(-1) == 'jpg' || imgDocFile.split(".").at(-1) == 'png' || imgDocFile.split(".").at(-1) == 'jpeg' ? `images-new/attach-img.svg` : `images-new/file-icon.svg`} `}
                                alt="file-icon"
                              />
                              {(showInitialPreview || (showPreviewOnHover && isHovering)) && (
                                <div style={{
                                  position: 'absolute',
                                  bottom: '100%',
                                  left: '0',
                                  padding: '5px',
                                  background: 'white',
                                  border: '1px solid #ddd',
                                  borderRadius: '4px',
                                  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                  zIndex: 1000
                                }}>
                                  {isUploading ? (
                                    <div className="loading-dots">
                                      <span>.</span><span>.</span><span>.</span>
                                    </div>
                                  ) : bURL ? (
                                    <img src={bURL} alt="Preview" style={{ maxWidth: '500px', maxHeight: '500px' }} />
                                  ) : (
                                    <div>No preview available</div>
                                  )}
                                </div>
                              )}
                            </div>
                            <p className="mb-0 text-color-9">{imgDocFile.length < 6 ? imgDocFile : imgDocFile.slice(0,5)+"..."}</p>
                            <button type="button" className="btn p-0 lh-0 chat-area-btn-fix">
                              <img src="images/Close-icon.svg" alt="close-icon" onClick={() => {
                                setImgDocFile("");
                                setFileUploaded(false);
                                setBUrl("");
                                setShowInitialPreview(false);
                                setShowPreviewOnHover(false);
                              }} />
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <textarea
                     
  disabled={isRecording ? true : false}
  type="text"
  id="ok"
  className="form-control border-0 focus-none bg-transprent  sendMessage font-16 focus-none text-color-2"
  placeholder={pauseButtonEnable ? "Listening..." : hiddenObjValues.inputFix}
  value={isEditing  ? "" : sendMessageValue}
  onChange={(e) => {
    setsendMessageValue(e.target.value);
    setPopulateMsg(e.target.value);
  }}
  onPaste={(e) => handlePaste(e)}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a new line on Enter
      setRegen(false)
      setPopMsg(false)
    } else if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault(); // Prevents the default behavior of adding two new lines
      setsendMessageValue((prevValue) => prevValue + "\n"); // Manually add a single newline
      setRegen(false)
      setPopMsg(false)
    }
  }}
  onKeyUp={async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setFileUploaded(false);
      setRegen(false)
      setPopMsg(false)
 
      if (sendMessageValue.trim() != "") {
        if (hiddenObjValues.inputFix == "Fix your response here") {
          sethiddenObjValues({
            ...hiddenObjValues,
            inputFix: "Send a message...",
            sendMessage: true,
          });
          setsendMessageValue("");
          pushChat(currentChat, "user", sendMessageValue);
          postMessage({
            role: "user",
            content: sendMessageValue,
          });
        } else {
          setmessageOrder(0);
          sethiddenObjValues({
            ...hiddenObjValues,
            inputFix: "Send a message...",
            sendMessage: true,
          });
          setsendMessageValue("");
          if (codeSnippetValue && CodeSnippet)
            sendMessageValue += " Code:" + codeSnippetValue.toString() + " ";
 
          if (isEditMessage == true) {
            const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
            if (lastUserChatIndex !== -1) {
              currentChat[lastUserChatIndex].content = sendMessageValue;
            }
          }
 
          if (isWebSearch) {
            const { formattedResponse, rawOutput } = await handleSearch(sendMessageValue);
            sendMessageValue = formattedResponse;
            webSearchResult = rawOutput;
          }
 
          if (fixButtonHide && !apiCallMade) {
            try {
              pushChat(currentChat, "user", sendMessageValue);
              const result = await handleAPICall(sendMessageValue);
              setAskAsamiChatName(result);
              await postMessage({
                role: "user",
                content: sendMessageValue,
              }, null, null, webSearchResult);
 
              if (webSearchResult != "") {
                pushChat(currentChat, "user", "", "", webSearchResult);
              }
            } catch (error) {
              console.error('\n');
            }
          }
 
          if (askAsamiChatCreation === false) {
            if (!isEditMessage) {
              pushChat(currentChat, "user", sendMessageValue);
            }
            await postMessage({
              role: "user",
              content: sendMessageValue,
            }, null, null, webSearchResult);
            if (webSearchResult != "") {
              pushChat(currentChat, "user", "", "", webSearchResult);
            }
          }
        }
      }
    }
  }}
/>

                      {/* <div>
                        <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3 mb-3" data-bs-toggle="modal" data-bs-target="#Helpus-popup" style={{ "display": pauseButtonEnable ? "none" : "block" }}> Add Knowledge Base</button>
                        <AddKnowledgeBase setAskAsamiKbRetriever={setAskAsamiKbRetriever} editKburl={kbAskAsamiRetrieverUrl} initialData={askAsamiKnowledge} knowledgeData_func={setAskAsamiKnowledge} knowledgeUrl_func={setAskAsamiKbUrl} index_func={setAskAsamiIndexName} colletionName={askAsamiCollectionName} indexName={askAsamiIndexName} collection_func={setAskAsamiCollectionName} promptName={askAsamiChatID} promptID={" "} />
                      </div> */}


                      <button type="button" className="file-input-wrapper cursor-pointer btn focus-none border-0" style={{ "display": pauseButtonEnable ? "none" : "block" }} onClick={(e) => {
                        voiceButtonClick(e);
                        setPauseButtonEnable(true);
                      }}>
                        {/* <input type="file" name="fileToUpload" id="fileToUpload" /> */}
                        <img className="filter-icons" src="\images\microphone-icon.svg" alt="microphone" />
                      </button>
                      <button class="btn focus-none border-0 px-4" type="button" id="button-addon2" style={{ "display": pauseButtonEnable ? "block" : "none" }} onClick={(e) => {
                        voiceButtonClick(e)
                        setPauseButtonEnable(false)
                      }}>
                        <img className="filter-icons" src="images/stop-icon.svg" alt="stop" />
                      </button>

                      <button
                        className="btn focus-none border-0 px-4"
                        style={{ "display": pauseButtonEnable ? "none" : "block" }}
                        type="button"
                        id="button-addon2"
                        onClick={async (e) => {
                          setRegen(false)

                          if (sendMessageValue.trim() != "") {
                            if (
                              hiddenObjValues.inputFix == "Fix your response here"
                            ) {
                              sethiddenObjValues({
                                ...hiddenObjValues,
                                inputFix: "Send a message...",
                                sendMessage: true,
                              });
                              setsendMessageValue("");
                              pushChat(currentChat, "user", sendMessageValue);
                              postMessage({
                                role: "user",
                                content: sendMessageValue,
                              });
                            } else {
                              setmessageOrder(0);
                              sethiddenObjValues({
                                ...hiddenObjValues,
                                inputFix: "Send a message...",
                                sendMessage: true,
                              });
                              setsendMessageValue("");
                              if (codeSnippetValue && CodeSnippet)
                                sendMessageValue +=
                                  " Code:" + codeSnippetValue.toString() + " ";

                              if (isEditMessage == true) {
                                const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
                                if (lastUserChatIndex !== -1) {
                                  currentChat[lastUserChatIndex].content = sendMessageValue;
                                }
                              }

                              if (isWebSearch) {
                                //
                                const { formattedResponse, rawOutput } = await handleSearch(sendMessageValue);
                                sendMessageValue = formattedResponse;
                                webSearchResult = rawOutput;
                              }

                              // For chatID creation  and insertion during ask asami chat
                              // {
                              //   fixButtonHide && !apiCallMade && (() => {
                              //     //

                              //     //(askAsamiChatCreation, "???")
                              //     pushChat(currentChat, "user", sendMessageValue);
                              //     handleAPICall(sendMessageValue)
                              //       .then(result => {
                              //         // Set askAsamiChatName
                              //         setAskAsamiChatName(result);
                              //         // pushChat(currentChat, "user", sendMessageValue);
                              //         postMessage({
                              //           role: "user",
                              //           content: sendMessageValue,
                              //         }, null, null, webSearchResult);
                              //         if (webSearchResult != "") {
                              //           pushChat(currentChat, "user", "", "", webSearchResult);
                              //         }
                              //         // Set apiCallMade to true to indicate that the API call has been made
                              //       })
                              //       .catch(error => {
                              //         console.error('Error:', error);
                              //       });
                              //   })()
                              // }
                              if (fixButtonHide && !apiCallMade) {
                                try {
                                  //
                                  //(askAsamiChatCreation, "???")
                                  pushChat(currentChat, "user", sendMessageValue);
                                  const result = await handleAPICall(sendMessageValue);  // Await the handleAPICall function
                                  setAskAsamiChatName(result);
                                  if (imgDocFile) {
                                    await postMessage({
                                      role: "user",
                                      content: imgDocFile + " " + sendMessageValue,
                                    }, null, null, webSearchResult);
                                  }

                                  else {
                                    await postMessage({
                                      role: "user",
                                      content: sendMessageValue,
                                    }, null, null, webSearchResult);
                                  }

                                  if (webSearchResult != "") {
                                    pushChat(currentChat, "user", "", "", webSearchResult);
                                  }
                                  // Set apiCallMade to true to indicate that the API call has been made
                                } catch (error) {
                                  console.error('\n');
                                }
                              }

                              if (askAsamiChatCreation === false) {
                                //
                                if (!isEditMessage) {
                                  pushChat(currentChat, "user", sendMessageValue);
                                }
                                if (imgDocFile) {
                                  await postMessage({
                                    role: "user",
                                    content: imgDocFile + " " + sendMessageValue,
                                  }, null, null, webSearchResult);
                                }

                                else {
                                  await postMessage({
                                    role: "user",
                                    content: sendMessageValue,
                                  }, null, null, webSearchResult);
                                }
                                if (webSearchResult != "") {
                                  pushChat(currentChat, "user", "", "", webSearchResult);
                                }
                              }

                            }
                          } else {
                            //("give you content");
                          }
                          // setImgDocFile("");
                          setFileUploaded(false)
                          // console.log(sendButton,"meeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")

                        }}
                        // disabled = {sendButton}
                        
                      >
                        <img className="filter-icons" src="images/Default/send-img.svg" alt="send-img" />
                      </button>
                    </div>


                    <div>
                      {yesFixButton ? (
                        <div class="col-md-4 col-sm-12 web-search-fix-chat-page d-flex align-items-center" >
                          <p class="font-15 font-semibold mb-0 text-color-4" style={{ marginRight: "2px" }}>Web Search</p>
                          <div class="form-check form-switch me-5 d-inline-block" style={{ paddingBottom: "3px" }} >
                            <input
                              class="form-check-input custom-toggle-input"
                              type="checkbox"
                              role="switch"
                              id="webSearch"
                              style={{ cursor: "pointer", marginLeft: "-35px" }}
                              checked={isWebSearch}
                              onClick={() => {
                                // setPromptMessageData({
                                //   ...promptMessageData,
                                //   isWebSearch: !isWebSearch // Toggle the value directly
                                // });
                                setIsWebSearch(prevState => !prevState); // Toggle the state
                              }}
                            /> 
                            {/* <label class="form-check-label font-14 font-medium light-black-color ms-2" for="webSearch">
                        {isWebSearch ? "Yes" : "No"}
                      </label> */}
                          </div>
                       
                       {projectNameFromLocal?(
                        <>
                          <p class="font-15 font-semibold mb-0 text-color-4" style={{ marginRight: "2px" }}>KG Search</p>
                          <div class="form-check form-switch me-5 d-inline-block" style={{ paddingBottom: "3px" }} >
                            <input
                              class="form-check-input custom-toggle-input"
                              type="checkbox"
                              role="switch"
                              id="webSearch"
                              checked={isKGBuild}
                              style={{ cursor: "pointer", marginLeft: "-35px" }}
                              onClick={() => {
                               setIsKGBuild(prevState => !prevState)
                              }}
                            />
                            </div>
                            </>
                            ):null}
                           
               
                        
 
                        </div>
                      ) : (
                        <button
                          className=" btn bg-transprent focus-none color-light-blue p-0"
                          // data-bs-toggle="modal"
                          // data-bs-target="#codesnippet-popup"

                          onClick={(e) => {
                            setCodeSnippet(true);
                          }}
                        >
                          {" "}
                          <span
                            className="me-2 "
                            // data-bs-toggle="modal"
                            // data-bs-target="#codesnippet-popup"

                            onClick={(e) => {
                              setCodeSnippet(true);
                            }}
                          >
                            <img
                              src="images/Default/add-blue-icon.svg"
                              // class="btn btn-primary"
                              // data-bs-toggle="modal"
                              // data-bs-target="#codesnippet-popup"

                              onClick={(e) => {
                                setCodeSnippet(true);
                              }}
                            />
                          </span>
                          Add Code Snippet
                        </button>)}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

        </div>
      ) : (
        // <div className="main-content">
        //   <div style={{marginLeft:'2.5%'}}>
            <Loader props={{ isloading: hiddenObjValues.loader }} />
          // </div>

        // </div>
      )}
      {hiddenObjValues.popUp ? (
        <PopUpComponent
          yesFunction={handleBtns}
          errorMessage={hiddenObjValues.popUpErrorMsg}
          id={hiddenObjValues.popUpId}
        />
      ) : null}
      {/* <div>
        <div hidden={formHidden}> */}
      {formHidden && !newChat ? (
        <MyContext.Provider
          value={{
            setformHidden,
            currentPrompt,
            replaceObj,
            appendJson,
            setJSON,
            setcurrentChatID,
            selectedFunction
          }}
        >
          <PromptFormComponent />
        </MyContext.Provider>
      ) : (
        <></>
      )}
      {/* </div>
      </div> */}
      {CodeSnippet ? (
        <CodeSnippetComponent
          props={{ variable: setCodeSnippet, fun: appendCodeSnippet }}
        />
      ) : null}

      <AddKnowledgeBase
        setAskAsamiKbRetriever={setAskAsamiKbRetriever}
        editKburl={kbAskAsamiRetrieverUrl}
        initialData={askAsamiKnowledge}
        knowledgeData_func={setAskAsamiKnowledge}
        knowledgeUrl_func={setAskAsamiKbUrl}
        index_func={setAskAsamiIndexName}
        collectionName={askAsamiCollectionName}
        indexName={askAsamiIndexName}
        collection_func={setAskAsamiCollectionName}
        promptName={askAsamiChatID}
        promptID={" "}
      // style={{ "display": "none" }}
      />
      {userData.role ==="User"?<FeedBackForm/>:<></>}
    </div>
    </div>
  );
}
