import React, { createContext, useState, useContext, useCallback } from 'react';
import { getBlobURL } from '../../AI Service/Api';
 
const FileUploadContext = createContext();
 
export const FileUploadProvider = ({ children }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [imgDocFile, setImgDocFile] = useState('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showSuccessEffect, setShowSuccessEffect] = useState(false);
  const [bURL, setBUrl] = useState('');
  const [ImageDocLinks, setImageDocLinks] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showInitialPreview, setShowInitialPreview] = useState(false);
  const [showPreviewOnHover, setShowPreviewOnHover] = useState(false);
 
 
  const uploadMedia = useCallback(async (e, record, recordData) => {
    // debugger
    setIsUploading(true);
    setAlertMessage("");
    setAlertType("");
    setShowInitialPreview(true);
 
    let file = e.target.files[0];
    if (!file) {
      setIsUploading(false);
      return;
    }
    console.log(file.type,"filetypeee")
    let fileName = file.name;
    setImgDocFile(fileName);
 
    const supportedFormats = [
      'image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf', 'text/csv', 'text/plain', 'video/mp4',
      'application/x-terraform',  // Terraform
      'application/yaml', 'application/x-yaml', 'text/yaml', // CloudFormation YAML
      'application/json' // CloudFormation JSON and ARM
    ];
 
 
    if (file.size > 30 * 1024 * 1024) {
      setAlertMessage('File size exceeds 30MB');
      setAlertType('error');
      setIsUploading(false);
      return;
    }
    const supportedExtensions = [
      '.jpeg', '.jpg', '.png', '.gif', '.webp',
      '.docx',  // Word document
      '.xlsx',  // Excel spreadsheet
      '.pdf', 
      '.csv',
      '.txt',
      '.mp4',
      '.tf',    // Terraform
      '.yml', '.yaml',  // CloudFormation YAML
      '.json'  // CloudFormation JSON and ARM
    ];
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
    // if (!supportedFormats.includes(file.type) && !supportedExtensions.includes(fileExtension)) {
    //   setAlertMessage('Unsupported file format');
    //   setAlertType('error');
    //   setIsUploading(false);
    //   return;
    // }
 
    try {
      let data;
      const supportedFormats = ['image/jpeg', 'image/png', 'application/pdf']; // Add other supported formats here
      const supportedExtensions = ['.jpg', '.jpeg', '.png', '.pdf']; // Add other supported extensions here
      const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2); // Extract file extension
    
      if (!supportedFormats.includes(file.type) && !supportedExtensions.includes(fileExtension)) {
        // Unsupported file format, convert to text
        let base = await handleImageDocChange(file);
        const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".txt"; // Remove original extension and append .txt
        data = {
          file: base,
          fileName: newFileName,
          fileExtension: 'text/plain',
        };
      } else if (fileExtension === 'jsx' || file.type === "text/plain") {
        // Handle .jsx files or plain text files
        let base = await handleImageDocChange(file);
        data = {
          file: base,
          fileName: file.name,
          fileExtension: file.type,
        };
      } else if (file.type === "video/mp4") {
        // Handle video files
        const formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('video', file);
        data = formData;
      } else {
        // Handle other supported formats
        let base = await handleImageDocChange(file);
        data = {
          file: base,
          fileName: file.name,
          fileExtension: file.type,
        };
      }
    
      const results = await getBlobURL(data);
    
      if (results.status_code === 200) {
        setUploadedFileUrl(results.BlobURL);
        setBUrl(results.BlobURL);
        setImageDocLinks(results.BlobURL);
        setUploadSuccess(true);
        setFileUploaded(true);
        setAlertType("success");
        setAlertMessage("File uploaded successfully");
        setShowSuccessEffect(true);
        if (record) {
          record.handleFieldValues(
            record.record.labelName,
            results.BlobURL,
            record.record.inputValueOrder,
            record.record.required
          );
        }
      } else {
        setAlertType("error");
        setAlertMessage("Upload failed");
        setUploadSuccess(false);
        setFileUploaded(false);
      }
      setTimeout(() => setShowSuccessEffect(false), 2000);
    } catch (error) {
      console.error('Upload error:', error);
      setAlertType("error");
      setAlertMessage("Upload failed");
      setUploadSuccess(false);
      setFileUploaded(false);
    } finally {
      setIsUploading(false);
    }
 
    setTimeout(() => {
      setAlertMessage('');
      setShowInitialPreview(false);
      setShowPreviewOnHover(true);
    }, 3000);
  }, []);
 
  const handleUploadResult = useCallback((results, record, recordData) => {
    if (results.status_code === 200) {
      setUploadedFileUrl(results.BlobURL);
      setBUrl(results.BlobURL);
      setUploadSuccess(true);
      setFileUploaded(true);
      setAlertType("success");
      setAlertMessage("File uploaded successfully");
     
      if (record && recordData) {
        record.handleFieldValues(
          recordData.labelName,
          results.BlobURL,
          recordData.inputValueOrder,
          recordData.required
        );
      }
    } else {
      setAlertType("error");
      setAlertMessage("Upload failed");
      setUploadSuccess(false);
      setFileUploaded(false);
    }
  }, []);
 
  const handleImageDocChange = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }, []);
 
  return (
    <FileUploadContext.Provider
      value={{
        isUploading,
        setIsUploading,
        alertMessage,
        setAlertMessage,
        alertType,
        setAlertType,
        imgDocFile,
        setImgDocFile,
        uploadedFileUrl,
        setUploadedFileUrl,
        uploadSuccess,
        setUploadSuccess,
        showSuccessEffect,
        setShowSuccessEffect,
        bURL,
        setBUrl,
        ImageDocLinks,
        setImageDocLinks,
        fileUploaded,
        setFileUploaded,
        showInitialPreview,
        setShowInitialPreview,
        showPreviewOnHover,
        setShowPreviewOnHover,
        uploadMedia,
        handleUploadResult,
        handleImageDocChange,
        getBlobURL,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};
 
export const useFileUpload = () => {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error('useFileUpload must be used within a FileUploadProvider');
  }
  return context;
};