import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import devConfig from "./config/env";
import TechStack from "./AI Components/TechStack";
import { EditorComponent } from "./AI Components/Editor";
import { AddKnowledgeBase } from "./AI Components/AddKnowledgeBase";

import ChatComponent from "./AI Components/ChatComponent";

import { HeaderComponent } from "./AI Components/HeaderComponent";
// import { Invalidxml } from "./Builder-Components/Service-Builder/invalidXMLPage";
// import { XMLFileUpload } from "./Builder-Components/Service-Builder/XMLFileUploadPage";
// import { ERTableJoinData } from "./Builder-Components/Service-Builder/ERTableDataPage";
// import { EntityCreation } from "./Builder-Components/Service-Builder/entityCreationPage";
import NotificationComponent from "./AI Components/NotificationComponent";
// import { MethodMappingComponent } from "./Builder-Components/Service-Builder/methodMappingPage";
// import { BuilderGrid } from "./Builder-Components/builderGridPage";
// import { BuilderForm } from "./Builder-Components/builderFormPage";
// import { FormBuilder } from "./Builder-Components/Form-Builder/FormBuilderPage";
// import { GridBuilder } from "./Builder-Components/Grid-Builder/gridBuilderPage";
import { FilterModule } from "./Builder-Components/Filter-Builder/FilterModulePage";
import { Error } from "./Other-Components/Error";
import Loader from "./Other-Components/loader";
import { LlmConfigGrid } from "./AI Components/llmConfigGrid";
import  D2Preview  from "./AI Components/D2Comp";


// import ChatHistory from "./AI Components/ChatHistory";
import { ChatHistory } from "./AI Components/ChatHistory";
import { LlmConfigForm } from "./AI Components/LlmConfigForm";
import ManageRequest from "./AI Components/ManageRequest";
import UserActivityLogs from "./AI Components/UserActivityLogs";
import Manageprompt from "./AI Components/ManagePrompt";

import { useState, useEffect } from "react";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";

import axios from "axios";
import { Login } from "./Login-Component/Login";
import UserContext from "./Auth/useContext";
import { Header } from "./Other-Components/header";
import {
  getSessionItem,
  setSessionItem,
} from "./util-services/encrypt-storage";

import { AddFileOrFolderSample } from "./AI Components/AddFileStuctureSample";
import AuthCallback from "./Other-Components/auth-callback";
import ManagePromptCheck from "./AI Components/ManagePromptCheck";
import Dashboard from "./AI Components/Hub-Comp/HubComponent";
import AsamiUsageTable from "./AI Components/Hub-Comp/AsamiUsageTable";
import { fetchActiveUsers } from "./AI Service/Api";
import CostAnalysisTable from "./AI Components/Hub-Comp/CostAnalysisTable";

// Build consolidation imports

import ManageProject from "./AI Components/managetaskgrid/ManageProject";
import ManageProjectForm from "./AI Components/managetaskgrid/TfsForm";
import ManageTask from "./AI Components/ManageIssues/ManageTask";
import ManageIssues from "./AI Components/ManageIssues/ManageIssues";
import ManageStory from "./AI Components/ManageIssues/ManageStory";
import { ConfigPopup } from "./AI Components/ManageIssues/ConfigPopUp";
import FeedBackCard from "./AI Components/FeedBackCards";
import PlantUMLPreview from "./AI Components/PlantUmlPreview";
import FeedBackForm from "./AI Components/FeedBackForm";
import { ConfigProvider } from "./AI Components/ConfigContext";
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { Colors } from "chart.js";
import { right } from "@popperjs/core";
import MermaidPreview from "./AI Components/mermaidComp";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// const useTabCloseEffect = () => {
// };


function App() {





  // return <>
  //   {/* <AsamiUsageTable/> */}

  // </>

  const [userDetails, setUserDetails] = useState(null);
  const { instance, accounts } = useMsal();
  const token = getSessionItem("access-token");

  const [errorPage, setErrorPage] = useState(false);

  const [navOpen, setNavOpen] = useState("");

  const account = useAccount(accounts[0] || null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = window.location;



  const [activeUserCount, setActiveUserCount] = useState(1)


  // localStorage.setItem("activeUser", activeUserCount)

  // if (localStorage.getItem("activeUsers")) {
  //   let temp = localStorage.getItem("activeUsers")+1 ;
  //   setActiveUserCount(temp)
  // }


  // const handleTabClose = async () => {
  //   const postData = async (url = 'http://51.8.201.226/activeuser/activeuser', data = {
  //     "onlineStatus": 0,
  //     "userEmailId": "Pradeepkumar.Kumaravel@avasoft.com",
  //     "timePeriod": "month"
  //   }) => {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(data)
  //     });
  //     return response.json();
  //   };
  //   postData()
  // }


  // window.addEventListener('onunload', function () {
  //   if (activeUserCount == 1) {
  //     handleTabClose()
  //   }
  //   setActiveUserCount(previous => previous-1)
  // });
  const previousLocation = sessionStorage.getItem('previousLocation');
useEffect(()=>{
  // if(previousLocation=="/"&& location?.pathname === "/chat"){
  //   window.location.reload()
  // }
})
  // Store the current location as the previous location for the next render
  React.useEffect(() => {
      sessionStorage.setItem('previousLocation', location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Logic to get the token
      if (account && location?.pathname !== "/") {
        (async () => {
          try {
            const accountResponse = await instance.acquireTokenSilent({
              account,
              scopes: ["User.Read"],
            });
      
            const apiToken = await instance.acquireTokenSilent({
              account,
              scopes: [devConfig?.roleConfig?.ScopeID],
            });
      
            // const aiToken = await instance.acquireTokenSilent({
            //   account,
            //   scopes: ["https://cognitiveservices.azure.com"],
            // });
            // console.log("hi).log(aiToken);
            setSessionItem("api-token", apiToken.accessToken);
            // console.log("api-token", apiToken.accessToken);
      
            setSessionItem("access-token", accountResponse.accessToken);
          } catch (error) {
            console.error("Error acquiring tokens:", error);
          }
        })();
      }
    }, 300000);
  
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [account, location?.pathname, instance, devConfig?.roleConfig?.ScopeID]);

  useEffect(()=>{
    const heartBeatInterval = 10*60*1000
    const updateActiveUsers =  async () => {
      // console.log("Activer users call")
      try{
        let reqBody = {
          "onlineStatus":1,
          "userEmailId": account.username,
          "timerPeriod":"month",
          "VisitorsData": false
        }
        let respone = await fetchActiveUsers(reqBody)
      }
      catch(error){
        // console.log("Active users error")
      }
    }
    updateActiveUsers();
    const intervalId = setInterval(updateActiveUsers, heartBeatInterval)

    return () => clearInterval(intervalId)
  },[])





  useEffect(() => {
    const fetchData = async () => {
      if (account && location?.pathname !== "/" && localStorage.getItem("login")=="true") {
        // console.log(localStorage.getItem("login"),"Mervin is here")
        const reqbody = {
          onlineStatus: 0,
          userEmailId: "",
          timePeriod: "",
          VisitorsData: ""  
        };
        try {
          const resbody = await fetchActiveUsers(reqbody);
          // console.log(resbody,"response body")
          let setrun=""
          for(let i=0;i<resbody.data.allUserNames.length;i++){
            if(account.username==resbody.data.allUserNames[i]){
              setrun="setRunFalse"
              console.log("mervin",setrun)
              break
            }
          }
          if (false && setrun!=="setRunFalse" && await func(account.username)) {
            // console.log("mervin",account)
            setpop(true);
          }
          // console.log("Response Body", resbody.data.allUserNames, account.username, account.username in resbody.data.allUserNames);
        } catch (error) {
          console.error("Error fetching active users:", error);
        }
        localStorage.setItem("login",false)
      }
 
    };
 
    fetchData();
  }, [account, location?.pathname]);



  useEffect(() => {
    setErrorPage(false);
    if (account && location?.pathname != "/") {
      fetchUserData();
    }
  }, [account, token]);

  const updateUserDetails = (newUserDetails) => {
    setUserDetails(newUserDetails);
  };

  const fetchUserData = async () => {
    try {
      setIsLoading(false);
      let userDetails = {
        userName: account.name,
        email: account.username,
        role: "User",
        jobTitle: "",
        profileImage: "images/Default/default-profile-image.png",
      };
      setSessionItem("user-email", userDetails.email);
      const accountResponse = await instance.acquireTokenSilent({
        account,
        scopes: ["User.Read"],
      });

      const apiToken = await instance.acquireTokenSilent({
        account,
        scopes: [devConfig?.roleConfig?.ScopeID],
      });

      // const aiToken = await instance.acquireTokenSilent({
      //   account,
      //   scopes: ["https://cognitiveservices.azure.com"],
      // });
      //(aiToken);
      setSessionItem("api-token", apiToken.accessToken);

      setSessionItem("access-token", accountResponse.accessToken);

      const profileData = await axios.get(
        `https://graph.microsoft.com/v1.0/me`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );
      userDetails.jobTitle = profileData.data.jobTitle;
      try {
        const profileImage = await axios.get(
          `https://graph.microsoft.com/v1.0/me/photo/$value`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
            responseType: "blob",
          }
        );

        const reader = new FileReader();
        reader.onloadend = () => {
          const dataUrl = reader.result;
          userDetails.profileImage = dataUrl;
        };
        reader.readAsDataURL(profileImage.data);
      } catch (error) {
        //(error);
      }
      try {
        const departmentResponse = await axios.get(
          `https://graph.microsoft.com/beta/users/${account.localAccountId}/department`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );
        userDetails.department = departmentResponse.data.value || "Not specified";
        if ((userDetails.department).includes("Team")) {
          userDetails.department = userDetails.department.replace(/\bTeam\b/g, "").trim();
        }
        // console.log(userDetails,"User Details")
      } catch (error) {
        console.error("Error fetching department:", error);
        userDetails.department = "Not available";
      }

      try {
        const departmentResponse = await axios.get(
          `https://graph.microsoft.com/beta/users/${account.localAccountId}/department`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );
        userDetails.department = departmentResponse.data.value || "Not specified";
        if ((userDetails.department).includes("Team")) {
          userDetails.department = userDetails.department.replace(/\bTeam\b/g, "").trim();
        }
        // console.log(userDetails,"User Details")
      } catch (error) {
        console.error("Error fetching department:", error);
        userDetails.department = "Not available";
      }

      try {
        const departmentResponse = await axios.get(
          `https://graph.microsoft.com/beta/users/${account.localAccountId}/department`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );
        userDetails.department = departmentResponse.data.value || "Not specified";
        if ((userDetails.department).includes("Team")) {
          userDetails.department = userDetails.department.replace(/\bTeam\b/g, "").trim();
        }
        // console.log(userDetails,"User Details")
      } catch (error) {
        console.error("Error fetching department:", error);
        userDetails.department = "Not available";
      }

      try {
        //Check User is in leadership DL or not
        const isInLeadership = await axios.get(
          `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipDL}/transitiveMembers/${account.localAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );

        userDetails.role = "Leadership";
      } catch (err) {
        try {
          const isInLeadership = await axios.get(
            `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipGroup}/members/${account.localAccountId}`,
            {
              headers: {
                Authorization: `Bearer ${accountResponse.accessToken}`,
              },
            }
          );
          userDetails.role = "Leadership";
        } catch (err) {
          try {
            //Check User is in leadership DL or not
            const isInLeads = await axios.get(
              `https://graph.microsoft.com/v1.0/users/${account.localAccountId}/directReports`,
              {
                headers: {
                  Authorization: `Bearer ${accountResponse.accessToken}`,
                },
              }
            );
            if (isInLeads?.data?.value?.length > 0) {
              userDetails.role = "Lead";
            } else {
              try {
                const isInLeads = await axios.get(
                  `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${account.localAccountId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${accountResponse.accessToken}`,
                    },
                  }
                );
                userDetails.role = "Lead";
              } catch (error) {
                userDetails.role = "User";
              }
            }
            // userDetails.role =
            //   isInLeads?.data?.value?.length > 0 ? "Lead" :
          } catch (err) {
            try {
              const isInLeads = await axios.get(
                `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${account.localAccountId}`,
                {
                  headers: {
                    Authorization: `Bearer ${accountResponse.accessToken}`,
                  },
                }
              );
              userDetails.role = "Lead";
            } catch (error) {
              userDetails.role = "User";
            }
          }
        }
      }

      updateUserDetails(userDetails);
      setIsLoading(true);
    } catch (error) {
      //(error);
    }
  };


  useEffect(() => {
    //(navOpen, "FFFF");
  }, [navOpen])

  const func=async(email)=>{
    const accountResponse = await instance.acquireTokenSilent({
      account,
      scopes: ["User.Read"],
    });
    try {
      //Check User is in leadership DL or not
      const isInLeadership = await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipDL}/transitiveMembers/${account.localAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );
   
      return false
    } catch (err) {
      try {
        const isInLeadership = await axios.get(
          `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipGroup}/members/${account.localAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );
        return false
      } catch (err) {
        try {
          //Check User is in leadership DL or not
          const isInLeads = await axios.get(
            `https://graph.microsoft.com/v1.0/users/${account.localAccountId}/directReports`,
            {
              headers: {
                Authorization: `Bearer ${accountResponse.accessToken}`,
              },
            }
          );
          if (isInLeads?.data?.value?.length > 0) {
            return false
          } else {
            try {
              const isInLeads = await axios.get(
                `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${account.localAccountId}`,
                {
                  headers: {
                    Authorization: `Bearer ${accountResponse.accessToken}`,
                  },
                }
              );
              return false
            } catch (error) {
              return true
            }
          }
          // userDetails.role =
          //   isInLeads?.data?.value?.length > 0 ? "Lead" :
        } catch (err) {
          try {
            const isInLeads = await axios.get(
              `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${account.localAccountId}`,
              {
                headers: {
                  Authorization: `Bearer ${accountResponse.accessToken}`,
                },
              }
            );
            return false
          } catch (error) {
            return true
          }
        }
      }
    }
   
  }
  //mervin changes
  const [run, setRun] = useState(false);
  const [pop,setpop]=useState(false)
  useEffect(()=>{ const myModalElement = document.getElementById('intro-popup');
    if (myModalElement) {
      const myModal = new window.bootstrap.Modal(myModalElement);
      myModal.show();
    }},[pop])  
      const steps = [
      {
        target: '.NewChat',
        content: "Starting a new chat displays a selection of pre-made prompts. Choosing an appropriate prompt helps in crafting more effective queries and obtaining more relevant AI responses.",
        disableBeacon: true,
        title:"New Chat",
        stepval:"1 / 8"
 
      },
      {
        target: '.AskAsami',
        content: 'Step into the future of human-AI collaboration and unlock a world of endless possibilities. Whether you seek knowledge, inspiration, or simply an intellectually stimulating dialogue, the Chat Page and Asami await, ready to elevate your journey to new heights of understanding and enlightenment. ',
        disableBeacon: true,
        title:"Ask Asami",
        stepval:"2 / 8"
 
      },
      {
        target: '.hub',
        content: 'The project allows users to work directly on assigned Issues, Stories, and Tasks eliminating the need for manual data entry and improving overall efficiency.',
        disableBeacon: true,
        title:"Projects", // Disable beacon for this step
        stepval:"3 / 8",
      },
      {
        target: '.pastconversation',
        content: 'Asami’s “Past Conversations” feature lets users revisit and review their previous chat interactions.',
        disableBeacon: true,
        title:"Past Conversation",
        stepval:"4 / 8"
 
      },
      {
        target: '.AsamiSupport',
        content: 'Need support? Please feel free to reach us.',
        disableBeacon: true,
        title:"Asami Support",
        stepval:"5 / 8"
 
      },
     
      {
        target:".configurations",
        content:"Choose the AI model and configure it as you want.",
        disableBeacon: true,
        title:"Configuration",
        stepval:"6 / 8"
      },
      {
        target:".knowledgedropdown",
        content:"Upload files to search based on the files.",
        disableBeacon: true,
        title:"Upload Files",
        stepval:"7 / 8"
      },
      {
        target:".CustomKnowledegeBase",
        content:"Upload files to search based on the files.",
        disableBeacon: true,
        title:"Knowledge Base",
        stepval:"8 / 8"
      }
    ];

  const[ChatHistory001,setChatHistory]=useState(false)
  const [stepcount,setstepcount]=useState(0)
  const [settrue,setSetTrue]=useState(false)
  const [dropdown,setdropdown]=useState(false)
  useEffect(() => {
   default00()
  },[stepcount,run])
  function default00(){
    // // 
    if(localStorage.getItem("step")==5){
      // // 
      // console.log("mervin is here")
      setstepcount(Number(localStorage.getItem("step")))
      setSetTrue(true)
    }
    if(localStorage.getItem("step")==4){
      // 
      // console.log("mervin is here")
      setstepcount(Number(localStorage.getItem("step")))
      setSetTrue(true)
    }
  }
  const onNewChatToggleStepComplete = () => {
    // Your custom function logic goes here
    // console.log('NewChat step completed, calling custom function...');
    localStorage.setItem("step",4)
    setmovechat(true);
  };
  const onNewChatStepComplete = () => {
    // Your custom function logic goes here
    // console.log('NewChat step completed, calling custom function...');
    setChatHistory(true)
    
    
  };
  const onconfigurationStepComplete = () => {
    // Your custom function logic goes here
    // console.log('NewChat step completed, calling custom function...');
    setdropdown(true)
  };
  const onconfigurationtoggleStepComplete = () => {
    // Your custom function logic goes here
    // console.log('NewChat step completed, calling custom function...');
    setdropdown(false)
  };
  const [movechat,setmovechat]=useState(false)

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
 
    // console.log()
    if (type === 'step:after') {
      if (action === 'next' || action === 'prev') {
        // Use this to call a function when a specific step is completed
        if (index === steps.findIndex(step => step.target === '.AsamiSupport')) {
          if(action ==='next'){onNewChatStepComplete();}
          // onNewChatStepComplete();
        }
 
        if (index === steps.findIndex(step => step.target === '.configurations')) {
          // onconfigurationStepComplete();
          
          if(action ==='next'){onconfigurationStepComplete()}
          else if(action==='prev'){
            onNewChatToggleStepComplete();
   
          }
          }
         
         
          if (index === steps.findIndex(step => step.target === '.knowledgedropdown')) {
            if( action === 'prev'){onconfigurationtoggleStepComplete()}
   
            }
       
        const newStepCount = action === 'next' ? index + 1 : index - 1;
        // console.log("newStepCount============================newStepCount",newStepCount)
        setstepcount(newStepCount);
        localStorage.setItem("step", newStepCount);
      }
 
    } else if (type === 'tour:end' || (type === 'step:after' && action === 'skip')) {
      // Handle the end of the tour or skipping the tour
      setmovechat(true);
      setstepcount(0); // Reset to the first step
      setRun(false);
      localStorage.setItem("step", 0);
      setSetTrue(false); // Stop the tour
    }
  };

  const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    skipProps
  }) => (
    <div className={step.pos}>
  <div className="popover-container position-relative">
    <div className="p-4">
      <div className="d-flex justify-content-between mb-3">
        <p className="m-0 color-white font-14 font-bold">{step.title}</p>
        <p className="m-0 color-white font-14 font-bold">{step.stepval}</p>
      </div>
      <p className="font-14 font-medium color-white m-0">
      {step.content}
      </p>
    </div>
    <div className="nav-container w-100 px-4 py-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-4">
        {index > 0 && (
              <button {...backProps} className="btn font-14 font-bold color-white p-0 direct-btn shadow-none">
                Previous
              </button>
            )}
        <button {...skipProps} className="btn font-14 font-bold color-white p-0 direct-btn shadow-none">Skip tour</button>
          {!isLastStep && <button {...primaryProps} className="btn font-14 font-bold color-white p-0 direct-btn shadow-none next" style={{float:right}}>Next</button>}
          {isLastStep && <button {...primaryProps}  className="btn font-14 font-bold color-white p-0 direct-btn shadow-none" >End tour</button>}
        </div>
      </div>
    </div>
  </div>
</div>
  );
  // mervin changes end
 

  return (
    <>
    {/* <div className="message-received"> */}
      <Joyride run={run}
  steps={steps}
  stepIndex={stepcount}
  continuous={true}
  showSkipButton={true}
  scrollToFirstStep={false}
  callback={handleJoyrideCallback}
  tooltipComponent={CustomTooltip}
  locale={{ last: 'End tour', skip: 'Skip tour', next: 'Next', back: 'Previous' }}
  styles={{
    options: {
      arrowColor: '#7176EF',
      backgroundColor: '#865BE7',
      overlayColor: 'rgba(79, 26, 0, 0.4)',
      primaryColor: '#000',
      textColor: 'white',
      width: 900,
      zIndex: 1000,
    },
    buttonBack: {
      textColor: 'violet',
      backgroundColor: 'black',
    },
    buttonNext: {
      textColor: 'violet',
      backgroundColor: 'black',
    },
    overlay: {
      inset: 'unset',
      overflow: 'hidden',
    }
  }}
      
/>

{pop==true?<div
  className="modal fade"
  id="intro-popup"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabIndex={-1}
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered custom-box border-1">
    <div className="modal-content p-3 border-0 popup-bg bg-color-4">
      <div className="modal-header border-0 p-0 d-flex justify-content-end">
        <button
          type="button"
          className="btn-close close-icon-filter me-0"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {/* <img src="images/close-popup.svg" alt="close-icon" /> */}
        </button>
      </div>
      <div className="modal-body popup-scrl pt-0 pb-2">
        {/* content */}
        <div className="text-center">
          <a href="#">
            <img
              src="images/asami-logo.svg"
              alt="asami-icon"
              className="mb-4"
            />
          </a>
          <h4 className="font-20 font-bold text-color-1 my-4">
            When AI Meets Code, Limitless Possibilities emerges
          </h4>
          <p className="font-16 font-semibold mb-4 pt-3 text-color-9">
            Provide informative and well-researched responses, and assist users
            with a wide range of tasks.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn gradient-btn font-14 font-semibold w-50 border-radius-5 color-white py-2 px-5  mt-4 w-60"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=>{setRun(true)}}
            >
              <span className="color-gradient">Start a Quick Tour</span>
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn custom-outline-btn font-14 font-semibold border-radius-5  py-2 px-4 mt-2 w-50"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Skip the Tour
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
:<></>}

      {!isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Router>
          <UserContext.Provider value={userDetails}>
            {/* <Header></Header> */}
            {location?.pathname != "/" && !errorPage && userDetails != null ? (
              <HeaderComponent setNavOpen={setNavOpen} />
            ) : (
              <></>
            )}
            <ConfigProvider>
              <Switch>

                {/* Service Module - CSS work done */}
                {/* <Route exact path="/Invalidxml" component={Invalidxml}></Route> */}
                {/* <Route
                  exact
                  path="/servicebuilder"
                  component={XMLFileUpload}
                ></Route> */}
                {/* <Route
                  exact
                  path="/tabledata"
                  component={ERTableJoinData}
                ></Route> */}
                {/* <Route exact path="/entity" component={EntityCreation}></Route> */}
                {/* <Route
                  exact
                  path="/methodMappingComponent"
                  component={MethodMappingComponent}
                ></Route> */}

                {/* Grid Module - CSS work done */}

                {/* <Route exact path="/Gridbuilder" component={GridBuilder}></Route> */}
                <Route
                  exact
                  path="/FilterModule"
                  component={FilterModule}
                ></Route>

                {/* Form Module - CSS work done */}
                {/* <Route exact path="/Formbuilder" component={FormBuilder}></Route> */}

                {/* Main Page -CSS work done */}
                {/* <Route exact path="/buildgrid" component={BuilderGrid}></Route>
                <Route exact path="/buildform" component={BuilderForm}></Route> */}
                {/* AI Module */}
                <Route exact path="/chat" render={() => <ChatComponent navOpen={navOpen} setRun={setRun} ChatHistory={ChatHistory001} settrue001={settrue} dropdown={dropdown} movechat={movechat}  />}></Route>
                {/* <Route path="/chat" component={AuthCallback}></Route> */}

                <Route exact path="/chat?chat" render={() => <ChatComponent navOpen={navOpen} />}></Route>
                {/* //-- /chat */}
                <Route exact path="/no" component={AddKnowledgeBase}></Route>
                <Route exact path="/Check" component={EditorComponent}></Route>
                <Route exact path="/d2" render={() => <D2Preview navOpen={navOpen} />}></Route>
                <Route exact path="/mermaid" render={() => <MermaidPreview navOpen={navOpen} />}></Route>
                {/* <Route exact path="/manage" component={ManagePromptCheck(navOpen)}></Route> */}
                <Route
                  exact
                  path="/add"
                  component={AddFileOrFolderSample}
                ></Route>
                {/* {ManagePrompt} */}
                <Route exact path="/chat-history" render={() => <ChatHistory navOpen={navOpen} />}></Route>
                {userDetails?.role != "User" && (
                  <Route //L1, L2
                    exact
                    path="/asami-manage-request?asami-manage-request"
                    render={() => <ManageRequest navOpen={navOpen} />}
                  ></Route>

                )}
                {userDetails?.role != "User" && (
                  <Route //L1, L2
                    exact
                    path="/asami-manage-request"
                    render={() => <ManageRequest navOpen={navOpen} />}
                  ></Route>

                )}

                {userDetails?.role != "User" && (
                  <Route //L1, L2
                    exact
                    path="/asami-user-activity"
                    render={() => <UserActivityLogs navOpen={navOpen} />}
                  ></Route>
                )}
                {/* /Asami/user-activity */}
                {userDetails?.role === "Leadership" && (
                  <Route // L1
                    exact
                    path="/asami-manage-prompt"
                    render={() => <Manageprompt navOpen={navOpen} />}
                  ></Route>
                )}
                {userDetails?.role === "Leadership" && (
                  <Route // L1
                    exact
                    path="/asami-create-prompt"
                    render={() => <Manageprompt navOpen={navOpen} />}
                  ></Route>
                )}
                {userDetails?.role === "Leadership" && (
                  <Route // L1
                    exact
                    path="/asami-edit-prompt"
                    render={() => <Manageprompt navOpen={navOpen} />}
                  ></Route>
                )}
                {userDetails?.role === "Leadership" && (
                  // <Route // L1
                  //   exact
                  //   path="/Asami-llm-config"
                  //   component={LlmConfigForm}
                  // ></Route>
                  <Route exact path="/asami-llm-config" render={() => <LlmConfigForm navOpen={navOpen} />}></Route>
                )}
                {userDetails?.role === "Leadership" && (
                  <Route // L1
                    exact
                    path="/llm-config-grid"
                    render={() => <LlmConfigGrid navOpen={navOpen} />}
                  ></Route>
                )}
                {/* /Asami/manage-prompt */}
                {/* <Route exact path="/Q" component={QuestionComponent}></Route> */}
                {/* <Route exact path="/manage" component={ManageTask}></Route> */}
                {/* <Route exact path="/choices" component={Choices}></Route> */}
                {/* <Route exact path="/phase1form" component={CodeGenForm}></Route> */}
                {/* <Route exact path="/phase1grid" component={GridCodeGenHooks}></Route> */}
                {/* <Route exact path="/auth/cb" component={GridCodeGenHooks}></Route> */}

                {/* Build Consolidation Changes */}
                <Redirect from="/asami-edit-prompt" to="/asami-manage-prompt" />
  
                <Redirect from="/asami-create-prompt" to="/asami-manage-prompt" />
                <Route exact path="/" render={(props) => <Login myProp={setpop} {...props} />} />
                
                <Route // L1
                    exact
                    path="/dashboard"
                    render={() => <Dashboard navOpen={navOpen} />}
                  ></Route>
              <Route exact path="/costanalysistable" render={() => <CostAnalysisTable navOpen={navOpen}/>}></Route>
              <Route exact path="/asamiusagetable" render={() => <AsamiUsageTable navOpen={navOpen} />}></Route>

                <Route exact path="/manageproject" render={() => <ManageProject navOpen={navOpen} />}></Route>
              <Route exact path="/manageprojectform" component={ManageProjectForm}></Route>
                <Route exact path="/managetasks" render={() => <ManageTask navOpen={navOpen} />}></Route>
              <Route exact path="/manageissue" render={() => <ManageIssues navOpen={navOpen} />}></Route>
              <Route exact path="/managestory" render={() => <ManageStory navOpen={navOpen} />}></Route>
                <Route exact path="/configpopup" component={ConfigPopup}></Route>
                <Route exact path="/asamiusagetable" component={AsamiUsageTable}></Route>
              <Route exact path="/feedbackcards"  render={() => <FeedBackCard navOpen={navOpen} />}></Route>
              <Route exact path="/plantuml" render={() => <PlantUMLPreview navOpen={navOpen} />}></Route>
              <Route exact path="/feedbackform" component={FeedBackForm}></Route>
              <Route exact path="/feedbackform" component={FeedBackForm}></Route>



                <Route path="help"></Route>
                {token ? (
                  <Route
                    component={(props) => (
                      <Error {...props} setErrorPage={setErrorPage} />
                    )}
                  ></Route>
                ) : null}
              </Switch>
            </ConfigProvider>
          </UserContext.Provider>
        </Router>
      )}

      {/* <div className="cust-overlay"></div> */}
    {/* </div> */}
    
    </>
  );
}

export default App;