import React, { useEffect, useState, useContext } from "react";
import toggleContext from "./Context";
import { getBlobURL } from "../AI Service/Api";
import styled from 'styled-components';
import { useFileUpload } from "./ContextFiles/FileUploadContext";
export function MediaUpload({ record }) {
  const [hideError, setHideError] = useState(true);

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const toggle = useContext(toggleContext);

  const {
    isUploading,
    alertMessage,
    alertType,
    imgDocFile,
    uploadedFileUrl,
    showSuccessEffect,
    uploadMedia,
    setUploadedFileUrl,
    ImageDocLinks,
    setImageDocLinks,
    recordData,
    setRecordData,
    updateRecord  // Add this function to your context
  } = useFileUpload();


  useEffect(() => {
    if (record?.record?.inputValue) {
      setRecordData(record?.record);
      setUploadedFileUrl(record?.record?.inputValue);
      updateRecord(record);  // Update the record in the context
      setImageDocLinks(ImageDocLinks)
    }
  }, [record, setRecordData, setUploadedFileUrl, updateRecord]);

  console.log(ImageDocLinks,imgDocFile,"imagedoclinks, files")
  console.log(record,"IIIII");
  
  const SuccessHighlight = styled.div`
  padding: 10px;
  border-radius: 5px;
  animation: highlightSuccess 2s ease-in-out;

  @keyframes highlightSuccess {
    0% {
      background-color: rgba(76, 175, 80, 0.1);
      box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
    }
    50% {
      background-color: rgba(76, 175, 80, 0.2);
      box-shadow: 0 0 0 6px rgba(76, 175, 80, 0.2);
    }
    100% {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;
  
  console.log(recordData,"recorddata")
  

  const renderError = () => {
    if (recordData && record.validationErrors) {
      const err = recordData.inputValueOrder;
      return hideError ? (
        <span className="mandatory-star font-14 font-medium mb-0" style={{ color: "red" }}>
          {record.validationErrors[err]}
        </span>
      ) : null;
    }
    return null;
  };

  return (
    <>
      {record?.record?.labelName && (
        <label className="text-color-14 font-15 font-semibold label-color" htmlFor="Code-similar">
          {record?.record?.labelName}
          {recordData?.required === "true" && <span className="color-red">*</span>}
        </label>
      )}

      <div className="col-md-12">
        <div className="upload-btn-wrapper">
          <button
            type="button"
            className="upload-btn font-13 font-medium px-4 py-2 bg-color text-color-5"
            disabled={toggle || isUploading}
          >
            <img src="images/ai-images/upload-icon.svg" alt="upload icon" className="me-2" />
            {isUploading ? "Uploading..." : (recordData?.placeHolder || "Upload Media")}
          </button>

          <input
            disabled={toggle || isUploading}
            className="custom-cursor"
            type="file"
            name="myfile"
            id={recordData?.labelName}
            onChange={(e)=>uploadMedia(e,record, recordData)}
          />

          {alertMessage && (
            <p className={`alert-message ${alertType}`}>
              {alertMessage}
            </p>
          )}
        </div>

        {uploadedFileUrl && (
         <SuccessHighlight className={showSuccessEffect ? 'active' : ''}>
         <div className="uploaded-file-container">
           <label className="uploaded-file-label font-15 font-semibold label-color">
             Uploaded File: {imgDocFile}
           </label>
         </div>
       </SuccessHighlight>
        )}
      </div>

      {renderError()}
    </>
  );
}