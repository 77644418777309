//PS_CGAI_2.1 import the necessary imports
import { useState, useEffect } from "react";
import NotificationComponent from "./NotificationComponent";
import UserProfileComponent from "./UserProfileComponent";
import { useContext } from "react";
import UserContext from "../Auth/useContext";
import * as SignalR from "@microsoft/signalr";
import { deleteConnection, setConnectionID } from "../AI Service/Api";
import { useHistory, useLocation } from "react-router-dom";
import toggleContext from "./Context";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import PopUpComponent from "./Popup";
import { _config } from "../api/Api-Constants";
import { getSessionItem } from "../util-services/encrypt-storage";
import { getChatHistory, fetchChat } from "../AI Service/Api";
import ChatComponent from "./ChatComponent";
import { getBindParams, insertModelConfig } from "../AI Service/Api";
/**
 * PS_CGAI_2.2 to PS_CGAI_2.4 Declar the global variable and call signalRNotification() in useEffect
 * PS_CGAI_2.18 to PS_CGAI_2.23 if else condition to check the type of user and show the nav links
 * PS_CGAI_2.25 to PS_CGAI_2.28 to render UserProfileComponent
 * PS_CGAI_2.29 to PS_CGAI_2.32 show the requet and logs
 * PS_CGAI_2.35 to PS_CGAI_2.38 shows the other nav links
 * PS_CGAI_2.38 to PS_CGAI_2.41 to show the chat history
 * @returns the header nav bar for the apllication
 */
export function HeaderComponent({ setNavOpen }) {
  const { instance } = useMsal();
  const location = useLocation();
  let activeTab = new URLSearchParams(location.search).get("tab");
  const history = useHistory();
  const userData = useContext(UserContext);
  const [userObj, setuserObj] = useState();
  const [selectedTheme,setSelectedTheme]=useState("")
 
  const [showRequestAndLogs, setshowRequestAndLogs] = useState(true);
  const [isCopied, setIsCopied] = useState(false)
  const [showManagePrompt, setshowManagePrompt] = useState(true);
  const [showLlmConfig, setShowLlmConfig] = useState(true);
  const [sort, setSort] = useState("createdDateTime Desc");
  const [showNotification, setshowNotification] = useState(false);
  const [show, setshow] = useState(false);
  const [notifyDot, setnotifyDot] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [bindChatHistory, setBindChatHistory] = useState(false);
  const userDetails = useContext(UserContext);
  const [tab, setTab] = useState("Asami");
  const [leftNav, setLeftNav] = useState("")
  const [count, setCount] = useState(10);
  const [notifiaction, setNotification] = useState("")
  const [chatContent, setChatContent] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const share = window.location.href;
  const [sharePromptName, setSharePromptName] = useState("")
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(true)
  const [shareDate, setShareDate] = useState("")
  const [manageRequest, setManageRequest] = useState("");

  const params = new URLSearchParams(window.location.search);
  const chatIDFromUrl = params.get('chatID');
  const [chatHistoryHover, setChatHistoryHover] = useState(null)
  const [chatHistoryHoverClicked, setChatHistoryHoverClicked] = useState(null)
  const [chatIdHoverPurpose, setChatIdHoverPurpose] = useState(chatIDFromUrl)
  const [shareChatID, setShareChatID] = useState("")
  const [showProjects, setShowProjects] = useState(true);
  const [showHub, setShowHub] = useState(true);
  const [showAsamiUsage, setShowAsamiUsage] = useState(true);
  const [showFeedback, setShowFeedback] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  

  const url = new URL(share)
  const baseUrl = url.origin;
  useEffect(() => {
    //("hello !!")
    fetchBindParams();
  }, []);
  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      setManageRequest(response.data.manageRequest[0]["isActive"])

    } catch (error) {
      //(error);
    }
  };
  let connection;
  let connection_id;
  const handleBeforeUnload = async (event) => {
    //("The page is about to be unloaded!");
    connection.stop();
    await deleteConnection({
      userEmailID: userData?.email,
      connectionID: connection_id,
    });
  };
  const handleCopyClick = () => {
    const chatUrl = `${baseUrl}/chat?chatID=${shareChatID}&share=true`;
 
    navigator.clipboard.writeText(chatUrl).then(() => {
      // history.push('/chat-history');
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  useEffect(() => {
    //(new URLSearchParams(location.search).get("tab"));
    //(activeTab);
    signalRNotification();
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  useEffect(() => {

    let path = window.location.pathname;
    //(path, "path...........................................");
    if (path == "/chat") {
      localStorage.setItem("ViewChat","False")
      localStorage.setItem("PastConversation","True") 
      setTab("Asami");
    } 
    else if (path == "/dashboard") {
      setTab("hub");
    }
    else if (path == "/manageproject") {
      setTab("manage-project");
    }
    else if (path == "/asamiusagetable") {
      setTab("asami-usage");
    }
    else if (path == "/asami-manage-prompt") {
      setTab("manage-prompt");
    }
    else if (path == "/llm-config-grid") {
      setTab("llm-grid");
    }
    else if (path == "/asami-user-activity") {

      setTab("user-activity");
    }
    else if (path == "/feedbackcards") {
      setTab("feedback");
    }
    else {
      setTab("Asami");
    }
  }, [location]);
  useEffect(() => {
    setuserObj(userData);
    setProfileImage(userObj?.profileImage)
    //(userData, "6789");
    if (userData) {
      if (userData?.role == "Lead") {
        setshowRequestAndLogs(false);
        setShowProjects(false);
        setShowFeedback(false);
      } else if (userObj?.role == "Leadership") {
        setShowHub(false);
        setShowAsamiUsage(false);
        setshowManagePrompt(false);
        setShowLlmConfig(false);
        setShowProjects(false);
        setShowFeedback(false);
        // setshowRequestAndLogs(false);
      } else if (userData?.role == "User") {
        setshowManagePrompt(true);
        setshowRequestAndLogs(true);
        setShowHub(true);
        setShowProjects(false);
        setShowAsamiUsage(true);
        setShowLlmConfig(true);
      }
    }
  }, [userObj, userData]);

  useEffect(() => {
    getChatMessages()
    fetchChatHistory();
  }, [sort, count]);

  const getChatMessages = async (chatID) => {
    //(chatID,"OOOOOOOOO");
    // sethiddenObjValues({
    //   ...hiddenObjValues,
    //   binding: false,
    // });

    try {
      let result = await fetchChat({ chatID: chatID });
      //(result.ResponseData.currentChat,"LLLLLLLLLLLLLL");
      setChatContent(result.ResponseData.currentChat)
    }
    catch (error) {
      //(error)

    }
  }


  const applyTheme = (theme) => {
    document.documentElement.setAttribute("data-theme", `${theme}-theme`);
    const bodyClasses = document.body.classList;
    bodyClasses.remove('light-theme', 'dark-theme');
    bodyClasses.add(`${theme}-theme`);
  };

  useEffect(() => {
    // Load the saved theme from localStorage on component mount
    const savedTheme = localStorage.getItem("selectedTheme");
    if (savedTheme) {
      setIsChecked(savedTheme === "dark");
      applyTheme(savedTheme);
    }
  }, []);



  const toggleTheme = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    const newTheme = newChecked ? "dark" : "light";
    applyTheme(newTheme);
    localStorage.setItem("selectedTheme", newTheme);
    setSelectedTheme(newTheme)
 
  };


  const fetchChatHistory = async () => {
    try {

      // setIsLoading(false)
      let chatHistoryObj = {
        userEmailID: userDetails?.email, //userData?.email,
        search: "",
        sort: sort,
        count: count,
        fromDate: "",
        toDate: "",
        approvalStatusName: "",
        codegenStatusName: ""
      };
      let response = await getChatHistory(chatHistoryObj);
      //(response, "OOOOOOOOOOOOOOOOOOO")

      if (response.status === 200) {
        // setIsLoading(true)
        setBindChatHistory(response.data.ResponseData[0]);

        // setRecords(response.data.ResponseData[0].length)
      }
    } catch (error) {
      //(error);
    }
  };
  function bindChatContent(chatContent) {
    if (chatContent && Array.isArray(chatContent)) {
      return chatContent.map((value, index) => (

        <div className="col-md-12 py-3 px-3">
          <span className="d-flex">
            <span className="d-block">
              <img className style={{ height: "44px", width: "44px", borderRadius: "50%" }} src={value.role === "user" ? profileImage : "images/Default/asami-bot.svg"} alt="profile-pic" />
            </span>
            <span className="d-block ms-3 mt-2">
              <h5 className="font-18 font-bold text-color-1">{value.role === "user" ? "You" : "Asami"}</h5>
              <p className="scroll-content font-medium font-16 text-color-10">{value.content}</p>
            </span>
          </span>
        </div>
      ));
    }
  }
  //(bindChatHistory,"HHHH");

  // const handleChatClick = (chatID , chatType) => {
  //   history.push(`/chat?chatID=${chatID}`);
  //   window.location.reload()
  // };


  // Kathir Changes
const handleChatClick = (chatID, chatType) => {
    const pathname = `/chat`;
    const search = `?chatID=${chatID}`;
    
    const state = chatType === 'General' 
      ? {
          yesFixButton: true,
          getAskAsamiChat: true,
          hidePrompts: true,
          sendMessage: false,
        }
      : {
          getAskAsamiChat: true,
          yesFixButton: false
        };
  
    // Store the state in sessionStorage
    sessionStorage.setItem('chatState', JSON.stringify(state));
  
    // Construct the full URL
    const fullURL = `${pathname}${search}`;
  
    // Use replaceState to update the URL without navigation
    // history.push(fullURL)
  
    // Force a reload
    window.location.assign(fullURL);
  }


  function bindMobileChat(bindChatHistory) {
    if (bindChatHistory) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const groupedChats = bindChatHistory.reduce((groups, value) => {
        const chatDate = new Date(value.createdDateTime);
        const isToday = chatDate.toDateString() === today.toDateString();
        const isYesterday = chatDate.toDateString() === yesterday.toDateString();

        let groupKey = 'other'; // Default group key

        if (isToday) {
          groupKey = 'today';
        } else if (isYesterday) {
          groupKey = 'yesterday';
        }

        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }

        groups[groupKey].push(value);

        return groups;
      }, {});

      return (
        <>
          {groupedChats.today && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">Today</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none">
                {groupedChats.today.map((chat, index) => (
                  <li key={index} className=" position-relative nav-link d-flex justify-content-between cust-past-convo">
                    {/* <img src="images/Asami-logo-sm"/> */}
                    <a
                      className={` link-body-emphasis text-color-4 font-14 font-medium gap-2 d-flex ${chatHistoryHover == index + "t" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage") ? `active` : ''}`} onMouseEnter={() => { setChatHistoryHover(index + "t"); }} onMouseLeave={() => setChatHistoryHover(null)} onClick={() => { handleChatClick(chat.chatID , chat.chatType); setChatIdHoverPurpose(chat?.chatID) }}>{chat.chatName}{" "}
                    </a>
                    {/* //("showing chat-id", chat?.chatID) */}
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "t" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                      }} onMouseEnter={() => setChatHistoryHover(index + "t")} onMouseLeave={() => setChatHistoryHover(null)} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {groupedChats.yesterday && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">Yesterday</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none " >
                {groupedChats.yesterday.map((chat, index) => (
                  <li key={index} className="position-relative nav-link d-flex justify-content-between cust-past-convo">
                    {/* //("showing chat-id", chat?.chatID) */}
                    <a className={` link-body-emphasis font-14  font-medium gap-2 d-flex ${chatHistoryHover == index + "y" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage")? `active` : ''}`} onMouseEnter={() => { setChatHistoryHover(index + "y"); }} onMouseLeave={() => setChatHistoryHover(null)} onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID) }}><span style={{color:'white'}}>{chat.chatName}</span>{" "}
                      {/* <button data-bs-toggle="modal"
                data-bs-target="#share-convo-popup"
                onClick={() => {
                  setSharePromptName(chat.promptName);
                  // setShareChatID(value.chatID)
                  
                  history.push(`/chat?chatID=${chat.chatID}&share=true`);
                  getChatMessages(chat.chatID)
                  setIsSharePopupOpen(true); // Assuming you want to open the popup here
                }}></button> */}
                    </a>
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "y" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                      }} onMouseEnter={() => setChatHistoryHover(index + "y")} onMouseLeave={() => setChatHistoryHover(null)} />

                  </li>
                ))}
              </ul>
            </>
          )}

          {groupedChats.other && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">This Month</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none">
                {groupedChats.other.map((chat, index) => (
                  <li key={index} className="position-relative nav-link d-flex justify-content-between cust-past-convo">
                    {/* //("showing chat-id", chat?.chatID) */}
                    <a className={` link-body-emphasis text-color-4 d-flex gap-2 font-14 font-medium  ${chatHistoryHover == index + "o" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage")? `active ` : ''}`} onMouseEnter={() => { setChatHistoryHover(index + "o"); }} onMouseLeave={() => setChatHistoryHover(null)} onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID) }}>{chat.chatName}{" "}
                    </a>
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "o" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                      }} onMouseEnter={() => setChatHistoryHover(index + "o")} onMouseLeave={() => setChatHistoryHover(null)} />
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      );
    }
  }


  // hamburger - past conversation
  function bindChat(bindChatHistory) {
    if (bindChatHistory) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
 
      const groupedChats = bindChatHistory.reduce((groups, value) => {
        const chatDate = new Date(value.createdDateTime);
        const isToday = chatDate.toDateString() === today.toDateString();
        const isYesterday = chatDate.toDateString() === yesterday.toDateString();
 
        let groupKey = 'other'; // Default group key
 
        if (isToday) {
          groupKey = 'today';
        } else if (isYesterday) {
          groupKey = 'yesterday';
        }
 
        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }
 
        groups[groupKey].push(value);
 
        return groups;
      }, {});
 
      return (
        <>
          {groupedChats.today && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">Today</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none">
                {groupedChats.today.map((chat, index) => (
                  <a className="nav-link chat-history-link" onMouseEnter={() => { setChatHistoryHover(index + "t"); }} onMouseLeave={() => setChatHistoryHover(null)}>
                  <li 
                  onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID);localStorage.setItem("PastConversation","True") }}
                  key={index} className=" position-relative nav-link d-flex justify-content-between cust-past-convo chat-history-link" style={{cursor:"pointer"}}>
                    <span
                      //("showing chat-id", chat?.chatID)
                      className={`  d-flex gap-2 font-14 text-color-2 font-medium ${chatHistoryHover == index + "t" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage") ? `active` : ''}`}
                      onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID);localStorage.setItem("PastConversation","True") }}> <img src={ chat.chatType == 'General' ?  `images/Asami-logo-sm.svg` : `images-new/transparent-img-sm.svg`} width={"15px"} /> <span className="chat-history-link text-color-9"> {chat.chatName.length > 10 ? `${chat.chatName.substring(0, 10)}...` : chat.chatName}</span>{" "}
                    </span>
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "t" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        // debugger;
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                      }} onMouseEnter={() => setChatHistoryHover(index + "t")} onMouseLeave={() => setChatHistoryHover(null)} />
                  </li>
                  </a>
                ))}
              </ul>
            </>
          )}
 
          {groupedChats.yesterday && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">Yesterday</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none " >
                {groupedChats.yesterday.map((chat, index) => (
                      <a  className='nav-link chat-history-link'onMouseEnter={() => { setChatHistoryHover(index + "y"); }} onMouseLeave={() => setChatHistoryHover(null)}>
                     <li 
                     onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID);localStorage.setItem("PastConversation","True") }}
                     key={index} className="position-relative nav-link d-flex justify-content-between cust-past-convo chat-history-link" style={{cursor:"pointer"}}>
                       {/* //("showing chat-id", chat?.chatID) */}
                       <span className={` d-flex gap-2 font-14 text-color-2 font-medium ${chatHistoryHover == index + "y" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage")? `active` : ''}`}
                     onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID);localStorage.setItem("PastConversation","True") }}><img src={ chat.chatType == 'General' ?  `images/Asami-logo-sm.svg` : `images-new/transparent-img-sm.svg`} width={"15px"} /> {chat.chatName.length > 10 ? `${chat.chatName.substring(0, 10)}...` : chat.chatName}{" "}</span>
                      {/* <button data-bs-toggle="modal"
                data-bs-target="#share-convo-popup"
                onClick={() => {
                  setSharePromptName(chat.promptName);
                  // setShareChatID(value.chatID)
                 
                  history.push(`/chat?chatID=${chat.chatID}&share=true`);
                  getChatMessages(chat.chatID)
                  setIsSharePopupOpen(true); // Assuming you want to open the popup here
                }}></button> */}
                   
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "y" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                      }} onMouseEnter={() => setChatHistoryHover(index + "y")} onMouseLeave={() => setChatHistoryHover(null)} />
 
                  </li>
                  </a>
                ))}
              </ul>
            </>
          )}
 
          {groupedChats.other && (
            <>
              <label className="font-14 font-medium text-color-9 mb-2 custom-none">This Month</label>
              <ul className="nav nav-pills flex-column mb-2 custom-none ">
                {groupedChats.other.map((chat, index) => (
                  <a  className='past-convo-a chat-history-link' onMouseEnter={() => { setChatHistoryHover(index + "y"); }} onMouseLeave={() => setChatHistoryHover(null)}>
                  <li key={index} className="position-relative  nav-link d-flex justify-content-between cust-past-convo chat-history-link" style={{cursor:"pointer"}}>
                    {/* //("showing chat-id", chat?.chatID) */}
                    {/* <a className={`link-body-emphasis d-flex gap-2 font-14 font-medium ${chatHistoryHover == index + "o" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage") ? `active` : ''}`} onMouseEnter={() => { setChatHistoryHover(index + "o"); }} onMouseLeave={() => setChatHistoryHover(null)} onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID) ;localStorage.setItem("PastConversation","True")}}> <img src={ chat.chatType == 'General' ?  `images/Asami-logo-sm.svg` : `images-new/transparent-img-sm.svg`} width={"15px"} /> {chat.chatName}{" "}
                    </a> */}
                    <span className={` d-flex gap-2 font-14 text-color-2 font-medium ${chatHistoryHover == index + "y" || chatIdHoverPurpose == chat?.chatID && (tab!="llm-grid" && tab!="manage-prompt" && tab!="request-logs" && tab!="hub" && tab!="manage-project" && tab!="asami-usage")? `active` : ''}`}
                     onClick={() => { handleChatClick(chat.chatID, chat.chatType); setChatIdHoverPurpose(chat?.chatID);localStorage.setItem("PastConversation","True") }}><img src={ chat.chatType == 'General' ?  `images/Asami-logo-sm.svg` : `images-new/transparent-img-sm.svg`} width={"15px"} />{chat.chatName.length > 10 ? `${chat.chatName.substring(0, 10)}...` : chat.chatName}{" "}</span>
                    <img src="images/Default/share-icon.svg" className={`share-button-chat-history ${chatHistoryHover == index + "o" || chatIdHoverPurpose == chat?.chatID ? `d-block` : 'd-none'}`} data-bs-toggle="modal"
                      data-bs-target="#share-convo-popup"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSharePromptName(chat.promptName);
                        setShareChatID(chat.chatID)
                        getChatMessages(chat.chatID)
                        setIsSharePopupOpen(true); // Assuming you want to open the popup here
                        localStorage.setItem("PastConversation","True")
                      }} onMouseEnter={() => setChatHistoryHover(index + "o")} onMouseLeave={() => setChatHistoryHover(null)} />
                  </li>
                  </a>
                ))}
              </ul>
            </>
          )}
        </>
      );
    }
  }
  //(bindChatHistory.chatID,"LLL");

  const handleToggle = () => {
    //("Before toggle: ", leftNav,leftNav === "Close");
    setLeftNav(leftNav === "Close" ? "Open" : "Close");
    setNavOpen(leftNav === "Close" ? "Open" : "Close")
    //("After toggle: ", leftNav);
  }
  /**
   * PS_CGAI_2.4 to PS_CGAI_2.17 configur and get the ID insert into table fpr sendind notification
   */
  const signalRNotification = async () => {
    try {
      connection = new SignalR.HubConnectionBuilder()
        .withUrl(_config.nodeBaseURL, {
          headers: {
            Authorization: `Bearer ${await getSessionItem("api-token")}`,
          },
        })

        .withAutomaticReconnect()
        .build();
      const notificationSound = new Audio(
        "https://avaeuscdegennpstgacc.blob.core.windows.net/ai-assests/Notification Audio/mixkit-dry-pop-up-notification-alert-2356.wav"
      );

      connection.on("newMessage", (message) => {
        setshowNotification(!showNotification);

        const notificationSound = new Audio(
          "https://avaeuscdegennpstgacc.blob.core.windows.net/ai-assests/Notification Audio/mixkit-dry-pop-up-notification-alert-2356.wav"
        );

        notificationSound.play();
      });
      connection.onclose(async () => {
        await deleteConnection({
          userEmailID: userData?.email,
          connectionID: id,
        });

        //  fetch("http://localhost:8080/api/embeddedToken");
      });
      let result = await connection
        .start()
        .catch((err) => console.error(err, "connection error"));

      const id = connection.connectionId;
      connection_id = id;
      if (id) {
        await setConnectionID({
          userEmailID: userData?.email,
          connectionID: id,
        });
      }
    } catch (error) {
      //(error.message);
    }
  };

  //(leftNav, "ghjkl");

  const handleLogout = async () => {
    try {
      let result = await deleteConnection({ userEmailID: userData.email });
      if ((result.Success = true)) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
        setShowPopup(false);

      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleNewChat = () => {

  //    

    // Navigate to "/chat" if not already there
    localStorage.setItem("PastConversation","False") 
    history.push("/chat");
    window.location.reload();

  };
  
  const askAsami = () => {
    //

    localStorage.setItem('workItemFromMI', "")
    localStorage.setItem('collectionNameFromMI', "")
    localStorage.setItem('issueIDFromMI', "")
    localStorage.setItem('projectNameFromLocal', "")
    // Navigate to "/chat"
    history.push({
      pathname: '/chat',
      state: {
        startConversation: true,
        hidePrompts: true,
        sendMessage: false,
        fixButton: true,
        yesFixButton:true
      }
    });
    window.location.reload();
  };
  const navigateToChat = () => {
    history.push({
      pathname: '/chat',
      state: { startConversation: false }
    });
    window.location.reload();
  };


  return (
    <>
      <div className="width-full">
        {/*left nav responsive start*/}
        <nav className="navbar navbar-light bg-color-2 d-block d-lg-none overlay-style sticky-top">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div style={{ display: "flex" }}>
                <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <span className="navbar-toggler-icon icon-filter" />
                 
                  {/* <img src="images/hamburger-menu.svg" alt="hamburger-menu"/> */}
                </button>
                <a className="navbar-brand" onClick={() => {
                  window.location.href = "/chat";
                }}>
                  <img src="images/Default/asami-logo.svg" alt="logo" className="d-inline-block align-text-top w-100" onClick={() => {
                    window.location.href = "/chat";
                  }} />
                </a>
              </div>
              {/* <div className="d-lg-none d-block">
                <a href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}"><img className="me-3" src="images/Default/help-icon.svg" alt="helpicon" title="Contact Support: AsamiSupportCrew@avasoft.com" /></a>
                <div className="btn-group me-2">
                  <img data-bs-toggle="dropdown" style={{ height: 30, width: 25 }} aria-expanded={`${notifiaction === "show" ? "true" : "fasle"}`} src="images/Default/notification-icon.svg" alt="notification-icon" onClick={() => { setNotification("show") }} className={` ${notifiaction === "show" ? "show" : ""} mobile-header-profile position-relative`} />
                  {notifyDot ? (<span className="position-absolute p-1 bg-danger rounded-circle  dot-styling" />) : null}
                  <span className={`dropdown-menu dropdown-menu-end mobile-notification-container theme-dropdown border-0 ${notifiaction === "show" ? "show" : ""}`} data-bs-popper="none">
                    <span className="width d-flex justify-content-between align-items-center px-3 py-2 custom-border-bottom color-light-grey font-semibold font-12">Notifications
                      <span className="cursor-pointer"><img src="images/Default/close-icon.svg" alt="close-icon" onClick={() => { setNotification("") }} /></span>
                    </span>
                    <NotificationComponent
                      props={setnotifyDot}
                      notificationTrigger={showNotification}
                      value={notifyDot}
                      setTab={setTab}
                    />
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
        {/*left nav responsive ends*/}
        {/*responsive leftnav body start here*/}
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" style={{ zIndex: 1052 }}>
          <div className="offcanvas-body nav-bg">

            <div className="py-2 px-2 nav-bg border-radius-12 nav-custom-style">
              <div className="col-md-12 custom-border-bottom px-3 d-flex justify-content-between mb-3">
                {/* <a ><img className="mb-3" src="images/Default/asami-logo.svg" alt="logo" /></a> */}
                <button type="button" className="btn-close text-reset icon-filter" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>
              <div className="col-md-12 my-2">
              <button className="btn primary-gradiant-btn border-0 border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { handleNewChat() }} type="button"><img  src="images/Default/plus-icon-white.svg" onClick={() => { handleNewChat(); }} alt="add-icon" /> <span className="text-white custom-none ms-1" onClick={() => { handleNewChat(); localStorage.setItem("ViewChat","False");localStorage.setItem("PastConversation","False")}}>New Chat</span></button>
              {/* <button className="btn nav-gradient-btn cust-nav-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { askAsami() }}type="button"><img src="images/Default/plus-icon.svg" onClick={() => { askAsami() }} alt="add-icon" /> <span className=" custom-none ms-1" onClick={() => { askAsami() }}>Ask Asami</span></button> */}
              <button className="btn btn-gradient-outline cust-nav-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { askAsami() }}type="button"><img src="images\Vector.svg" onClick={() => { askAsami() }} alt="add-icon" /> <span className=" custom-none primary-color ms-1" onClick={() => { askAsami() }}>Ask Asami</span></button>
                {/* <button className="btn btn-gradient-outline border-radius-4 font-14 font-medium w-100 py-2 mb-2" type="button"><img className="me-1" src="images/Default/plus-icon.svg" alt="add-icon" /> <span className="color-gradient">New Chat</span></button> */}
              </div>
              <ul className="nav nav-pills">

            {!showHub &&
              <li className=" mb-2 w-100 cursor-pointer" onClick={() => {
                  setTab("hub");

                  activeTab = "";
                }}>
                  <a className={`nav-link link-nav-color text-color-9  primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "hub" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {
                      // setTab("request-logs");

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/dashboard`); }
                    }}>
                    <img src="images-new/hub-icon.svg" alt="request-icon" className={tab === "hub" ? "active-icon" : ""}/>
                    {/* <svg 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill={tab === "hub" ? "white" : "grey"} 
                      xmlns="http://www.w3.org/2000/svg"
                    >
    {/* Your SVG path goes here */}
                  {/* <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                </svg> */} 
                    <span className="custom-none ms-2">Hub</span>
                  </a>
                </li>
}
                <li className=" mb-2 w-100 cursor-pointer" hidden={showProjects} onClick={() => {
                  setTab("manage-project");

                  activeTab = "";
                }}>
                  <a className={`nav-link link-nav-color text-color-9  primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "manage-project" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {
                      // setTab("request-logs");

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/manageproject`); }
                    }}>
                    <img className="left-nav-icons" left-nav-icons src="images-new/projects-icon.svg" alt="request-icon" />
                    <span className="custom-none ms-2">Projects</span>
                  </a> 
                </li>

              {!showAsamiUsage &&
                <li className=" mb-2 w-100 cursor-pointer" onClick={() => {
                  setTab("asami-usage");

                  activeTab = "";
                }}>
                  <a className={`nav-link link-nav-color text-color-9 primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "asami-usage" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/asamiusagetable`); }
                    }}>
                    <img className="left-nav-icons" src="images-new/asami-usage-icon.svg" alt="asami-usage" />
                    <span className="custom-none ms-2">Asami Usage</span>
                  </a> 
                </li>
              }
                {!showManagePrompt &&
                <li className="mb-2 w-100 cursor-pointer" 
                  onClick={() => {
                    setTab("manage-prompt");
                    activeTab = "";
                  }}>
                  <a className={`nav-link link-nav-color text-color-9 primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "manage-prompt" ? "active " : ""
                    }`} onClick={() => {
                      history.push(`/asami-manage-prompt`);
                      window.location.reload()
                    }}>
                    <img className="left-nav-icons" src="images-new/manage-prompts.svg" alt="prompt-icon" onClick={() => {
                      history.push(`/asami-manage-prompt`);
                      window.location.reload()
                    }} />
                    <span className="custom-none ms-2">Manage Prompts</span>
                  </a>
                </li>
                } 
              {!showLlmConfig &&
                <li className="w-100 cursor-pointer"  onClick={() => {
                  setTab("llm-grid")
                  activeTab = "";
                }}>
                  <a className={`nav-link link-nav-color text-color-9 font-14 primary-gradiant-btn font-medium custom-left-padding ${tab === "llm-grid" ? "active " : ""
                    }`} onClick={() => {
                      history.push(`/llm-config-grid`);
                    }}>
                    <img className="left-nav-icons" src="images-new/llm-config.svg" alt="config-icon" onClick={() => {
                      history.push(`/llm-config-grid`);
                    }} />
                    <span className="custom-none ms-2">LLM Configuration</span>
                  </a>
                </li>
                }
              </ul>
              <div className="under-line-nav"></div>

              <div className="d-flex align-items-center justify-content-between  mt-4 mb-1">
                <h5 className="font-16 font-semibold mb-0 align-items-center text-color-1"><span className="custom-none">Past Conversations</span></h5>
                <a href="" class="custom-none"><img className="left-nav-icons" src="images-new/next-black-arrow.svg" alt="Next" onClick={() => { history.push("/chat-history") }}/></a>
              </div>

              <div className= {userData?.role == "User"? "admin-custom-convo-scroll-style cust-hamburger header-view-all user-height-past-conv" : userData?.role == "Lead" ? "admin-custom-convo-scroll-style cust-hamburger header-view-all lead-height-past-conv" : "admin-custom-convo-scroll-style cust-hamburger header-view-all"} >
            <div>
              
            
              <div className="col-md-12 px-3">
 


              <div className="mb-3 ">
                {bindChat(bindChatHistory)}
              </div>
  
              <div>
            </div>
            </div>
            </div>
                         
          </div>
          
              
              <div className="col-md-12 mt-4 mb-2 px-3">
              <div className="py-2">
            <ul class="nav nav-pills">

            {/* <li class="mb-2 w-100 cursor-pointer " >
                 <a  class= "nav-link link-dark primary-gradiant-btn font-14 font-medium custom-left-padding "
                    aria-current="page" >
                    <img src="images-new/feedback-icon.svg" alt="request-icon" />
                    <span class="custom-none ms-2">Dark Mode</span>
                  </a> 
                </li> */}

      <li className="mb-1 w-100">
      <div className="form-check form-switch d-flex justify-content-between align-items-center px-3 py-2 nav-link link-dark text-color-9 font-14 font-medium custom-left-padding position-relative ">
        <div>
          <img src="images/darkTheme/dark-mode-icon.svg" alt="dark-mode-icon" className="icon-filter" />
          <label className="form-check-label ms-2 custom-none" htmlFor="check-5">Dark Mode</label>
        </div>
        <div className="checkbox-wrapper-5 d-flex align-items-center custom-none">
          <div className="check">
            {/* <input id="check-5" type="checkbox" className="form-check-input shadow-none" defaultChecked /> */}
            <input id="check-5"
                    className="form-check-input shadow-none"
                    type="checkbox"
                    checked={isChecked}
                    onChange={toggleTheme} defaultChecked>
            </input>
            <label htmlFor="check-5"></label>
          </div>
        </div>
      </div>
    </li>

          {  !showFeedback&&    <li class="mb-1 w-100 cursor-pointer" onClick={() => {
                    setTab("feedback")
                    activeTab = "";
                  }}>
                 <a  class={`nav-link link-dark text-color-9 font-14 primary-gradiant-btn font-medium custom-left-padding ${tab === "feedback" ? "active " : ""
                      }`}
                    aria-current="page" onClick={()=>{
                      history.push("/feedbackcards")
                    }}>
                    <img className="left-nav-icons" src="images-new/feedback-icon.svg" alt="request-icon" />
                    <span class="custom-none ms-2">Feedback</span>
                  </a> 
                </li>}

                <li className="mb-1 w-100 ">
      <a href="#" className="nav-link text-color-9 link-dark primary-gradiant-btn font-14 font-medium custom-left-padding">
        <img className="left-nav-icons" src="images-new/asami-support-icon.svg" alt="prompt-icon" />
        <a
          href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}"

        >
          <span className="custom-none ms-2">Asami Support</span>
        </a>
      </a>
    </li>
                {/* <li class="mb-2 w-100">
                  <a href="#" class="nav-link link-dark font-14 font-medium custom-left-padding position-relative">
                    <img src="images-new/notification-icons.svg" alt="config-icon" />
                    <span class="position-absolute p-1 bg-danger notification-circle dot-styling"></span>
                    <span class="custom-none ms-2">Notifications</span>
                  </a>
                </li> */}

              </ul>
            </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <img className="profile-img-style" src={userObj?.profileImage} alt="profile-img" />
                    <span className="d-block ms-3">
                      <span className="font-16 font-semibold d-block text-color-4">{userData.userName}</span>
                      <span className="font-12 font-14 text-color-10 d-block">{userData.jobTitle}</span>
                    </span>
                  </div>
                  <div>
                    <a onClick={() => { handleLogout() }}><img className="left-nav-icons" src="images/Default/logout-icon.svg" alt="logout-icon" onClick={() => { handleLogout() }} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*responsive leftnav body ends here*/}
      </div>
      
      <div id="sidebar" className={`custom-left-section-width profile-margin-padding d-none d-lg-block nav-bg nav-custom-style border-radius-12 ${leftNav === "Close" ? "active" : ""} left-nav-fix`}>
         {/* <div className="py-3 px-2 nav-custom-style custom-sicky-left-menu" >FIX */}
        <div>
          <div className={` ${leftNav === "Open" ? "w-100" : "col-md-12"} custom-border-bottom px-3 position-relative`}>
          { localStorage.getItem('selectedTheme') !== "dark"  ?
           ( <a className="nav-light-logo" style={{ cursor: "pointer" }} ><img className="mb-2 asami-logo" style={{ cursor: "pointer", height: "60px", width: "150px" }} src="videos\Logo yy.gif" alt="logo"
            // onClick={() => {
            //   window.location.href = "/chat";
 
            // }}
            onClick={navigateToChat}
             /></a>):(
 
            <a className="nav-dark-logo" style={{ cursor: "pointer" }} ><img className="mb-2 asami-logo" style={{ cursor: "pointer", height: "60px", width: "150px" }} src="videos\Logo white.gif" alt="logo"
            // onClick={() => {
            //   window.location.href = "/chat";
 
            // }}
            onClick={navigateToChat}
             /></a>)}

            <a style={{ cursor: "pointer" }}><img className="mb-2 asami-logo-navclose" style={{ cursor: "pointer", height: "60px", width: "60px" }} src="videos\Logo.gif" alt="logo" 
            // onClick={() => {
            //   window.location.href = "/chat";
            // }}
            onClick={navigateToChat}
             /></a>
            <a className="position-absolute open-collapse-position handle-toggle-fix" style={{ cursor: "pointer" }} onClick={() => { handleToggle() }}><img className="hamburger-open sidebarCollapse" src="images/Default/leftnav-open.svg" alt="leftnav-icon" onClick={() => { handleToggle() }} /></a>
            <a className="position-absolute close-collapse-position handle-toggle-fix" style={{ cursor: "pointer" }} onClick={() => { handleToggle() }}><img className="hamburger sidebarCollapse " src="images/Default/leftnav-close.svg" alt="leftnav-icon" onClick={() => { handleToggle() }} /></a>
            {/* <ChatComponent></ChatComponent> */}
          </div>
          <div className="col-md-12 my-4 mb-2 px-3">
            <button className="btn primary-gradiant-btn border-0 border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { handleNewChat() }} type="button"><img src="images/Default/plus-icon-white.svg" onClick={() => { handleNewChat() }} alt="add-icon" /> <span className="text-white custom-none NewChat ms-1" onClick={() => { handleNewChat();localStorage.setItem("ViewChat","False");localStorage.setItem("PastConversation","False")}}>New Chat</span></button>
            <button className="btn btn-gradient-outline cust-nav-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { askAsami() }}type="button"><img src="images\Vector.svg" onClick={() => { askAsami() }} alt="add-icon" /> <span className=" custom-none AskAsami primary-color ms-1" onClick={() => { askAsami() }}>Ask Asami</span></button>
          </div>
          <div className="px-3">
                <ul className="nav nav-pills">

              { !showHub&&
                <li className=" mb-1 w-100 cursor-pointer" onClick={() => {
                  setTab("hub");

                  activeTab = "";
                }}>
                  {/* desktop leftnav */}
                  <a className={`nav-link link-dark  primary-gradiant-btn font-14 font-medium text-color-9 custom-left-padding ${tab === "hub" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/dashboard`); }
                    }}>
                    <img className="left-nav-icons" src="images-new/hub-icon.svg" alt="request-icon" />
                    <span className="custom-none ms-2 hub ">Hub</span>
                  </a> 
                </li>
              }
                <li className=" mb-1 w-100 cursor-pointer" hidden={showProjects} onClick={() => {
                  setTab("manage-project");

                  activeTab = "";
                }}>
                 <a className={`nav-link link-dark  primary-gradiant-btn font-14 font-medium text-color-9 custom-left-padding ${tab === "manage-project" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {
                      // setTab("request-logs");

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/manageproject`); }
                    }}>
                    <img className="left-nav-icons" src="images-new/projects-icon.svg" alt="request-icon" />
                    <span className="custom-none ms-2">Projects</span>
                  </a>
                </li>

              { !showAsamiUsage &&
                <li className=" mb-1 w-100 cursor-pointer" onClick={() => {
                  setTab("asami-usage");

                  activeTab = "";
                }}>
                  <a className={`nav-link link-dark  primary-gradiant-btn font-14 font-medium text-color-9 custom-left-padding ${tab === "asami-usage" ? "active  " : ""
                    }`} aria-current="page" onClick={() => {
                      // setTab("request-logs");

                      { manageRequest ? history.push(`/asami-manage-request`) : history.push(`/asamiusagetable`); }
                    }}>
                    <img className="left-nav-icons" src="images-new/asami-usage-icon.svg" alt="request-icon" />
                    <span className="custom-none ms-2 ">Asami Usage</span>
                  </a> 
                </li>
              } 
               {!showRequestAndLogs &&   <li className="mb-1 w-100 cursor-pointer" 
                    onClick={() => {
                      setTab("user-activity");
                      activeTab = "";
                    }}>
                    <a className={`nav-link link-dark text-color-9 primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "user-activity" ? "active " : ""
                      }`} onClick={() => {
                        history.push(`/asami-user-activity`);
                      }}>
                      <img className="left-nav-icons" src="images-new/activity-log-icon.svg" alt="prompt-icon" onClick={() => {
                        history.push(`/asami-user-activity`);
                      }} />
                      <span className="custom-none ms-2">User Activity Logs</span>
                    </a>
                  </li>}

                      { !showManagePrompt &&
                  <li className="mb-1 w-100 cursor-pointer" 
                    onClick={() => {
                      setTab("manage-prompt");
                      activeTab = "";
                    }}>
                    <a className={`nav-link text-color-9 link-dark primary-gradiant-btn font-14 font-medium custom-left-padding ${tab === "manage-prompt" ? "active " : ""
                      }`} onClick={() => {
                        history.push(`/asami-manage-prompt`);
                        window.location.reload()
                      }}>
                      <img className="left-nav-icons" src="images-new/manage-prompt-icon.svg" alt="prompt-icon" onClick={() => {
                        history.push(`/asami-manage-prompt`);
                        window.location.reload()
                      }} />
                      <span className="custom-none ms-2 ">Manage Prompts</span>
                    </a>
                  </li>
}

                  {!showLlmConfig&&<li className="w-100 cursor-pointer"  onClick={() => {
                    setTab("llm-grid")
                    activeTab = "";
                  }}>
                    <a className={`nav-link link-dark text-color-9 font-14 primary-gradiant-btn font-medium custom-left-padding ${tab === "llm-grid" ? "active " : ""
                      }`} onClick={() => {
                        history.push(`/llm-config-grid`);
                      }}>
                      <img className="left-nav-icons" src="images-new/llm-config.svg" alt="config-icon" onClick={() => {
                        history.push(`/llm-config-grid`);
                      }} />
                      <span className="custom-none ms-2">LLM Configuration</span>
                    </a>
                  </li>}
                  
                </ul>


                {/* <div>
                <span>dark theme?</span>
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={toggleTheme}
                  />
                </label>
                </div> */}


                <div className="d-flex align-items-center justify-content-between  mt-4 mb-1">
                <h5 className="font-16 font-semibold mb-0 align-items-center text-color-1 pastconversation"><span className="custom-none">Past Conversations</span></h5>
                <a href="" class="custom-none"><img className="left-nav-icons" src="images-new/next-black-arrow.svg" alt="Next" onClick={() => { history.push("/chat-history") }}/></a>
              </div>
          </div>
          
          <div className= {userData?.role == "User"? "admin-custom-convo-scroll-style header-view-all user-height-past-conv" : userData?.role == "Lead" ? "admin-custom-convo-scroll-style header-view-all lead-height-past-conv" : "admin-custom-convo-scroll-style header-view-all"} >
            <div>
            
            <div className="col-md-12 px-3">
 


   <div className="mb-3 ">
     {bindChat(bindChatHistory)}
   </div>
  
   <div>
   </div>
 </div>
            </div>
                         
          </div>
          {/* <div>
              <div className="custom-height40">
                <button className="btn btn-blue-outline border-radius-4 font-14 font-medium w-100 py-2 mb-2 custom-none" type="button" onClick={() => { history.push("/chat-history") }}>View All<img className="ms-2" src="images/Default/right-arrow.svg" alt="rightarrow-icon" /></button>
              </div>
            </div> */}
            
            </div>
          <div className="col-md-12 mt-1 mb-2 px-3 ">
          <div className="py-2">
            <ul class="nav nav-pills">

            {/* <li class="mb-2 w-100 cursor-pointer " >
                 <a  class= "nav-link link-dark primary-gradiant-btn font-14 font-medium custom-left-padding "
                    aria-current="page" >
                    <img src="images-new/feedback-icon.svg" alt="request-icon" />
                    <span class="custom-none ms-2">Dark Mode</span>
                  </a> 
                </li> */}

      <li className="mb-1 w-100">
      <div className="form-check form-switch d-flex justify-content-between align-items-center px-3 py-2 nav-link link-dark text-color-9 font-14 font-medium custom-left-padding position-relative pdgiving5">
        <div>
          <img src="images/darkTheme/dark-mode-icon.svg" alt="dark-mode-icon" className="icon-filter" />
          <label className="form-check-label ms-2 custom-none" htmlFor="check-5">Dark Mode</label>
        </div>
        <div className="checkbox-wrapper-5 d-flex align-items-center custom-none">
          <div className="check">
            {/* <input id="check-5" type="checkbox" className="form-check-input shadow-none" defaultChecked /> */}
            <input id="check-5"
                    className="form-check-input shadow-none"
                    type="checkbox"
                    checked={isChecked}
                    onChange={toggleTheme} defaultChecked>
            </input>
            <label htmlFor="check-5"></label>
          </div>
        </div>
      </div>
    </li>

           { !showFeedback &&    <li class="mb-1 w-100 cursor-pointer" onClick={() => {
                    setTab("feedback")
                    activeTab = "";
                  }}>
                 <a  class={`nav-link link-dark text-color-9 font-14 primary-gradiant-btn font-medium custom-left-padding ${tab === "feedback" ? "active " : ""
                      }`}
                    aria-current="page" onClick={()=>{
                      history.push("/feedbackcards")
                    }}>
                    <img className="left-nav-icons" src="images-new/feedback-icon.svg" alt="request-icon" />
                    <span class="custom-none ms-2">Feedback</span>
                  </a> 
                </li>
}
                <li className="mb-1 w-100 AsamiSupport">
      <a href="#" className="nav-link text-color-9 link-dark primary-gradiant-btn font-14 font-medium custom-left-padding">
        <img className="left-nav-icons" src="images-new/asami-support-icon.svg" alt="prompt-icon" />
        <a
          href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}"

        >
          <span className="custom-none ms-2">Asami Support</span>
        </a>
      </a>
    </li>
                {/* <li class="mb-2 w-100">
                  <a href="#" class="nav-link link-dark font-14 font-medium custom-left-padding position-relative">
                    <img src="images-new/notification-icons.svg" alt="config-icon" />
                    <span class="position-absolute p-1 bg-danger notification-circle dot-styling"></span>
                    <span class="custom-none ms-2">Notifications</span>
                  </a>
                </li> */}

              </ul>
            </div>
            <div className=" pb-2b d-flex align-items-center justify-content-between ">
              <div className="d-flex">
                <div className="dropdown dropup">
                  <img className="profile-img-style" src={userObj?.profileImage} alt="profile-img" />

                  {/* <ul className="dropdown-menu logout-button" style={{"min-width": "10rem"}}>
                    <li>
                      <a className="dropdown-item font-14 font-medium" style={{"cursor":"pointer"}}onClick={() => { handleLogout() }}>Logoute</a>
                    </li>
                  </ul> */}
                </div>
                <span className="d-block ms-3 custom-none">
                  <span className="font-14 font-semibold d-block text-color-4">{userData.userName}</span>
                  <span className="font-12 font-14 text-color-10 d-block">{userData.jobTitle}</span>
                </span>
              </div>
              <div className="dropdown dropup">
                <div className="custom-none">
                  <a style={{ cursor: "pointer" }} onClick={() => { handleLogout() }}><img className="icon-filter" style={{ cursor: "pointer" }} src="images/Default/logout-icon.svg" alt="logout-icon" onClick={() => { handleLogout() }} /></a>

                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>


      <div className="position-absolute top-0 end-0 margin-top">
        <div className="d-flex justify-content-end">
          {/* <div className="d-lg-block d-none">
            <a href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}"><img className="me-3" src="images/Default/help-icon.svg" alt="helpicon"
              title="Contact Support: AsamiSupportCrew@avasoft.com" /></a>
            <div className="btn-group me-3 cursor-pointer position-relative">
              <img data-bs-toggle="dropdown" style={{ height: 30, width: 25 }} aria-expanded={`${notifiaction === "show" ? "true" : "fasle"}`} src="images/Default/notification-icon.svg" alt="notification-icon" className={`header-profile position-relative  ${notifiaction === "show" ? "show" : ""}`} onClick={() => {
                if (notifiaction == "show") {
                  setNotification("")
                } else {
                  setNotification("show")
                }
              }} />
              {notifyDot ? (<span className="position-absolute p-1 bg-danger rounded-circle dot-styling" />) : null}
              <span className={`dropdown-menu notification-container border-0 ${notifiaction === "show" ? "show" : ""}`} data-bs-popper="none">
                <span className=" width d-flex justify-content-between p-3 custom-border-bottom color-light-grey font-semibold font-16">Notifications
                  <span className="cursor-pointer"><img src="images/Default/close-icon.svg" alt="close-icon" onClick={() => { setNotification("") }} /></span>
                </span>



                <NotificationComponent
                  props={setnotifyDot}
                  notificationTrigger={showNotification}
                  value={notifyDot}
                  setTab={setTab}
                />
              </span>
            </div>
          </div> */}
        </div>


      </div>


      {showPopup ? (
        <PopUpComponent
          text={"logout"}
          yesFunction={handleLogout}
          noFunction={setShowPopup}
        />
      ) : (
        <></>
      )}
      {/* <div hidden={isSharePopupOpen}> */}
      <div class="row justify-content-center">
        <div className="modal fade" id="share-convo-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered custom-popup-width border-0">
            <div className="modal-content p-3 border-0 bg-color-4">
              <div className="modal-header border-0 pt-2 px-1 pb-0">
                <h5 className="modal-title text-dark font-20 font-bold text-color-1">Share Conversation Link</h5>
                <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body pt-2">
                <div className="row border border-radius-2 custom-border-5">
                  <div className="custom-convo-scroll">
                    {/*conversation*/}
                    {bindChatContent(chatContent)}
                    {/*conversation*/}
                    {/*buttons*/}
                  </div>
                  <div className="d-flex justify-content-between align-items-center custom-border-top py-2">
                    <div>
                      <h5 className="font-16 font-medium text-color-1 mb-0" id="promptNameDisplay">{sharePromptName}</h5>
                      <span className="font-14 font-medium text-color-10">{shareDate}</span>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => handleCopyClick()}
                      >
                        <img className="me-2" src="images/Default/link-icon.svg" alt="link-icon" />
                        Copy Link
                      </button>
                    </div>
                  </div>
                  {/*buttons*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCopied && (
        <div className="notification-bar">
          <span className="check-icon">✓</span>
          Copied conversation URL to Clipboard!
        </div>
      )}
      </div>
      {/* </div> */}

    </>
  );
}


// <div className="col-md-12 px-3 pb-3 custom-border-bottom">
// <ul className="nav nav-pills">
  
// </ul>
// </div>