import { Urls, UrlsAi } from "../api/Api-Constants";
import { client } from "./Client";


export const CostCalculationNav = async (body = {}) => {
  let result = await client(UrlsAi.CostCalculationNav, body, "POST");
  return result.data;
};



export const getInputTypeAndTechStack = async (body = {}) => {
  // console.log(body,"LLL");
  
  let result = await client(UrlsAi.getInputTypeAndTechStack, body, "post");
  return result;
};

export const securityKeyCheck = async (body = {}) => {
  let result = await client(UrlsAi.securityKeyCheck, body, "post");
  return result.data;
};

export const errorLogs = async (body = {}) => {
  let result = await client(UrlsAi.insertErrorLogs, body, "post");
  return result.data;
};

// export const fetchPrompt = async (body = {}) => {
//   let result = await client(UrlsAi.getPrompt, body, "post");
//   return result;
// };

// export const fetchChat = async (body = {}) => {
//   let result = await client(UrlsAi.getChat, body, "post");
//   return result.data;
// };

export const fetchNotification = async (body = {}) => {
  let result = await client(UrlsAi.getNotifications, body, "post");
  return result.data;
};

export const readNotification = async (body = {}) => {
  let result = await client(UrlsAi.updateNotification, body, "post");
  return result.data;
};

export const setConnectionID = async (body = {}) => {
  let result = await client(UrlsAi.insertConnectionID, body, "post");
  return result.data;
};

// export const insertMessage = async (body = {}) => {
//   let result = await client(UrlsAi.postMessage, body, "post");
//   return result.data;
// };

export const upsertNotification = async (body = {}) => {
  let result = await client(UrlsAi.updateNotification, body, "post");
  return result.data;
};
export const talkToAI = async (body = {}) => {
  let result = await client(UrlsAi.talkToAI, body, "post");
  return result;
};



export const deleteConnection = async (body = {}) => {
  let result = await client(UrlsAi.deleteConnectionID, body, "post");
  return result.data;
};
// export async function getChatHistory(body = {}) {
//   try {
//     //(body, "SSSSSSSSs");
//     let result = await client(UrlsAi.getChatHistory, body, "POST");
//     //(result, "PPPPPPPP");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

export async function getTeamHistory(body = {}) {

  try {
    let result = await client(UrlsAi.getTeamHistory, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function validateD2Diagram(code) {
  try {
    const response = await client(UrlsAi.d2Service, { code }, "post");
    return response.data;
  } catch (error) {
    console.error('Error validating diagram:', error);
    throw error;
  }
}

export async function generateD2Diagram(finalCode) {
  try {
    const response = await client(UrlsAi.krokiService, finalCode, "post");
    return response.data;
  } catch (error) {
    console.error('Error generating diagram:', error);
    throw error;
  }
}

export async function updateApproval(body = {}) {
  try {
    let result = await client(UrlsAi.updateApproval, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getApprovalRequestData(body = {}) {

  try {
    let result = await client(UrlsAi.getApprovalRequestData, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}


export async function getPromptFieldJson(body = {}) {

  try {

    let result = await client(UrlsAi.getPromptFieldJson, body, "post");

    return result;
  }
  catch (error) {
    //(error)
  }
}



// export async function insertPrompt(body = {}) {

//   try {


//     let result = await client(UrlsAi.insertPrompt, body, "post");

//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }


export async function deletePrompt(body = {}) {

  try {

    let result = await client(UrlsAi.deletePrompt, body, "post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getBindParams(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getBindParams, body, "get");
    // let result = await client("http://127.0.0.1:8080/getBindParams", body, "get");
    return result;
  }
  catch (error) {
    //(error)
  }
}

// export async function getBindParams(body = {}) {

//   try {
//     //("in");
//     let result = await client("http://localhost:3001/getBindParams", body, "get");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

// export async function insertModelConfig(body = {}) {

//   try {
//     //("in");
//     let result = await client(UrlsAi.insertModelConfiguration, body, "Post");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

export async function getModelConfig(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getModelConfiguration, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getAllModelConfig(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getAllModelConfig, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getPractice(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getPractice, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function deleteModelConfiguration(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.deleteModelConfiguration, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}



export async function kbInsert(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.kbUpload, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}


export async function serpAPI(body = {}) {

  try {
    //(body, "in");
    let result = await client(UrlsAi.serpAPI, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}

// export async function KBRetriever(body = {}) {

//   try {
//     //(body, "in");
//     let result = await client(UrlsAi.kbRetriever, body, "POST");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

// export async function pseudocodeCSV(body = {}) {

//   try {
//     //(body, "in");
//     let result = await client(Urls.pseudocodeCSV, body, "POST");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

export async function LoadBalancer(body = {}) {

  try {
    //(body, "in");
    let result = await client(UrlsAi.loadBalancer, body, "POST");
    return result;
  }
  catch (error) {
    //(error)
  }
}


// PS_DB_09
// Fetching active users and total users 
// The visitors graph details based on monthly , weekly and yearly basis is fetched
export async function fetchActiveUsers(requestBody = {}) {
  try {
    //("entered active users fetch")
    let result = await client(UrlsAi.activeuser, requestBody, "POST")
    return result;

  }
  catch (error) {
    //(error)
  }
}

// PS_DB_38 , PS_DB_176
//Function to get the data for fixed response section which is at the bottom of the dash board
export async function fetchFixedResponseData(requestBody = {}) {
  try {
    //("entered active users fetch")
    let result = await client(UrlsAi.fixedResponseFetch, requestBody, "POST")
    return result;

  }
  catch (error) {
    //(error)
  }
}

// PS_DB_145
// This function fetches the prompt info from backend
export async function fetchMostOrLeastUsedPrompt(requestBody = {}) {
  try {
    //("entered active users fetch")
    let result = await client(UrlsAi.promptUsageFetch, requestBody, "POST")
    return result;

  }
  catch (error) {
    //(error)
  }
}

// PS_DB_60
// The function which hits the api and gets the graph data is declared here (Practice data)
export async function fetchAsamiUsageData(requestBody = {}) {
  try {
    //("Asami USage")
    let result = await client(UrlsAi.getAsamiUsage, requestBody, "POST")
    // let result = await client("http://127.0.0.1:8030/getAsamiUsage", requestBody, "POST")
    //(result)
    return result;

  }
  catch (error) {
    //(error)
  }
}

export async function insertChatID(body = {}) {
  try {
    //(body,"in");
    let result = await client(UrlsAi.insertChatinProject, body, "POST")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}

// PS_DB_95 , PS_DB_102 ,PS_DB_112
// The function which hits the cost analysis api and gets the called
export async function fetchCostAnalysisData(requestBody = {}) {
  try {
    let result = await client(UrlsAi.costAnalysis, requestBody, "POST")
    // let result = await client("http://127.0.0.1:8030/costAnalysis", requestBody, "POST")
    //(result, "cost data ")
    return result
  }
  catch (error) {
    //(error)
  }
}


export async function fetchProjectCost(body = {}) {

  try {
    // let result = await client(UrlsAi.costAnalysis, body, "POST"); 
    let result = await client(UrlsAi.costAnalysis, body, "POST")
    //("MervinJoseph is here")
    //(result,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}
export async function fetchPracticeCost(body = {}) {

  try {
    // let result = await client(UrlsAi.costAnalysis, body, "POST"); 
    let result = await client(UrlsAi.costAnalysis, body, "POST")
    //("MervinJoseph is here")
    //(result,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}



export async function fetchIndividualCost(body = {}) {

  try {
    // let result = await client(UrlsAi.costAnalysis, body, "POST"); 
    let result = await client(UrlsAi.costAnalysis, body, "POST")
    //("MervinJoseph is here")
    //(result,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


export async function pushToTFSFunc(body = {}) {

  try {
    //(body,"in");
    let result = await client(UrlsAi.pushToTFS, body, "POST")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


// 
export async function fetchFeedbackData(body = {}) {

  try {
    let result = await client(UrlsAi.getBindParams, body, "GET"); 
    // let response = await client("http://127.0.0.1:8080/getBindParams", body, "get");
    return result.data;
  }
  catch (error) {
    //(error)
  }
}



export async function getImageDocResponse(body = {}) {

  try {
    let result = await client(UrlsAi.getImageDocResposne, body, "POST"); 
    return result.data;
  }
  catch (error) {
    //(error)
  }
}
export async function getBlobURL(body = {}) {

  try {
    let result = await client(UrlsAi.getBlobURL, body, "POST"); 
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


export async function getProjectConfig(body = {} , cancelToken) {
  // //
  try {
    //("in");
    // let result = await client(UrlsAi.fetchProject, body, "POST");
    let result = await client(UrlsAi.fetchProjectConfig, body, "POST", cancelToken);
    //("Printing results for project grid", result)
    return result;
  }
  catch (error) {
    //(error)
  }
}


export async function getIssuesConfig(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getIssuesConfig, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getTaskConfig(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getTaskConfig, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getProject(body = {}) {
  // //
  try {
    //("in");
    // let result = await client(UrlsAi.fetchProject, body, "POST");
    let result = await client(UrlsAi.allProjects, body, "POST");
    // let result = await client(UrlsAi.fetchProject, body, "POST");
    //("Printing results for project grid", result)
    return result;
  }
  catch (error) {
    //(error)
  }
}
 
 
export async function getIssues(body = {}) {
 
  try {
    //("in");
    let result = await client(UrlsAi.fetchIssues, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}
 
export async function getTask(body = {}) {
 
  try {
    //("in");
    let result = await client(UrlsAi.fetchTask, body, "Post");
    // let result = await client(UrlsAi.getTask, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function similarityCheck(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.similarityCheck, body, "Post");
    // let result = await client("http://127.0.0.1:8007/similaritySearch", body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}


export async function insertModelConfig(body ={}){
  try {
    //(body,"in");
    let result = await client(UrlsAi.insertModelConfig, body, "POST");
    //("MervinJoseph is here")
    //(result?.data?.data,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}




export async function restoreManagePrompt(body = {}) {
     
  try {
    //(body,"in");
    let result = await client(UrlsAi.insertPrompt, body, "POST");
    //("MervinJoseph is here")
    //(result?.data?.data,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}

// export async function insertPrompt(body = {}) {
//   //("---------------------->",body)
//   // body.promptFields
 
//   //("errormightoccurhere")
//   try {
   
 
//     // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
//     let result = await client("http://127.0.0.1:6500/insertPrompt", body, "post");
 
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }

// export async function promptGen(body = {}) {
//   //("---------------------->",body)
//   // body.promptFields
 
//   //("errormightoccurhere")
//   try {
   
 
//     // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
//     let result = await client("http://127.0.0.1:8000/promptGen", body, "post");
 
//     return result.data;
//   }
//   catch (error) {
//     //(error)
//   }
// }


// export async function systemMessageGen(body = {}) {
//   //("---------------------->",body)
//   // body.promptFields
 
//   //("errormightoccurhere")
//   try {
   
 
//     // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
//     let result = await client("http://127.0.0.1:8080/systemPrompt", body, "post");
 
//     return result.data;
//   }
//   catch (error) {
//     //(error)
//   }
// }
 
export async function getPromptHistory(body = {}) {
 
  try {
    //(body,"in");
    let result = await client(UrlsAi.getPromptHistory, body, "POST");
    // let result = await client("http://127.0.0.1:6500/getPromptHistory", body, "POST");
    //("MervinJoseph is here")
    //(result?.data?.data,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}

export const fetchPrompt = async (body = {}) => {
  let result = await client(UrlsAi.fetchPromptData, body, "post");
  //("KAAAAAAAAAAAAAAAAAAAAa",result)
  return result;
};

export async function fetchPromptHistory(body = {}) {

  try {
    //(body,"in");
    // let result = await client("http://127.0.0.1:6500/getPromptHistory", body, "POST");
    let result = await client(UrlsAi.getPromptHistory, body, "POST");
    //("MervinJoseph is here")
    //(result?.data?.data,"out")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


export const insertGetFeedBack = async (Reqbody={})=>{
  let response=await client(UrlsAi.insertGetFeedBack ,Reqbody,"POST");
  return response.data;
 }


 export const fetchPromptEmj = async (Reqbody={})=>{
  let response=await client(UrlsAi.getBindParams,Reqbody,"GET");
  // let response = await client("http://127.0.0.1:8080/getBindParams", Reqbody, "get");
  //  let response ={data:{PromptData:[{"id":"1","prompt":"oska"},{"id":"21","prompt":"gm"}],ReactionData:[{"id":"23","Reaction":"happy"}]}}
  //(response)
  return response.data;
  }






export async function tfsPush(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.tfsPush, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getProjectForPrompt(body = {}) {
 
  try {
    // let result = await client(UrlsAi.getBindParams, body, "GET");
    let result = await client(UrlsAi.allProjects, body, "POST");
    return result.data;
  }
  catch (error) {
    //(error)
  }
}





export async function getStory(body = {}) {

  try {
    //("in");
    let result = await client(UrlsAi.getStory, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function kgBuildService(body={}){
  try {
    //("in");
    let result = await client(UrlsAi.functionalDescription, body, "Post");
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function ExistData(body = {}) {

  try {
    //(body,"in");
    let result = await client(UrlsAi.configRoute,body,"POST");
    //(result,"Hellotee")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


export async function tfsconfigForm(body={}){
  try {
    //(body,"in");
    let result = await client(UrlsAi.postTfsConfig,body ,"POST");
    //(result,"sapofmsapofpsa")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}


export async function saveConfiguration(body={}){
  try {
    //(body,"in");
    let result = await client(UrlsAi.configureOrUpdate,body ,"POST");
    //(result,"sapofmsapofpsa")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}




export async function fetchReposAndBranch(body={}){
  try {
    //(body,"in");
    let result = await client(UrlsAi.getRepoandBranch,body ,"POST");
    //(result,"sapofmsapofpsa")
    return result.data;
  }
  catch (error) {
    //(error)
  }
}



export async function kgCheck(body={}){
  try {
    //("in");
    let result = await client("http://127.0.0.1:8040/dummy2", body, "Post");
    return result.data;
  }
  catch (error){
    //(error)
  }
}





// export async function kbInsert(body = {}) {

//   try {
//     //(body,"in");
//     let result = await client("http://localhost:7071/api/kbUpload", body, "POST");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }


// export async function KBRetriever(body = {}) {

//   try {
//     //(body,"in");
//     let result = await client("http://localhost:3002/KBRetriever", body, "POST");
//     return result;
//   }
//   catch (error) {
//     //(error)
//   }
// }
export async function insertPrompt(body = {}) {
  //("---------------------->",body)
  // body.promptFields
 
  //("errormightoccurhere")
  try {
   
 
    // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
    let result = await client(UrlsAi.insertPrompt, body, "post");
 
    return result;
  }
  catch (error) {
    //(error)
  }
}

// export const fetchPrompt = async (body = {}) => {
//    
//   // let result = await client("http://51.8.201.226/get_prompt/get_prompt", body, "post");
//   // let result = await client(UrlsAi.get_prompt, body, "post");
//   let result = await client("http://127.0.0.1:6500/get_prompt", body, "post");
//   //("KAAAAAAAAAAAAAAAAAAAAa",result)
//   return result;
// };

export async function promptGen(body = {}) {
  //("---------------------->",body)
  // body.promptFields
 
  //("errormightoccurhere")
  try {
   
 
    // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
    let result = await client(UrlsAi.promptGen, body, "post");
 
    return result.data;
  }
  catch (error) {
    //(error)
  }
}

export async function systemMessageGen(body = {}) {
  //("---------------------->",body)
  // body.promptFields
 
  //("errormightoccurhere")
  try {
   
 
    // let result = await client("http://51.8.201.226/insertPrompt/insertPrompt", body, "post");
    let result = await client(UrlsAi.systemMessageGen, body, "post");
 
    return result.data;
  }
  catch (error) {
    //(error)
  }
}



// kathir
export async function KBRetriever(body = {}) {

  try {
    //(body,"in");
    let result = await client(UrlsAi.kbRetriever, body, "POST"); 
    return result;
  }
  catch (error) {
    //(error)
  }
}

export async function getChatHistory(body = {}) {
  
    //(body,"SSSSSSSSs");
    // let result = await client("https://npapi.asami.ai/getChatHistory/getChatHistory", body, "POST");
    let result = await client(UrlsAi.getChatHistory, body, "POST");
    //(result,"PPPPPPPP");
    return result;
  }


  export const insertMessage = async (body = {}) => {
  //  let result = await client("http://127.0.0.1:8080/postMessage", body, "POST");
  let result = await client(UrlsAi.postMessage, body, "POST");
  return result.data;
};

export const fetchChat = async (body = {}) => {
  let result = await client(UrlsAi.getChat, body, "POST");
  // let result = await client("http://127.0.0.1:8070/getChat", body, "post");
  return result.data;
};
export async function DownloadAsExcel(body = {}) {
 
  try {
    //(body,"in");
    // let result = await client("https://npapi.asami.ai/kbRetriever/kbRetriever", body, "POST");
    let result = await client(UrlsAi.downloadExcel, body, "POST");
    // console.log(result, "excelexcelexcel")
    return result;
  }
  catch (error) {
    //(error)
  }
}
export const generateNewPrompt = async (body={}) => {
   
  try{
    let result = await client(UrlsAi.inputEnhancer, body , "POST")
    return result.data
  }
  catch(error){
    //(error)
  }
}