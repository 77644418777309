import React, { useEffect, useState, useContext } from "react";
import toggleContext from "./Context";
import { getBlobURL } from "../AI Service/Api";
import styled from 'styled-components';

export function MediaUpload({ record }) {
  const [recordData, setRecordData] = useState("");
  const [hideError, setHideError] = useState(true);
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [imgDocFile, setImgDocFile] = useState("");
  const [showSuccessEffect, setShowSuccessEffect] = useState(false)
  const toggle = useContext(toggleContext);

  useEffect(() => {
    if (record?.record?.inputValue) {
      setRecordData(record?.record);
      setUploadedFileUrl(record?.record?.inputValue);
    }
  }, [record]);
  // console.log(recordData,"IIIII");
  
  const SuccessHighlight = styled.div`
  padding: 10px;
  border-radius: 5px;
  animation: highlightSuccess 2s ease-in-out;

  @keyframes highlightSuccess {
    0% {
      background-color: rgba(76, 175, 80, 0.1);
      box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
    }
    50% {
      background-color: rgba(76, 175, 80, 0.2);
      box-shadow: 0 0 0 6px rgba(76, 175, 80, 0.2);
    }
    100% {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;
  
  // console.log(recordData,"recorddata")
  const uploadMedia = async (e) => {
    setIsUploading(true);
    setAlertMessage("");
    setAlertType("");

    let file = e.target.files[0];
    if (!file) {
      setIsUploading(false);
      return;
    }

    let fileName = file.name;
    setImgDocFile(fileName);

    const supportedFormats = [
      'image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf', 'text/csv', 'text/plain', 'video/mp4'
    ];

    if (file.size > 30 * 1024 * 1024) {
      setAlertMessage('File size exceeds 30MB');
      setAlertType('error');
      setIsUploading(false);
      return;
    }

    if (!supportedFormats.includes(file.type)) {
      setAlertMessage('Unsupported file format');
      setAlertType('error');
      setIsUploading(false);
      return;
    }

    try {
      let data;
      if (file.type === "video/mp4") {
        const formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('video', file);
        data = formData;
      } else {
        let base = await handleImageDocChange(file);
        data = {
          file: base,
          fileName: file.name,
          fileExtension: file.type,
        };
      }

      const results = await getBlobURL(data);
      
      if (results.status_code === 200) {
        setUploadedFileUrl(results.BlobURL);
        setUploadSuccess(true);
        setAlertType("success");
        // setAlertMessage("File uploaded successfully");
        setShowSuccessEffect(true)
        // Call handleFieldValues to update the parent component's state
        record.handleFieldValues(
          record.record.labelName,
          results.BlobURL,
          record.record.inputValueOrder,
          record.record.required
        );
        }
        setTimeout(() => setShowSuccessEffect(false), 2000);
    } catch (error) {
      console.error('Upload error:', error);
      setAlertType("error");
      setAlertMessage("Upload failed");
      setUploadSuccess(false);
    } finally {
      setIsUploading(false);
    }

    setTimeout(() => setAlertMessage(''), 3000);
  };

  const handleUploadResult = (results) => {
    if (results.status_code === 200) {
      setUploadedFileUrl(results.BlobURL);
      setUploadSuccess(true);
      setAlertType("success");
      setAlertMessage("File uploaded successfully");
      
      if (record && recordData) {
        record.handleFieldValues(
          recordData.labelName,
          results.BlobURL,
          recordData.inputValueOrder,
          recordData.required
        );
      }
      
    } else {
      setAlertType("error");
      setAlertMessage("Upload failed");
      setUploadSuccess(false);
    }
  };

  const handleImageDocChange = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const renderError = () => {
    if (recordData && record.validationErrors) {
      const err = recordData.inputValueOrder;
      return hideError ? (
        <span className="mandatory-star font-14 font-medium mb-0" style={{ color: "red" }}>
          {record.validationErrors[err]}
        </span>
      ) : null;
    }
    return null;
  };

  return (
    <>
      {record?.record?.labelName && (
        <label className="text-color-14 font-15 font-semibold label-color" htmlFor="Code-similar">
          {record?.record?.labelName}
          {recordData?.required === "true" && <span className="color-red">*</span>}
        </label>
      )}

      <div className="col-md-12">
        <div className="upload-btn-wrapper">
          <button
            type="button"
            className="upload-btn font-13 font-medium px-4 py-2 bg-color text-color-5"
            disabled={toggle || isUploading}
          >
            <img src="images/ai-images/upload-icon.svg" alt="upload icon" className="me-2" />
            {isUploading ? "Uploading..." : (recordData?.placeHolder || "Upload Media")}
          </button>

          <input
            disabled={toggle || isUploading}
            className="custom-cursor"
            type="file"
            name="myfile"
            id={recordData?.labelName}
            onChange={uploadMedia}
          />

          {alertMessage && (
            <p className={`alert-message ${alertType}`}>
              {alertMessage}
            </p>
          )}
        </div>

        {uploadedFileUrl && (
         <SuccessHighlight className={showSuccessEffect ? 'active' : ''}>
         <div className="uploaded-file-container">
           <label className="uploaded-file-label font-15 font-semibold label-color">
             Uploaded File: {imgDocFile}
           </label>
         </div>
       </SuccessHighlight>
        )}
      </div>

      {renderError()}
    </>
  );
}

