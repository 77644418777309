// ConfigurationButton.js
import React, { useState, useContext, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { getBindParams } from '../AI Service/Api';
import { ConfigContext } from './ConfigContext';
import { mode } from 'crypto-js';
import CustomDropdown from './CustomDropDown';

const ConfigurationButton = ({ isImage }) => {
  const buttonStyle = {
    // position: 'fixed',
    // top: '10px',
    // right: '15px',
    // zIndex: 1000,
    // width: '48px',
    // height: '48px'
  };

  const { config, setConfig } = useContext(ConfigContext);
  //(config, setConfig, "poiuytrewq")


  const defaultMaxResponse = 4096;
  const defaultTemperature = 0.7;
  const defaultTopP = 0.2;
  const defaultFrequencyPenalty = 0.1;
  const defaultPresencePenalty = 0.1;
  const [defaultModel, setDefaultModel] = useState()


  const [showConfigPopUp, setShowConfigPopUp] = useState(false);
  const [maxResponse, setMaxResponse] = useState(config.maxResponse);
  const [temperature, setTemperature] = useState(config.temperature);
  const [topP, setTopP] = useState(config.topP);
  const [frequencyPenalty, setFrequencyPenalty] = useState(config.frequencyPenalty);
  const [presencePenalty, setPresencePenalty] = useState(config.presencePenalty);
  const [modelData, setModelData] = useState("");
  const [selectedModel, setSelectedModel] = useState("")
  // const [maxResponse, setMaxResponse] = useState(config.maxResponse);
  const handlePopUp = () => {
    setShowConfigPopUp(prev => !prev);
  };

  const handleReset = () => {
    setMaxResponse(defaultMaxResponse);
    setTemperature(defaultTemperature);
    setTopP(defaultTopP);
    setFrequencyPenalty(defaultFrequencyPenalty);
    setPresencePenalty(defaultPresencePenalty);
    setSelectedModel("")

  };
  const handleCancel = () => {
    setMaxResponse(defaultMaxResponse);
    setTemperature(defaultTemperature);
    setTopP(defaultTopP);
    setFrequencyPenalty(defaultFrequencyPenalty);
    setPresencePenalty(defaultPresencePenalty);
    setSelectedModel("")
    setShowConfigPopUp(false)

  };


  const handleApply = () => {
    let config = "HyperConfig"
    setConfig({
      maxResponse,
      temperature,
      topP,
      frequencyPenalty,
      presencePenalty,
      selectedModel
    });
    setShowConfigPopUp(false);
  };

  useEffect(() => {
    fetchBindParams();
  }, [])

  const fetchBindParams = async () => {
    //;
    try {
      const response = await getBindParams({});
      //(response.data.modelConfiguration, "(((");

      setModelData(response.data.modelConfiguration)
    } catch (error) {
      //(error);
    }
  };

  const [description, setDescription] = useState('');
  const [isOpenCustom, setIsOpenCustom] = useState(false);
  const [selectedOptionVal,setSelectedOptionVal]=useState(false)
  const handleOptionClick = (option) => {
    setSelectedOptionVal(option);
    setIsOpenCustom(false);
    setDescription('');
  };

  
  const popop = {
    zIndex: 1000,
    display: showConfigPopUp ? "block" : "none",
    minWidth: 550
  };

  const handleSliderChange = (setter) => (value) => {
    setter(value);
  };

  const handleModelChange = (event) => {
    //(event.target.value)
    setSelectedModel(event.modelConfigurationID);
    // const selectedModelData = modelData.find(model => model.modelConfigurationID === selectedModel);
    //(selectedModelData,"sample")
    // setIsBedrockOrClaude(selectedModelData && (selectedModelData.modelName.toLowerCase().includes('bedrock') || selectedModelData.modelName.toLowerCase().includes('claude sonnet')));
  };
  const sliderStyles = ``;

  const isBedrockOrClaudeM = () => {
    if (!selectedModel || !modelData) return false;
    const selectedModelData = modelData.find(model => model.modelConfigurationID === selectedModel);
    return selectedModelData && (
      selectedModelData.modelName.toLowerCase().includes('bedrock') ||
      selectedModelData.modelName.toLowerCase().includes('claude')
    );
  };

  function bindModel(modelData) {
    //(modelData, "+++");
    if (modelData && Array.isArray(modelData)) {
      //
      // const azureGpt3Model = modelData.find(model => model.modelName === "Azure gpt 3.5 preview");
      //(azureGpt3Model,"______");
      // // Extract modelName and modelConfigurationID
      //  modelName = azureGpt3Model ? azureGpt3Model.modelName : null;
      //  modelConfigurationID = azureGpt3Model ? azureGpt3Model.modelConfigurationID : null;
      //   setselectedModel()
      // let tempModeldata = modelData.filter(value => value.displayName === 'Claude 3 Sonnet V1')
      // setDefaultModel(tempModeldata)
      //(defaultModel,"consoling default model")
      //(selectedModel, "SDSFDSFSFDS")
      //  selectedModel()
      return modelData.filter(value => value.modelName !== "Test" && value.modelName !== "Assistants API" && value.modelName !== "GPT-vision-Asami" && value.modelName !== "TestModel" && value.modelName !== "").map((value, index) => (
        <option value={value.modelConfigurationID} id="modelConfiguaration"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          data-bs-original-title={value.modelDescription}
          title={value.modelDescription}
          key={index}  >
          {value.displayName}
        </option>
      ));
    }
    return null;
  }

  return (
    <div className="btn-group" id="chat-configure-parent">
      <button className="btn btn-light border rounded-2 configurations border-color-10 bg-color-10 custom-dropdown-li py-2" style={buttonStyle} onClick={handlePopUp}>
        <img className='config-icon' src="images/settings.svg" alt="setting" />
      </button>

      <div className="dropdown-menu rounded-3 custom-dpd cust-right p-4" id='chat-configure-child' style={popop}>
        <style>{sliderStyles}</style>
        <div className="row row-gap-32px">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <h4 className="font-16 text-color-1 font-medium">Configurations</h4>
              <img src="images\Close-icon.svg" alt="close" className="cursor-pointer filter-icons" width="16" onClick={() => handleCancel()} />
            </div>
          </div>
          {/* Fillers */}
          <div className="col-md-6">
            <div className="dropdown">
              <label for="model" class="form-label label-color font-15 font-semibold text-color-2 mb-2">Choose Model</label>
              <CustomDropdown modelData={modelData} handleModelChange={handleModelChange} isImage={isImage} handleOptionClick={handleOptionClick} isOpen={isOpenCustom} setIsOpen={setIsOpenCustom} description={description}setDescription={setDescription} selectedOption={selectedOptionVal} setSelectedOption={setSelectedOptionVal}></CustomDropdown>

            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="mx-rsponse" className="form-label label-color font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Limits the maximum size or number of items in the API response">Max Response</label>
            <div className="d-flex flex-row align-items-center gap-3">

              <ReactSlider
                className="slider"
                thumbClassName="thumb"
                trackClassName="track"
                value={maxResponse}
                onChange={handleSliderChange(setMaxResponse)}
                ariaLabelledBy="mx-rsponse"
                step={1}
                min={1}
                max={4096}
              />
              <input
                type="text"
                className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                id="mx-rsponse"
                value={maxResponse}
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (newValue === '') {
                    setMaxResponse('');
                  } else {
                    const numericValue = parseInt(newValue, 10);
                    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 4000) {
                      setMaxResponse(numericValue);
                    }
                  }
                }}
                onBlur={() => {
                  const finalValue = Math.min(4000, Math.max(0, parseInt(maxResponse, 10) || 0));
                  setMaxResponse(finalValue);
                }}
                style={{ width: '75px' }}
              />

            </div>
          </div>

          <div className="col-md-6">
          <label htmlFor="Temperature_" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config margin-left" data-title="Controls the randomness or creativity of the generated output (higher values for more randomness).">Temperature</label>
            <div className="d-flex flex-row align-items-center gap-3">
              <ReactSlider
                className="slider"
                thumbClassName="thumb"
                trackClassName="track"
                value={temperature}
                onChange={handleSliderChange(setTemperature)}
                ariaLabelledBy="Temperature_"
                step={0.01}
                min={0}
                max={1}
                style={{ width: 'calc(100% - 75px)' }} // Adjust the width as needed
              />
              <input
                type="text"
                className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3 "
                id="Temperature_"
                value={temperature}
                // readOnly
                onChange={(event) => {
                  let newValue = event.target.value;
                  if (newValue === '' || newValue === '.') {
                    setTemperature(newValue);
                  } else {
                    const numValue = parseFloat(newValue);
                    if (!isNaN(numValue) && numValue >= 0 && numValue <= 1) {
                      setTemperature(newValue);
                    }
                  }
                }}
                onBlur={() => {
                  let finalValue = parseFloat(presencePenalty);
                  if (isNaN(finalValue) || finalValue < 0) {
                    finalValue = 0;
                  } else if (finalValue > 1) {
                    finalValue = 1;
                  }
                  setTemperature(finalValue);
                }}

                style={{ width: '75px' }} // Adjust the width as needed
              />
            </div>
          </div>
          {!isBedrockOrClaudeM() && (
            <div className="col-md-6">
              <label htmlFor="Top_P" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Top-p sampling picks words until their combined chance meets a certain limit, ensuring variety and sense">Top P</label>
              <div className="d-flex flex-row align-items-center gap-3">
                <ReactSlider
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={topP}
                  onChange={handleSliderChange(setTopP)}
                  ariaLabelledBy="Top_P"
                  step={0.01}
                  min={0}
                  max={1}
                  style={{ width: 'calc(100% - 75px)' }} // Adjust the width as needed
                />
                <input
                  type="text"
                  className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                  id="Top_P"
                  value={topP}
                  // readOnly
                  onChange={(event) => {
                    let newValue = event.target.value;
                    if (newValue === '' || newValue === '.') {
                      setTopP(newValue);
                    } else {
                      const numValue = parseFloat(newValue);
                      if (!isNaN(numValue) && numValue >= 0 && numValue <= 1) {
                        setTopP(newValue);
                      }
                    }
                  }}
                  onBlur={() => {
                    let finalValue = parseFloat(presencePenalty);
                    if (isNaN(finalValue) || finalValue < 0) {
                      finalValue = 0;
                    } else if (finalValue > 1) {
                      finalValue = 1;
                    }
                    setTopP(finalValue);
                  }}

                  style={{ width: '75px' }} // Adjust the width as needed
                />
              </div>
            </div>)}
          {!isBedrockOrClaudeM() && (
            <div className="col-md-6">
              <label htmlFor="Frequency_Penalty" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Discourages the model from repeating tokens to promote more varied output.">Frequency Penalty</label>
              <div className="d-flex flex-row align-items-center gap-3">
                <ReactSlider
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={frequencyPenalty}
                  onChange={handleSliderChange(setFrequencyPenalty)}
                  ariaLabelledBy="Frequency_Penalty"
                  step={0.01}
                  min={0}
                  max={1}
                  style={{ width: 'calc(100% - 75px)' }} // Adjust the width as needed
                />
                <input
                  type="text"
                  className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                  id="Frequency_Penalty"
                  value={frequencyPenalty}
                  // readOnly
                  onChange={(event) => {
                    let newValue = event.target.value;
                    if (newValue === '' || newValue === '.') {
                      setFrequencyPenalty(newValue);
                    } else {
                      const numValue = parseFloat(newValue);
                      if (!isNaN(numValue) && numValue >= 0 && numValue <= 1) {
                        setFrequencyPenalty(newValue);
                      }
                    }
                  }}
                  onBlur={() => {
                    let finalValue = parseFloat(presencePenalty);
                    if (isNaN(finalValue) || finalValue < 0) {
                      finalValue = 0;
                    } else if (finalValue > 1) {
                      finalValue = 1;
                    }
                    setFrequencyPenalty(finalValue);
                  }}

                  style={{ width: '75px' }} // Adjust the width as needed
                />
              </div>
            </div>)}
          {!isBedrockOrClaudeM() && (
            <div className="col-md-6">
              <label htmlFor="Presence_Penalty" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Encourages the model to introduce new topics by penalizing repeated tokens.">Presence Penalty</label>
              <div className="d-flex flex-row align-items-center gap-3">
                <ReactSlider
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={presencePenalty}
                  onChange={handleSliderChange(setPresencePenalty)}
                  ariaLabelledBy="Presence_Penalty"
                  step={0.01}
                  min={0}
                  max={1}
                  style={{ width: 'calc(100% - 75px)' }} // Adjust the width as needed
                />
                <input
                  type="text"
                  className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                  id="Presence_Penalty"
                  value={presencePenalty}
                  onChange={(event) => {
                    let newValue = event.target.value;
                    if (newValue === '' || newValue === '.') {
                      setPresencePenalty(newValue);
                    } else {
                      const numValue = parseFloat(newValue);
                      if (!isNaN(numValue) && numValue >= 0 && numValue <= 1) {
                        setPresencePenalty(newValue);
                      }
                    }
                  }}
                  onBlur={() => {
                    let finalValue = parseFloat(presencePenalty);
                    if (isNaN(finalValue) || finalValue < 0) {
                      finalValue = 0;
                    } else if (finalValue > 1) {
                      finalValue = 1;
                    }
                    setPresencePenalty(finalValue);
                  }}
                  style={{ width: '75px' }} // Adjust the width as needed
                />
              </div>
            </div>)}

          <div className="col-md-12">
            <div className="d-flex justify-content-end align-items-center">
              <button type="button" className="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-bold" onClick={handleReset}>Reset</button>
              <button type="button" className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" onClick={handleApply}>Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationButton;