import React, { useState, useRef, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PlantUML from "react-plantuml";
import Editor from "@monaco-editor/react";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PlantUMLPreview = ({ navOpen }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plantUMLCode1 = sessionStorage.getItem('plantUMLCode');
  const lines = plantUMLCode1.split("\n");
  let plantUMLCode = [];

  if (lines[0].startsWith("@startuml")) {
    plantUMLCode = lines;
  } else {
    plantUMLCode = lines.slice(1).join("\n");
  }

  const [code, setCode] = useState(plantUMLCode);
  const [zoom, setZoom] = useState(1);
  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState(false);
  const [chatId, setChatId] = useState(null);
  const ref = useRef("");
  const [copiedImage, setCopiedImage] = useState(false);
  const [isDiagramFullscreen, setIsDiagramFullscreen] = useState(false);
  const [isEditorFullscreen, setIsEditorFullscreen] = useState(false);

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("plantUMLData");
    if (storedData) {
      const { chatId } = JSON.parse(storedData);
      setChatId(chatId);
    }
  }, []);

  const ensureHttps = (url) => {
    if (typeof url !== "string") return url;
    if (url.startsWith("https://")) return url;
    if (url.startsWith("http://"))
      return url.replace(/^http:\/\//i, "https://");
    if (url.startsWith("//")) return `https:${url}`;
    return `https://${url}`;
  };

  const handleZoomOut = () => {
    if (zoom > 0.2) {
      setZoom(zoom - 0.1);
    }
  };

  const handlecopyImage = () => {
    showResult();
    setCopiedImage(!copiedImage);
  };

  const showResult = async () => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const writeToCanvas = (src) =>
      new Promise((res, rej) => {
        const secureUrl = ensureHttps(src);
        fetch(secureUrl)
          .then((res) => res.blob())
          .then((imgBlob) => {
            const imgURL = URL.createObjectURL(imgBlob);
            img.src = imgURL;
          })
          .then(() => {
            img.onload = function () {
              canvas.width = img.naturalWidth;
              canvas.height = img.naturalHeight;
              ctx.drawImage(img, 0, 0);
              canvas.toBlob((blob) => {
                res(blob);
              }, "image/png");
            };
          });
      });

    const copyToClipboard = async (src) => {
      const image = await writeToCanvas(src);
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            [image.type]: image,
          }),
        ]);
        setCopied(!copied);
      } catch (e) {
        // console.log(`Copy failed: ${e}`);
      }
    };

    copyToClipboard(ref.current.querySelector("img").src + ".png");
  };

  const handlecopyText = () => {
    setCopiedText(!copiedText);
    return <CopyToClipboard text={code}></CopyToClipboard>;
  };

  const history = useHistory();

  const handleGoBack = () => {
    history.push(`/chat?chatID=${chatId}`);
  };

  const toggleDiagramFullscreen = () => {
    setIsDiagramFullscreen(!isDiagramFullscreen);
  };

  const toggleEditorFullscreen = () => {
    setIsEditorFullscreen(!isEditorFullscreen);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
        <div
          className={
            navOpen === "Close"
              ? `col-11 me-xl-4 me-lg-3`
              : `col-lg-9 me-md-2 cust-me-lg-4 col-11`
          }
        >
          <div className="row">
            <div className="col-md-12">
              <h3 className="font-24 font-semibold text-color-1 mt-3 mb-4">
                <a href="#" onClick={() => handleGoBack()}>
                  <img
                    src="images/back-black-arrow.svg"
                    alt="Back"
                    className="me-3 icon-filter"
                  />
                </a>
                Code Snippet & UML Diagram
              </h3>

              <div
                className="accordion mb-3 rounded-3 overflow-hidden"
                id="accordionExample"
              >
                <div className="accordion-item cust-code-accordion-item bg-transparent rounded-3">
                  <h2
                    className="accordion-header p-3 rounded-top"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button cust-code-accordion-btn rounded-3 text-color-2 font-medium font-16"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      View Instructions
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-2 rounded-bottom">
                      <ul className="ms-4">
                        <li className="font-regular font-14 mb-2 text-color-9">
                          Please validate whether the data's are correct
                        </li>
                        <li className="font-regular font-14 text-color-9">
                          If things are fine just click{" "}
                          <span className="font-bold">'back'</span> then on{" "}
                          <span className="font-bold">'Looks good'</span> and
                          proceed to next steps
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {!isDiagramFullscreen && (
                  <div className={isEditorFullscreen ? "col-md-12" : "col-md-6"}>
                    <div className="position-relative">
                      <Editor
                        defaultLanguage="markdown"
                        className="px-2 py-2 er-snippet code-snippet-area"
                        value={code}
                        onChange={setCode}
                        options={{
                          minimap: { enabled: false },
                        }}
                        height={isEditorFullscreen ? "80vh" : "400px"}
                      />

                      <div className="code-btns">
                        <CopyToClipboard text={`${code}`}>
                          <button
                            type="button"
                            className={
                              !copiedText
                                ? "btn textarea-btn me-3"
                                : "btn textarea-btn copied postion-retlative me-3"
                            }
                            onClick={handlecopyText}
                          >
                            {copiedText ? (
                              <div>
                                <img src="images/copy-icon-btn.svg" alt="Copy" />
                                <img
                                  src="images/tick-icon.svg"
                                  alt="copied"
                                  className="emoji-active-tick"
                                ></img>
                              </div>
                            ) : (
                              <img src="images/copy-icon-btn.svg" alt="Copy" />
                            )}
                          </button>
                        </CopyToClipboard>
                        <button
                          type="button"
                          className={`btn textarea-btn me-3`}
                          onClick={toggleEditorFullscreen}
                        >
                          {isEditorFullscreen ? (
                            <img src="images/minimize-icon.svg" alt="Minimize" />
                          ) : (
                            <img src="images/maximize-icon.svg" alt="Maximize" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className={isDiagramFullscreen ? "col-md-12" : isEditorFullscreen ? "d-none" : "col-md-6"}>
                  <div
                    className="position-relative mb-200 "
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      overflow: "visible",
                    }}
                  >
                    <div
                      className="px-2 py-2 er-snippet code-snippet-area div12 position-relative uml-scroll"
                      ref={ref}
                      style={{
                        height: isDiagramFullscreen ? "80vh" : "400px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        overflow: "auto",
                      }}
                    >
                      <div
                        className="px-2 py-2 er-snippet code-snippet-area div2"
                        style={{
                          transform: `scale(${zoom})`,
                          transformOrigin: "top left",
                          width: "fit-content",
                          height: "fit-content",
                          flexGrow: 1,
                          minWidth: "100%",
                          marginLeft: "-1rem", // Add this line
                        }}
                      >
                        <PlantUML
                          src={code}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>

                      <div
                        className="btn-group cust-btngrp p-1 mt-2 position-fixed"
                        role="group"
                        aria-label="Basic example"
                      >
                        <div>
                          <button
                            type="button"
                            className="d-block btn zoom-btn px-1 border-bottom rounded-0"
                            onClick={handleZoomIn}
                          >
                            <img src="images/plus-icons.svg" alt="Zoom In" />
                          </button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn zoom-btn px-2 rounded-0"
                            onClick={handleZoomOut}
                          >
                            <img src="images/minus-icon.svg" alt="Zoom Out" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="code-btns">
                      <CopyToClipboard text={`${code}`}>
                        <button
                          type="button"
                          className={
                            !copiedImage
                              ? "btn textarea-btn me-3"
                              : "btn textarea-btn copied postion-retlative me-3"
                          }
                          onClick={() => handlecopyImage()}
                        >
                          {copiedImage ? (
                            <div>
                              <img src="images/copy-icon-btn.svg" alt="Copy" />
                              <img
                                src="images/tick-icon.svg"
                                alt="copied"
                                className="emoji-active-tick"
                              ></img>
                            </div>
                          ) : (
                            <img src="images/copy-icon-btn.svg" alt="Copy" />
                          )}
                        </button>
                      </CopyToClipboard>
                      <button
                        type="button"
                        className={`btn textarea-btn me-3`}
                        onClick={toggleDiagramFullscreen}
                      >
                        {isDiagramFullscreen ? (
                          <img src="images/minimize-icon.svg" alt="Minimize" />
                        ) : (
                          <img src="images/maximize-icon.svg" alt="Maximize" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantUMLPreview;
